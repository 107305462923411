import { MutableRefObject, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { isEqual, pick } from 'lodash';
import Debug from 'debug';

import { useMemoDeepEquals } from '../../components/basic';
import { hasMinimalHash } from '../../utils/compute-url';
import { WorkflowId } from '../../framework/workflows';

const debug = Debug('settings:hooks:use-route-ids');

export interface RouteIds {
    workflowId?: WorkflowId;
    command?: string;
    params?: string[];
    minimal?: boolean;

    hash?: string;
}

export function useRouteIds(...scopes: string[]): RouteIds {
    const location = useLocation();

    const paramsCache = useRef<string[]>();

    const memoizedScopes = useMemoDeepEquals(() => {
        return scopes;
    }, [scopes]);

    const routeIds = useMemo(() => {
        let routeIds = parsePathname(location.pathname, paramsCache);

        const hash = location.hash;
        if (hash && hash.charAt(0) === '#') {
            routeIds.hash = hash.substring(1);
            routeIds.minimal = hasMinimalHash(location);
        }

        if (memoizedScopes?.length) {
            routeIds = pick(routeIds, memoizedScopes);
        }

        return routeIds;
    }, [location, memoizedScopes]);

    debug('use-route-ids', 'route ids=', location, '=>', routeIds);

    return routeIds;
}

export function parsePathname(pathname: string, paramsCache?: MutableRefObject<string[] | undefined>): RouteIds {
    const ps = pathname.split('/');

    const routeIds: RouteIds = {};

    for (let i = 2; i < ps.length; i++) {
        const p = ps[i];

        if (!p) {
            continue;
        }

        switch (p) {
            case 'workflows':
            case 'w':
                routeIds.workflowId = ps[++i];
                break;
            default: {
                if (p.length === 1) {
                    // Ignore
                    i++;
                    continue;
                }
                routeIds.command = ps[i++];

                let newParams = ps.slice(i);
                if (paramsCache) {
                    const old = paramsCache.current;
                    if (isEqual(newParams, old)) {
                        newParams = old!;
                    } else {
                        paramsCache.current = newParams;
                    }
                }
                routeIds.params = newParams;
                i = ps.length;
                break;
            }
        }
    }

    return routeIds;
}
