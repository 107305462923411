import { useCallback, useState } from 'react';

import { ArgButton, useClassNames } from 'src/components/basic';
import { ApplicationButton } from './application-button';
import { useAvailableArgonosModules } from 'src/components/application/argonos-modules-registry';
import { ArgonosModuleId } from 'src/components/application/modules';
import { useGetMyPermissions } from 'src/contexts/user-permissions-context';

import './application-selector.less';

const CLASSNAME = 'common-applications-selector';

interface ApplicationSelectorProps {
  currentModuleId: ArgonosModuleId;
}

export default function ApplicationSelector({
    currentModuleId,
}: ApplicationSelectorProps) {
    const classNames = useClassNames(CLASSNAME);
    const [popoverVisibility, setPopoverVisibility] = useState(false);
    const { permissions: userPermissions } = useGetMyPermissions();
    const availableArgonosModules = useAvailableArgonosModules(userPermissions);

    const handlePopover = useCallback(() => {
        return (
            <div className={classNames('&-popup')}>
                {availableArgonosModules.map((argonosModule) => {
                    return (
                        <ApplicationButton
                            key={argonosModule.id}
                            selected={argonosModule.id === currentModuleId}
                            argonosModule={argonosModule}
                        />
                    );
                })}
            </div>
        );
    }, [classNames, availableArgonosModules, currentModuleId]);

    return <ArgButton
        className={classNames('&-dots')}
        icon='icon-menu-9dots'
        size='large'
        type='ghost'
        popover={handlePopover}
        popoverVisible={popoverVisibility}
        onPopoverVisibleChange={setPopoverVisibility}
        popoverPlacement='bottomLeft'
    />;
}

