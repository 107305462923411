import { filter, isEmpty, isFunction, isNil, isString, isUndefined, map } from 'lodash';
import React, { CSSProperties, isValidElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import { useToolNodes } from './use-tool-nodes';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ToolContext, ToolTreeContext, ToolTreeNode } from './tool-context';
import { ThreeDotsLoading } from '../arg-loading/three-dots-loading';
import { isMessageDescriptor } from '../utils/is-message-descriptor';
import { getToolIcon, getToolLabel, isToolDisabled, isToolSelected, isToolVisible, Tool } from './tool';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgToolItemRenderContext } from '../arg-toolbar/arg-toolbar-item';
import { ArgMenuItem } from '../arg-menu/arg-menu-item';
import { ButtonClickEvent } from '../arg-button/arg-button';
import { ArgMenuItemDivider } from '../arg-menu/arg-menu-item-divider';
import { ArgSubMenu } from '../arg-menu/arg-sub-menu';
import { renderText } from '../utils/message-descriptor-formatters';
import { preventContextMenu } from '../../../utils/prevent-context-menu';
import { KeyBindingTooltipContent } from '../keybindings/keybinding-tooltip-content';
import { ArgToolBarLabel, ArgToolBarLabelRenderContext } from '../arg-toolbar/arg-toolbar-label';
import { ArgPopoverRenderer } from '../arg-popover/types';

import './arg-tool-menu.less';

export interface OverlayProps {
    x?: number;
    y?: number;
}

export interface ArgToolMenuProps<T> {
    overlay?: OverlayProps;
    visible?: boolean;
    className?: ClassValue;
    menuContext: ToolContext<T>;
    onCloseMenu: () => void;
    getPopupContainer?: (node: HTMLElement) => HTMLElement;

    environmentContext: T;
}

export function ArgToolMenu<T>(props: ArgToolMenuProps<T>) {
    const {
        overlay,
        visible,
        getPopupContainer,
        className,
        menuContext,
        onCloseMenu,
        environmentContext,
    } = props;

    const classNames = useClassNames('arg-tool-menu');

    const [activeKey, setActiveKey] = useState<string>();

    useEffect(() => {
        //        console.log('**** ON SHOW');
        menuContext.emit('OnShow');

        return () => {
            //            console.log('**** ON HIDE');
            menuContext.emit('OnHide');
        };
    }, []);

    const style = useMemo<CSSProperties | undefined>(() => {
        if (!isNil(overlay) && !isEmpty(overlay)) {
            const x: string | number = overlay?.x ? `${overlay?.x}px` : 0;
            const y: string | number = overlay?.y ? `${overlay?.y}px` : 0;

            return {
                top: y,
                left: x,
            };
        }
    }, [overlay]);

    const renderedNodes = useRenderToolMenu<T>(menuContext, environmentContext, onCloseMenu, setActiveKey);

    // Manage the overlay menu to be hidden
    if (visible === false) {
        return null;
    }

    const cls = {
        'overlaying': !isNil(overlay) && !isEmpty(overlay),
    };

    return <ArgMenu
        style={style}
        openKeys={activeKey ? [activeKey] : undefined}
        onContextMenu={preventContextMenu}
        getPopupContainer={getPopupContainer}
        className={classNames('&', className, cls)}
        data-tool-context={menuContext.id}
    >
        {renderedNodes}
    </ArgMenu>;
}

export function useRenderToolMenu<T>(
    menuContext: ToolContext<T>,
    environmentContext: T,
    onCloseMenu: () => void
    , setActiveKey?: (key: (string | undefined)) => void): React.ReactNode[] {
    const [toolNodes, toolTreeContext] = useToolNodes<T>(menuContext, environmentContext);

    const ret = useRenderToolMenuFromNodes<T>(toolNodes, toolTreeContext, environmentContext, onCloseMenu, setActiveKey);

    return ret;
}

export function useRenderToolMenuFromNodes<T>(
    toolNodes: ToolTreeNode<T>[],
    toolTreeContext: ToolTreeContext<T>,
    environmentContext: T,
    onCloseMenu: () => void,
    setActiveKey?: (key: string | undefined) => void,
): ReactNode[] {
    const classNames = useClassNames('arg-tool-menu');

    const hasAnyPreventCloseClickChildren = useMemo(() => {
        const ret = toolNodes.filter(node => !!node.children)
            .flatMap(node => node.children)
            .findIndex(childNode => !!childNode?.preventCloseMenuOnClick) >= 0;

        return ret;
    }, [toolNodes]);

    const handleNodeClick = useCallback(async (node: ToolTreeNode<T>, event: ButtonClickEvent): Promise<void> => {
        event.stopPropagation();
        event.preventDefault();
        if (!node.preventCloseMenuOnClick) {
            onCloseMenu();
        }

        await node.onClick?.(node, environmentContext, event);
    }, [environmentContext, onCloseMenu]);

    const intl = useIntl();

    const renderNodes = useCallback((nodes: ToolTreeNode<T>[]): ReactNode[] => {
        let needSeparator = false;

        return map(nodes, (node: ToolTreeNode<T>) => {
            if (!isToolVisible(node, environmentContext)) {
                return;
            }

            const context: ArgToolItemRenderContext = {
                onCloseMenu,
                menuItemClassName: classNames('&-item', 'arg-menu-item', node.className),
            };

            // Get the icon
            let _icon = node.icon;
            if (isString(_icon)) {
                _icon = <ArgIcon name={_icon} className={classNames('&-icon')} />;
            } else {
                _icon = renderText(getToolIcon(node, environmentContext));
            }

            // Create the icon component
            const icon = _icon && (
                <span className={classNames('&-icon-container')}>
                    {_icon}
                </span>
            );

            // Get the label
            let label = node.label;
            if (label && !isString(label)) {
                label = renderText(getToolLabel(node, environmentContext));
            }
            if (!label && node.keyBinding) {
                label = node.keyBinding.name;
            }

            if (isMessageDescriptor(label)) {
                label = (
                    <FormattedMessage {...label} />
                );
            }

            const isLoading = node.loading || toolTreeContext.fetchedTools[node.path]?.progressMonitor?.isRunning;

            // Don't render the menu if there is no visible children
            const isValidMenu = node.type === 'menu' && !isEmpty(filter(node.children, (child) => isToolVisible(child, environmentContext)));

            if (node.type === 'label') {
                const visible = isToolVisible(node, environmentContext);
                const customRender = node.customRender
                    ? (props: Tool<T>, context: ArgToolBarLabelRenderContext, popoverRender?: ArgPopoverRenderer) => {
                        return node.customRender?.(props, environmentContext, context, popoverRender);
                    }
                    : undefined;

                return <ArgToolBarLabel<T>
                    label={label}
                    tooltip={label}
                    testid={node.testid}
                    visible={visible}
                    className={node.className}
                    customRender={customRender}
                    environmentContext={environmentContext}
                />;
            }

            // Render the group when ready
            if (isValidMenu && !isLoading && !isToolDisabled(node, environmentContext)) {
                needSeparator = true;

                return (
                    <ArgSubMenu
                        key={node.path}
                        icon={icon}
                        label={label}
                        popupOffset={[10, 0]}
                        className={classNames('&-submenu')}
                        popupClassName={classNames('&-submenu-popup', node.menuClassName)}
                        onTitleMouseEnter={(event, menuKey) => {
                            if (hasAnyPreventCloseClickChildren) {
                                setActiveKey?.(menuKey);
                            }
                            node.onMouseOver?.(event);
                        }}
                        data-tool-path={node.path}
                        data-tool-order={node.order}
                    >
                        {node.children && renderNodes(node.children)}
                    </ArgSubMenu>
                );
            }

            // Render separator
            if (node.type === 'separator') {
                needSeparator = false;

                return (
                    <ArgMenuItemDivider
                        key={node.path}
                        className={classNames('&-item-separator')}
                        data-tool-path={node.path}
                        data-tool-order={node.order}
                    />
                );
            }

            if (node.type === 'group') {
                if (node.children?.length) {
                    const childrenNodes = renderNodes(node.children);
                    const hasChildren = !!childrenNodes.find((childNode) => !isUndefined(childNode));
                    const addSeparator = needSeparator && hasChildren;

                    needSeparator = hasChildren;

                    return (
                        <React.Fragment key={node.path}>
                            {addSeparator && <ArgMenuItemDivider
                                key={node.path}
                                className={classNames('&-item-separator')}
                                data-tool-path={node.path}
                                data-tool-order={node.order}
                            />}
                            {childrenNodes}
                        </React.Fragment>
                    );
                }
            }

            needSeparator = true;

            const customRenderResult = node.customRender?.(node, environmentContext, context);
            if (node.menuItemCustomRender) {
                return customRenderResult;
            }

            const tooltip = getNodeTooltip(node, intl, environmentContext);

            // Render menu item or loading menu
            return (
                <ArgMenuItem
                    key={node.path}
                    icon={icon}
                    disabled={isToolDisabled(node, environmentContext) || isLoading || (node.type === 'menu' && !isValidMenu)}
                    className={classNames(context.menuItemClassName, {
                        'custom': !!customRenderResult,
                        'selected': isToolSelected(node, environmentContext),
                    })}
                    tooltip={tooltip}
                    onClick={(event) => handleNodeClick(node, event)}
                    onMouseEnter={() => {
                        if (hasAnyPreventCloseClickChildren
                            && node.path.split('/').length === 1 /* root node case => close opened nodes */) {
                            setActiveKey?.(undefined);
                        }
                    }}
                    data-path={node.path}
                >
                    {(label || isLoading) &&
                        <span className={classNames('&-item-label-container')}>
                            <span className={classNames('&-item-label')} data-testid='menu-item-label'>{(label)}</span>
                            {isLoading && (
                                <ThreeDotsLoading className={classNames('&-item-label-loading')} />
                            )}
                        </span>}
                    {customRenderResult}
                </ArgMenuItem>
            );
        });
    }, [classNames, environmentContext, handleNodeClick, hasAnyPreventCloseClickChildren, intl, onCloseMenu, setActiveKey, toolTreeContext.fetchedTools]);

    const renderedNodes = useMemo<ReactNode[]>(() => {
        const ret = renderNodes(toolNodes);

        return ret;
    }, [renderNodes, toolNodes]);

    return renderedNodes;
}

function getNodeTooltip<T>(node: ToolTreeNode<T>, intl: IntlShape, environmentContext: T) {
    let _tooltip: ReactNode;
    let tooltip = node.tooltip;

    if (tooltip && isValidElement(tooltip)) {
        return tooltip;
    }

    if (tooltip === undefined && node.keyBinding) {
        _tooltip = (
            <KeyBindingTooltipContent
                tooltip={node.keyBinding.name}
                keyBinding={node.keyBinding}
            />
        );
    }
    if (tooltip) {
        if (isFunction(tooltip)) {
            tooltip = tooltip(node, environmentContext);
        }

        if (isMessageDescriptor(tooltip)) {
            _tooltip = intl.formatMessage(tooltip);
        } else {
            _tooltip = String(tooltip);
        }
    }

    return _tooltip;
}
