import { useCallback, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import * as uuid from 'uuid';

import { ProgressMonitor, SelectionProvider, useArgNotifications, useCallbackAsync } from 'src/components/basic';
import ReferencesConnector from 'src/settings/connectors/references-connector';
import { ReferenceItem } from 'src/settings/models/references';

const messages = defineMessages({
    createReferenceTableError: {
        id: 'settings.references.use-create-reference.CreateReferenceTableError',
        defaultMessage: 'Failed to create reference "{referenceName}"',
    },
    invalidTableNameError: {
        id: 'settings.references.use-create-reference.InvalidTableNameError',
        defaultMessage: 'Reference name is required',
    },
    invalidRowsError: {
        id: 'settings.references.use-create-reference.InvalidRowsError',
        defaultMessage: 'Every row must be valid',
    },
    duplicatedRowKeyError: {
        id: 'settings.references.use-create-reference.DuplicatedRowKeyError',
        defaultMessage: 'At least one row has the same key',
    },
});

interface Params {
    initialName?: string;
    initialRows?: ReferenceItem[];
    onCreate?: () => void;
    selectionProvider?: SelectionProvider<ReferenceItem>;
    onError?: (error: Error) => void;
}

interface ValidationResult {
    isValid: boolean;
    errors: MessageDescriptor[];
}

interface UseCreateReferenceTableResult {
    tableName: string;
    setTableName: (name: string) => void;
    tableDescription: string;
    setTableDescription: (description: string) => void;
    rows: ReferenceItem[];
    initRows: (rows: ReferenceItem[]) => void;
    addRow: () => void;
    updateRow: (index: number, field: keyof ReferenceItem, value: string) => void;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
    deleteRow: (index: number) => void;
    deleteRows: (index: number[]) => void;
    createReferenceTable: () => Promise<void>;
    createReferenceTableProgressMonitor?: ProgressMonitor; // Replace 'any' with the actual type from useCallbackAsync
    isValid: boolean;
    errors: MessageDescriptor[];
}

const isRowValid = (row: ReferenceItem) => {
    const { key, value } = row;

    if (!key || !value) {
        return false;
    }

    const ret = key.trim() !== '' && value.trim() !== '';

    return ret;
};

const isRowKeyDuplicated = (rows: ReferenceItem[]) => {
    const duplicates = rows.filter((item, index) => {
        const hasDuplicateRow = rows.find((i, iIndex) => i.key === item.key && index !== iIndex);

        return hasDuplicateRow;
    });

    return duplicates.length > 0;
};

export function useCreateReference(params: Params = {}): UseCreateReferenceTableResult {
    const notifications = useArgNotifications();

    const { initialName = '', initialRows = [], onCreate, onError } = params;

    const [tableName, setTableName] = useState(initialName);
    const [tableDescription, setTableDescription] = useState('');
    const [rows, setRows] = useState<ReferenceItem[]>(initialRows);

    const initRows = useCallback((rows: ReferenceItem[]) => {
        setRows(rows);
    }, []);

    const addRow = useCallback(() => {
        setRows(prev => [...prev, { id: uuid.v4(), key: '', value: '' }]);
    }, []);

    const updateRow = useCallback((index: number, field: keyof ReferenceItem, value: string) => {
        setRows(prev =>
            prev.map((item, i) => (i === index ? { ...item, [field]: value } : item)),
        );
    }, []);

    const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
        setRows((prevRows) => {
            const newRows = [...prevRows];
            newRows.splice(dragIndex, 1);
            newRows.splice(hoverIndex, 0, prevRows[dragIndex]);

            return newRows;
        });
    }, []);

    const deleteRow = useCallback((index: number) => {
        setRows(prev => prev.filter((_, i) => i !== index));
    }, []);

    const deleteRows = useCallback((indexes: number[]) => {
        setRows((prev) => {
            const ret = prev.filter((_, i) => {
                return !indexes.includes(i);
            });

            return ret;
        });
    }, []);

    const validationResult = useMemo((): ValidationResult => {
        const errors: MessageDescriptor[] = [];

        if (!tableName.trim()) {
            errors.push(messages.invalidTableNameError);
        }

        if (!rows.every(isRowValid)) {
            errors.push(messages.invalidRowsError);
        }

        if (isRowKeyDuplicated(rows)) {
            errors.push(messages.duplicatedRowKeyError);
        }

        return {
            isValid: errors.length === 0,
            errors,
        };
    }, [tableName, rows]);

    const [createReferenceTable, createReferenceTableProgressMonitor] = useCallbackAsync(async (progressMonitor) => {
        try {
            if (!validationResult.isValid) {
                throw new Error(validationResult.errors.join(', '));
            }

            await ReferencesConnector().createReference({
                name: tableName,
                items: rows,
                description: tableDescription,
            }, progressMonitor);
            onCreate?.();
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            const errorMessage = error instanceof Error ? error.message : String(error);
            notifications.snackError(
                { message: messages.createReferenceTableError },
                new Error(errorMessage),
                { referenceTableName: tableName },
            );
            onError?.(error instanceof Error ? error : new Error(errorMessage));
            throw error;
        }
    }, [notifications, onCreate, onError, rows, tableDescription, tableName, validationResult.errors, validationResult.isValid]);

    return {
        tableName,
        setTableName,
        rows,
        tableDescription,
        setTableDescription,
        addRow,
        initRows,
        updateRow,
        moveRow,
        deleteRow,
        deleteRows,
        createReferenceTable,
        createReferenceTableProgressMonitor,
        isValid: validationResult.isValid,
        errors: validationResult.errors,
    };
}
