import { BasicEnvironmentContext } from 'src/framework/environment-contexts/basic-environment-context';
import { WorkflowsStateAccess } from '../../../framework/workflows/caches/use-workflows-state-access';
import { WorkflowsObjectCache } from '../../../framework/workflows';

export const WORKFLOW_TABLE_ROW_MENU_CONTEXT = 'settings.workflows:RowMenuContext';

export const WORKFLOWS_VIEW_LEFT_CONTEXT = 'settings.workflows:WorkflowsViewLeftContext';

export const WORKFLOWS_VIEW_RIGHT_CONTEXT = 'settings.workflows:WorkflowsViewRightContext';

export interface WorkflowsEnvironmentContext extends BasicEnvironmentContext {
    workflowsStateAccess: WorkflowsStateAccess;
    workflowsObjectCache: WorkflowsObjectCache;
}
