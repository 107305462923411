import { OntologyPropertyType } from '../../types';
import { TypeItem } from './const';

export const getBaseType = (type: TypeItem | undefined): OntologyPropertyType | null => {
    if (type === TypeItem.boolean) {
        return OntologyPropertyType.Boolean;
    }
    if (type === TypeItem.character) {
        return OntologyPropertyType.Char;
    }
    if (type === TypeItem.date) {
        return OntologyPropertyType.Date;
    }
    if (type === TypeItem.dateAndTime) {
        return OntologyPropertyType.DateTime;
    }
    if (type === TypeItem.string) {
        return OntologyPropertyType.String;
    }
    if (type === TypeItem.text) {
        return OntologyPropertyType.Text;
    }
    if (type === TypeItem.integer32b) {
        return OntologyPropertyType.Int32;
    }
    if (type === TypeItem.integer64b) {
        return OntologyPropertyType.Int64;
    }
    if (type === TypeItem.integer8b) {
        return OntologyPropertyType.Byte;
    }
    if (type === TypeItem.float) {
        return OntologyPropertyType.Single;
    }
    if (type === TypeItem.geoCoordinates) {
        return OntologyPropertyType.Coordinates;
    }
    if (type === TypeItem.email) {
        return OntologyPropertyType.Email;
    }
    if (type === TypeItem.url) {
        return OntologyPropertyType.Url;
    }
    if (type === TypeItem.phoneNumber) {
        return OntologyPropertyType.PhoneNumber;
    }
    if (type === TypeItem.address) {
        return OntologyPropertyType.Address;
    }
    if (type === TypeItem.country) {
        return OntologyPropertyType.Country;
    }

    return null;
};

export const getTypeItem = (baseType: OntologyPropertyType): TypeItem | null => {
    if (baseType === 'Boolean') {
        return TypeItem.boolean;
    } else if (baseType === 'Int32') {
        return TypeItem.integer32b;
    } else if (baseType === 'Char') {
        return TypeItem.character;
    } else if (baseType === 'Date') {
        return TypeItem.date;
    } else if (baseType === 'DateTime') {
        return TypeItem.dateAndTime;
    } else if (baseType === 'String') {
        return TypeItem.string;
    } else if (baseType === 'Text') {
        return TypeItem.text;
    } else if (baseType === 'Int64') {
        return TypeItem.integer64b;
    } else if (baseType === 'Byte') {
        return TypeItem.integer8b;
    } else if (baseType === 'Single') {
        return TypeItem.float;
    } else if (baseType === 'Coordinates') {
        return TypeItem.geoCoordinates;
    } else if (baseType === 'Email') {
        return TypeItem.email;
    } else if (baseType === 'Url') {
        return TypeItem.url;
    } else if (baseType === 'PhoneNumber') {
        return TypeItem.phoneNumber;
    } else if (baseType === 'Address') {
        return TypeItem.address;
    } else if (baseType === 'Country') {
        return TypeItem.country;
    }

    return null;
};
