import { ReactNode, useCallback, useState } from 'react';

import { ArgButton } from '../arg-button/arg-button';
import { ArgMessageValues, ArgRenderedText, ArgSize } from '../types';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { renderText } from '../utils/message-descriptor-formatters';
import { isIn } from '../utils/is-in';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';

import './arg-collapse.less';

const CLASSNAME = 'arg-collapse';

export type ArgCollapseMode = 'horizontal' | 'vertical'

export interface ArgCollapseProps {
    /**
     * A clasnname for the container
    */
    className?: ClassValue;
    /**
     * A childrne to display
    */
    children?: ReactNode;
    /**
     * A props to set a default value to collapsed state by default
    */
    defaultCollapsed?: boolean;
    /**
     * Trigger text collapsed state
    */
    title?: ArgRenderedText;
    /**
     * A props for i18n value
    */
    messageValues?: ArgMessageValues;
    /**
     * A callback that trigger at collapse
    */
    onCollapse?: (isCollapsed: boolean) => void;
    /**
     * A props to set value to collapsed state
    */
    collapsed?: boolean;
    /**
     * A props to set value to collapsed state
    */
    disabled?: boolean;
    /**
     * This component have 2 mode 'horizontal' and 'vertical'
    */
    mode?: ArgCollapseMode;
    /**
     * This component have 4 size 'small', 'medium', 'large' and 'extra-large'
    */
    size?: ArgSize;
}
/**
 * This component is designed to a collapse menu.
 *
 * @example
 * ```
 * <ArgCollapse children={<p>a p children</p>} title='Title collapse' />
 * ```
 */
export function ArgCollapse(props: ArgCollapseProps) {
    const {
        className,
        children,
        defaultCollapsed = false,
        title,
        onCollapse,
        collapsed,
        disabled,
        messageValues,
        mode = 'vertical',
        size = 'large',
    } = props;
    const classNames = useClassNames(CLASSNAME);
    const useInternalCollapsed = !isIn(props, 'collapsed');

    const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed || defaultCollapsed);

    const _collapsed: boolean = useInternalCollapsed ? isCollapsed : ((collapsed === undefined) ? false : collapsed);
    const isHorizontalCollapse = mode === 'horizontal' && _collapsed;

    const handleClickTitle = useCallback(() => {
        if (useInternalCollapsed) {
            setIsCollapsed((prevState: boolean) => {
                return !prevState;
            });

            return;
        }

        onCollapse?.(!collapsed);
    }, [collapsed, onCollapse, useInternalCollapsed]);

    const cls = {
        collapsed: _collapsed,
    };

    return (
        <div className={classNames('&', className, cls)} data-testid='arg-collapse'>
            <div className={classNames('&-title')}>
                {disabled ? (
                    <ArgMessageRenderer
                        size={size}
                        message={title}
                        messageValues={messageValues}
                        className={classNames('&-button')}
                    />
                ) : (
                    <ArgButton
                        size={size}
                        type='ghost'
                        label={renderText(title, messageValues)}
                        icon={_collapsed ? 'icon-cheveron-down' : 'icon-cheveron-up'}
                        className={classNames('&-button', { horizontal: isHorizontalCollapse })}
                        onClick={handleClickTitle}
                    />
                )}
            </div>

            {!_collapsed && (
                <div className={classNames('&-body')}>
                    {children}
                </div>
            )}
        </div>
    );
}
