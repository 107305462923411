import { defineMessages } from 'react-intl';

import { ArgonosModuleId } from '../../components/application/modules';
import { registerWorkflowTarget } from '../../framework/workflows/registry/workflows-registry';

const messages = defineMessages({
    brief: {
        id: 'exploration.workflow-types.Brief',
        defaultMessage: 'Brief',
    },
    object: {
        id: 'exploration.workflow-types.Object',
        defaultMessage: 'Object',
    },
    link: {
        id: 'exploration.workflow-types.Link',
        defaultMessage: 'Link',
    },
});

export function setupWorkflowTypes(argonosModuleId: ArgonosModuleId) {
    registerWorkflowTarget({
        targetType: 'Brief',
        argonosModuleId,
        label: messages.brief,
    });

    registerWorkflowTarget({
        targetType: 'Object',
        argonosModuleId,
        label: messages.object,
    });

    registerWorkflowTarget({
        targetType: 'Link',
        argonosModuleId,
        label: messages.link,
    });
}
