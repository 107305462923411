import React, { CSSProperties, ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';

import './arg-auto-scalable.less';

const DEFAULT_ZOOM_MAX = 1;

export interface Margins {
    horizontal: number;
    vertical: number;
}

export const NO_MARGINS: Margins = {
    horizontal: 0,
    vertical: 0,
};

interface AutoScalableContentProps {
    /**
     * An HTML class for the container
     */
    className?: ClassValue;

    /**
     * The content to be scaled within the container.
     */
    children: ReactNode;

    /**
     * Margins applied to the content inside the container.
     */
    margins?: Margins;

    /**
     * Maximum zoom level.
     */
    zoomMax?: number;
}

/**
 * This component is designed to scales its child content to fit within the container while respecting the specified margins and zoom constraints.
 *
 * @example
 * ```
 * <ArgAutoScalable className={classNames('&-scale')}>
 *      {boxesContent}
 * </ArgAutoScalable>
 * ```
 */
export function ArgAutoScalable(props: AutoScalableContentProps) {
    const { className, children, margins = NO_MARGINS, zoomMax = DEFAULT_ZOOM_MAX } = props;

    const classNames = useClassNames('arg-auto-scalable');

    const [bodyStyle, setBodyStyle] = useState<CSSProperties>();

    const ref = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    const handleResize = useCallback(() => {
        if (!ref.current || !bodyRef.current) {
            return;
        }

        const parent = ref.current.getBoundingClientRect();
        const body = {
            width: bodyRef.current.offsetWidth,
            height: bodyRef.current.offsetHeight,
        };

        const parentWidth = parent.width - margins.horizontal * 2;
        const parentHeight = parent.height - margins.vertical * 2;

        if (parentWidth <= 0 || parentHeight <= 0) {
            setBodyStyle({ visibility: 'hidden' });

            return;
        }

        const scaleX = parentWidth / body.width;
        const scaleY = parentHeight / body.height;
        const scale = Math.min(scaleX, scaleY, zoomMax);

        setBodyStyle({
            visibility: 'visible',
            transform: `scale(${scale})`,
            left: `${margins.horizontal + (parentWidth - body.width * scale) / 2}px`,
            top: `${margins.vertical + (parentHeight - body.height * scale) / 2}px`,
        });
    }, [margins, zoomMax]);

    useResizeObserver(ref, handleResize);
    useResizeObserver(bodyRef, handleResize);

    useLayoutEffect(() => {
        handleResize();
    }, [handleResize]);

    return (
        <div className={classNames('&', className)} ref={ref}>
            <div className={classNames('&-body')} ref={bodyRef} style={bodyStyle}>
                {children}
            </div>
        </div>
    );
}
