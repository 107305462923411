import { defineMessages } from 'react-intl';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    ArgButton,
    ArgDivider,
    ArgPanelsConfiguration,
    ArgSwitch,
    ArgToolbarLayout,
    ClassValue,
    useClassNames,
    useTargetToolContext,
    useToolItem,
} from 'src/components/basic';
import { DEFAULT_PANELS_CONFIGURATION } from '../../../components/common/panels/default-panels-configuration';
import {
    useToolbarPanelsConfigurationForUser,
} from '../../../components/common/panels/use-toolbar-panels-configuration-for-user';
import { WorkflowActivitiesPanel } from './workflow-activities-panel';
import { computeWorkflowsURL } from '../utils/compute-urls';
import { useRouteIds } from 'src/settings/hooks/use-route-ids';
import { useWorkflowObjectCacheWorkflow } from '../../../framework/workflows';
import { WorkflowFlowchart } from './workflow-flowchart';

import './workflow-page.less';

const CLASSNAME = 'settings-workflow-page';

const PANEL_MIN_WIDTH = 180;
const PANEL_MAX_WIDTH = '40%';
const LEFT_PANEL_DEFAULT_WIDTH = '25%';

const ACTIVITIES_LIST_PANEL_PATH = 'left/activities/list';

const WORKFLOW_PANELS_CONFIGURATION: ArgPanelsConfiguration = {
    ...DEFAULT_PANELS_CONFIGURATION,

    minRightPanelWidth: PANEL_MIN_WIDTH,
    maxRightPanelWidth: PANEL_MAX_WIDTH,
    minLeftPanelWidth: PANEL_MIN_WIDTH,
    maxLeftPanelWidth: PANEL_MAX_WIDTH,
    defaultLeftPanelWidth: LEFT_PANEL_DEFAULT_WIDTH,

    leftPanelWidthConfigurationName: 'settings.ui.workflow.panels.left.width',
    leftSelectedPanelConfigurationName: 'settings.ui.workflow.panels.left.panel',

    defaultLeftSelectedPanel: ACTIVITIES_LIST_PANEL_PATH,
};


const messages = defineMessages({
    active: {
        id: 'settings.workflow-page.Active',
        defaultMessage: 'Active',
    },
    export: {
        id: 'settings.workflow-page.Export',
        defaultMessage: 'Export',
    },
    publish: {
        id: 'settings.workflow-page.Publish',
        defaultMessage: 'Publish',
    },
});

const SETTINGS_WORKFLOW_PAGE_TOOLBAR_CONTEXT_NAME = 'settings.workflow-page.toolbar';

interface WorkflowPageProps {
    className?: ClassValue;
}

export function WorkflowPage(props: WorkflowPageProps) {
    const { className } = props;

    const classNames = useClassNames(CLASSNAME);
    const navigate = useNavigate();

    const { workflowId } = useRouteIds('workflowId');

    const panelsProps = useToolbarPanelsConfigurationForUser(WORKFLOW_PANELS_CONFIGURATION);
    const toolbarContext = useTargetToolContext<any>(SETTINGS_WORKFLOW_PAGE_TOOLBAR_CONTEXT_NAME);

    const [workflow] = useWorkflowObjectCacheWorkflow(workflowId);

    const redirectToWorkflowsList = useCallback(() => {
        navigate(computeWorkflowsURL());
    }, [navigate]);

    const activitiesPanelRender = useCallback(() => {
        return (
            <WorkflowActivitiesPanel />
        );
    }, []);

    useToolItem(toolbarContext, {
        path: ACTIVITIES_LIST_PANEL_PATH,
        type: 'panel',
        icon: 'icon-checkbox-list',
    }, { panelRender: activitiesPanelRender });

    const isActive = true;

    if (!workflow) {
        return null;
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-headers')}>
                <ArgButton
                    size='large'
                    type='ghost'
                    icon='icon-triangle-left'
                    label={workflow.name}
                    className={classNames('&-headers-name')}
                    onClick={redirectToWorkflowsList}
                />

                <div className={classNames('&-headers-right')}>
                    <ArgSwitch value={isActive} label={messages.active} />

                    <ArgDivider type='vertical' className={classNames('&-headers-divider')} />

                    <ArgButton
                        type='secondary'
                        icon='icon-upload'
                        label={messages.export}
                    />

                    <ArgButton label={messages.publish} />
                </div>
            </div>

            <ArgToolbarLayout
                className={classNames('&-body')}
                toolbarContext={toolbarContext}
                environmentContext={undefined}
                {...panelsProps}
            >
                <WorkflowFlowchart />
            </ArgToolbarLayout>
        </div>
    );
}
