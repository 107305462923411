export enum ConfigurationType {
    Preferences = 'Preferences',
    Administration = 'administration',
    Exploration = 'exploration',
    Preparation = 'preparation',
    KnowledgeBase = 'knowledge-base',
    ExplorationKeyBinding = 'exploration.ui.KEY_BINDINGS',
    PreparationKeyBinding = 'preparation.ui.KEY_BINDINGS',
    GlobalAdminRole = 'global-administration.roles',
    Users = 'global-administration.users',
    ApplicationSettings = 'all-application-settings',
    Groups = 'environment.user-groups',
    RemoteComponents = 'data-preparation.remote-components',
    Secrets = 'data-preparation.secrets',
    PreparationRole = 'data-preparation.roles',
    Composites = 'data-preparation.composites',
    Processes = 'data-preparation.processes',
    ExplorationStyle = 'data-exploration.ExplorationStyle',
    ExplorationRole = 'data-exploration.roles',
    KnowledgeBaseRole = 'knowledge-base.roles',
    UserProfiles = 'global-administration.users-profiles',
    ProfileFields = 'global-administration.profile-fields',
    // Support for 24.2 import
    LegacyUserProfileBundle = 'global-administration.user-profiles-fields-bundle',
    BriefTemplate = 'data-exploration.Brief',
    Universe = 'data-exploration.Universe',
    ContextualVariable = 'data-exploration.contextual-variable',
    Ontologies = 'data-exploration.ontologies',
    ExplorationExtensions = 'data-exploration.extensions',

    ExplorationUserRoles = 'data-exploration.users-roles',
    PreparationUserRoles = 'data-preparation.users-roles',
    AdminUserRoles = 'global-administration.users-roles',
    KnowledgeBaseUserRoles = 'knowledge-base.users-roles',

    ExplorationGroupsRoles = 'data-exploration.groups-roles',
    PreparationGroupsRoles = 'data-preparation.groups-roles',
    EnvironmentGroupsRoles = 'environment.groups-roles',
    KnowledgeBaseGroupsRoles = 'knowledge-base.groups-roles',

    ExplorationWebhooks = 'data-exploration.webhooks',
    PreparationWebhooks = 'data-preparation.webhooks',
    KnowledgeBaseWebhooks = 'knowledge-base.webhooks',
    EnvironmentWebhooks = 'environment.webhooks',
}

export const usersRolesConfigurationTypes = [
    ConfigurationType.ExplorationUserRoles,
    ConfigurationType.PreparationUserRoles,
    ConfigurationType.AdminUserRoles,
    ConfigurationType.KnowledgeBaseUserRoles,
];

export const groupsRolesConfigurationTypes = [
    ConfigurationType.ExplorationGroupsRoles,
    ConfigurationType.PreparationGroupsRoles,
    ConfigurationType.EnvironmentGroupsRoles,
    ConfigurationType.KnowledgeBaseGroupsRoles,
];

export const CONFIGURATION_POLICIES_OPTIONS = 'misc';

export type ConfigurationsScope = string;
/*    | 'data_exploration'
    | 'data_preparation'
    | 'data_collection'
    | 'proceo'
    | 'admin'
    | 'settings'
    ;
*/
