import { isNumber } from 'lodash';

import { useBasicState } from '../../../utils/rt-states/use-basic-state';
import { WorkflowDefinitionVersion, WorkflowId } from '../model/workflows';
import { WorkflowDefinitionState } from './workflow-definition-state';

export function workflowDefinitionStateFactory(url: string, processId: WorkflowId, version: WorkflowDefinitionVersion) {
    return new WorkflowDefinitionState(url, processId, version);
}

export function computeWorkflowDefinitionStateURL(workflowId: WorkflowId, version: WorkflowDefinitionVersion) {
    return `/workflows/${workflowId}/definition/${version}`;
}

export function useWorkflowDefinitionState(workflowId: WorkflowId, version: WorkflowDefinitionVersion): WorkflowDefinitionState;
export function useWorkflowDefinitionState(workflowId: WorkflowId | undefined, version: WorkflowDefinitionVersion | undefined): WorkflowDefinitionState | undefined;

export function useWorkflowDefinitionState(workflowId: WorkflowId | undefined, version: WorkflowDefinitionVersion | undefined): WorkflowDefinitionState | undefined {
    const state = useBasicState<WorkflowDefinitionState>(
        (url) => workflowDefinitionStateFactory(url, workflowId!, version!),
        (workflowId && isNumber(version)) ? computeWorkflowDefinitionStateURL(workflowId, version) : undefined,
    );

    return state;
}
