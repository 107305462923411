import { ArgRenderedIcon, ArgRenderedText, ClassValue, renderIcon, renderText, useClassNames } from '..';

import './arg-link.less';

type ArgLinkIconPlacement = 'left' | 'right';

export interface ArgLinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'className'> {
    /**
     * An HTML class for the component
     */
    className?: ClassValue;
    /**
     * A label for the link
     */
    label?: ArgRenderedText;
    /**
     * An icon for the link
     */
    icon?: ArgRenderedIcon;
    /**
     * An icon placement for the link
     */
    iconPlacement?: ArgLinkIconPlacement;
}

/**
 * This component is designed to display a link.
 *
 * @example
 * ```
 * <ArgLink
 *     className={classNames('&-footer-logs-access-link')}
 *     icon='icon-code-tags-check'
 *     href={LOG_MANAGER_URL}
 *     target='_blank'
 *     label={messages.logManager}
 * />
 * ```
 */
export function ArgLink({
    className,
    children,
    label,
    icon,
    iconPlacement = 'left',
    ...otherProps
}: ArgLinkProps) {
    const classNames = useClassNames('arg-link');

    return (
        <a
            className={classNames('&', className)}
            data-testid='arg-link'
            {...otherProps}
        >
            <div className={classNames('&-icon-and-label', iconPlacement)}>
                {renderIcon(icon)}
                {renderText(label)}
            </div>
            <div className={classNames('&-children')}>
                {children}
            </div>
        </a>
    );
}
