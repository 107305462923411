import { defineMessages } from 'react-intl';
import { isEmpty } from 'lodash';

import { Workflow, WORKFLOW_DND_TYPE, WorkflowConnector } from '../../../framework/workflows';
import {
    WORKFLOWS_VIEW_LEFT_CONTEXT,
    WorkflowsEnvironmentContext,
} from '../environment-contexts/workflows-environment-context';
import { WorkflowsListState } from '../../../framework/workflows/states/workflows-list-state';
import { registerToolItem, registerToolItemForMimeType, TargetEnvironmentContext } from '../../../components/basic';

const messages = defineMessages({
    delete: {
        id: 'settings.workflows.actions.Delete',
        defaultMessage: 'Delete',
    },
    canNotDelete: {
        id: 'settings.workflows.actions.CanNotDelete',
        defaultMessage: 'You cannot delete a workflow while instances are running. You must first purge the workflow before you can delete it.',
    },
});


async function deleteWorkflows(workflows: Workflow[], environmentContext: WorkflowsEnvironmentContext): Promise<void> {
    const ps = workflows.map(async (workflow: Workflow) => {
        await WorkflowConnector.getInstance().deleteWorkflow(workflow.id);
    });

    await environmentContext.workflowsStateAccess.runWorkflowsList(async (workflowsListState: WorkflowsListState) => {
        workflowsListState.change('deleted');
    });

    await Promise.all(ps);
}

export function registerDeleteWorkflowActions() {
    registerToolItemForMimeType<WorkflowsEnvironmentContext, Workflow>(WORKFLOW_DND_TYPE, {
        path: 'delete/delete',
        label: messages.delete,
        disabled: (environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>) => {
            const workflow = environmentContext.firstItem();
            if (!workflow) {
                return false;
            }
            const result = workflow.instancesCount > 0;

            return result;
        },
        tooltip: (_, environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>) => {
            const workflow = environmentContext.firstItem();
            if (!workflow) {
                return false;
            }
            const result = workflow.instancesCount > 0;

            return result ? messages.canNotDelete : undefined;
        },
        onClick: async (_, environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>): Promise<void> => {
            const workflow = environmentContext.firstItem();
            if (!workflow) {
                return;
            }
            await deleteWorkflows([workflow], environmentContext);
        },
    });

    registerToolItem<TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>>(WORKFLOWS_VIEW_LEFT_CONTEXT, {
        path: 'actions/delete/delete',
        order: 900,
        type: 'button',
        label: messages.delete,
        disabled: (workflowEnvironmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>) => {
            const workflows = workflowEnvironmentContext.listItemKeys().filter((workflowId) => {
                const workflow = workflowEnvironmentContext.getItem(workflowId)!;

                if (workflow.instancesCount > 0) {
                    return false;
                }

                return true;
            });

            return isEmpty(workflows);
        },
        onClick: async (_, workflowEnvironmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>) => {
            const workflows = workflowEnvironmentContext.listItemKeys().map((workflowId) => {
                const workflow = workflowEnvironmentContext.getItem(workflowId)!;

                return workflow;
            });

            await deleteWorkflows(workflows, workflowEnvironmentContext);
        },
    });
}
