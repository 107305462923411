import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import { chain, debounce, filter, fromPairs, keys, toPairs } from 'lodash';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { Dayjs } from 'dayjs';

import { createChangeExplorationStyleUndoRedo } from '../features/actions/change-graph-style';
import { ExplorationId } from '../model/exploration';
import {
    ConfigurableEdges,
    ConfigurableVertices,
    ExplorationVisualizationStyleCapabilities,
    ExplorationVisualizationStyles,
    VertexPropertyParameters,
} from '../model/exploration-visualization';
import {
    AdvancedStyleType,
    ContinuousValueIntervaleType,
    DiscreteValue,
    Gradient,
    Interval,
    LineAnimation,
    LineStyle,
    RuleSet,
    StyleControlType,
    UserDefinedContent,
} from '../../components/common/graph/customisation/graph-style';
import {
    UniverseEdgeTypeName,
    UniversePropertyType,
    UniversePropertyTypes,
    UniverseType,
    UniverseVertexTypeName,
} from '../model/universe';
import { graphStyleCustomisationReducer, TYPES } from '../reducers/graph-style-customisation-reducer';
import {
    GLOBAL_PM,
    ProgressMonitor,
    SubProgressMonitor,
    useArgNotifications,
    useCallbackAsync,
    useEffectAsync,
    useStateEquals,
} from '../../components/basic';
import { GRAPH_NODE_MIN_SIZE } from '../../components/common/graph/constants';
import { GradientOrInterval, Side } from '../../components/common/graph/customisation/advanced-style';
import { VertexUtils } from '../utils/vertex-utils';
import { stringSorter } from '../../utils/sorter';
import { ActionsEngine } from '../../utils/actions/actions-engine';
import { ExplorationState } from '../rt-states/exploration-state';
import { EditorState } from '../rt-states/editor-state';
import { ExplorationConnector } from '../utils/connector/exploration-connector';
import { getUniverseVertexTypeByName } from 'src/exploration/utils/universe.utils';

export interface GraphCustomizerState {
    /**
     *  Vertex or edge name
     */
    label?: string;
    titleProperty?: string;
    clusteringProperty?: string;
    groupbyProperty?: string;
    timelineProperty?: string;
    geographicalProperty?: string;
    userDefinedContent: UserDefinedContent;
    ruleSets: Record<string, RuleSet[]>;

    defaultPropertiesExpanded: boolean;
    colorAndIconEditorVisible: boolean;
}


export type GraphStylesInfos = Omit<GraphCustomizerState, 'defaultPropertiesExpanded' | 'colorAndIconEditorVisible'>;

const OMIT_BY_PROPERTY_TYPE_FILTER = ['Boolean', 'Address', 'Action', 'Resources', 'Image'];

const INITIAL_STATE: GraphCustomizerState = {
    label: undefined,
    titleProperty: undefined,

    userDefinedContent: {
        size: undefined,

        fillColor: undefined,
        strokeColor: undefined,
        iconColor: 'blue',

        iconName: undefined,
        iconFontFamily: undefined,
        lineStyle: 'solid',
        lineAnimation: LineAnimation.NoAnimation,
    },

    ruleSets: {},
    defaultPropertiesExpanded: true,
    colorAndIconEditorVisible: false,
};

const INITIAL_EXPLORATION_STYLES: ExplorationVisualizationStyles = {
    vertexStyles: {},
    edgeStyles: {},
};

export const messages = defineMessages({
    loadExplorationVisualizationsStyleError: {
        id: 'exploration.use-graph-customisation.loadExplorationVisualizationsStyleError',
        defaultMessage: 'Failed to load visualization styles',
    },
    resetExplorationStyleError: {
        id: 'exploration.use-graph-customisation.ResetExplorationStyleError',
        defaultMessage: 'Failed to reset visualization styles',
    },
    loadExplorationStyleError: {
        id: 'exploration.use-graph-customisation.LoadExplorationStyleError',
        defaultMessage: 'Failed to load visualization styles',
    },
    fetchExplorationVisualizationStyleCapabilities: {
        id: 'exploration.use-graph-customisation.FetchExplorationVisualizationStyleCapabilities',
        defaultMessage: 'Get Visualization Style Capabilities',
    },
    fetchExplorationVisualizationStyles: {
        id: 'exploration.use-graph-customisation.FetchExplorationVisualizationStyles',
        defaultMessage: 'Get Visualizations Styles',
    },
});

export interface UseGraphStyleCustomisationReturnType {
    initialStyles: ExplorationVisualizationStyles;
    currentStyles: ExplorationVisualizationStyles;
    defaultVertexStyle: GraphStylesInfos | undefined;
    state: GraphCustomizerState;
    objectTypeList: string[];
    clusterableProperties: UniversePropertyType[];
    canBeDefinedAsTitleProperties: string[];
    properties: string[];
    geographyProperties: UniversePropertyType[];
    refreshInitialStyles: () => void;
    loadStyles: (
        templateId: string,
        progressMonitor: ProgressMonitor,
        vertexStyles?: Record<UniverseVertexTypeName, GraphStylesInfos>,
        edgeStyles?: Record<UniverseEdgeTypeName, GraphStylesInfos>,
    ) => Promise<void>;
    resetStyles: () => Promise<void>;
    setFillColor: (color: string) => Promise<void>;
    setStrokeColor: (color: string) => Promise<void>;
    setIconColor: (color: string) => Promise<void>;
    setIcon: (iconName: string) => Promise<void>;
    setIsStrokeTransparent: (isTransparent: boolean) => Promise<void>;
    setIconAndColorVisibility: (visible: boolean) => void;
    setClusteringProperty: (clusteringProperty: string) => Promise<void>;
    setGeographicalProperty: (geographicalProperty: string) => Promise<void>;
    setAdvancedStyleProperty: (
        advancedStyleType: AdvancedStyleType,
        property: string | undefined,
        controlType?: StyleControlType,
    ) => Promise<void>;
    setAdvancedStyleDiscretePropertyValue: (
        advancedStyleType: AdvancedStyleType,
        value: string | number | boolean | null,
        index: number,
        isBool?: boolean
    ) => Promise<void>;
    setAdvancedStyleRangeValue: (
        advancedStyleType: AdvancedStyleType,
        side: Side,
        value: number | Dayjs | null,
        index: number,
    ) => Promise<void>;
    setAdvancedStyleRangeIntervalType: (
        advancedStyleType: AdvancedStyleType,
        side: Side,
        index: number,
    ) => Promise<void>;
    setAdvancedStyle: (
        advancedStyleType: AdvancedStyleType,
        style: Record<string, any>,
        index: number,
    ) => Promise<void>;
    addNewAdvancedStyleRule: (
        advancedStyleType: AdvancedStyleType,
        value: DiscreteValue | Interval | null,
    ) => Promise<void>;
    removeAdvancedStyleRule: (advancedStyleType: AdvancedStyleType, index: number) => Promise<void>;
    onVertexLabelChange: (vertexLabel: string) => Promise<void>;
    isSomeProgressMonitorsRunning: boolean | undefined;
    onToggleDefaultPropClick: () => void;
    onDisplayPropertyChange: (displayProperty: string | undefined) => Promise<void>;
    onSizeChange: (size: number | null) => Promise<void>;

    toggleGradientInterval: (
        advancedStyleType: AdvancedStyleType,
        gradientOrInterval: GradientOrInterval,
        property: string,
    ) => Promise<void>;
    setGradientPropertyRangeValue: (
        type: 'property' | 'size',
        side: Side,
        value: number | Dayjs | null,
    ) => Promise<void>;
    intl: IntlShape;
    onResetStyles: (vertexLabel: string) => Promise<void>;
    addUndefinedValueStylesRule: (advancedStyleType: AdvancedStyleType) => Promise<void>;
    removeUndefinedValueStylesRule: (advancedStyleType: AdvancedStyleType) => Promise<void>;

    setLineStyle: (lineStyle: LineStyle) => Promise<void>;
    setLineAnimation: (lineAnimation: LineAnimation) => Promise<void>;
}

export enum VertexOrEdge {
    Vertex = 'Vertex',
    Edge = 'Edge',
}

export type GraphActiveElement = 'object' | 'link'

export function useGraphStyleCustomisation(
    actionsEngine: ActionsEngine,
    universe: UniverseType,
    explorationId: ExplorationId,
    explorationState: ExplorationState,
    editorState: EditorState,
    vertexOrEdge: VertexOrEdge,
): UseGraphStyleCustomisationReturnType {
    const [state, dispatch] = useReducer(graphStyleCustomisationReducer, INITIAL_STATE);
    const intl = useIntl();
    const notifications = useArgNotifications();

    const [styleCapabilities, setStyleCapabilities] = useStateEquals<ExplorationVisualizationStyleCapabilities | undefined>(undefined);
    const initialStateRef = useRef<GraphCustomizerState>(state);

    const initialStyles = useRef<ExplorationVisualizationStyles>(INITIAL_EXPLORATION_STYLES);
    const currentStyles = useRef<ExplorationVisualizationStyles>(INITIAL_EXPLORATION_STYLES);
    const shouldUpdateInitialStyle = useRef<boolean>(true);

    const objectTypeListWithCapabilities = useMemo<ConfigurableVertices | ConfigurableEdges>(() => {
        return (vertexOrEdge === VertexOrEdge.Vertex ? styleCapabilities?.configurableVertices : styleCapabilities?.configurableEdges) ?? {};
    }, [styleCapabilities, vertexOrEdge]);


    const defaultVertexStyle = useMemo(() => {
        if (!state?.label) {
            return INITIAL_STATE;
        }

        const objectProperties: UniversePropertyType[] = VertexUtils.getType(universe, state.label)?.properties ?? [];
        const defaultObjectProperty = objectProperties.filter((property) => (
            property.isTitle
        ))[0];

        const defaultVertexStyle = VertexUtils.getStyle(universe, state.label);

        const defaultGraphStyle: GraphStylesInfos = {
            titleProperty: defaultObjectProperty?.name,
            clusteringProperty: undefined,
            groupbyProperty: undefined,
            timelineProperty: undefined,
            geographicalProperty: undefined,
            ruleSets: {},
            userDefinedContent: {
                fillColor: defaultVertexStyle?.fillColor,
                iconFontFamily: defaultVertexStyle?.iconFontFamily,
                iconName: defaultVertexStyle?.iconName,
                iconScale: defaultVertexStyle?.iconScale,
                size: defaultVertexStyle?.size,
                strokeColor: defaultVertexStyle?.strokeColor,
            },
        };

        return defaultGraphStyle;
    }, [universe, state.label]);

    const undoRedoFactory = useCallback(async (
        initialStateRef: React.MutableRefObject<GraphCustomizerState>,
        state: GraphCustomizerState,
        explorationId: ExplorationId,
        actionType: TYPES,
    ) => {
        try {
            const action = createChangeExplorationStyleUndoRedo(
                dispatch,
                initialStateRef,
                state,
                explorationId,
                explorationState,
                editorState,
                actionType,
                vertexOrEdge,
            );

            if (!action) {
                return;
            }
            await actionsEngine.do(action);
        } catch (error) {
            console.error(error);
        }
    }, [actionsEngine, explorationState, vertexOrEdge, editorState]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const undoRedoHandler = useCallback(debounce(undoRedoFactory, 100), [undoRedoFactory]);

    const objectTypeList = useMemo(() => {
        const list = keys(objectTypeListWithCapabilities).sort((v1, v2) => stringSorter<string>(v1, v2, item => item));

        return list;
    }, [objectTypeListWithCapabilities]);

    const [fetchExplorationVertexStyle, getExplorationStyleDefinitionProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor, vertexLabel: string) => {
        try {
            const explorationStyle = await ExplorationConnector.getInstance().getExplorationStyleDefinition(
                explorationId,
                vertexLabel,
                vertexOrEdge,
                progressMonitor,
            );

            return explorationStyle;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            console.error(error);
            notifications.snackError(undefined, error as Error);
        }
    }, [explorationId, notifications, vertexOrEdge], 'Get ExplorationVisualization Style Capabilities');

    const onVertexLabelChange = useCallback(async (vertexLabel: string) => {
        if (vertexLabel === state.label &&
            objectTypeList &&
            objectTypeList.includes(vertexLabel)) {
            // avoid blinking
            return;
        }

        const explorationStyleDefinition = await fetchExplorationVertexStyle(vertexLabel);
        if (!explorationStyleDefinition) {
            return;
        }

        const payload: GraphStylesInfos = {
            label: vertexLabel,
            titleProperty: explorationStyleDefinition.titleProperty,
            userDefinedContent: explorationStyleDefinition?.userDefinedContent ?? {},
            ruleSets: explorationStyleDefinition?.ruleSets ?? {},
            clusteringProperty: explorationStyleDefinition.clusteringProperty,
            geographicalProperty: explorationStyleDefinition.geographicalProperty,
            groupbyProperty: explorationStyleDefinition.groupbyProperty,
            timelineProperty: explorationStyleDefinition.timelineProperty,
        };

        dispatch({
            type: TYPES.SET_VERTEX_LABEL,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_VERTEX_LABEL),
            payload,
        });
    }, [state.label, objectTypeList, fetchExplorationVertexStyle, undoRedoHandler, explorationId]);

    const fetchExplorationVisualizationStyleCapabilities = useCallback(async (progressMonitor: ProgressMonitor) => {
        try {
            const styleCapabilities = await ExplorationConnector.getInstance().getExplorationVisualizationStyleCapabilities(explorationId, progressMonitor);

            setStyleCapabilities(styleCapabilities);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            console.error(error);
            notifications.snackError(undefined, error as Error);
        }
    }, [explorationId, notifications, setStyleCapabilities]);

    const fetchExplorationStyles = useCallback(async (progressMonitor: ProgressMonitor) => {
        progressMonitor.beginTask(messages.fetchExplorationVisualizationStyleCapabilities, 1);

        try {
            const explorationVisualizationStyles = await ExplorationConnector.getInstance()
                .getExplorationVisualizationStyles(explorationId, progressMonitor);
            currentStyles.current = {
                templateId: explorationVisualizationStyles?.templateId,
                vertexStyles: explorationVisualizationStyles.vertexStyles,
                edgeStyles: explorationVisualizationStyles.edgeStyles,
            };

            if (shouldUpdateInitialStyle.current) {
                initialStyles.current = {
                    templateId: explorationVisualizationStyles?.templateId,
                    vertexStyles: explorationVisualizationStyles.vertexStyles,
                    edgeStyles: explorationVisualizationStyles.edgeStyles,
                };
                shouldUpdateInitialStyle.current = false;
            }

            return explorationVisualizationStyles;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.loadExplorationVisualizationsStyleError }, error as Error);
            throw error;
        }
    }, [explorationId, notifications]);

    useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        if (!explorationId) {
            return;
        }

        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await fetchExplorationStyles(sub1);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await fetchExplorationVisualizationStyleCapabilities(sub2);
        } catch (error) {
            console.error(error);
        }
    }, [explorationState.stateId]);

    useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        if (!explorationId) {
            return;
        }

        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await fetchExplorationVisualizationStyleCapabilities(sub1);

            const sub2 = new SubProgressMonitor(progressMonitor, 1);
            await fetchExplorationStyles(sub2);
        } catch (error) {
            console.error(error);
        }
    }, [fetchExplorationStyles, fetchExplorationVisualizationStyleCapabilities], undefined, 1, GLOBAL_PM);

    useEffectAsync(async () => {
        if (!objectTypeList || objectTypeList.length === 0) {
            return;
        }

        if (!state.label || (!objectTypeList.includes(state.label) && initialStateRef.current.label === state.label)) {
            const firstObjectTypeLabel = objectTypeList[0];
            await onVertexLabelChange(firstObjectTypeLabel);
        }
    }, [objectTypeList, state.label, onVertexLabelChange]);

    const clusterableProperties = useMemo(() => {
        if (vertexOrEdge === VertexOrEdge.Vertex && state.label && objectTypeListWithCapabilities[state.label]) {
            const objectTypeProperties = getUniverseVertexTypeByName((universeVertexType) => universeVertexType.name === state.label, universe)?.properties;

            if (!objectTypeProperties) {
                return [];
            }

            const clusterableReducedProperties = fromPairs(
                filter(
                    toPairs((objectTypeListWithCapabilities as ConfigurableVertices)[state.label].properties),
                    (entry) => entry[1].isClusterable,
                ),
            );

            const clusterablesProperties = objectTypeProperties.filter(p => clusterableReducedProperties[p.name]);

            return clusterablesProperties;
        }

        return [];
    }, [objectTypeListWithCapabilities, state.label, universe, vertexOrEdge]);

    const geographyProperties = useMemo(() => {
        if (state.label) {
            return VertexUtils.getVertexSchema(universe, state.label)
                ?.properties?.filter((property) => property.type === UniversePropertyTypes.Coordinates)
                ?? [];
        }

        return [];
    }, [universe, state.label]);

    const canBeDefinedAsTitleProperties = useMemo(() => {
        if (state.label && objectTypeListWithCapabilities[state.label]) {
            return keys(
                fromPairs(
                    filter(
                        toPairs(objectTypeListWithCapabilities[state.label].properties),
                        (entry) => entry[1].canBeDefinedAsTitle,
                    ),
                ),
            );
        }

        return [];
    }, [objectTypeListWithCapabilities, state.label]);

    const properties = useMemo(() => {
        if (state.label && objectTypeListWithCapabilities[state.label]) {
            return chain(objectTypeListWithCapabilities[state.label].properties)
                .omitBy(
                    (property, propertyName) => OMIT_BY_PROPERTY_TYPE_FILTER.includes(propertyName)
                        || (vertexOrEdge === VertexOrEdge.Vertex && !(property as VertexPropertyParameters).isClusterable),
                )
                .keys()
                .value();
        }

        return [];
    }, [objectTypeListWithCapabilities, state.label, vertexOrEdge]);

    const onToggleDefaultPropClick = useCallback(() => {
        dispatch({
            type: TYPES.TOGGLE_DEFAULT_PROP,
            payload: undefined,
        });
    }, []);

    const onResetStyles = useCallback(
        async (vertexLabel: string) => {
            try {
                dispatch({
                    type: TYPES.RESET,
                    undoRedo: (newState: GraphCustomizerState) =>
                        undoRedoHandler(initialStateRef, newState, explorationId, TYPES.RESET),
                    payload: {
                        ...initialStyles.current.vertexStyles[vertexLabel],
                    },
                });
            } catch (error) {
                console.error(error);
            }
        },
        [undoRedoHandler, explorationId],
    );

    const refreshStyles = useCallback(async (progressMonitor: ProgressMonitor, vertexLabel: string, type: TYPES) => {
        try {
            const explorationStyleDefinition = await fetchExplorationVertexStyle(vertexLabel);
            shouldUpdateInitialStyle.current = true;
            explorationState.change();
            if (!explorationStyleDefinition) {
                return;
            }

            dispatch({
                type: type,
                undoRedo: (newState: GraphCustomizerState) => {
                    undoRedoHandler(initialStateRef, newState, explorationId, type);
                },
                payload: type === TYPES.RESET_STYLES ? {
                    ...INITIAL_STATE,
                    ...explorationStyleDefinition,
                } : explorationStyleDefinition,
            });
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            console.error(error);
        }
    }, [explorationState, fetchExplorationVertexStyle, undoRedoHandler, explorationId]);

    const [resetStyles, resetStylesProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await ExplorationConnector.getInstance().resetExplorationStyles(explorationId, sub1);

            if (state?.label) {
                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                await refreshStyles(sub2, state.label, TYPES.RESET_STYLES);
            }
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.resetExplorationStyleError }, error as Error);
            throw error;
        }
    }, [explorationId, state.label, refreshStyles, notifications]);

    const loadStyles = useCallback(async (
        templateId: string,
        progressMonitor: ProgressMonitor,
        vertexStyles?: Record<UniverseVertexTypeName, GraphStylesInfos>,
        edgeStyles?: Record<UniverseEdgeTypeName, GraphStylesInfos>,
    ) => {
        try {
            const sub1 = new SubProgressMonitor(progressMonitor, 1);
            await ExplorationConnector.getInstance().applyExplorationVisualizationStyles(explorationId, templateId, vertexStyles, edgeStyles, sub1);

            if (state?.label) {
                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                await refreshStyles(sub2, state.label, TYPES.LOAD_STYLES);
            }
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.loadExplorationStyleError }, error as Error);
        }
    }, [explorationId, state.label, refreshStyles, notifications]);

    const [refreshInitialStyles, refreshInitialStylesProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            const explorationVisualizationStyles = await fetchExplorationStyles(progressMonitor);
            if (!explorationVisualizationStyles) {
                return;
            }

            initialStyles.current = explorationVisualizationStyles;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            console.error(error);
        }
    }, [fetchExplorationStyles]);

    const onDisplayPropertyChange = useCallback(async (displayProperty?: string) => {
        dispatch({
            type: TYPES.UPDATE_DISPLAY_PROPERTY,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.UPDATE_DISPLAY_PROPERTY),
            payload: displayProperty,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const onSizeChange = useCallback(async (size: number | null) => {
        let _size = size;
        if (_size === null || _size < GRAPH_NODE_MIN_SIZE) {
            _size = GRAPH_NODE_MIN_SIZE;
        }

        if (!_size) {
            return;
        }

        const _sizeRounded = Number((Number((_size * 10).toFixed(2)) / 10).toFixed(2));
        dispatch({
            type: TYPES.UPDATE_SIZE,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.UPDATE_SIZE),
            payload: _sizeRounded,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setIconAndColorVisibility = useCallback((visible: boolean) => {
        dispatch({
            type: TYPES.SET_COLOR_AND_ICON_VISIBILITY,
            payload: visible,
        });
    }, []);

    const setFillColor = useCallback(async (color: string) => {
        dispatch({
            type: TYPES.SET_FILL_COLOR,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_FILL_COLOR),
            payload: color,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setStrokeColor = useCallback(async (color: string) => {
        dispatch({
            type: TYPES.SET_STROKE_COLOR,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_STROKE_COLOR),
            payload: color,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setIconColor = useCallback(async (color: string) => {
        dispatch({
            type: TYPES.SET_ICON_COLOR,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_ICON_COLOR),
            payload: color,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setIcon = useCallback(async (iconName: string) => {
        dispatch({
            type: TYPES.SET_ICON,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_ICON),
            payload: iconName,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setLineStyle = useCallback(async (lineStyle: LineStyle) => {
        dispatch({
            type: TYPES.SET_ADVANCED_STYLE_LINE_STYLE,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_ADVANCED_STYLE_LINE_STYLE),
            payload: lineStyle,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setLineAnimation = useCallback(async (lineAnimation: LineAnimation) => {
        dispatch({
            type: TYPES.SET_ADVANCED_STYLE_LINE_ANIMATION,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(
                    initialStateRef,
                    newState,
                    explorationId,
                    TYPES.SET_ADVANCED_STYLE_LINE_ANIMATION,
                ),
            payload: lineAnimation,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setIsStrokeTransparent = useCallback(async (isTransparent: boolean) => {
        dispatch({
            type: TYPES.SET_STROKE_TRANSPARENT_COLOR,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_STROKE_TRANSPARENT_COLOR),
            payload: isTransparent,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setClusteringProperty = useCallback(async (clusteringProperty: string) => {
        dispatch({
            type: TYPES.SET_CLUSTERING_PROPERTY,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_CLUSTERING_PROPERTY),
            payload: clusteringProperty,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setGeographicalProperty = useCallback(async (geographicalProperty: string) => {
        dispatch({
            type: TYPES.SET_DEFAULT_GEOGRAPHY_PROPERTY,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_DEFAULT_GEOGRAPHY_PROPERTY),
            payload: geographicalProperty,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const isSomeProgressMonitorsRunning =
        getExplorationStyleDefinitionProgressMonitor?.isRunning ||
        refreshInitialStylesProgressMonitor?.isRunning;

    const setAdvancedStyleProperty = useCallback(async (advancedStyleType: AdvancedStyleType, property: string | undefined, controlType?: StyleControlType) => {
        let value: DiscreteValue | Interval | Gradient | null = null;

        if (property !== undefined && controlType !== undefined) {
            switch (controlType) {
                case 'gradient':
                    value = {
                        gradient: {
                            type: 'Closed' as ContinuousValueIntervaleType,
                            left: null,
                            right: null,
                        },
                    } as Gradient;

                    break;
                case 'interval':
                    value = {
                        interval: {
                            type: 'Open' as ContinuousValueIntervaleType,
                            left: null,
                            right: null,
                        },
                    } as Interval;
                    break;

                default:
                    value = {
                        value: null,
                    } as DiscreteValue;
                    break;
            }
        }

        const payload = {
            advancedStyleType,
            property,
            value,
            controlType,
        };

        dispatch({
            type: TYPES.SET_ADVANCED_STYLE_PROPERTY,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_ADVANCED_STYLE_PROPERTY),
            payload,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setAdvancedStyleDiscretePropertyValue = useCallback(async (advancedStyleType: AdvancedStyleType, value: string | number | boolean | null, index: number, isBool?: boolean) => {
        const payload = {
            advancedStyleType,
            value,
            index,
            state,
        };

        dispatch({
            type: TYPES.SET_ADVANCED_DISCRETE_PROPERTY_VALUE,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(
                    initialStateRef,
                    newState,
                    explorationId,
                    TYPES.SET_ADVANCED_DISCRETE_PROPERTY_VALUE,
                ),
            payload,
        });
    }, [state, explorationId, undoRedoHandler, initialStateRef]);

    const setAdvancedStyleRangeValue = useCallback(async (advancedStyleType: AdvancedStyleType, side: Side, value: number | Dayjs | null, index: number) => {
        const payload = {
            advancedStyleType,
            side,
            value,
            index,
        };
        dispatch({
            type: TYPES.SET_RANGE_VALUE,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_RANGE_VALUE),
            payload,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setAdvancedStyleRangeIntervalType = useCallback(async (advancedStyleType: AdvancedStyleType, side: Side, index: number) => {
        const payload = {
            advancedStyleType,
            clickSide: side,
            position: index,
        };
        const actionType = TYPES.SET_ADVANCED_STYLE_INTERVAL_TYPE;
        dispatch({
            type: actionType,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, actionType),
            payload,
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setAdvancedStyle = useCallback(async (advancedStyleType: AdvancedStyleType, style: Record<string, any>, index: number) => {
        dispatch({
            type: TYPES.SET_ADVANCED_STYLE,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.SET_ADVANCED_STYLE),
            payload: { advancedStyleType, style, index },
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const addNewAdvancedStyleRule = useCallback(async (advancedStyleType: AdvancedStyleType, value: DiscreteValue | Interval | null) => {
        dispatch({
            type: TYPES.ADD_NEW_ADVANCED_STYLE_RULE,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.ADD_NEW_ADVANCED_STYLE_RULE),
            payload: { advancedStyleType, value },
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const removeAdvancedStyleRule = useCallback(async (advancedStyleType: AdvancedStyleType, index: number) => {
        dispatch({
            type: TYPES.REMOVE_ADVANCED_STYLE_RULE,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.REMOVE_ADVANCED_STYLE_RULE),
            payload: { advancedStyleType, position: index },
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const toggleGradientInterval = useCallback(async (advancedStyleType: AdvancedStyleType, gradientOrInterval: GradientOrInterval, property: string) => {
        dispatch({
            type: TYPES.TOGGLE_GRADIENT_INTERVAL,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(initialStateRef, newState, explorationId, TYPES.TOGGLE_GRADIENT_INTERVAL),
            payload: { advancedStyleType, gradientOrInterval, property },
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const setGradientPropertyRangeValue = useCallback(async (type: 'property' | 'size', side: Side, value: number | Dayjs | null) => {
        dispatch({
            type: TYPES.SET_GRADIENT_PROPERTY_RANGE_INFOS,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(
                    initialStateRef,
                    newState,
                    explorationId,
                    TYPES.SET_GRADIENT_PROPERTY_RANGE_INFOS,
                ),
            payload: { side, value, type },
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const addUndefinedValueStylesRule = useCallback(async (advancedStyleType: AdvancedStyleType) => {
        dispatch({
            type: TYPES.ADD_UNDEFINED_VALUE_STYLES_RULE,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(
                    initialStateRef,
                    newState,
                    explorationId,
                    TYPES.ADD_UNDEFINED_VALUE_STYLES_RULE,
                ),
            payload: { advancedStyleType },
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    const removeUndefinedValueStylesRule = useCallback(async (advancedStyleType: AdvancedStyleType) => {
        dispatch({
            type: TYPES.REMOVE_UNDEFINED_VALUE_STYLES_RULE,
            undoRedo: (newState: GraphCustomizerState) =>
                undoRedoHandler(
                    initialStateRef,
                    newState,
                    explorationId,
                    TYPES.REMOVE_UNDEFINED_VALUE_STYLES_RULE,
                ),
            payload: { advancedStyleType },
        });
    }, [explorationId, undoRedoHandler, initialStateRef]);

    return {
        initialStyles: initialStyles.current,
        currentStyles: currentStyles.current,

        defaultVertexStyle,
        state,

        objectTypeList,
        clusterableProperties,
        canBeDefinedAsTitleProperties,
        properties,
        geographyProperties,

        refreshInitialStyles,

        loadStyles,
        resetStyles,

        setFillColor,
        setStrokeColor,
        setIconColor,
        setIcon,
        setIsStrokeTransparent,
        setIconAndColorVisibility,
        setClusteringProperty,
        setGeographicalProperty,

        setAdvancedStyleProperty,
        setAdvancedStyleDiscretePropertyValue,
        setAdvancedStyleRangeValue,
        setAdvancedStyleRangeIntervalType,
        setAdvancedStyle,

        addNewAdvancedStyleRule,
        removeAdvancedStyleRule,

        onVertexLabelChange,
        isSomeProgressMonitorsRunning,
        onToggleDefaultPropClick,
        onDisplayPropertyChange,
        onSizeChange,

        toggleGradientInterval,
        setGradientPropertyRangeValue,

        intl,

        onResetStyles,

        addUndefinedValueStylesRule,
        removeUndefinedValueStylesRule,
        setLineStyle,
        setLineAnimation,
    };
}
