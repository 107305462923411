import { RtApi, RtApiSubType } from '../../../utils/rt-states/rt-api';
import { getWorkflowBaseApi } from '../../../utils/connectors/api-url';
import { WORKFLOWS_ALL_EVENTS } from './events';

let workflowsRtApi: RtApi | undefined = undefined;

export function getWorkflowsRtApi(): RtApi {
    if (workflowsRtApi) {
        return workflowsRtApi;
    }

    const api = getWorkflowBaseApi();
    if (!api) {
        throw new Error('WorkflowsApi is not defined');
    }

    workflowsRtApi = {
        argonosModuleId: 'chapsVision.Workflows',
        subType: RtApiSubType.Workflows,
        api,
        defaultServiceUrl: '/workflows',
        events: WORKFLOWS_ALL_EVENTS,
    };

    return workflowsRtApi;
}
