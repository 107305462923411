import { OntologyPropertyType } from '../universes/ontology/types';

export function getPropertyIconName(propertyType: OntologyPropertyType, possibleValues?: boolean) {
    switch (propertyType) {
        case OntologyPropertyType.Boolean:
            return 'icon-boolean';

        case OntologyPropertyType.Date:
        case OntologyPropertyType.DateTime:
            return 'icon-calendar';

        case OntologyPropertyType.String:
            if (!possibleValues) {
                return 'icon-text';
            }

            return 'icon-list';

        case OntologyPropertyType.Text:
        case OntologyPropertyType.Char:
        case OntologyPropertyType.Guid:
        case OntologyPropertyType.Email:
        case OntologyPropertyType.Url:
        case OntologyPropertyType.PhoneNumber:
        case OntologyPropertyType.Address:
        case OntologyPropertyType.Country:
            return 'icon-text';

        case OntologyPropertyType.Coordinates:
            return 'icon-globe';

        case OntologyPropertyType.Byte:
        case OntologyPropertyType.Int16:
        case OntologyPropertyType.Int32:
        case OntologyPropertyType.Int64:
        case OntologyPropertyType.Double:
            return 'icon-numeric';

        case OntologyPropertyType.Single:
            return 'icon-decimal';
    }

    return '';
}
