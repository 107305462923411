import { RtMessageEventList } from 'src/utils/rt-states/events';
import { ENTITY_ALL_EVENTS, ENTITY_EVENTS, FOLDERS_EVENTS } from '../../utils/rt-states/folders/events';

export const PROCESS_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
    // Le contenu du process a changé  (mais pas son status, completness, debug-infos et output)
    'ProcessUpdated': true,
};

export const PROCESS_STATUS_EVENTS: RtMessageEventList = {
    // Le status du processus a changé
    'ProcessStatusUpdated': true,
};

export const PROCESS_STATISTICS_EVENTS: RtMessageEventList = {
    'ProcessInstanceCreated': true,
    'ProcessInstanceUpdated': true,
};

export const PROCESS_PRIORITY_EVENTS: RtMessageEventList = {
    // La priorité du processus a changé
    'ProcessPriorityUpdated': true,
};

export const PROCESS_COMPONENTS_PRIORITY_EVENTS: RtMessageEventList = {
    // La priorité du processus a changé
    'ProcessComponentsPriorityUpdated': true,
};

export const PROCESS_COMPONENTS_COMPLETENESS_EVENTS: RtMessageEventList = {
    // La liste de components completeness a changé
    'ProcessComponentsCompletenessUpdated': true,
};

export const PROCESS_DEBUG_INFOS_EVENTS: RtMessageEventList = {
    // La liste des debug infos a changé
    'ProcessDebugInfoUpdated': true,
};

export const PROCESS_OUTPUT_EVENTS: RtMessageEventList = {
    // L'output du process a changé
    'ProcessOutputUpdated': true,
};

export const PROCESS_SCHEDULING_EVENTS: RtMessageEventList = {
    'ProcessSchedulingUpdated': true,
};

export const PROCESS_TAGS_EVENTS: RtMessageEventList = {
    'ProcessTagCreated': true,
    'ProcessTagDeleted': true,
//    'ProcessTagUpdated': true,
};

export const PROCESSING_DATA_EVENTS: RtMessageEventList = {
    'ProcessMonitoringDataUpdated': true,
};

export const PROCESS_SAMPLE_DATA_EVENTS: RtMessageEventList = {
    'ProcessSampleDataUpdated': true,
};

export const COMPOSITE_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
    // Le contenu du composite a changé
    'CompositeComponentUpdated': true,
};

export const COMPOSITE_TAGS_EVENTS: RtMessageEventList = {
    'CompositeComponentTagCreated': true,
    'CompositeComponentTagDeleted': true,
//    'CompositeComponentTagUpdated': true,
};

export const PREPARATION_ENTITY_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
    ...PROCESS_PRIORITY_EVENTS,
    ...PROCESS_SCHEDULING_EVENTS,
    'ProcessUpdated': true,
};

export const SECRET_EVENTS: RtMessageEventList = {
    ...ENTITY_EVENTS,
    // Le contenu du composite a changé
    'SecretUpdated': true,
};

export const PROCESS_GLOBAL_INSTANCES:RtMessageEventList = {
    ProcessInstancesCreated: true,
};

export const REFERENCE_TABLES_EVENT_NAMES:RtMessageEventList = {
    ReferenceTableRemoved: true,
};


export const REFERENCES_TABLE_EVENT_NAMES:RtMessageEventList = {
    ReferencesUpdated: true,
};

export const PROCESSES_EVENT_NAMES:RtMessageEventList = {
    ProcessCreated: true,
    ProcessDeleted: true,
};

export const PREPARATION_ALL_ENTITY_EVENTS:RtMessageEventList = {
    ...ENTITY_ALL_EVENTS,
    ...FOLDERS_EVENTS,

    ...PROCESS_EVENTS,
    ...PROCESS_STATUS_EVENTS,
    ...PROCESS_COMPONENTS_COMPLETENESS_EVENTS,
    ...PROCESS_DEBUG_INFOS_EVENTS,
    ...PROCESS_OUTPUT_EVENTS,
    ...PROCESS_SAMPLE_DATA_EVENTS,
    ...PROCESS_PRIORITY_EVENTS,
    ...PROCESS_COMPONENTS_PRIORITY_EVENTS,
    ...PROCESS_STATISTICS_EVENTS,
    ...PROCESS_TAGS_EVENTS,
    ...PROCESS_SCHEDULING_EVENTS,

    ...PROCESSING_DATA_EVENTS,

    ...COMPOSITE_EVENTS,
    ...COMPOSITE_TAGS_EVENTS,

    ...SECRET_EVENTS,
    ...PROCESS_GLOBAL_INSTANCES,

    ...PROCESSES_EVENT_NAMES,

    // ...REFERENCE_TABLES_EVENT_NAMES,// Not yet implemented by the BACK
    // ...REFERENCES_TABLE_EVENT_NAMES, // Not yet implemented by the BACK
};

export const PREPARATION_EVENTS:RtMessageEventList = {
    ComponentListChanged: true,
};
