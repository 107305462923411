import { ClassValue } from 'src/components/basic';
import { WorkflowProperty } from './workflow-property';
import { WorkflowId } from '../model/workflows';


interface WorkflowDescriptionProps {
    className?: ClassValue;
    workflowId: WorkflowId;
    search?: string;
}

export function WorkflowDescription(props: WorkflowDescriptionProps) {
    const {
        className,
        workflowId,
        search,
    } = props;

    return (
        <WorkflowProperty
            className={className}
            workflowId={workflowId}
            render='description'
            search={search}
        />
    );
}
