import React, { useCallback, useRef, useState } from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgButton, ArgButtonProps, ButtonClickEvent } from '../arg-button/arg-button';
import { DEFAULT_BUTTON_TYPE, DEFAULT_SIZE } from '../defaults';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgMenuItem } from '../arg-menu/arg-menu-item';
import { ArgRenderedText } from '../types';

import './arg-action-dropdown-button.less';

export type ArgActionKey = string;

export interface ArgActionDropdownButtonAction {
    key: ArgActionKey;
    label: ArgRenderedText;
    isDisabled?: boolean;
    tooltip?: ArgRenderedText;
    onClick?: (event: ButtonClickEvent) => (Promise<void> | void);
}

export interface ArgActionDropdownButtonProps extends ArgButtonProps {
    /**
     * An HTML class for the container
     */
    className?: ClassValue;
    /**
     * A boolean to explicitly render the dropdown button disabled
     */
    isDropDownButtonDisabled?: boolean;
    /**
     * The actions list for the component's dropdown button
     */
    actions: ArgActionDropdownButtonAction[];
    /**
     * The default actionKey for the component's dropdown button
     */
    defaultActionKey?: ArgActionKey;
}

/**
 * This component is designed to display an action dropdown button.
 *
 * @example
 * ```
 * <ArgActionDropdownButton
 *     className={classNames('&-right-buttons')}
 *     popoverClassName={classNames('&-insert-popover')}
 *     type='primary'
 *     label={currentCartAction.message}
 *     disabled={cartItemsSelectedUniverses.length !== 1}
 *     tooltip={tooltip}
 *     onClick={() => handleActionsMenuClick(currentCartAction.value)}
 *     actions={actions}
 * />
 * ```
 */
export function ArgActionDropdownButton(props: ArgActionDropdownButtonProps) {
    const {
        className,
        popoverClassName,
        actions,
        size = DEFAULT_SIZE,
        disabled,
        isDropDownButtonDisabled = disabled,
        type = DEFAULT_BUTTON_TYPE,
        defaultActionKey,
    } = props;

    const classNames = useClassNames('arg-action-dropdown-button');
    const buttonsContainerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);


    const handleActionClick = useCallback((action: ArgActionDropdownButtonAction, event: ButtonClickEvent) => {
        action?.onClick?.(event);

        setIsOpen(false);
    }, []);

    const handleClickActions = useCallback((evt: MenuInfo) => {
        const { key } = evt;

        const action = actions.find((action: ArgActionDropdownButtonAction) => action.key === key);
        if (!action) {
            setIsOpen(false);

            return;
        }
        handleActionClick(action, evt.domEvent);
    }, [actions, handleActionClick]);

    const menuCls = {
        [`size-${size}`]: true,
    };

    const popoverMenu = (
        <ArgMenu
            className={classNames('&-popover-menu', menuCls)}
            data-testid='arg-action-dropdown-button-popover-menu'
            onClick={handleClickActions}
        >
            {
                actions.map((action: ArgActionDropdownButtonAction) => {
                    if (defaultActionKey === action.key) {
                        return null;
                    }

                    return (
                        <ArgMenuItem
                            key={action.key}
                            data-testid={`extra-actions-${action.key}`}
                            className={classNames('&-popover-menu-item')}
                            disabled={action.isDisabled}
                            tooltip={action.tooltip}
                        >
                            {renderText(action.label)}
                        </ArgMenuItem>
                    );
                })
            }
        </ArgMenu>
    );

    const iconName = getIconName(isOpen);

    const buttonProps = { ...props };

    if (defaultActionKey) {
        const action = actions.find((a) => a.key === defaultActionKey);
        if (action) {
            buttonProps.label = action.label;
            buttonProps.disabled = action.isDisabled;
            buttonProps.tooltip = action.tooltip;
            buttonProps.onClick = (event) => handleActionClick(action, event);
        }
    }

    return (
        <div ref={buttonsContainerRef} className={classNames('&', className)}>
            <ArgButton
                {...buttonProps}
                type={type}
                className={classNames('&-action')}
            />
            <ArgButton
                data-testid='dropwdown-toggle-button'
                className={classNames('&-dropdown')}
                type={type}
                icon={iconName}
                size={size}
                disabled={isDropDownButtonDisabled}
                popover={popoverMenu}
                popoverPlacement='bottomRight'
                popoverClassName={classNames('&-popover', popoverClassName)}
                onPopoverVisibleChange={setIsOpen}
                popoverVisible={isOpen}
            />
        </div>
    );
}

function getIconName(isOpen: boolean) {
    if (isOpen) {
        return 'icon-cheveron-up';
    }

    return 'icon-cheveron-down';
}
