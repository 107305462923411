import { defineMessages } from 'react-intl';
import { isEmpty } from 'lodash';

import { Workflow, WORKFLOW_DND_TYPE } from '../../../framework/workflows';
import {
    WORKFLOWS_VIEW_LEFT_CONTEXT,
    WorkflowsEnvironmentContext,
} from '../environment-contexts/workflows-environment-context';
import { registerToolItem, registerToolItemForMimeType, TargetEnvironmentContext } from '../../../components/basic';

const messages = defineMessages({
    purge: {
        id: 'settings.workflows.actions.Purge',
        defaultMessage: 'Purge',
    },
});

async function purgeWorkflows(workflows: Workflow[], workflowEnvironmentContext: WorkflowsEnvironmentContext): Promise<void> {
}


export function registerPurgeWorkflowsActions() {
    registerToolItemForMimeType<WorkflowsEnvironmentContext, Workflow>(WORKFLOW_DND_TYPE, {
        path: 'actions/purge',
        label: messages.purge,
        disabled: (environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>) => {
            const workflow = environmentContext.firstItem();
            if (!workflow) {
                return false;
            }
            const result = !workflow.instancesCount;

            return result;
        },
        onClick: async (_, environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>): Promise<void> => {
            const workflow = environmentContext.firstItem();
            if (!workflow) {
                return;
            }
            await purgeWorkflows([workflow], environmentContext);
        },
    });

    registerToolItem<TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>>(WORKFLOWS_VIEW_LEFT_CONTEXT, {
        path: 'actions/actions/purge',
        type: 'button',
        order: 300,
        label: messages.purge,
        disabled: (workflowEnvironmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>) => {
            const workflows = workflowEnvironmentContext.listItemKeys().filter((workflowId) => {
                const workflow = workflowEnvironmentContext.getItem(workflowId)!;

                if (!workflow.instancesCount) {
                    return false;
                }

                return true;
            });

            return isEmpty(workflows);
        },
        onClick: async (_, workflowEnvironmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>) => {
            const workflows = workflowEnvironmentContext.listItemKeys().map((workflowId) => {
                const workflow = workflowEnvironmentContext.getItem(workflowId)!;

                return workflow;
            });

            await purgeWorkflows(workflows, workflowEnvironmentContext);
        },
    });
}
