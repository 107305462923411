import { UniverseId } from 'src/exploration/model/universe';
import { OntologyId } from '../universes/ontology/types';
import { ValuationPolicyId } from '../models/valuation-policy';
import { FormPolicyId } from '../models/form-policy';
import { PolicyId } from '../models/dtoApi';
import { SETTINGS_ROUTE_PATH } from '../settings-router';

export function computeValuationPolicyUrl(ontologyId: OntologyId, universeId: UniverseId) {
    return `${SETTINGS_ROUTE_PATH}/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/valuation-policies`;
}

export function computeValuationPolicyDetailsUrl(ontologyId: OntologyId, universeId: UniverseId, valuationPolicyId: ValuationPolicyId) {
    return `${SETTINGS_ROUTE_PATH}/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/valuation-policies/${encodeURIComponent(valuationPolicyId)}`;
}

export function computeFormCustomisationUrl(ontologyId: OntologyId, objectOrEdgeName: string) {
    return `${SETTINGS_ROUTE_PATH}/data_exploration/ontology/${encodeURIComponent(ontologyId)}/forms/${encodeURIComponent(objectOrEdgeName)}`;
}

export function computeFormsUrl(ontologyId: OntologyId) {
    return `${SETTINGS_ROUTE_PATH}/data_exploration/ontology/${encodeURIComponent(ontologyId)}/forms`;
}

export function computePoliciesUrl(ontologyId: OntologyId, universeId: UniverseId) {
    return `${SETTINGS_ROUTE_PATH}/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/policies`;
}

export function computePolicyDetailsUrl(ontologyId: OntologyId, universeId: UniverseId, policyId: PolicyId) {
    return `${SETTINGS_ROUTE_PATH}/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/policies/${encodeURIComponent(policyId)}`;
}

export function computeFormPoliciesUrl(ontologyId: OntologyId, universeId: UniverseId) {
    return `${SETTINGS_ROUTE_PATH}/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/forms-policies`;
}

export function computeFormPolicyDetailsUrl(ontologyId: OntologyId, universeId: UniverseId, formPolicyId: FormPolicyId) {
    return `${SETTINGS_ROUTE_PATH}/data_exploration/ontology/${encodeURIComponent(ontologyId)}/universe/${encodeURIComponent(universeId)}/forms-policies/${encodeURIComponent(formPolicyId)}`;
}

export function computeOntologyUrl(ontologyId: OntologyId) {
    return `${SETTINGS_ROUTE_PATH}/data_exploration/ontology/${encodeURIComponent(ontologyId)}/schema`;
}
