import React, { FocusEvent, ReactNode, Ref, useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgInput, ArgInputCustomComponentProps } from './arg-input';
import { KeyCommand } from '../keybindings/keybinding';
import { ArgChangeReason, ArgInputType, ArgSize, ArgInputState, ArgPlaceholderText } from '../types';
import { RESERVED_KEYBINDING_ATTRIBUTE } from '../keybindings/keybindings-engine';
import { createKeyCommandFromEvent, mapSpecialCommand, parseKeyCommand } from '../keybindings/utils';
import { isMessageDescriptor } from '../utils/is-message-descriptor';

import './arg-input-key.less';


const messages = defineMessages({
    placeholder: {
        id: 'basic.arg-input-key.Placeholder',
        defaultMessage: 'Use keyboard',
    },
});

export interface ArgInputKeyProps {
    /**
     * The className for the input key component
     */
    className?: ClassValue;
    /**
     * The size for the input key component
     */
    size?: ArgSize;
    /**
     * The state for the input key component
     */
    state?: ArgInputState;
    /**
     * The type for the input key component
     */
    type?: ArgInputType;
    /**
     * The value for the input key component
     */
    value?: KeyCommand;
    onChange: (newValue: KeyCommand, reason: ArgChangeReason) => void;
    /**
     * A boolean to explicitly disable the input key component
     */
    disabled?: boolean;
    /**
     * A boolean to explicitly readOnly the input key component
     */
    readOnly?: boolean;
    /**
     * The placeholder for the input key component
     */
    placeholder?: ArgPlaceholderText;
    /**
     * A boolean to explicitly autoFocus the input key component
     */
    autoFocus?: boolean;
    onFocus?: (event: FocusEvent) => void;
    onBlur?: (event: FocusEvent) => void;
}

/**
 * This component is designed to display an input key field.
 *
 * @example
 * ```
 * <ArgInputKey value={value} onChange={handleChangeValue} />
 * ```
 */
export function ArgInputKey(props: ArgInputKeyProps) {
    const {
        className,
        disabled,
        readOnly,
        value: externalValue,
        onChange,
        size,
        type,
        placeholder,
        autoFocus,
        onFocus,
        onBlur,
        state,
    } = props;

    const classNames = useClassNames('arg-input-key');

    const intl = useIntl();

    const useInternalValue = !('value' in props);

    const [internalValue, setInternalValue] = useState<KeyCommand | undefined>(externalValue);

    const value = (useInternalValue) ? internalValue : externalValue;

    const [focus, setFocus] = useState<boolean>(false);

    const handleFormatValue = useCallback((keyCommand: KeyCommand | null) => {
        return keyCommand || '';
    }, []);

    const handleParseValue = useCallback((value: string) => {
        return value;
    }, []);

    const handleChange = useCallback((value: KeyCommand | null, reason: ArgChangeReason) => {
        // Not used !
    }, []);

    const renderInputComponent = useCallback((props: ArgInputCustomComponentProps<KeyCommand>): ReactNode => {
        const inputProps = {
            key: 'input',
            id: props.id,
            ref: props.ref as Ref<HTMLInputElement>,
            className: classNames('&-input-focus'),
            value: '',
            onChange: () => (true),
            onFocus: props.onFocus,
            onBlur: props.onBlur,
            maxLength: props.maxLength,
            placeholder: props.placeholder,
            [RESERVED_KEYBINDING_ATTRIBUTE]: true,
        };
        let keys;
        if (props.value) {
            keys = parseKeyCommand(props.value).map((key, index) => {
                const _key = mapSpecialCommand(key);

                return <span key={index} className={classNames('&-input-key')}>{_key}</span>;
            });
        }

        return <div className={classNames('&-input', className)}>
            {keys}
            <input {...inputProps} data-testid='input-key' />
        </div>;
    }, [className, classNames]);

    const handleFocus = useCallback((event: React.FocusEvent<any>) => {
        setFocus(true);

        onFocus?.(event);
    }, [onFocus]);

    const handleBlur = useCallback((event: React.FocusEvent<any>) => {
        setFocus(false);

        onBlur?.(event);

        if (value !== internalValue) {
            onChange(internalValue || '', 'selection');
        }
    }, [onBlur, value, internalValue, onChange]);

    useEffect(() => {
        if (!focus) {
            return;
        }

        const handler = (event: KeyboardEvent) => {
            let found;
            for (let node = event.target as Element | null; node; node = node!.parentElement) {
                if (!node.hasAttribute(RESERVED_KEYBINDING_ATTRIBUTE)) {
                    continue;
                }
                found = node;
                break;
            }

            if (!found) {
                return;
            }

            event.preventDefault();

            if (readOnly) {
                return;
            }

            const command = createKeyCommandFromEvent(event);

            setInternalValue(command);
            onChange?.(command, 'keypress');
        };

        window.addEventListener('keydown', handler, { capture: true });

        return () => {
            window.removeEventListener('keydown', handler, { capture: true });
        };
    }, [focus, onChange, readOnly]);

    let _placeholder;
    if (internalValue === undefined) {
        _placeholder = placeholder;
        if (_placeholder === undefined) {
            _placeholder = messages.placeholder;
        }

        if (isMessageDescriptor(_placeholder)) {
            _placeholder = intl.formatMessage(_placeholder);
        }
    }

    return <ArgInput<KeyCommand> className={className}
                                 value={internalValue}
                                 type={type}
                                 state={state}
                                 size={size}
                                 autoFocus={autoFocus}
                                 onChange={handleChange}
                                 onFocus={handleFocus}
                                 onBlur={handleBlur}
                                 placeholder={_placeholder}
                                 readOnly={true}
                                 disabled={disabled}
                                 formatValue={handleFormatValue}
                                 parseValue={handleParseValue}
                                 renderInputComponent={renderInputComponent}
    />;
}
