import { Environment } from 'src/utils/environment';

export function getSettingsApi() {
    if (Environment.apiServerUrlSettings) {
        return Environment.apiServerUrlSettings;
    }

    return getAdministrationApi();
}

export function getDataExplorationApi() {
    if (Environment.apiServerUrlExploration) {
        return Environment.apiServerUrlExploration;
    }

    //console.warn('apiServerUrlExploration URL is not configured');

    return undefined;
}

export function getDataPreparationApi() {
    if (Environment.apiServerUrlDataPrep) {
        return Environment.apiServerUrlDataPrep;
    }

    //console.warn('apiServerUrlDataPrep URL is not configured');

    return undefined;
}

export function getDataCollectionApi() {
    if (Environment.apiServerUrlDataCollect) {
        return Environment.apiServerUrlDataCollect;
    }

    //console.warn('apiServerUrlDataCollect URL is not configured');

    return undefined;
}

export function getAdministrationApi() {
    if (Environment.apiServerUrlAdministration) {
        return Environment.apiServerUrlAdministration;
    }

    //console.warn('apiServerUrlAdministration URL is not configured');

    return undefined;
}

export function getProceoApi() {
    if (Environment.apiServerUrlProceo) {
        return Environment.apiServerUrlProceo;
    }

    //console.warn('getProceoApi URL is not configured');

    return undefined;
}

export function getKnowledgeBaseApi() {
    if (Environment.apiServerUrlKnowledgeBase) {
        return Environment.apiServerUrlKnowledgeBase;
    }

    console.warn('getKnowledgeBaseApi URL is not configured');

    return undefined;
}


export function getWorkflowBaseApi() {
    return getSettingsApi();
}


export function getWatchlistApi() {
    return getDataExplorationApi();
}

export function getNotificationsApi() {
    if (Environment.apiServerUrlNotifications) {
        return Environment.apiServerUrlNotifications;
    }

    //console.warn('apiServerUrlNotifications URL is not configured');

    return undefined;
}
