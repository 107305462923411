import { defineMessages } from 'react-intl';

import { Ontology } from '../models/dtoApi';
import { NavItem } from '../models/navigation';
import { computeFormPoliciesUrl, computeOntologyUrl, computePoliciesUrl } from '../utils/compute-url';
import { UNIVERSE_MANAGEMENT_ROUTE } from './ontology/ontology-router';
import { OntologyMetaProperty, OntologyProperty, OntologyPropertyType } from './ontology/types';
import { Property } from 'src/components/common/controls/controls-type';

const messages = defineMessages({
    universes: {
        id: 'settings.universes.menu.title',
        defaultMessage: 'Universes',
    },
    universesManagement: {
        id: 'settings.universes.menu.universe-management',
        defaultMessage: 'Universes management',
    },
    ontology: {
        id: 'settings.universes.menu.ontology',
        defaultMessage: 'Ontology',
    },
    forms: {
        id: 'settings.universes.menu.forms',
        defaultMessage: 'Forms',
    },
    policies: {
        id: 'settings.universes.menu.policies',
        defaultMessage: 'Policies',
    },
    retention: {
        id: 'settings.universes.menu.retention',
        defaultMessage: 'Auto deletion',
    },
    defaultConfiguration: {
        id: 'settings.universes.menu.default-configuration',
        defaultMessage: 'Default configuration',
    },
    valuationPolicies: {
        id: 'settings.universes.menu.valuation-policies',
        defaultMessage: 'Valuation policies',
    },
    formPolicies: {
        id: 'settings.universes.menu.form-policies',
        defaultMessage: 'Form policies',
    },
});


function universeMenuItems(ontology: Ontology): NavItem {
    return {
        path: `/settings/data_exploration/ontology/${encodeURIComponent(ontology.id)}`,
        label: { id: ontology.id, defaultMessage: ontology.name },
        icon: 'icon-universe',
        children: [
            {
                path: `/settings/data_exploration/ontology/${encodeURIComponent(ontology.id)}/universe/${encodeURIComponent(ontology.universeIds[0])}/configuration`,
                label: messages.defaultConfiguration,
                icon: 'icon-file-table-box-outline',
                iconSize: 18,
            },
            {
                path: computeOntologyUrl(ontology.id),
                label: messages.ontology,
                icon: 'icon-ontology',
                iconSize: 24,
            },
            {
                path: computeFormPoliciesUrl(ontology.id, ontology.universeIds[0]),
                label: messages.formPolicies,
                icon: 'icon-form-policy',
                iconSize: 20,
            },
            {
                path: computePoliciesUrl(ontology.id, ontology.universeIds[0]),
                label: messages.policies,
                icon: 'icon-access-policy',
                iconSize: 20,
            },
            {
                path: `/settings/data_exploration/ontology/${encodeURIComponent(ontology.id)}/universe/${encodeURIComponent(ontology.universeIds[0])}/valuation-policies`,
                label: messages.valuationPolicies,
                icon: 'icon-meta-policy',
                iconSize: 20,
            },
            {
                path: `/settings/data_exploration/ontology/${encodeURIComponent(ontology.id)}/universe/${encodeURIComponent(ontology.universeIds[0])}/retention`,
                label: messages.retention,
                icon: 'icon-trash-open',
                iconSize: 20,
            },
        ],
    };
}

export function universesNavItems(ontologies: Ontology[]): NavItem[] {
    return [
        ...ontologies.map((ontology) => universeMenuItems(ontology)),
    ];
}

export function universeManagementNavItem(): NavItem[] {
    return [{
        path: UNIVERSE_MANAGEMENT_ROUTE,
        label: messages.universesManagement,
        icon: 'icon-earth',
    }];
}

export function ontologyPropertyToControlProperty(property: OntologyProperty | OntologyMetaProperty): Property {
    const fixedValues = property.constraint?.fixedValues?.map((item) => item.value);
    const isContinuous = (property as OntologyProperty).isContinuous || undefined;

    return {
        displayName: property.displayName,
        name: property.name,
        type: property.type,
        isContinuous,
        fixedValues,
    };
}


export function getPropertyCompatibilityCode(property: OntologyProperty | OntologyMetaProperty | undefined): string | undefined {
    if (!property) {
        return undefined;
    }

    return OntologyPropertyType[property.type];
}
