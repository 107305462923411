import { defineMessages } from 'react-intl';

import { ArgMessageRenderer } from 'src/components/basic';
import { getFormattedDuration } from 'src/exploration/common/previews/common/utils';
import { UniversePropertyType } from 'src/exploration/model/universe';
import { AudioTagProperties, PdfTagProperties, TagProperties, TagType, VideoTagProperties } from './types';

const messages = defineMessages({
    annotationImage: {
        id: 'exploration.tag.utils.annotationImage',
        defaultMessage: 'Image page {pageNumber}',
    },
    annotationText: {
        id: 'exploration.tag.utils.annotationText',
        defaultMessage: '<i>Text page {pageNumber}:</i> "{textValue}"',
    },
    annotationVideo: {
        id: 'exploration.tag.utils.annotationVideo',
        defaultMessage: '<i>Video image at:</i> {time}',
    },
    annotationAudio: {
        id: 'exploration.tag.utils.annotationAudio',
        defaultMessage: '<i>Audio between:</i> {start} - {end}',
    },
    annotationProperty: {
        id: 'exploration.tag.utils.annotationProperty',
        defaultMessage: '<i>Property:</i> {propertyName}',
    },
});

interface AnnotationDescriptionProps {
    tagProperties: TagProperties;
    objectProperty?: UniversePropertyType;
}

export function AnnotationDescription(props: AnnotationDescriptionProps) {
    const {
        tagProperties,
        objectProperty,
    } = props;

    const time = (tagProperties as VideoTagProperties).position?.time;
    const start = (tagProperties as AudioTagProperties).position?.start;
    const end = (tagProperties as AudioTagProperties).position?.end;

    const message = getAnnotationMessage(tagProperties.type);

    const messageValues = {
        textValue: tagProperties.text,
        pageNumber: (tagProperties as PdfTagProperties).position?.page,
        ...(time && { time: getFormattedDuration(time) }),
        ...(start && { start: getFormattedDuration(start) }),
        ...(end && { end: getFormattedDuration(end) }),
    };

    return (
        <>
            <ArgMessageRenderer
                size='small'
                message={message}
                messageValues={messageValues}
            />
            {objectProperty && (
                <ArgMessageRenderer
                    size='small'
                    message={messages.annotationProperty}
                    messageValues={{ propertyName: objectProperty.displayName }}
                />
            )}
        </>
    );
}


function getAnnotationMessage(type: TagType) {
    switch (type) {
        case TagType.Text:
            return messages.annotationText;
        case TagType.Image:
            return messages.annotationImage;
        case TagType.Video:
            return messages.annotationVideo;
        case TagType.Audio:
            return messages.annotationAudio;
    }
}
