import { ArgRenderedText, renderText } from '../../basic';
import { BasicPane, BasicPaneProps } from './basic-pane';

export interface BasicPaneI18nProps extends Omit<BasicPaneProps, 'message'> {
    message?: ArgRenderedText;
    messageValues?: Record<string, any>;
}

export function BasicPaneI18n(props: BasicPaneI18nProps) {
    const {
        children,
        message,
        messageValues,
        size = 'large',
        ...otherProps
    } = props;

    const messageComponent = renderText(message || children, messageValues);

    return <BasicPane
        size={size}
        message={messageComponent}
        {...otherProps}
    />;
}
