import React, { CSSProperties, MouseEvent, ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { DraggableItem, useReorder } from '../../../hooks/use-reorder';
import { SelectionProvider } from '../arg-providers/selection-provider';
import { ArgCheckbox } from '../arg-checkbox/arg-checkbox';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgOrderedColumn } from './arg-ordered-list';
import { ArgTable2Column, getTableCellValue } from '../arg-table/arg-table2';
import { getSizePercent, getSizePixel } from './utils';

import './arg-ordered-item.less';

const CLASSNAME = 'arg-ordered-item';

export interface ArgOrderedItemProps<T> {
    orderedItem: T;
    orderedItemIndex: number;
    dragType: string;
    draggableItem: DraggableItem;
    columns: ArgOrderedColumn<T>[];
    onMoveItem: (dragIndex: number, hoverIndex: number) => void;
    selectionProvider: SelectionProvider<T>;
    selectionSource: string;
    className?: ClassValue;
    search?: string;
    onRowClick?: (row: T, event: MouseEvent) => void;
    onRowDoubleClick?: (row: T, event: MouseEvent) => void;
    rowClassName?: (row: T, index: number) => ClassValue;
    renderRow?: (row: T, index: number, rowClassName: ClassValue | undefined, cells: ReactNode) => ReactNode;
}

export function ArgOrderedItem<T>(props: ArgOrderedItemProps<T>) {
    const {
        className,
        dragType,
        orderedItemIndex,
        draggableItem,
        orderedItem,
        columns,
        selectionSource,
        selectionProvider,
        onMoveItem,
        search,
        onRowClick,
        onRowDoubleClick,
        rowClassName,
        renderRow,
    } = props;

    const classNames = useClassNames(CLASSNAME);
    const intl = useIntl();

    const selected = selectionProvider.has(orderedItem);

    const { handlerId, isDragging, drag, ref } = useReorder(
        dragType,
        onMoveItem,
        orderedItemIndex,
        draggableItem,
    );

    const handleOrderedItemSelection = useCallback(() => {
        if (selected) {
            selectionProvider.remove(orderedItem, selectionSource);

            return;
        }

        selectionProvider.add(orderedItem, selectionSource);
    }, [orderedItem, selected, selectionProvider, selectionSource]);

    const handleRowClick = useCallback((event: MouseEvent) => {
        onRowClick!(orderedItem, event);
    }, [onRowClick, orderedItem]);

    const handleRowDoubleClick = useCallback((event: MouseEvent) => {
        onRowDoubleClick!(orderedItem, event);
    }, [onRowDoubleClick, orderedItem]);

    const computedRowClassName = rowClassName?.(orderedItem, orderedItemIndex);

    const cls = { selected, dragging: isDragging };

    const columnComponents = columns.map((column) => {
        const cellData = getTableCellValue<T>(
            column as ArgTable2Column<T>,
            orderedItem,
            orderedItemIndex,
            intl,
            search,
            isDragging,
        );

        const cellStyle: CSSProperties = {};

        const { width, minWidth, maxWidth } = column;

        if (getSizePixel(width) !== undefined) {
            cellStyle.width = `${getSizePixel(width)}px`;
        } else if (getSizePercent(width) !== undefined) {
            cellStyle.flex = `${getSizePercent(width)} ${getSizePercent(width)}`;
        }

        const cls: ClassValue = {
            ellipsis: column.ellipsis,
        };

        return (
            <div
                key={column.key}
                style={cellStyle}
                className={classNames('&-columns-cell', column.className, cls)}
            >
                {cellData}
            </div>
        );
    });

    const fields = renderRow
        ? renderRow(orderedItem, orderedItemIndex, classNames('&-columns'), columnComponents)
        : <div className={classNames('&-columns')}>
            {columnComponents}
        </div>;

    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            className={classNames('&', computedRowClassName, className)}
            data-key={orderedItem}
            onClick={(onRowClick) ? handleRowClick : undefined}
            onDoubleClick={(onRowDoubleClick) ? handleRowDoubleClick : undefined}
        >
            <div className={classNames('&-order')} ref={drag}>
                <ArgIcon name='icon-6dots' className={classNames('&-order-drag-icon')} />
            </div>

            <div className={classNames('&-checkbox')}>
                <ArgCheckbox
                    size='node'
                    value={selected}
                    onChange={handleOrderedItemSelection}
                />
            </div>

            <div className={classNames('&-fields', cls)}>
                {fields}
            </div>
        </div>
    );
}
