import { chain, first, isEmpty, last, uniqBy } from 'lodash';
import React, { ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
    ArgFormattedMessage,
    ArgInputSearch,
    ArgNodeKey,
    ArgNodePath,
    ArgTree,
    ClassValue,
    Configuration,
    GetNodeKey,
    highlightSplit,
    normalizeText,
    useClassNames,
} from 'src/components/basic';
import { TemplateType } from '../../../exploration/model/template';
import { Environment } from '../../../utils/environment';
import { ConfigurationManifest, ConfigurationOption } from '../../../model/configuration';
import {
    CONFIGURATION_POLICIES_OPTIONS,
    ConfigurationType,
    groupsRolesConfigurationTypes,
    usersRolesConfigurationTypes,
} from '../../../settings/configuration/configuration-type';
import { ArgonosModulesRegistry } from 'src/components/application/argonos-modules-registry';

import './applications-parameters-configuration.less';

const CLASSNAME = 'common-applications-parameters-configuration';
const DEFAULT_OPEN_NODES: string[] = [
    ConfigurationType.Administration,
    ConfigurationType.Preparation,
    ConfigurationType.Exploration,
    ConfigurationType.KnowledgeBase,
];

const messages = defineMessages({
    knowledgeBase: {
        id: 'common.applications-parameters-configuration.knowledgeBase',
        defaultMessage: 'Knowledge base',
    },
    dataPreparation: {
        id: 'common.applications-parameters-configuration.dataPreparation',
        defaultMessage: 'Preparation Data',
    },
    dataExploration: {
        id: 'common.applications-parameters-configuration.dataExploration',
        defaultMessage: 'Exploration Data',
    },
    universe: {
        id: 'common.applications-parameters-configuration.universe',
        defaultMessage: 'Universe',
    },
    ontology: {
        id: 'common.applications-parameters-configuration.ontology',
        defaultMessage: 'Ontology',
    },
    miscellaneous: {
        id: 'common.applications-parameters-configuration.miscellaneous',
        defaultMessage: 'Policies & other configurations',
    },
    briefTemplates: {
        id: 'common.applications-parameters-configuration.briefTemplates',
        defaultMessage: 'Brief templates',
    },
    styleTemplates: {
        id: 'common.applications-parameters-configuration.styleTemplates',
        defaultMessage: 'Styles templates',
    },
    processes: {
        id: 'common.applications-parameters-configuration.processes',
        defaultMessage: 'Processes',
    },
    composites: {
        id: 'common.applications-parameters-configuration.composites',
        defaultMessage: 'Components',
    },
    remoteComponents: {
        id: 'common.applications-parameters-configuration.remoteComponents',
        defaultMessage: 'Additional components',
    },
    secrets: {
        id: 'common.applications-parameters-configuration.secrets',
        defaultMessage: 'Secrets',
    },
    keyBindings: {
        id: 'common.applications-parameters-configuration.keyBindings',
        defaultMessage: 'Key bindings',
    },
    centralAdministration: {
        id: 'common.applications-parameters-configuration.centralAdministration',
        defaultMessage: 'Central administration',
    },
    users: {
        id: 'common.applications-parameters-configuration.users',
        defaultMessage: 'Users',
    },
    groups: {
        id: 'common.applications-parameters-configuration.groups',
        defaultMessage: 'Groups',
    },
    roles: {
        id: 'common.applications-parameters-configuration.roles',
        defaultMessage: 'Roles',
    },
    extensions: {
        id: 'common.applications-parameters-configuration.extensions',
        defaultMessage: 'Extensions',
    },
    applicationSettings: {
        id: 'common.applications-parameters-configuration.applicationSettings',
        defaultMessage: 'Branding',
    },
    contextualVariables: {
        id: 'common.applications-parameters-configuration.contextualVariables',
        defaultMessage: 'Contextual variables',
    },
    userProfilesFieldsBundle: {
        id: 'common.applications-parameters-configuration.userProfilesFieldsBundle',
        defaultMessage: 'User profiles',
    },
    webhooks: {
        id: 'common.applications-parameters-configuration.webhooks',
        defaultMessage: 'Webhooks',
    },
    all: {
        id: 'common.applications-parameters-configuration.All',
        defaultMessage: 'All on synchronization',
    },
});

enum TreeNodeType {
    Universe = 'Universe',
    BriefTemplate = 'BriefTemplate',
    Processes = 'Processes',
    Composites = 'Composites',
    RemoteComponents = 'RemoteComponents',
    Secrets = 'Secrets',
    AppSettings = 'AppSettings',
    Ontology = 'Ontology',
    Extensions = 'Extensions',
    Group = 'Group',
    AdminRole = 'AdminRole',
    ExplorationRole = 'ExplorationRole',
    PreparationRole = 'PreparationRole',
    KnowledgeBaseRole = 'KnowledgeBaseRoles',
    ExplorationStyle = 'ExplorationStyle',
    User = 'User',
}

enum ConfigurationScope {
    Environment = 'environment',
    GlobalAdmin = 'admin',
    Exploration = 'data_exploration',
    Preparation = 'data_preparation',
    KnowledgeBase = 'kb'
}

interface TreeData {
    key: string;
    label: string;
    type?: TreeNodeType;
    children?: TreeData[];
    count?: number;

    // Whether this node checkbox should behave as select/unselect all for its children. If omitted default is true.
    aggregate?: boolean;
}

export interface ApplicationsParametersConfigurationProps {
    className?: ClassValue;
    defaultSelectedList?: ArgNodeKey[];
    currentConfigurations: ConfigurationManifest[];
    onSelectionChange: (selectedOptions: ConfigurationOption[]) => void;
    enableAggregate?: boolean;
}

export function ApplicationsParametersConfiguration(props: ApplicationsParametersConfigurationProps) {
    const {
        defaultSelectedList,
        currentConfigurations,
        onSelectionChange,
        enableAggregate,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const intl = useIntl();

    const [openedKeys, setOpenedKeys] = useState<string[]>(DEFAULT_OPEN_NODES);
    const [selectedList, setSelectedList] = useState<ArgNodeKey[] | undefined>(defaultSelectedList);
    const [searchedToken, setSearchedToken] = useState<string>();
    const [filteredBriefConfigurations, setFilteredBriefConfigurations] = useState<Configuration[]>();
    const [filteredProcessConfigurations, setFilteredProcessConfigurations] = useState<Configuration[]>();
    const [filteredCompositeConfigurations, setFilteredCompositeConfigurations] = useState<Configuration[]>();
    const [filteredRemoteComponentConfigurations, setFilteredRemoteComponentConfigurations] = useState<Configuration[]>();
    const [filteredSecretConfigurations, setFilteredSecretConfigurations] = useState<Configuration[]>();
    const [filteredGroupConfigurations, setFilteredGroupConfigurations] = useState<Configuration[]>();
    const [filteredRoleConfigurations, setFilteredRoleConfigurations] = useState<Configuration[]>();
    const [filteredStyleConfigurations, setFilteredStyleConfigurations] = useState<Configuration[]>();
    const [filteredUsersConfigurations, setFilteredUsersConfigurations] = useState<Configuration[]>();

    const handleGetNodeLabel = useCallback((item: ArgNodePath<TreeData>) => {
        const currentItem = item[item.length - 1];
        const label = highlightSplit(currentItem.label, searchedToken);

        let count: ReactNode = null;

        if (enableAggregate && currentItem.aggregate === false && selectedList?.includes(currentItem.key)) {
            count = <ArgFormattedMessage message={messages.all} />;
        } else if (currentItem?.count && !isEmpty(currentItem.children)) {
            const selectedCount = currentItem.children?.reduce((acc, child) => {
                if (selectedList?.includes(child.key)) {
                    return acc + 1;
                }

                return acc;
            }, 0) ?? 0;

            count = <> {selectedCount} / {currentItem.count} </>;
        }

        return (
            <div className={classNames('&-label-container')}>
                <span className={classNames('&-label-container-title')}>
                    {label}
                </span>

                {count && <div className={classNames('&-label-container-count')}>
                    {count}
                </div>}
            </div>
        );
    }, [classNames, searchedToken, selectedList, enableAggregate]);

    const handleGetNodeKey: GetNodeKey<TreeData> = useCallback((item: ArgNodePath<TreeData>) => {
        const currentItem = item[item.length - 1];

        return currentItem.key;
    }, []);

    const handleOnOpenNodes = useCallback((nodeKeys: SetStateAction<ArgNodeKey[]>) => {
        setOpenedKeys(nodeKeys);
    }, []);

    const filterNodeChildrenWithToken = useCallback((node: TreeData, token?: string): TreeData[] => {
        if (!node.children) {
            return [];
        }

        if (!token || normalizeText(node.label).includes(normalizeText(token))) {
            return node.children;
        }


        const searchedChildren: TreeData[] = node.children.filter((child) => {
            if (child.children && child.children.length > 0) {
                const subChild = filterNodeChildrenWithToken(child, token);

                return subChild.length > 0;
            }

            return normalizeText(child.label).includes(normalizeText(token));
        });

        return searchedChildren;
    }, []);

    const configurationsTree = useMemo<TreeData[]>(() => {
        const ret: TreeData[] = [];

        const briefConf = findConfByType(currentConfigurations, TemplateType.Brief);
        const ontologyConf = findConfByType(currentConfigurations, ConfigurationType.Ontologies);
        const appSettingsConf = findConfByType(currentConfigurations, ConfigurationType.ApplicationSettings);
        const styleConf = findConfByType(currentConfigurations, TemplateType.ExplorationStyle);
        const groupsConf = findConfByType(currentConfigurations, ConfigurationType.Groups);
        const processesConf = findConfByType(currentConfigurations, ConfigurationType.Processes);
        const compositesConf = findConfByType(currentConfigurations, ConfigurationType.Composites);
        const remoteComponentsConf = findConfByType(currentConfigurations, ConfigurationType.RemoteComponents);
        const secretsConf = findConfByType(currentConfigurations, ConfigurationType.Secrets);
        const usersConf = findConfByType(currentConfigurations, ConfigurationType.Users);
        const explorationExtensionsConf = findConfByType(currentConfigurations, ConfigurationType.ExplorationExtensions);

        const exploKeyBindingsConf = appSettingsConf?.configurations.find(conf => conf.id === ConfigurationType.Exploration);
        const prepaKeyBindingsConf = appSettingsConf?.configurations.find(conf => conf.id === ConfigurationType.Preparation);

        const exploRoles = filteredRoleConfigurations?.filter((conf) => conf.metadata.scope === ConfigurationScope.Exploration);

        const prepaRoles = filteredRoleConfigurations?.filter((conf) => conf.metadata.scope === ConfigurationScope.Preparation);

        const knowledgeBaseRoles = filteredRoleConfigurations?.filter((conf) => conf.metadata.scope === ConfigurationScope.KnowledgeBase);

        const dataExploChildren: TreeData[] = [];

        const universesNodes: { [universeId: string]: { children: TreeData[]; name: string } } = {};
        const getUniverseNode = (universeId: string, name: string) => {
            universesNodes[universeId] = universesNodes[universeId] || { children: [], name };

            return universesNodes[universeId];
        };

        ontologyConf?.configurations.forEach((ontology) => {
            // very surprising but ontologies do not reference universe by id (??) hence falling bach to using the metadata.name
            const universeId = ontology.id;
            const universeName = ontology.metadata.name;
            const children = getUniverseNode(universeId, ontology.metadata.name).children;

            dataExploChildren.push({
                key: `${ConfigurationType.Exploration}/Universe/${universeId}`,
                label: universeName,
                children: [
                    {
                        key: `${ConfigurationType.Exploration}/Universe/${universeId}.ontology`,
                        label: intl.formatMessage(messages.ontology),
                        children: [],
                    },
                    {
                        key: `${ConfigurationType.Exploration}/Universe/${universeId}.misc`,
                        label: intl.formatMessage(messages.miscellaneous),
                        children: [],
                    },
                ],
            });

            children.push({
                key: `${ConfigurationType.Exploration}/Universe/${universeId}/styles`,
                label: intl.formatMessage(messages.styleTemplates),
                children: filteredStyleConfigurations?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Exploration}/${ontology.id}/${ConfigurationType.ExplorationStyle}/${conf.id}`,
                        type: TreeNodeType.ExplorationStyle,
                        label: conf.metadata.templateName || conf.metadata.name,
                    };
                }),
                count: styleConf?.configurations?.length ?? 0,
                aggregate: enableAggregate ? false : undefined,
            });
        });


        dataExploChildren.push({
            key: `${ConfigurationType.Exploration}/${ConfigurationType.ExplorationRole}`,
            label: intl.formatMessage(messages.roles),
            children: exploRoles?.map((conf: Configuration) => {
                return {
                    key: `${ConfigurationType.Exploration}/${ConfigurationType.ExplorationRole}/${conf.id}`,
                    type: TreeNodeType.ExplorationRole,
                    label: conf.metadata.displayName,
                };
            }),
            count: exploRoles?.length ?? 0,
            aggregate: enableAggregate ? false : undefined,
        });

        dataExploChildren.push({
            key: `${ConfigurationType.Exploration}/${ConfigurationType.BriefTemplate}`,
            label: intl.formatMessage(messages.briefTemplates),
            children: filteredBriefConfigurations?.map((conf: Configuration) => {
                return {
                    key: `${ConfigurationType.Exploration}/${ConfigurationType.BriefTemplate}/${conf.id}`,
                    type: TreeNodeType.BriefTemplate,
                    label: conf.metadata.templateName || conf.metadata.name,
                };
            }),
            count: briefConf?.configurations?.length ?? 0,
            aggregate: enableAggregate ? false : undefined,
        });

        if (exploKeyBindingsConf) {
            dataExploChildren.push({
                key: `${ConfigurationType.Exploration}/${ConfigurationType.ExplorationKeyBinding}`,
                label: intl.formatMessage(messages.keyBindings),
                children: [],
            });
        }

        dataExploChildren.push({
            key: `${ConfigurationType.Exploration}/${TreeNodeType.Extensions}`,
            label: intl.formatMessage(messages.extensions),
            children: explorationExtensionsConf?.configurations.map(conf => {
                return {
                    key: `${ConfigurationType.Exploration}/${TreeNodeType.Extensions}/${conf.id}`,
                    type: TreeNodeType.Extensions,
                    label: conf.id,
                };
            }),
            count: explorationExtensionsConf?.configurations?.length ?? 0,
            aggregate: enableAggregate ? false : undefined,
        });

        addConfigurationTypeWithChildren(
            dataExploChildren,
            ConfigurationType.ExplorationWebhooks,
            intl.formatMessage(messages.webhooks),
            { childrenLabel: (conf) => conf.id },
        );

        const dataPrepaChildren: TreeData[] = [];

        if (prepaKeyBindingsConf) {
            dataPrepaChildren.push({
                key: `${ConfigurationType.Preparation}/${ConfigurationType.PreparationKeyBinding}`,
                label: intl.formatMessage(messages.keyBindings),
                children: [],
            });
        }

        dataPrepaChildren.push(
            {
                key: `${ConfigurationType.Preparation}/${ConfigurationType.PreparationRole}`,
                label: intl.formatMessage(messages.roles),
                children: prepaRoles?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Preparation}/${ConfigurationType.PreparationRole}/${conf.id}`,
                        type: TreeNodeType.PreparationRole,
                        label: conf.metadata.displayName,
                    };
                }),
                count: prepaRoles?.length ?? 0,
                aggregate: enableAggregate ? false : undefined,
            },
        );

        dataPrepaChildren.push({
            key: `${ConfigurationType.Preparation}/${ConfigurationType.Processes}`,
            label: intl.formatMessage(messages.processes),
            children: filteredProcessConfigurations?.map((conf: Configuration) => {
                return {
                    key: `${ConfigurationType.Preparation}/${ConfigurationType.Processes}/${conf.id}`,
                    type: TreeNodeType.Processes,
                    label: conf?.metadata?.templateName || conf?.metadata?.name || conf?.id,
                };
            }),
            count: processesConf?.configurations?.length ?? 0,
            aggregate: enableAggregate ? false : undefined,
        });

        dataPrepaChildren.push({
            key: `${ConfigurationType.Preparation}/${ConfigurationType.Composites}`,
            label: intl.formatMessage(messages.composites),
            children: filteredCompositeConfigurations?.map((conf: Configuration) => {
                return {
                    key: `${ConfigurationType.Preparation}/${ConfigurationType.Composites}/${conf.id}`,
                    type: TreeNodeType.Composites,
                    label: conf?.metadata?.templateName || conf?.metadata?.name || conf?.id,
                };
            }),
            count: compositesConf?.configurations?.length ?? 0,
            aggregate: enableAggregate ? false : undefined,
        });

        dataPrepaChildren.push({
            key: `${ConfigurationType.Preparation}/${ConfigurationType.RemoteComponents}`,
            label: intl.formatMessage(messages.remoteComponents),
            children: filteredRemoteComponentConfigurations?.map((conf: Configuration) => {
                return {
                    key: `${ConfigurationType.Preparation}/${ConfigurationType.RemoteComponents}/${conf.id}`,
                    type: TreeNodeType.RemoteComponents,
                    label: conf?.metadata?.templateName || conf?.metadata?.name || conf?.id,
                };
            }),
            count: remoteComponentsConf?.configurations?.length ?? 0,
            aggregate: enableAggregate ? false : undefined,
        });

        dataPrepaChildren.push({
            key: `${ConfigurationType.Preparation}/${ConfigurationType.Secrets}`,
            label: intl.formatMessage(messages.secrets),
            children: filteredSecretConfigurations?.map((conf: Configuration) => {
                return {
                    key: `${ConfigurationType.Preparation}/${ConfigurationType.Secrets}/${conf.id}`,
                    type: TreeNodeType.Secrets,
                    label: conf?.metadata?.templateName || conf?.metadata?.name || conf?.id,
                };
            }),
            count: secretsConf?.configurations?.length ?? 0,
            aggregate: enableAggregate ? false : undefined,
        });

        addConfigurationTypeWithChildren(
            dataPrepaChildren,
            ConfigurationType.PreparationWebhooks,
            intl.formatMessage(messages.webhooks),
            { childrenLabel: (conf) => conf.id },
        );


        const knowledgeBaseChildren: TreeData[] = [];

        knowledgeBaseChildren.push(
            {
                key: `${ConfigurationType.KnowledgeBase}/${ConfigurationType.KnowledgeBaseRole}`,
                label: intl.formatMessage(messages.roles),
                children: knowledgeBaseRoles?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.KnowledgeBase}/${ConfigurationType.KnowledgeBaseRole}/${conf.id}`,
                        type: TreeNodeType.KnowledgeBaseRole,
                        label: conf.metadata.displayName,
                    };
                }),
                count: knowledgeBaseRoles?.length ?? 0,
                aggregate: enableAggregate ? false : undefined,
            },
        );

        addConfigurationTypeWithChildren(
            knowledgeBaseChildren,
            ConfigurationType.KnowledgeBaseWebhooks,
            intl.formatMessage(messages.webhooks),
            { childrenLabel: (conf) => conf.id },
        );

        const centralAdminChildren: TreeData[] = [];

        centralAdminChildren.push(
            {
                key: `${ConfigurationType.Administration}/${ConfigurationType.Users}`,
                label: intl.formatMessage(messages.users),
                children: filteredUsersConfigurations?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Administration}/${ConfigurationType.Users}/${conf.id}`,
                        type: TreeNodeType.User,
                        label: conf.metadata.fullName,
                    };
                }),
                count: usersConf?.configurations?.length ?? 0,
                aggregate: enableAggregate ? false : undefined,
            },
        );

        centralAdminChildren.push(
            {
                key: `${ConfigurationType.Administration}/${ConfigurationType.Groups}`,
                label: intl.formatMessage(messages.groups),
                children: filteredGroupConfigurations?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Administration}/${ConfigurationType.Groups}/${conf.id}`,
                        type: TreeNodeType.Group,
                        label: conf.metadata.name,
                    };
                }),
                count: groupsConf?.configurations?.length ?? 0,
                aggregate: enableAggregate ? false : undefined,
            },
        );

        const applicationConf = currentConfigurations.find(conf => conf.type === ConfigurationType.ApplicationSettings);
        if (applicationConf) {
            centralAdminChildren.push({
                key: `${ConfigurationType.Administration}/${ConfigurationType.ApplicationSettings}`,
                label: intl.formatMessage(messages.applicationSettings),
            });
        }

        const adminRoles = filteredRoleConfigurations?.filter((conf) => conf.metadata.scope === 'admin');

        centralAdminChildren.push(
            {
                key: `${ConfigurationType.Administration}/${ConfigurationType.GlobalAdminRole}`,
                label: intl.formatMessage(messages.roles),
                children: adminRoles?.map((conf: Configuration) => {
                    return {
                        key: `${ConfigurationType.Administration}/${ConfigurationType.GlobalAdminRole}/${conf.id}`,
                        type: TreeNodeType.AdminRole,
                        label: conf.metadata.displayName,
                    };
                }),
                count: adminRoles?.length ?? 0,
                aggregate: enableAggregate ? false : undefined,
            },
        );

        // a helper that adds a node for a configuration type that has several child configurations.
        // TODO: use for roles (admin, dp, explo)
        function addConfigurationTypeWithChildren(array: TreeData[], configurationType: ConfigurationType, label: string, opts: {
            childrenLabel?: (conf: Configuration) => string;
        } = {}) {
            const configurationManifest = findConfByType(currentConfigurations, configurationType);
            const childrenLabel = opts.childrenLabel || (conf => conf.metadata.displayName);

            const filteredConfigurations = chain(configurationManifest?.configurations || [])
                .filter((conf) => {
                    return !(searchedToken && normalizeText(childrenLabel(conf) as string).indexOf(searchedToken) === -1);
                })
                .uniqBy((conf) => conf.id)
                .value();

            array.push(
                {
                    key: `${ConfigurationType.Administration}/${configurationType}`,
                    label: label,
                    children: filteredConfigurations.map((conf: Configuration) => {
                        return {
                            key: `${ConfigurationType.Administration}/${configurationType}/${conf.id}`,
                            label: childrenLabel(conf),
                        };
                    }),
                    count: filteredConfigurations.length,
                    aggregate: enableAggregate ? false : undefined,
                },
            );
        }

        addConfigurationTypeWithChildren(centralAdminChildren, ConfigurationType.ContextualVariable, intl.formatMessage(messages.contextualVariables));

        centralAdminChildren.push(
            {
                key: ConfigurationType.ProfileFields,
                label: intl.formatMessage(messages.userProfilesFieldsBundle),
            },
        );

        addConfigurationTypeWithChildren(
            centralAdminChildren,
            ConfigurationType.EnvironmentWebhooks,
            intl.formatMessage(messages.webhooks),
            { childrenLabel: (conf) => conf.id },
        );

        const centralAdminNode: TreeData = {
            key: ConfigurationType.Administration,
            label: intl.formatMessage(messages.centralAdministration),
            children: centralAdminChildren,
        };

        const dataExploNode: TreeData = {
            key: ConfigurationType.Exploration,
            label: intl.formatMessage(messages.dataExploration),
            children: dataExploChildren,
        };

        const dataPrepNode: TreeData = {
            key: ConfigurationType.Preparation,
            label: intl.formatMessage(messages.dataPreparation),
            children: dataPrepaChildren,
        };

        const knowledgeBaseNode: TreeData = {
            key: ConfigurationType.KnowledgeBase,
            label: intl.formatMessage(messages.knowledgeBase),
            children: knowledgeBaseChildren,
        };

        const modules = ArgonosModulesRegistry.getInstance().listEnabled().value();

        if (modules.some(m => m.id === 'chapsVision.Administration')) {
            ret.push(centralAdminNode);
        }

        if (modules.some(m => m.id === 'chapsVision.DataExploration')) {
            ret.push(dataExploNode);
        }

        if (modules.some(m => m.id === 'chapsVision.DataPreparation')) {
            ret.push(dataPrepNode);
        }

        if (modules.some(m => m.id === 'chapsVision.KnowledgeBase')) {
            ret.push(knowledgeBaseNode);
        }

        return ret;
    }, [
        currentConfigurations,
        filteredRoleConfigurations,
        intl,
        filteredStyleConfigurations,
        filteredBriefConfigurations,
        filteredProcessConfigurations,
        filteredCompositeConfigurations,
        filteredRemoteComponentConfigurations,
        filteredSecretConfigurations,
        filteredGroupConfigurations,
        searchedToken,
        filteredUsersConfigurations,
        enableAggregate,
    ]);

    const handleSelection = useCallback(async (selection: ArgNodeKey[]) => {
        const selectionDiff = selection?.filter(item => !openedKeys?.includes(item));
        const openedDiff = openedKeys?.filter(item => !selection?.includes(item));

        if (selectionDiff && selectionDiff.length > 0 && (!openedDiff || openedDiff.length === 0)) {
            setOpenedKeys([...selection]);
        }

        setSelectedList(selection);

        const groupsSelection = buildNewSelection(currentConfigurations, ConfigurationType.Groups, selection);
        const groupsRolesSelection = getAssociatedRolesConfigurations(currentConfigurations, groupsSelection, groupsRolesConfigurationTypes);

        const usersSelection = buildNewSelection(currentConfigurations, ConfigurationType.Users, selection);
        const userRolesSelection = getAssociatedRolesConfigurations(currentConfigurations, usersSelection, usersRolesConfigurationTypes);
        const userProfileFieldsSelection = getAssociatedProfileFieldsConfigurations(usersSelection);

        const newSelectedOptions: ConfigurationOption[] = [
            ...buildNewSelection(currentConfigurations, TemplateType.Brief, selection),
            ...buildNewSelectionForOntologies(currentConfigurations, selection),
            ...groupsSelection,
            ...groupsRolesSelection,
            ...buildNewSelection(currentConfigurations, ConfigurationType.GlobalAdminRole, selection, (conf) => conf.metadata.scope === ConfigurationScope.GlobalAdmin),
            ...buildNewSelection(currentConfigurations, ConfigurationType.PreparationRole, selection, (conf) => conf.metadata.scope === ConfigurationScope.Preparation),
            ...buildNewSelection(currentConfigurations, ConfigurationType.ExplorationRole, selection, (conf) => conf.metadata.scope === ConfigurationScope.Exploration),
            ...buildNewSelection(currentConfigurations, ConfigurationType.KnowledgeBaseRole, selection, (conf) => conf.metadata.scope === ConfigurationScope.KnowledgeBase),
            ...buildNewSelection(currentConfigurations, TemplateType.ExplorationStyle, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.ContextualVariable, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.Processes, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.RemoteComponents, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.Composites, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.Secrets, selection),
            ...usersSelection,
            ...userRolesSelection,
            ...userProfileFieldsSelection,
            ...buildNewSelection(currentConfigurations, ConfigurationType.ExplorationExtensions, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.ExplorationWebhooks, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.PreparationWebhooks, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.KnowledgeBaseWebhooks, selection),
            ...buildNewSelection(currentConfigurations, ConfigurationType.EnvironmentWebhooks, selection),
        ];

        const selectionHasConfigurationType = (type: string) => {
            const ret = selection.some((s) => s.includes(type));

            return ret;
        };

        if (selectionHasConfigurationType(ConfigurationType.ExplorationKeyBinding)) {
            newSelectedOptions.push(
                {
                    type: ConfigurationType.ApplicationSettings,
                    configurationKeys: [ConfigurationType.ExplorationKeyBinding],
                    options: {
                        applicationId: Environment.appId,
                    },
                },
            );
        }

        if (selectionHasConfigurationType(ConfigurationType.PreparationKeyBinding)) {
            newSelectedOptions.push(
                {
                    type: ConfigurationType.ApplicationSettings,
                    configurationKeys: [ConfigurationType.PreparationKeyBinding],
                    options: {
                        applicationId: Environment.appId,
                    },
                },
            );
        }

        if (selectionHasConfigurationType(ConfigurationType.ApplicationSettings)) {
            newSelectedOptions.push(
                {
                    type: ConfigurationType.ApplicationSettings,
                    configurationKeys: [ConfigurationType.ApplicationSettings],
                    options: {},
                },
            );
        }

        if (selectionHasConfigurationType(ConfigurationType.ProfileFields)) {
            newSelectedOptions.push(
                {
                    type: ConfigurationType.ProfileFields,
                    configurationKeys: [],
                    options: {},
                    everything: true,
                },
            );

            // Suport for legacy 24.2 import
            newSelectedOptions.push(
                {
                    type: ConfigurationType.LegacyUserProfileBundle,
                    configurationKeys: [],
                    options: {},
                    everything: true,
                },
            );
        }
        onSelectionChange(newSelectedOptions);
    }, [currentConfigurations, onSelectionChange, openedKeys]);

    const isNodeAggregate = useCallback((nodePath: ArgNodePath<TreeData>) => {
        return last(nodePath)?.aggregate ?? true;
    }, []);

    const getNodeCheckValue = useCallback((nodePath: ArgNodePath<TreeData>, nodeKey: ArgNodeKey, selectedList: ArgNodeKey[], childrenCount: number, childrenCheckedCount: number): boolean | 'minus' | undefined => {
        if (last(nodePath)?.aggregate !== false) {
            return undefined;
        }

        if (selectedList?.includes(nodeKey)) {
            return true;
        }

        if (childrenCheckedCount) {
            return 'minus';
        }

        return false;
    }, []);

    const handleSearch = useCallback((search: string) => {
        setSearchedToken(normalizeText(search));
    }, []);

    useEffect(() => {
        const briefConf = currentConfigurations.find(conf => conf.type === TemplateType.Brief);
        const filteredBriefConfigurations = briefConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata.templateName as string || conf.metadata.name as string)?.indexOf(searchedToken) === -1);
        });

        const processesConf = currentConfigurations.find(conf => conf.type === ConfigurationType.Processes);
        const filteredProcessConfigurations = processesConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata?.templateName as string || conf.metadata?.name as string || conf?.id)?.indexOf(searchedToken) === -1);
        });

        const compositesConf = currentConfigurations.find(conf => conf.type === ConfigurationType.Composites);
        const filteredCompositeConfigurations = compositesConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata?.templateName as string || conf.metadata?.name as string || conf?.id)?.indexOf(searchedToken) === -1);
        });

        const remoteComponentsConf = currentConfigurations.find(conf => conf.type === ConfigurationType.RemoteComponents);
        const filteredRemoteComponentConfigurations = remoteComponentsConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata?.templateName as string || conf.metadata?.name as string || conf?.id)?.indexOf(searchedToken) === -1);
        });

        const secretsConf = currentConfigurations.find(conf => conf.type === ConfigurationType.Secrets);
        const filteredSecretConfigurations = secretsConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata?.templateName as string || conf.metadata?.name as string || conf?.id)?.indexOf(searchedToken) === -1);
        });

        const groupConf = currentConfigurations.find(conf => conf.type === ConfigurationType.Groups);
        const filteredGroupConfigurations = groupConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata.name as string).indexOf(searchedToken) === -1);
        });

        const usersConf = currentConfigurations.find(conf => conf.type === ConfigurationType.Users);
        const filteredUsersConfigurations = usersConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata.fullName as string).indexOf(searchedToken) === -1);
        });

        const roles = [
            currentConfigurations.find(conf => conf.type === ConfigurationType.GlobalAdminRole),
            currentConfigurations.find(conf => conf.type === ConfigurationType.ExplorationRole),
            currentConfigurations.find(conf => conf.type === ConfigurationType.PreparationRole),
            currentConfigurations.find(conf => conf.type === ConfigurationType.KnowledgeBaseRole),
        ];

        const roleConfigurations = roles
            .filter((roleConf): roleConf is ConfigurationManifest => roleConf !== undefined)
            .flatMap((roleConf) => roleConf.configurations);

        // ExplorationRole and PreparationRole may contains GlobalAdminRole
        const roleConfigurationsWithoutDuplicate = uniqBy(roleConfigurations, (roleConf) => roleConf.id);

        const filteredRoleConfigurations = roleConfigurationsWithoutDuplicate.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata.displayName as string).indexOf(searchedToken) === -1);
        });

        const styleConf = currentConfigurations.find(conf => conf.type === TemplateType.ExplorationStyle);
        const filteredStyleConfigurations = styleConf?.configurations.filter((conf) => {
            return !(searchedToken && normalizeText(conf.metadata.templateName as string || conf.metadata.name as string).indexOf(searchedToken) === -1);
        });

        setFilteredBriefConfigurations(filteredBriefConfigurations);
        setFilteredProcessConfigurations(filteredProcessConfigurations);
        setFilteredCompositeConfigurations(filteredCompositeConfigurations);
        setFilteredRemoteComponentConfigurations(filteredRemoteComponentConfigurations);
        setFilteredSecretConfigurations(filteredSecretConfigurations);
        setFilteredGroupConfigurations(filteredGroupConfigurations);
        setFilteredRoleConfigurations(filteredRoleConfigurations);
        setFilteredStyleConfigurations(filteredStyleConfigurations);
        setFilteredUsersConfigurations(filteredUsersConfigurations);
    }, [searchedToken, currentConfigurations]);

    return (
        <React.Fragment>
            <ArgInputSearch
                onInputChange={handleSearch}
                className={classNames('&-search-bar')}
            />
            <div className={classNames('&-configurations-list-body')}>
                <ArgTree<TreeData>
                    checkable={true}
                    getNodeLabel={handleGetNodeLabel}
                    onOpen={handleOnOpenNodes}
                    getNodeKey={handleGetNodeKey}
                    openedNodes={openedKeys}
                    root={configurationsTree}
                    openAllNodes={!!searchedToken}
                    value={selectedList}
                    hasNodeSearchedToken={(item: ArgNodePath<TreeData>) => {
                        const currentItem = item[item.length - 1];
                        const childNode = filterNodeChildrenWithToken(currentItem, searchedToken);

                        return childNode.length > 0;
                    }}
                    getNodeChildren={(item) => {
                        const currentItem = item[item.length - 1];
                        const childNode = filterNodeChildrenWithToken(currentItem, searchedToken);

                        return childNode;
                    }}
                    className={classNames('&-configurations-list-tree')}
                    onChange={async (selection: ArgNodeKey[]) => {
                        await handleSelection(selection);
                    }}
                    isNodeAggregate={isNodeAggregate}
                    getNodeCheckValue={getNodeCheckValue}
                />
            </div>
        </React.Fragment>
    );
}

function buildNewSelection(
    configurations: ConfigurationManifest[],
    type: string,
    selection: ArgNodeKey[],
    configurationFilter: ((conf: Configuration) => boolean) | undefined = undefined,
): ConfigurationOption[] {
    const newSelectedOptions: ConfigurationOption[] = [];
    const newSelectedConfs: string[] = [];
    const conf = configurations.find(conf => conf.type === type);
    const everything = hasEverything(selection, type);

    const filteredSelection = selection.filter((s) => {
        return s.replace('/', '.').includes(type);
    });

    filteredSelection.forEach((key) => {
        const configurationKey = last(key.split('/'));

        if (configurationKey
            && !newSelectedConfs.includes(configurationKey)
            && conf?.configurations.find(conf =>
                (conf.id === configurationKey) && (configurationFilter?.(conf) !== false))
        ) {
            newSelectedConfs?.push(configurationKey);
        }
    });


    if (newSelectedConfs.length > 0 || everything) {
        let options = {};
        if (type === TemplateType.Brief || type === TemplateType.ExplorationStyle) {
            options = {
                setAsDefault: true,
                resetDefaultConfiguration: false,
            };
        }
        newSelectedOptions.push({
            type,
            configurationKeys: newSelectedConfs,
            options,
            everything,
        });
    }

    return newSelectedOptions;
}

function findConfByType(configurations: ConfigurationManifest[], type: string) {
    return configurations.find(conf => conf.type === type);
}


function buildNewSelectionForOntologies(configurations: ConfigurationManifest[], selection: ArgNodeKey[]) {
    const newSelectedOptions: ConfigurationOption[] = [];
    const newSelectedConfs: string[] = [];
    const conf = configurations.find(conf => conf.type === ConfigurationType.Ontologies);

    selection.forEach((selectionKey) => {
        const key = last(selectionKey.split('/'));

        if (!key) {
            return;
        }

        const confKey = first(key.split('.'));
        const confOptions = last(key.split('.'));

        if (!confKey) {
            return;
        }

        const hasConfKey = conf?.configurations.some(conf => conf.id === confKey);
        const hasConfKeyInSelected = newSelectedConfs.includes(confKey);

        if (hasConfKeyInSelected || !hasConfKey) {
            return;
        }

        newSelectedConfs.push(confKey);
        const withPolicies = !!confOptions && (confOptions === confKey || confOptions === CONFIGURATION_POLICIES_OPTIONS);

        newSelectedOptions.push({
            type: ConfigurationType.Ontologies,
            configurationKeys: [confKey],
            options: {
                withPolicies,
            },
        });
    });

    return newSelectedOptions;
}


function getAssociatedRolesConfigurations(
    configurations: ConfigurationManifest[],
    configToAdd: ConfigurationOption[],
    additionalConfiguration: ConfigurationType[],
): ConfigurationOption[] {
    if (configToAdd.length === 0) {
        return [];
    }

    const baseConfiguration = configToAdd[0];

    const rolesTypes = additionalConfiguration.filter((type) => (
        configurations.some((conf) => conf.type === type)
    ));

    const rolesConfigurations = rolesTypes.map<ConfigurationOption>((type) => ({
        type: type,
        configurationKeys: [],
        options: {
            requestedRolesEntities: baseConfiguration.configurationKeys,
            everything: baseConfiguration.everything,
        },
    }));

    return rolesConfigurations;
}

function getAssociatedProfileFieldsConfigurations(
    usersConfigurations: ConfigurationOption[],
): ConfigurationOption[] {
    if (usersConfigurations.length === 0) {
        return [];
    }

    const usersConfiguration = usersConfigurations[0];

    const userProfilesConfiguration: ConfigurationOption = {
        type: ConfigurationType.UserProfiles,
        configurationKeys: [],
        options: {
            requestedProfilesEntities: usersConfiguration.configurationKeys,
            everything: usersConfiguration.everything,
        },
    };

    return [userProfilesConfiguration];
}

// Returns whether array includes key for type.
function hasEverything(array: ArgNodeKey[], type: string) {
    return array.some(key => key.endsWith(`/${type}`));
}
