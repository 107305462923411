import { useMemo } from 'react';
import { isArray } from 'lodash';

import { AsyncTargetEnvironmentContext, TargetEnvironmentContext } from './target-tool-context';
import { SelectionProvider } from '../arg-providers/selection-provider';
import { immutableEmptyArray } from '../utils/immutable-set';

const NO_KEYS = immutableEmptyArray<string>() as string[];

export function useCreateTargetEnvironmentContext<T, K>(
    environmentContext: T,
    items: {
        selectionProvider?: SelectionProvider<K>;
        getItemByKey?: (environmentContext: T, key: string) => (K | undefined);
        selectedKey?: string;
        selectedItem?: K;
    },
    selectedMimeTypes?: string | string[],
): TargetEnvironmentContext<T, K> {
    const { selectedKey, selectedItem, getItemByKey, selectionProvider } = items;

    const targetEnvironmentContext = useMemo<TargetEnvironmentContext<T, K>>(() => {
        const result: TargetEnvironmentContext<T, K> = {
            ...environmentContext,

            firstItem(): K | undefined {
                if (selectedItem !== undefined) {
                    return selectedItem;
                }
                if (selectedKey !== undefined && getItemByKey) {
                    const selectedObject = getItemByKey(environmentContext, selectedKey);

                    return selectedObject;
                }
                if (selectionProvider && getItemByKey) {
                    const firstKey = selectionProvider.first();
                    if (firstKey === undefined) {
                        return undefined;
                    }

                    const firstObject = getItemByKey(environmentContext, firstKey);

                    return firstObject;
                }

                return undefined;
            },
            getItem(key: string): K | undefined {
                if (!getItemByKey) {
                    throw new Error('getItem is not defined !');
                }

                const result = getItemByKey(environmentContext, key);

                return result;
            },
            listItemKeys(): string[] {
                if (selectedKey !== undefined) {
                    return [selectedKey];
                }
                if (selectionProvider) {
                    return selectionProvider.list();
                }

                return NO_KEYS;
            },
            countItem(): number {
                if (selectedKey !== undefined) {
                    return 1;
                }
                if (selectionProvider) {
                    return selectionProvider.count;
                }

                return 0;
            },
        };

        if (selectedMimeTypes) {
            const i = isArray(selectedMimeTypes) ? selectedMimeTypes : [selectedMimeTypes];
            result.getItemsMimeTypes = () => {
                return i;
            };
        }

        return result;
    }, [environmentContext, selectedMimeTypes, selectedItem, selectedKey, getItemByKey, selectionProvider]);

    return targetEnvironmentContext;
}

export function useCreateAsyncTargetEnvironmentContext<T, K>(
    environmentContext: T,
    items: {
        selectionProvider?: SelectionProvider<K>;
        getItemByKey?: (environmentContext: T, key: string) => Promise<K | undefined>;
        selectedKey?: string;
        selectedItem?: K;
    },
    selectedMimeTypes?: string | string[],
): AsyncTargetEnvironmentContext<T, K> {
    const { selectedKey, selectedItem, getItemByKey, selectionProvider } = items;

    const targetEnvironmentContext = useMemo<AsyncTargetEnvironmentContext<T, K>>(() => {
        const result: AsyncTargetEnvironmentContext<T, K> = {
            ...environmentContext,

            async firstItem(): Promise<K | undefined> {
                if (selectedItem !== undefined) {
                    return selectedItem;
                }
                if (selectedKey !== undefined && getItemByKey) {
                    const selectedObject = await getItemByKey(environmentContext, selectedKey);

                    return selectedObject;
                }
                if (selectionProvider && getItemByKey) {
                    const firstKey = selectionProvider.first();
                    if (firstKey === undefined) {
                        return undefined;
                    }

                    const firstObject = await getItemByKey(environmentContext, firstKey);

                    return firstObject;
                }

                return undefined;
            },
            async getItem(key: string): Promise<K | undefined> {
                if (!getItemByKey) {
                    throw new Error('getItem is not defined !');
                }

                const result = await getItemByKey(environmentContext, key);

                return result;
            },
            listItemKeys(): string[] {
                if (selectedKey !== undefined) {
                    return [selectedKey];
                }
                if (selectionProvider) {
                    return selectionProvider.list();
                }

                return NO_KEYS;
            },
            countItem(): number {
                if (selectedKey !== undefined) {
                    return 1;
                }
                if (selectionProvider) {
                    return selectionProvider.count;
                }

                return 0;
            },
        };

        if (selectedMimeTypes) {
            const i = isArray(selectedMimeTypes) ? selectedMimeTypes : [selectedMimeTypes];
            result.getItemsMimeTypes = () => {
                return i;
            };
        }

        return result;
    }, [environmentContext, selectedMimeTypes, selectedItem, selectedKey, getItemByKey, selectionProvider]);

    return targetEnvironmentContext;
}
