import { defineMessages } from 'react-intl';

import { registerToolItem } from '../../../components/basic';
import {
    WORKFLOWS_VIEW_LEFT_CONTEXT,
    WorkflowsEnvironmentContext,
} from '../environment-contexts/workflows-environment-context';
import { registerDisableWorkflowsActions } from './disable-workflows-action';
import { registerEnableWorkflowsActions } from './enable-workflows-action';
import { registerExportWorkflowsActions } from './export-workflows-action';
import { registerModifyWorkflowActions } from './modify-workflow-action';
import { registerCreateWorkflowActions } from './create-workflow-action';
import { registerDeleteWorkflowActions } from './delete-workflows-action';
import { registerImportWorkflowsAction } from './import-workflow-action';
import { registerPurgeWorkflowsActions } from './purge-workflows-action';
import { registerOpenWorkflowActions } from './open-workflow-action';

const messages = defineMessages({
    actionsButton: {
        id: 'settings.workflows.list.ActionsButton',
        defaultMessage: 'Actions',
    },
});

export function setupWorkflowActions() {
    registerToolItem<WorkflowsEnvironmentContext>(WORKFLOWS_VIEW_LEFT_CONTEXT, {
        path: 'actions',
        order: 200,
        type: 'combo',
        buttonType: 'secondary',
        label: messages.actionsButton,
    });

    registerToolItem<WorkflowsEnvironmentContext>(WORKFLOWS_VIEW_LEFT_CONTEXT, {
        path: 'actions/actions',
        order: 200,
        type: 'group',
    });

    registerToolItem<WorkflowsEnvironmentContext>(WORKFLOWS_VIEW_LEFT_CONTEXT, {
        path: 'actions/delete',
        order: 800,
        type: 'group',
    });

    registerModifyWorkflowActions();
    registerCreateWorkflowActions();
    registerDeleteWorkflowActions();
    registerImportWorkflowsAction();
    registerPurgeWorkflowsActions();
    registerExportWorkflowsActions();
    registerEnableWorkflowsActions();
    registerDisableWorkflowsActions();
    registerOpenWorkflowActions();
}

