import React, { ReactNode, useCallback, useState } from 'react';
import { isString } from 'lodash';
import { defineMessages } from 'react-intl';

import { NotificationKey, NotificationType } from './types';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ICONS_NAME } from '../arg-alert-card/arg-alert-card';
import { ArgIcon, renderIcon } from '../arg-icon/arg-icon';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgButton } from '../arg-button/arg-button';
import { ArgMessageValues, ArgRenderedIcon, ArgRenderedText } from '../types';
import { BasicTag } from '../arg-tag/basic-tag';

import './arg-notification.less';

const messages = defineMessages({
    showDetails: {
        id: 'basic.arg-notification.Show details',
        defaultMessage: 'Show more details',
    },
    ignore: {
        id: 'basic.arg-notification.Ignore',
        defaultMessage: 'Ignore',
    },
    open: {
        id: 'basic.arg-notification.Open',
        defaultMessage: 'Open',
    },
});

export interface ArgNotificationProps {
    /**
     * A key to identify the notification
     */
    key: NotificationKey;
    /**
     * Defined the notification type, used to style notification
     */
    type: NotificationType;
    /**
     * The notification title
     */
    message: ArgRenderedText;
    /**
     * A text to add more details if title is unsufisant
     */
    description?: ArgRenderedText;
    /**
     * An other text to add more details, this one is dislayed under collapsed button 'Show more details' that toggle the text supplied at click
     */
    details?: ArgRenderedText;
    /**
     * A custom label for the confirmation notification button
     */
    buttonLabel?: ArgRenderedText;
    /**
     * A props for i18n values
     */
    messageValues?: ArgMessageValues;
    /**
     * A props to customize the icon displayed in the top-left corner
     */
    icon?: ArgRenderedIcon;
    /**
     * A classname for the notification container
     */
    className?: ClassValue;
    /**
     * A callback when clicking on the close icon
     */
    onClose?: () => void;
    /**
     * A callback for the confirmation notification button, need to be set to display the button
     */
    onClick?: () => void;
}
/**
 * This component is designed to display a notification message.
 *
 * @example
 * ```
 * <ArgNotification key="id-key" type="warning" message='My notification message' />
 * ```
 */
export function ArgNotification(props: ArgNotificationProps) {
    const {
        type,
        message,
        description,
        className,
        messageValues,
        icon,
        onClose,
        details,
        onClick,
        buttonLabel = messages.open,
    } = props;

    const classNames = useClassNames('arg-notification');

    const [showDetails, setShowDetails] = useState<boolean>(false);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleOk = useCallback(() => {
        onClick?.();
        onClose?.();
    }, [onClick, onClose]);

    const handleToggleDetails = useCallback(() => {
        setShowDetails((prev) => !prev);
    }, []);

    let iconComponent: ReactNode = null;

    if (!icon) {
        iconComponent = <ArgIcon name={ICONS_NAME[type]} />;
    } else if (isString(icon)) {
        iconComponent = <ArgIcon name={icon} />;
    } else {
        iconComponent = renderIcon(icon);
    }

    const cls: ClassValue = {
        error: type === 'error',
        warning: type === 'warning',
        success: type === 'success',
        info: type === 'info',
    };

    return (
        <div className={classNames('&', cls, className)}>
            <div className={classNames('&-icon')} data-testid='arg-notification-icon-container'>
                {iconComponent}
            </div>

            <div className={classNames('&-content')}>
                <span className={classNames('&-content-message')}>
                    {renderText(message, messageValues)}
                </span>
                <span className={classNames('&-content-description')}>
                    {renderText(description, messageValues)}
                </span>

                <div className={classNames('&-content-actions', { 'no-details': !details })}>
                    {details && (
                        <BasicTag
                            icon={showDetails ? 'icon-triangle-up' : 'icon-triangle-down'}
                            label={messages.showDetails}
                            onClick={handleToggleDetails}
                            className={classNames('&-content-actions-show-details')}
                        />
                    )}

                    <div className={classNames('&-content-actions-notif')}>
                        <ArgButton size='medium' type='secondary' label={messages.ignore} onClick={handleClose} />
                        {onClick && <ArgButton size='medium' type='primary' label={buttonLabel} onClick={handleOk} />}
                    </div>
                </div>

                {details && showDetails && (
                    <div className={classNames('&-content-details')}>
                        {renderText(details)}
                    </div>
                )}
            </div>

            <ArgButton
                icon='icon-cross'
                type='ghost'
                onClick={onClose}
                className={classNames('&-close')}
            />
        </div>
    );
}
