import { ArgUserInfo } from 'src/components/basic';
import { EntityId } from '../../../model/argonos-piece';
import {
    WorkflowActivityApplication,
    WorkflowActivityConfigurationKey,
    WorkflowActivityKey,
    WorkflowActivityOutcomeKey,
    WorkflowActivityOutputKey,
} from './workflow-activity';

export const WORKFLOW_DND_TYPE = 'application/arg-workflow';

export enum WorkflowStatus {
    Disabled = 'Disabled',
    Enabled = 'Enabled',
}

export type WorkflowMetadata = Record<string, string | number | boolean>;

export type WorkflowDefinitionVersion = number;

export interface WorkflowVersions {
    editing: WorkflowDefinitionVersion;
    published: WorkflowDefinitionVersion;
}

export type WorkflowId = EntityId;

export interface Workflow {
    id: WorkflowId;

    name: string;
    description?: string;

    targetType: string; // Or TargetId ? (in Metadata on)
    order: number;

    createdDate: Date;
    createdBy: ArgUserInfo;

    lastUpdatedDate: Date;
    lastUpdatedBy: ArgUserInfo;

    lastPublishedDate: Date;
    lastPublishedBy: ArgUserInfo;

    status: WorkflowStatus;

    metadata?: WorkflowMetadata;

    version: WorkflowVersions;

    instancesCount: number; // ???
}

export type EditedWorkflow = Pick<Workflow, 'name' | 'description' | 'status' | 'metadata' | 'targetType'>

export type WorkflowDefinitionActivityId = string;

export type WorkflowDefinitionTriggerLockKey = string;

export interface WorkflowDefinitionTriggerLock {
    key: WorkflowDefinitionTriggerLockKey;
    order: number;
}

export interface WorkflowDefinitionActivityVariable {
    name: string;
    scope?: string;
}

export interface WorkflowDefinitionActivityConfiguration {
    variable: WorkflowDefinitionActivityVariable;
    value?: any;
}

export interface WorkflowDefinitionVariableSetting {
    setVariable?: string;
    setContextualVariable?: string;
}

export interface WorkflowDefinitionActivityOutput {
    variableSetting: WorkflowDefinitionVariableSetting;
}

export interface WorkflowDefinitionActivityOutcomes {
    nexActivities: string[];
}

export interface WorkflowDefinitionActivity {
    key: WorkflowActivityKey; // Composite Key
    application: WorkflowActivityApplication; // Composite Key

    configuration?: Record<WorkflowActivityConfigurationKey, WorkflowDefinitionActivityConfiguration>;
    outputs?: Record<WorkflowActivityOutputKey, WorkflowDefinitionActivityOutput>;
    outcomes?: Record<WorkflowActivityOutcomeKey, WorkflowDefinitionActivityOutcomes>;
    metadata?: WorkflowMetadata;
}

export type WorkflowDefinitionVariableId = string;

export interface WorkflowDefinition {
    activities: Record<WorkflowDefinitionActivityId, WorkflowDefinitionActivity>;

    variables: Record<WorkflowDefinitionVariableId, WorkflowDefinitionActivityConfiguration>;

    metadata?: WorkflowMetadata;
}

