import { defineMessages } from 'react-intl';
import { isEmpty } from 'lodash';

import { Workflow, WORKFLOW_DND_TYPE, WorkflowConnector, WorkflowStatus } from '../../../framework/workflows';
import { JsonChange } from '../../../utils/connector';
import { registerToolItem, registerToolItemForMimeType, TargetEnvironmentContext } from '../../../components/basic';
import {
    WORKFLOWS_VIEW_LEFT_CONTEXT,
    WorkflowsEnvironmentContext,
} from '../environment-contexts/workflows-environment-context';
import { WorkflowState } from '../../../framework/workflows/states/workflow-state';

const messages = defineMessages({
    disable: {
        id: 'settings.workflows.actions.Disable',
        defaultMessage: 'Disable',
    },
});

async function disableWorkflows(workflows: Workflow[], workflowEnvironmentContext: WorkflowsEnvironmentContext): Promise<void> {
    const changes: JsonChange[] = [{
        path: 'status',
        value: WorkflowStatus.Disabled,
    }];

    const ps = workflows.map(async (workflow: Workflow) => {
        await WorkflowConnector.getInstance().patchWorkflow(workflow.id, changes);

        await workflowEnvironmentContext.workflowsStateAccess.runWorkflow(workflow.id, async (workflowState: WorkflowState) => {
            workflowState.change('disabled');
        });
    });

    await Promise.all(ps);
}

export function registerDisableWorkflowsActions() {
    registerToolItemForMimeType<WorkflowsEnvironmentContext, Workflow>(WORKFLOW_DND_TYPE, {
        path: 'actions/disable',
        label: messages.disable,
        order: 100,
        visible: (environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>) => {
            const workflow = environmentContext.firstItem();
            if (!workflow) {
                return false;
            }
            const result = workflow.status === WorkflowStatus.Enabled;

            return result;
        },
        onClick: async (_, environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>): Promise<void> => {
            const workflow = environmentContext.firstItem();
            if (!workflow) {
                return;
            }
            await disableWorkflows([workflow], environmentContext);
        },
    });


    registerToolItem<TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>>(WORKFLOWS_VIEW_LEFT_CONTEXT, {
        path: 'actions/actions/disable',
        type: 'button',
        order: 200,
        label: messages.disable,
        disabled: (workflowEnvironmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>) => {
            const workflows = workflowEnvironmentContext.listItemKeys().filter((workflowId) => {
                const workflow = workflowEnvironmentContext.getItem(workflowId)!;

                if (workflow.status === WorkflowStatus.Disabled) {
                    return false;
                }

                return true;
            });

            return isEmpty(workflows);
        },
        onClick: async (_, workflowEnvironmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>) => {
            const workflows = workflowEnvironmentContext.listItemKeys().map((workflowId) => {
                const workflow = workflowEnvironmentContext.getItem(workflowId)!;

                return workflow;
            });

            await disableWorkflows(workflows, workflowEnvironmentContext);
        },
    });
}
