import { useBasicState } from '../../../utils/rt-states/use-basic-state';
import { WorkflowId } from '../model/workflows';
import { WorkflowState } from './workflow-state';

export function workflowStateFactory(url: string, processId: WorkflowId) {
    return new WorkflowState(url, processId);
}

export function computeWorkflowStateURL(workflowId: WorkflowId) {
    return `/workflows/${workflowId}`;
}

export function useWorkflowState(workflowId: WorkflowId): WorkflowState;
export function useWorkflowState(workflowId: WorkflowId | undefined): WorkflowState | undefined;

export function useWorkflowState(workflowId: WorkflowId | undefined): WorkflowState | undefined {
    const state = useBasicState<WorkflowState>(
        (url) => workflowStateFactory(url, workflowId!),
        (workflowId) ? computeWorkflowStateURL(workflowId) : undefined,
    );

    return state;
}
