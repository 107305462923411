import React, { ReactNode, useCallback, useMemo } from 'react';
import { NumberSize, Resizable, ResizeDirection, Size } from 're-resizable';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgToolbar } from '../arg-toolbar/arg-toolbar';
import { ToolContext } from '../arg-tools/tool-context';
import { useToolNodes } from '../arg-tools/use-tool-nodes';
import { countVisibleChildren } from '../arg-tools/utils';
import { LEFT_ENABLE, RIGHT_ENABLE } from '../utils/resizable';
import { useUserConfiguration } from '../../../hooks/use-user-configuration';
import { PreparationEnvironmentContext } from '../../../preparation/environment-contexts/preparation-environment-context';

import './arg-vertical-toolbar-layout.less';

const CLASSNAME = 'arg-vertical-toolbar-layout';

const DEFAULT_SIDE_PANEL_SIZE: Size = {
    width: 436,
    height: 'auto',
};

export type ArgVerticalToolbarPosition = 'left' | 'right';

export interface ArgVerticalToolbarLayoutProps<T> {
    /**
     * An HTML class for the container
     */
    className?: ClassValue;

    /**
     * The prefix for the toolbar's item filter.
     */
    prefix?: string;

    /**
     * The context for the toolbar.
     *
     * Permits to define a list of items to display.
     */
    toolbarContext?: ToolContext<T>;

    /**
     * Hide toolbar
     */
    toolbarHidden?: boolean;

    /**
     * The content of the toolbar layout
     */
    children?: ReactNode;

    /**
     * An HTML class for the body
     */
    bodyClassName?: ClassValue;

    /**
     * The toolbar position
     */
    position?: ArgVerticalToolbarPosition;

    /**
     * The side panel content
     */
    sidePanel?: ReactNode;

    /**
     * An HTML class for the toolbar
     */
    toolbarClassName?: ClassValue;

    /**
     * An HTML class for the side panel
     */
    sidePanelClassName?: ClassValue;

    /**
     * The side panel width config name
     */
    sidePanelWidthConfigName?: string;

    /**
     * The side panel default width
     */
    sidePanelDefaultWidth?: number;

    /**
     * The side panel min width
     */
    sidePanelMinWidth?: number | string;

    /**
     * The side panel max width
     */
    sidePanelMaxWidth?: number | string;

    /**
     * The environment context for the toolbar
     */
    environmentContext: T;
}

/**
 * This component is designed to display layout component that provides a vertical toolbar with an optional resizable side panel. It can be positioned on the left or right side of the layout.
 *
 * @example
 * ```
 * <ArgVerticalToolbarLayout
 *      toolbarContext={toolbarContext}
 *      environmentContext={environmentContext}
 * />
 * ```
 */
export function ArgVerticalToolbarLayout<T = undefined>(props: ArgVerticalToolbarLayoutProps<T>) {
    const {
        className,
        children,
        sidePanel,
        bodyClassName,
        position = 'left',
        toolbarContext,
        toolbarClassName,
        toolbarHidden = false,
        sidePanelClassName,
        sidePanelWidthConfigName,
        sidePanelDefaultWidth,
        sidePanelMinWidth,
        sidePanelMaxWidth,
        environmentContext,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const toolBarContext = toolbarContext || new ToolContext<any>('');
    const [toolbarNodes] = useToolNodes(toolBarContext, environmentContext);

    const [sidePanelWidth, setSidePanelWidth] = useUserConfiguration<string | number>(
        sidePanelWidthConfigName || 'ui.vertical-side-panel.panel-width',
        sidePanelDefaultWidth || DEFAULT_SIDE_PANEL_SIZE.width,
    );

    const handleSidePanelResized = useCallback((event: MouseEvent | TouchEvent, direction: ResizeDirection, elementRef: HTMLElement, delta: NumberSize) => {
        const bounds = elementRef.getBoundingClientRect();
        setSidePanelWidth(bounds.width);
    }, [setSidePanelWidth]);


    const isToolbarVisible = useMemo<boolean>(() => {
        const toolNodesVisible = toolbarNodes.filter((node) => {
            const visibleChildren = countVisibleChildren(node, environmentContext);

            return visibleChildren.length > 0;
        });

        return toolNodesVisible.length > 0 && !toolbarHidden;
    }, [toolbarHidden, toolbarNodes, environmentContext]);

    const defaultSize = useMemo(() => {
        return {
            width: sidePanelWidth,
            height: 'auto',
        };
    }, [sidePanelWidth]);

    let _sidePanel: ReactNode = null;
    if (sidePanel) {
        const resizeEnable = position === 'left' ? RIGHT_ENABLE : LEFT_ENABLE;
        const cls = {
            left: position === 'left',
            right: position === 'right',
        };

        if (resizeEnable) {
            _sidePanel = (
                <Resizable
                    enable={resizeEnable}
                    defaultSize={defaultSize}
                    minWidth={sidePanelMinWidth || DEFAULT_SIDE_PANEL_SIZE.width}
                    maxWidth={sidePanelMaxWidth}
                    onResizeStop={handleSidePanelResized}
                    className={classNames('&-side-panel', sidePanelClassName, cls)}
                >
                    {sidePanel}
                </Resizable>
            );
        } else {
            _sidePanel = <div
                className={classNames('&-side-panel', sidePanelClassName, cls)}
            >
                {sidePanel}
            </div>;
        }
    }

    return (
        <div
            className={classNames('&', className)}
            data-testid='arg-vertical-toolbar-layout'
        >
            {position === 'left' && isToolbarVisible && (
                <ArgToolbar<T>
                    key='left'
                    className={classNames('&-toolbar', toolbarClassName)}
                    toolbarContext={toolBarContext}
                    environmentContext={environmentContext}
                />
            )}

            {position === 'right' && _sidePanel}

            <div key='body' className={classNames('&-body', bodyClassName)}>
                {children}
            </div>

            {position === 'right' && isToolbarVisible && (
                <ArgToolbar<T>
                    key='right'
                    className={classNames('&-toolbar', toolbarClassName)}
                    toolbarContext={toolBarContext}
                    environmentContext={environmentContext}
                />
            )}

            {position === 'left' && _sidePanel}
        </div>
    );
}
