import React, { createContext, ReactNode, useContext, useEffect, useRef } from 'react';
import Debug from 'debug';
import { defineMessages } from 'react-intl';
import { chain } from 'lodash';

import {
    BulkRequests,
    CacheOptions,
    DataCacheRepository,
    DataCacheRepositoryResponse,
    IDataCacheRepository,
    ProgressMonitor,
    StateId,
    useDataCacheRepository,
} from '../../../components/basic';
import { Workflow, WorkflowDefinition, WorkflowDefinitionVersion, WorkflowId } from '../model/workflows';
import { WorkflowConnector } from '../utils/workflow-connector';
import { isResponse404 } from '../../../components/basic/utils/response-error';
import { useWorkflowState } from '../states/use-worflow-state';
import { useWorkflowsListState } from '../states/use-worflows-list-state';
import { useWorkflowDefinitionState } from '../states/use-worflow-definition-state';

const debug = Debug('preparation:caches:PreparationObjectCache');

const DEFAULT_TTLS = 0;
const ARGONOS_PIECES_DELAY_MS = 200;

const USE_BULK = localStorage.ARG_PREPARATION_BULK !== 'false';

const messages = defineMessages({
    loadingWorkflow: {
        id: 'workflows.cache.LoadingWorkflow',
        defaultMessage: 'Loading workflow',
    },
});

const LOADING_WORKFLOW_CACHE_OPTIONS: WorkflowCacheOptions = {
    loadingMessage: messages.loadingWorkflow,
};

type Types =
    | Workflow
    | WorkflowDefinition
    | WorkflowId[]
    ;

interface TypeKeys {
    workflowIds?: true;

    workflowId?: WorkflowId;

    workflowDefinitionId?: WorkflowId;
    workflowDefinitionVersion?: WorkflowDefinitionVersion;
}

export interface WorkflowCacheOptions extends CacheOptions {
    keepPrevious?: boolean;
}

export const KEEP_PREVIOUS: WorkflowCacheOptions = {
    keepPrevious: true,
};

export class WorkflowsObjectCache {
    #dataCache: IDataCacheRepository<Types>;

    constructor(cacheName: string, ttlMs: number = DEFAULT_TTLS) {
        this.#dataCache = new DataCacheRepository<Types>(cacheName, this.#dataLoader, { ttlMs });
    }

    get dataCache(): IDataCacheRepository<Types> {
        return this.#dataCache;
    }

    getWorkflowIds(workflowIdsStateId: StateId | undefined, progressMonitor?: ProgressMonitor): Promise<WorkflowId[] | null> {
        const keys: TypeKeys = { workflowIds: true };
        const ret = this.#dataCache.loadPromise(
            undefined,
            keys,
            workflowIdsStateId,
            LOADING_WORKFLOW_CACHE_OPTIONS,
            progressMonitor,
        );

        return ret as Promise<WorkflowId[] | null>;
    }

    getWorkflow(workflowId: WorkflowId, workflowStateId: StateId | undefined, progressMonitor?: ProgressMonitor): Promise<Workflow | null> {
        const keys: TypeKeys = { workflowId };
        const ret = this.#dataCache.loadPromise(
            undefined,
            keys,
            workflowStateId,
            LOADING_WORKFLOW_CACHE_OPTIONS,
            progressMonitor,
        );

        return ret as Promise<Workflow | null>;
    }

    getWorkflowDefinition(workflowDefinitionId: WorkflowId, workflowDefinitionVersion: WorkflowDefinitionVersion, workflowDefinitionStateId: StateId | undefined, progressMonitor?: ProgressMonitor): Promise<WorkflowDefinition | null> {
        const keys: TypeKeys = { workflowDefinitionId, workflowDefinitionVersion };
        const ret = this.#dataCache.loadPromise(
            undefined,
            keys,
            workflowDefinitionStateId,
            LOADING_WORKFLOW_CACHE_OPTIONS,
            progressMonitor,
        );

        return ret as Promise<WorkflowDefinition | null>;
    }


    #dataLoader = async (key: string, infos: TypeKeys, previousValue: Types | undefined, progressMonitor: ProgressMonitor): Promise<Types | null> => {
        try {
            if (infos.workflowIds) {
                const result = await WorkflowConnector.getInstance().listWorkflowIds(
                    progressMonitor,
                );

                return result;
            }
            if (infos.workflowId) {
                if (USE_BULK) {
                    const result = await workflowsBulkRequest.get(undefined, infos) as Workflow | null;

                    return result;
                }

                const result = await WorkflowConnector.getInstance().getWorkflow(
                    infos.workflowId,
                    previousValue as Workflow | undefined,
                    progressMonitor,
                );

                return result;
            }
            if (infos.workflowDefinitionId && infos.workflowDefinitionVersion !== undefined) {
                const ret = await WorkflowConnector.getInstance().getWorkflowDefinition(
                    infos.workflowDefinitionId,
                    infos.workflowDefinitionVersion,
                    previousValue as WorkflowDefinition | undefined,
                    progressMonitor,
                );

                return ret;
            }
        } catch (error) {
            if (isResponse404(error)) {
                debug('#dataLoader', 'DataLoader error=', error);

                return null;
            }

            console.error('DataLoader error=', error);
            throw error;
        }

        console.error('Unsupported Key=', infos);
        throw new Error('Unsupported key');
    };

    dispose() {
        this.#dataCache.dispose();
    }
}

export const WorkflowObjectCacheContext = createContext<WorkflowsObjectCache>(new WorkflowsObjectCache('$$default-context'));

interface WorkflowObjectCacheScopeProps {
    name: string;
    children: ReactNode;
    ttlMs?: number;
}

export function WorkflowObjectCacheScope(props: WorkflowObjectCacheScopeProps) {
    const { name, children, ttlMs } = props;

    const preparationObjectCacheRef = useRef<WorkflowsObjectCache>();
    if (!preparationObjectCacheRef.current) {
        preparationObjectCacheRef.current = new WorkflowsObjectCache(name, ttlMs);
    }

    useEffect(() => {
        return () => {
            if (!preparationObjectCacheRef.current) {
                return;
            }
            preparationObjectCacheRef.current?.dispose();
        };
    }, []);

    return <WorkflowObjectCacheContext.Provider value={preparationObjectCacheRef.current}>
        {children}
    </WorkflowObjectCacheContext.Provider>;
}

export function useWorkflowsObjectCache(workflowObjectCacheByParam?: WorkflowsObjectCache, ttlMs?: number, cacheName = 'useWorkflowObjectCache'): WorkflowsObjectCache {
    const workflowObjectCacheByContext = useContext(WorkflowObjectCacheContext);

    const workflowObjectCacheByRef = useRef<WorkflowsObjectCache>();

    useEffect(() => {
        return () => {
            if (workflowObjectCacheByRef.current) {
                workflowObjectCacheByRef.current.dispose();
            }
        };
    }, []);

    if (workflowObjectCacheByParam) {
        return workflowObjectCacheByParam;
    }
    if (workflowObjectCacheByContext) {
        return workflowObjectCacheByContext;
    }

    if (workflowObjectCacheByRef.current) {
        return workflowObjectCacheByRef.current;
    }
    const workflowObjectCache = new WorkflowsObjectCache(cacheName, ttlMs);

    workflowObjectCacheByRef.current = workflowObjectCache;

    return workflowObjectCache;
}

function useWorkflowCacheRepository<K = string>(
    infos: K | string | undefined,
    stateId: StateId | undefined,
    options: WorkflowCacheOptions = KEEP_PREVIOUS,
    workflowObjectCacheByParam?: WorkflowsObjectCache,
): DataCacheRepositoryResponse<Types> | undefined {
    const cacheContext = useWorkflowsObjectCache(workflowObjectCacheByParam);

    const dataRepository = cacheContext?.dataCache;

    const previousRef = useRef<Types | undefined>();

    let ret = useDataCacheRepository<Types, K>(infos, stateId, dataRepository, options);

    if (ret?.[0]) {
        previousRef.current = ret[0]!;
    } else if (!ret?.[1] && options?.keepPrevious && previousRef.current) {
        ret = [previousRef.current, ret?.[1], ret?.[2]];
    }

    return ret;
}

export function useWorkflowObjectCacheWorkflowIds(
    options?: CacheOptions,
    workflowObjectCacheByParam?: WorkflowsObjectCache,
): DataCacheRepositoryResponse<WorkflowId[] | null> {
    const workflowsListState = useWorkflowsListState();

    const key: TypeKeys = {
        workflowIds: true,
    };

    const ret = useWorkflowCacheRepository(
        key,
        workflowsListState.stateId,
        options,
        workflowObjectCacheByParam,
    );

    return ret as DataCacheRepositoryResponse<WorkflowId[]>;
}

export function useWorkflowObjectCacheWorkflow(
    workflowId: WorkflowId | undefined,
    options?: CacheOptions,
    workflowObjectCacheByParam?: WorkflowsObjectCache,
): DataCacheRepositoryResponse<Workflow | null> {
    const workflowState = useWorkflowState(workflowId);

    const key: TypeKeys = {
        workflowId,
    };

    const ret = useWorkflowCacheRepository(
        key,
        workflowState?.stateId,
        options,
        workflowObjectCacheByParam,
    );

    return ret as DataCacheRepositoryResponse<Workflow>;
}


export function useWorkflowObjectCacheWorkflowDefinition(
    workflowDefinitionId: WorkflowId,
    workflowDefinitionVersion: WorkflowDefinitionVersion,
    options?: CacheOptions,
    workflowObjectCacheByParam?: WorkflowsObjectCache,
): DataCacheRepositoryResponse<WorkflowDefinition | null> {
    const workflowDefinitionState = useWorkflowDefinitionState(workflowDefinitionId, workflowDefinitionVersion);

    const key: TypeKeys = {
        workflowDefinitionId,
        workflowDefinitionVersion,
    };

    const ret = useWorkflowCacheRepository(
        key,
        workflowDefinitionState?.stateId,
        options,
        workflowObjectCacheByParam,
    );

    return ret as DataCacheRepositoryResponse<WorkflowDefinition>;
}

const workflowsBulkRequest = new BulkRequests<TypeKeys, Workflow>(
    'preparation.ProcessLastStatistics',
    async (
        groupName: string | undefined,
        infos: TypeKeys[],
        progressMonitor: ProgressMonitor,
    ): Promise<(Workflow | null)[]> => {
        const ids = chain(infos)
            .map<WorkflowId | undefined>((i) => i.workflowId)
            .compact()
            .uniq()
            .value();
        const response = await WorkflowConnector.getInstance().listWorkflows(
            ids,
            progressMonitor,
        );

        const ret: (Workflow | null)[] = [];
        ret.fill(null, 0, infos.length);

        infos.forEach((info, index) => {
            response.forEach((v) => {
                if (v.id !== info.workflowId) {
                    return;
                }

                ret[index] = v;
            });
        });

        debug('workflow-last-graph-bulk', 'infos=', infos, 'ids=', ids, 'response=', response, 'ret=', ret);

        return ret;
    }, ARGONOS_PIECES_DELAY_MS);
