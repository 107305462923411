import React, { ReactNode } from 'react';

import {
    ArgRenderedIcon,
    ArgRenderedText,
    ArgSize,
    ClassValue,
    renderIcon,
    renderText,
    useClassNames,
} from '../../basic';
import { Background } from '../chapsvision-background';

import './basic-pane.less';

export interface BasicPaneProps {
    className?: ClassValue;
    message?: ArgRenderedText;
    description?: ArgRenderedText;
    children?: ReactNode;
    icon?: ArgRenderedIcon;
    hasBackground?: boolean;
    backgroundAnimation?: string;
    size?: ArgSize;
}

export function BasicPane(props: BasicPaneProps) {
    const {
        className,
        message,
        icon,
        hasBackground = true,
        description,
        backgroundAnimation,
        size = 'large',
    } = props;

    const classNames = useClassNames('basic-pane');

    const cls = {
        [`size-${size}`]: true,
        'with-message': !!message,
        'with-icon': !!icon,
    };

    return (
        <div className={classNames('&', className, cls)}>
            <div className={classNames('&-body')}>
                {hasBackground && (
                    <Background className={classNames('&-body-background')} animation={backgroundAnimation} />
                )}

                {renderIcon(icon, classNames('&-body-icon'))}
            </div>

            {message && <span className={classNames('&-message')}>
                {renderText(message)}
            </span>}

            {description && <span className={classNames('&-description')}>
                {renderText(description)}
            </span>}
        </div>
    );
}
