import { defineMessages } from 'react-intl';

import { SettingsEnvironmentContext } from '../../settings/envrionment-contexts/settings-environment-context';
import { registerRolesToolItems } from '../../settings/roles';
import { registerConfigurationToolItems } from '../../settings/configuration';
import {
    registerExternalComponentsToolItems,
    registerWebhookToolItems,
} from '../../settings/webhooks-external-components';
import { registerToolItem } from '../../components/basic';
import { ArgonosModule, ArgonosModuleId } from '../../components/application/modules';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../../settings/envrionment-contexts/context-names';
import { registerWorkflowTarget } from '../../framework/workflows/registry/workflows-registry';

const messages = defineMessages({
    processusTargetType: {
        id: 'preparation.workflows.targetTypes.Processus',
        defaultMessage: 'Processus',
    },
    compositeTargetType: {
        id: 'preparation.workflows.targetTypes.Composite',
        defaultMessage: 'Composite',
    },
    secretTargetType: {
        id: 'preparation.workflows.targetTypes.Secret',
        defaultMessage: 'Secret',
    },
});

export function setupSettings(preparationModule: ArgonosModule) {
    registerToolItem<SettingsEnvironmentContext>(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path: 'modules/preparation',
        order: 200,
        type: 'group',
        icon: preparationModule.iconURL,
        visible: !!preparationModule.enabled,
        label: preparationModule.name,
    });
    registerRolesToolItems('modules/preparation/roles', 100, preparationModule);
    registerConfigurationToolItems('modules/preparation/configuration', 200, preparationModule.scope);
    registerWebhookToolItems('modules/preparation/webhook', 250, preparationModule);
    registerExternalComponentsToolItems('modules/preparation/extensions', 300);
}

export function setupWorkflowTypes(argonosModuleId: ArgonosModuleId) {
    registerWorkflowTarget({
        targetType: `${argonosModuleId}:Processus`,
        label: messages.processusTargetType,
        argonosModuleId: argonosModuleId,
    });
    /*
    registerWorkflowTarget({
        targetType: `${argonosModuleId}.Composite`,
        label: messages.compositeTargetType,
        applicationId: argonosModuleId,
        tags: [`${argonosModuleId}.Composite`],
    });
    registerWorkflowTarget({
        targetType: `${argonosModuleId}.Secret`,
        label: messages.secretTargetType,
        applicationId: argonosModuleId,
        tags: [`${argonosModuleId}.Secret`],
    });
     */
}
