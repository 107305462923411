
export interface SettingsPermissions {
    'admin.user.access': true;
    'admin.user.edition': true;
    'admin.user.management': true;
    'admin.user.group.access': true;
    'admin.user.group.edition': true;
    'admin.user.role.access': true;
    'admin.user.role.edition': true;
    'admin.user.fields.access': true;
    'admin.user.fields.management': true;
    'admin.import.export.settings': true;
    'admin.webhook.access': true;
    'admin.webhook.edition': true;
    'admin.webhook.export': true;
    'admin.supervision': true;
    'admin.logs.management': true;
    'admin.visual.identity.management': true;
    'admin.contextual.variable.management': true;
    'admin.contextual.variable.edition': true;
    'admin.moduleExtensions.access': true;
    'admin.moduleExtensions.edition': true;
    'admin.moduleExtensions.delete': true;
    'admin.moduleExtensions.publish': true;
    'admin.reference.access': true;
    'admin.reference.edition': true;
    'admin.reference.export': true;
}

export const SETTINGS_PERMISSIONS_KEYS: (keyof SettingsPermissions)[] = [
    'admin.user.access',
    'admin.user.edition',
    'admin.user.management',
    'admin.user.group.access',
    'admin.user.group.edition',
    'admin.user.role.access',
    'admin.user.role.edition',
    'admin.user.fields.access',
    'admin.user.fields.management',
    'admin.import.export.settings',
    'admin.webhook.access',
    'admin.webhook.edition',
    'admin.webhook.export',
    'admin.supervision',
    'admin.logs.management',
    'admin.visual.identity.management',
    'admin.contextual.variable.management',
    'admin.contextual.variable.edition',
    'admin.moduleExtensions.access',
    'admin.moduleExtensions.edition',
    'admin.moduleExtensions.delete',
    'admin.moduleExtensions.publish',
];

export const SETTINGS_PERMISSIONS_REQUIRED: (keyof SettingsPermissions)[] = [
    'admin.user.access',
    'admin.user.edition',
    'admin.user.management',
    'admin.user.group.access',
    'admin.user.group.edition',
    'admin.user.role.access',
    'admin.user.role.edition',
    'admin.user.fields.access',
    'admin.user.fields.management',
    'admin.import.export.settings',
    'admin.webhook.access',
    'admin.webhook.edition',
    'admin.webhook.export',
    'admin.visual.identity.management',
    'admin.contextual.variable.management',
    'admin.contextual.variable.edition',
    'admin.moduleExtensions.access',
    'admin.moduleExtensions.edition',
    'admin.moduleExtensions.delete',
    'admin.moduleExtensions.publish',
    'admin.reference.access',
    'admin.reference.edition',
    'admin.reference.export',
];
