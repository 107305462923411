import { first } from 'lodash';

import { Workflow, WorkflowDefinition } from '../model/workflows';
import { mapDate } from '../../../utils/connectors/mappers';
import { WorkflowActivity } from '../model/workflow-activity';

export function mapWorkflow(raw: any): Workflow {
    const result = {
        ...raw,
        createdDate: mapDate(raw.createdDate),
        lastUpdatedDate: mapDate(raw.lastUpdatedDate),
        instancesCount: raw.instancesCount || 0,

        targetType: raw.triggerLock?.targetType,
        order: raw.triggerLock?.order || 0,
    } as Workflow;

    return result;
}


export function mapWorkflowDefinition(raw: any): WorkflowDefinition {
    const result = {
        ...raw,

    } as WorkflowDefinition;

    return result;
}


export function mapWorkflowActivity(raw: any): WorkflowActivity {
    const result = {
        ...raw,

        argonosModuleId: raw.application,
        name: raw.name.label,
        description: raw.description?.label,
        type: first(raw.types)!,

    } as WorkflowActivity;

    return result;
}

