import { isNumber, isString } from 'lodash';

export function getSizePixel(size: string | undefined | number): number | undefined {
    if (isNumber(size)) {
        return size;
    }

    if (!size) {
        return undefined;
    }

    const reg = /^([0-9\.]+)px$/.exec(size);
    if (!reg) {
        return undefined;
    }

    return parseFloat(reg[1]);
}

export function getSizePercent(size: string | undefined | number): number | undefined {
    if (!size || !isString(size)) {
        return undefined;
    }

    const reg = /^([0-9\.]+)%$/.exec(size);
    if (!reg) {
        return undefined;
    }

    return parseFloat(reg[1]);
}
