import { ArgPanelsConfiguration } from '../../basic';

const DEFAULT_PANEL_WIDTH = '20%';
const DEFAULT_PANEL_HEIGHT = '25%';

const MAX_PANEL_WIDTH = '40%';
const MIN_PANEL_WIDTH = 128;

const MIN_PANEL_HEIGHT = 100;
const MAX_PANEL_HEIGHT = '40%';

export const DEFAULT_PANELS_CONFIGURATION: ArgPanelsConfiguration = {
    leftSelectedPanelConfigurationName: 'ui.panels.left.selected',
    leftPanelWidthConfigurationName: 'ui.panels.left.width',
    rightSelectedPanelConfigurationName: 'ui.panels.right.selected',
    rightPanelWidthConfigurationName: 'ui.panels.right.width',
    selectedEditorConfigurationName: 'ui.editor.selected',
    footerPanelHeightConfigurationName: 'ui.panels.footer.height',

    defaultLeftPanelWidth: DEFAULT_PANEL_WIDTH,
    defaultRightPanelWidth: DEFAULT_PANEL_WIDTH,
    defaultFooterPanelHeight: DEFAULT_PANEL_HEIGHT,

    minLeftPanelWidth: MIN_PANEL_WIDTH,
    minRightPanelWidth: MIN_PANEL_WIDTH,
    minFooterPanelHeight: MIN_PANEL_HEIGHT,

    maxLeftPanelWidth: MAX_PANEL_WIDTH,
    maxRightPanelWidth: MAX_PANEL_WIDTH,
    maxFooterPanelHeight: MAX_PANEL_HEIGHT,
};
