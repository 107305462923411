import { ArgUserInfo } from 'src/components/basic';
import { Reference, ReferenceItem } from 'src/settings/models/references';
import { mapDate } from 'src/utils/connectors/mappers';

export interface RawReference {
    id: string;
    name: string;
    description?: string;

    createdDate: string;
    createdBy: ArgUserInfo;
    lastUpdatedDate: string;
    lastUpdatedBy: ArgUserInfo;
    items?: ReferenceItem[];
}

export function mapReference(rawReferenceTable: RawReference): Reference {
    const result = {
        ...rawReferenceTable,
        createdDate: mapDate(rawReferenceTable.createdDate),
        lastUpdatedDate: mapDate(rawReferenceTable.lastUpdatedDate),
    } as Reference;

    return result;
}
