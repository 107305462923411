import React, { useCallback } from 'react';

import { ArgInput, ArgInputKeyPressEvent, ArgInputProps } from './arg-input';
import { ArgChangeReason } from '../types';
import { useClassNames } from '../arg-hooks/use-classNames';

function stringFormatValue(value: string | null): string {
    if (value === null) {
        return '';
    }

    return value;
}

function stringParseValue(value: string): string | null {
    return value;
}

export interface ArgInputTextProps<U = any>
    extends Omit<ArgInputProps<string, U>, 'formatValue' | 'parseValue'> {
    /**
     * The keyPressRegExp for the input text time component
     */
    keyPressRegExp?: RegExp;
    /**
     * A boolean to explicitly perform change on input event for the input text time component
     */
    performChangeOnInput?: boolean;
}

/**
 * This component is designed to display an input text field.
 *
 * @example
 * ```
 * <ArgInputText value={value} onChange={handleChangeValue} />
 * ```
 */
export function ArgInputText<U = any>(inputProps: ArgInputTextProps<U>) {
    const {
        keyPressRegExp,
        onInputChange,
        performChangeOnInput,
        onChange,
        onKeyPress,
        htmlAutoComplete,
        initialValue,
        maxLength,
        onClear,
        className,
        inputType,
        ...props
    } = inputProps;

    if (process.env.NODE_ENV !== 'production') {
        if (htmlAutoComplete && htmlAutoComplete !== 'off' && !performChangeOnInput) {
            // throw new Error('You must enable performChangeOnInput property if autoComplete is enabled');
        }
    }

    const classNames = useClassNames('arg-input-text');

    let _initialValue = initialValue;

    const useInternalValue = !('value' in inputProps);
    if (useInternalValue) {
        if (initialValue === undefined) {
            _initialValue = '';
        }
    }

    const regexpKeypress = useCallback(
        (event: ArgInputKeyPressEvent): void => {
            if (!keyPressRegExp) {
                return;
            }

            const keyboardEvent = event.keyboardEvent;
            const key = keyboardEvent.key;

            if (
                key.length === 1 &&
                !keyboardEvent.ctrlKey &&
                !keyboardEvent.altKey &&
                !keyboardEvent.metaKey &&
                !keyPressRegExp.test(key)
            ) {
                keyboardEvent.preventDefault();
                keyboardEvent.stopPropagation();

                return;
            }
        },
        [keyPressRegExp],
    );

    const handleInputChange = useCallback((text: string) => {
        onInputChange?.(text);

        if (!performChangeOnInput) {
            return;
        }
        onChange?.(text, 'keypress');
    }, [performChangeOnInput, onInputChange, onChange]);

    const handleChange = useCallback((text: string | null, reason: ArgChangeReason) => {
        if (performChangeOnInput) {
            return;
        }
        onChange?.(text, reason);
    }, [performChangeOnInput, onChange]);


    const handleClear = useCallback(() => {
        if (!onClear) {
            //  onChange?.(null, 'clear'); // It was already sent !!!

            return;
        }

        onClear();
    }, [onChange, onClear]);

    let _htmlAutoComplete = htmlAutoComplete;
    if (!_htmlAutoComplete && inputType === 'password') {
        _htmlAutoComplete = 'new-password';
    }

    return (
        <ArgInput<string, U>
            formatValue={stringFormatValue}
            parseValue={stringParseValue}
            maxLength={maxLength}
            onKeyPress={onKeyPress || (keyPressRegExp && regexpKeypress) || undefined}
            htmlAutoComplete={_htmlAutoComplete}
            {...props}
            inputType={inputType}
            className={classNames('&', className)}
            initialValue={_initialValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onClear={handleClear}
        />
    );
}
