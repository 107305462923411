import { defineMessages, useIntl } from 'react-intl';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { groupBy, isEmpty, merge } from 'lodash';

import retentionPolicyConnector from 'src/settings/connectors/retention-policy-connector';
import {
    ArgButton,
    ArgIcon,
    ArgTable2Column,
    ArgToolbarLayout,
    ArgToolbarLayoutProps,
    ClassValue,
    GLOBAL_PM,
    ProgressMonitor,
    renderText,
    SubProgressMonitor,
    useArgModalContext,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useEffectAsync,
    useMemoAsync,
    useToolContext,
    useToolItem,
} from 'src/components/basic';
import {
    RetentionPolicy,
    RetentionPolicyActionEffects,
    RetentionPolicyRow,
    RetentionPolicyRowSelection,
} from '../types';
import { OverviewSection } from 'src/components/features/overview/overview-section';
import { ItemActions } from 'src/settings/types/item-actions';
import {
    addOrRemoveFromList,
    constructRetentionPolicyRows,
    getActionEffectOnPropertyTargetProperty,
    getActionsColumn,
    getExpandColumn,
    getModificationDateColumn,
    getObjectOrRelationOrPropertyColumn,
    getObjectTypesOrLinkTypesIndex,
    getRetentionPolicyColumn,
    getRetentionPolicyLabel,
    getRowId,
    getSelectionColumn,
    getSelectionObject,
    hasPolicies,
    isActionEffectOnObject,
    isActionEffectOnProperty,
} from '../utils';
import { ITEM_ACTIONS } from 'src/settings/constants/item-actions';
import {
    AutomaticObjectDeletionModal,
} from 'src/settings/universes/ontology/components/automatic-object-deletion-modal/automatic-object-deletion-modal';
import { PropertiesPanel, SelectedRetentionPolicy } from '../components/properties-panel';
import { DEFAULT_VERTEX_STYLE } from 'src/exploration/constants/default-vertex-style';
import { DEFAULT_EDGE_STYLE } from 'src/exploration/constants/default-edge-style';
import { VertexStyle } from 'src/exploration/model/vertex';
import { EdgeStyle } from 'src/exploration/model/edge';
import { getPropertyIconName } from 'src/settings/utils/properties-utils';
import ontologiesConnector from '../../../connectors/ontologies-connector';

import './retention-rules.less';

const messages = defineMessages({
    loadingOntologyError: {
        id: 'settings.retention-rules.LoadingOntologyError',
        defaultMessage: 'Failed to load the ontology',
    },
    publish: {
        id: 'settings.retention-rules.Publish',
        defaultMessage: 'Publish',
    },
    autoDeletionRulesTitle: {
        id: 'settings.retention-rules.AutoDeletionRulesTitle',
        defaultMessage: 'Auto deletion rules',
    },
    loadingRetentionPolicyError: {
        id: 'settings.retention-rules.LoadingRetentionPloicyError',
        defaultMessage: 'Failed to load the retention policy',
    },
    publishingRetentionPolicyError: {
        id: 'settings.retention-rules.PublishingRetentionPolicyError',
        defaultMessage: 'Failed to publish the retention policy',
    },
    publishingRetentionPolicyErrorWhenOntologyIsNotPublished: {
        id: 'settings.retention-rules.PublishingRetentionPolicyErrorWhenOntologyIsNotPublished',
        defaultMessage: 'The ontology must be published first',
    },
    objectOrRelationOrPropertyColumnTitle: {
        id: 'settings.retention-rules.ObjectOrRelationOrPropertyColumnTitle',
        defaultMessage: 'Object/Relation',
    },
    retentionPolicyColumnTitle: {
        id: 'settings.retention-rules.RetentionPolicyColumnTitle',
        defaultMessage: 'Automatic Deletion',
    },
    emptyRetentionPolicy: {
        id: 'settings.retention-rules.EmptyRetentionPolicy',
        defaultMessage: 'No automatic deletion policies',
    },
    modificationDateColumn: {
        id: 'settings.retention-rules.ModificationDateColumn',
        defaultMessage: 'Modification',
    },
    noItemSelectedTooltipMessage: {
        id: 'settings.retention-rules.NoItemSelectedTooltipMessage',
        defaultMessage: 'No item selected',
    },
});

const CLASSNAME = 'settings-retention-rules';
export const LIBRARY_PANEL_MIN_WIDTH = 400;
const SUPPORTED_ACTIONS = [ItemActions.Edit, ItemActions.Delete];
const EDIT_RETENTION_POLICY_MODAL_NAME = 'EDIT_RETENTION_POLICY_MODAL_NAME';
const ACTIONS_LIST = [{ key: ITEM_ACTIONS[ItemActions.Delete].key, label: ITEM_ACTIONS[ItemActions.Delete].label }];
const SIZING: Pick<ArgToolbarLayoutProps, 'rightPanelMinWidth' | 'leftPanelMinWidth' | 'rightPanelDefaultWidth' | 'leftPanelDefaultWidth'> = {
    rightPanelMinWidth: LIBRARY_PANEL_MIN_WIDTH,
    leftPanelMinWidth: LIBRARY_PANEL_MIN_WIDTH,
    rightPanelDefaultWidth: LIBRARY_PANEL_MIN_WIDTH,
    leftPanelDefaultWidth: LIBRARY_PANEL_MIN_WIDTH,
};

export interface RetentionRulesProps {
    className?: ClassValue;
}

export function RetentionRules({ className }: RetentionRulesProps) {
    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const { ontologyId } = useParams<{ ontologyId: string }>();
    const [expandedItems, setExpandedItems] = useState<string[]>([]);
    const [selection, setSelection] = useState<RetentionPolicyRowSelection[]>(() => []);
    const [retentionPolicy, setRetentionPolicy] = useState<RetentionPolicy>();
    const toolbarContext = useToolContext('settings.retention-rules.toolbar');
    const modalContext = useArgModalContext();
    const intl = useIntl();

    const [ontology, getOntologyProgressMonitor] = useMemoAsync(async (progressMonitor: ProgressMonitor) => {
        if (!ontologyId) {
            return;
        }

        try {
            const ontology = await ontologiesConnector.getFullOntology(ontologyId, progressMonitor);

            return ontology;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            notifications.snackError({ message: messages.loadingOntologyError }, error as Error);
            throw error;
        }
    }, [ontologyId]);

    const [getRetentionPolicyProgressMonitor] = useEffectAsync(async (progressMonitor: ProgressMonitor) => {
        if (!ontologyId) {
            return;
        }

        try {
            const retentionPolicy = await retentionPolicyConnector.getRetentionPolicy(ontologyId, progressMonitor);

            setRetentionPolicy(retentionPolicy);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            throw error;
        }
    }, [ontologyId]);
    const isExpanded = useCallback((rowKey: string) => expandedItems.includes(rowKey), [expandedItems]);

    const rows = useMemo(() => {
        const retentionPolicyRows = constructRetentionPolicyRows(retentionPolicy, ontology, isExpanded);

        return retentionPolicyRows;
    }, [ontology, retentionPolicy, isExpanded]);

    const [updateRetentionPolicy] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        if (!ontologyId) {
            return;
        }
        const newRetentionPolicy = await retentionPolicyConnector.getRetentionPolicy(ontologyId, progressMonitor);

        setRetentionPolicy(newRetentionPolicy);
    }, [ontologyId], undefined, undefined, GLOBAL_PM);

    const syncSelection = useCallback(async (filter: (retentionPolicyRowSelection: RetentionPolicyRowSelection) => boolean) => {
        setSelection(prevSelection => prevSelection.filter(filter));
    }, []);

    const [deleteRetentionPolicy] = useCallbackAsync(
        async (progressMonitor: ProgressMonitor, retentionPolicyRow: RetentionPolicyRow) => {
            try {
                const { kind, type, propertyName } = retentionPolicyRow;
                const action = retentionPolicy?.actions.find(({
                    target: {
                        kind: targetKind,
                        type: targetType,
                    },
                }) => targetKind === kind && targetType === type);
                if (!action) {
                    return;
                }

                const updatedEffects = action.effects.filter(effect => {
                    if (propertyName) {
                        if (isActionEffectOnObject(effect)) {
                            return true;
                        }

                        return getActionEffectOnPropertyTargetProperty(effect) !== propertyName;
                    }

                    return !isActionEffectOnObject(effect);
                });

                if (ontologyId) {
                    await retentionPolicyConnector.updateObjectTypesRetentionPolicy(ontologyId, type, kind, updatedEffects, progressMonitor);
                    await updateRetentionPolicy();
                    await syncSelection(selectedRow => selectedRow.id !== getRowId(retentionPolicyRow));
                }
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    throw error;
                }
                notifications.snackError({ message: messages.loadingRetentionPolicyError }, error as Error);
                throw error;
            }
        }, [ontologyId, retentionPolicy?.actions, syncSelection, updateRetentionPolicy]);

    const editRetentionPolicy = useCallback((retentionPolicyRow: RetentionPolicyRow) => {
        const key = getObjectTypesOrLinkTypesIndex(retentionPolicyRow.kind);
        const edgeOrVertex = ontology?.[key].find(itemType => itemType.name === retentionPolicyRow.type);
        if (!ontology || !edgeOrVertex) {
            return;
        }

        const retentionEffects: RetentionPolicyActionEffects = retentionPolicy?.actions
            .find(action => action.target.kind === retentionPolicyRow.kind && action.target.type === retentionPolicyRow.type)
            ?.effects || [];

        const retentionTarget = retentionPolicyRow.propertyName ? 'Property' : retentionPolicyRow.kind;
        const retentionLink = retentionPolicyRow.kind === 'Edge' ? 'link-types' : 'object-types';
        const propertyName = retentionPolicyRow.propertyName ? edgeOrVertex.properties.find(prop => prop.name === retentionPolicyRow.propertyName)?.name : undefined;

        modalContext.open(EDIT_RETENTION_POLICY_MODAL_NAME,
            <AutomaticObjectDeletionModal
                closeModal={() => modalContext.close(EDIT_RETENTION_POLICY_MODAL_NAME)}
                ontology={ontology}
                edgeOrVertex={edgeOrVertex}
                retentionTarget={retentionTarget}
                retentionLink={retentionLink}
                retention={{ effects: retentionEffects }}
                onChange={updateRetentionPolicy}
                property={retentionPolicyRow.propertyName}
                propertyName={propertyName}
            />,
        );
    }, [modalContext, ontology, retentionPolicy?.actions, updateRetentionPolicy]);

    const [deleteRetentionPolicies] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        try {
            if (!retentionPolicy) {
                return;
            }
            const selectionRecord = groupBy(selection, selectionItem => getRowId(selectionItem));
            const keysToRemoveFromSelection: string[] = [];
            const checkIfIndexExistsInRecordAndRegisterForSelectionSync = (idx: string) => {
                if (selectionRecord[idx]) {
                    keysToRemoveFromSelection.push(idx);

                    return true;
                }

                return false;
            };
            const newRetentionPolicyActions = retentionPolicy?.actions.map(action => {
                const newEffects = action.effects.filter(effect => {
                    if (isActionEffectOnProperty(effect)) {
                        return !checkIfIndexExistsInRecordAndRegisterForSelectionSync(`${action.target.kind}/${action.target.type}/${effect.property.targetProperty}`);
                    }

                    return !checkIfIndexExistsInRecordAndRegisterForSelectionSync(`${action.target.kind}/${action.target.type}/undefined`);
                });

                return {
                    ...action,
                    effects: newEffects,
                };
            });

            const newRetentionPolicy: Pick<RetentionPolicy, 'actions' | 'type'> = {
                type: retentionPolicy.type,
                actions: newRetentionPolicyActions,
            };

            if (ontologyId) {
                const sub1 = new SubProgressMonitor(progressMonitor, 1);
                await retentionPolicyConnector.updateOntologyRetentionPolicy(ontologyId, newRetentionPolicy, sub1);

                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                const updatedRetentionPolicy = await retentionPolicyConnector.getRetentionPolicy(ontologyId, sub2);

                setRetentionPolicy(updatedRetentionPolicy);

                syncSelection(selectedRow => !keysToRemoveFromSelection.includes(selectedRow.id));
            }
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.loadingRetentionPolicyError }, error as Error);
            throw error;
        }
    }, [ontologyId, retentionPolicy, selection, syncSelection]);

    useToolItem(toolbarContext, { path: 'left/auto-deletion-rules', type: 'button' },
        {
            customRender: () => <span
                className={classNames('&-auto-deletion-rules-title')}>{renderText(messages.autoDeletionRulesTitle)}</span>,
        },
    );

    const selectedRetentionPolicies = useMemo<SelectedRetentionPolicy[]>(() => {
        if (!ontology || !selection || selection.length === 0) {
            return [];
        }
        const onlyItemTypeSelection = selection.filter(selectionItem => !selectionItem.propertyName).map(selectedItem => selectedItem.kind + selectedItem.type);
        const selectedRows = rows.filter(row => !row.propertyName && onlyItemTypeSelection.includes(row.kind + row.type));

        return selectedRows.map(selectedRow => {
            const ontologyObjectType = ontology?.[selectedRow.kind === 'Vertex' ? 'objectTypes' : 'linkTypes'].find(objectType => objectType.name === selectedRow.type);

            const defaultItemTypeStyle = selectedRow.kind === 'Vertex' ? DEFAULT_VERTEX_STYLE : DEFAULT_EDGE_STYLE;
            const styles = ontologyObjectType?.style ? merge({}, defaultItemTypeStyle, ontologyObjectType.style) : defaultItemTypeStyle;

            return {
                object: {
                    label: ontologyObjectType?.displayName,
                    icon: selectedRow.kind === 'Vertex' ? (styles as VertexStyle).iconName : 'icon-dot-arrow-right',
                    iconColor: '#FFFFFF',
                    backgroundColor: selectedRow.kind === 'Vertex' ? (styles as VertexStyle).iconColor : (styles as Required<EdgeStyle>).color,
                    retentionPolicyLabel: selectedRow.retentionPolicyLabel,
                },
                properties: ontologyObjectType?.properties.map(property => {
                    return {
                        label: property.displayName,
                        icon: getPropertyIconName(property.type),
                        retentionPolicyLabel: selectedRow.children?.find(child => child.propertyName === property.name)?.retentionPolicyLabel ??
                            getRetentionPolicyLabel(undefined, selectedRow.kind),
                    };
                }) || [],
            };
        });
    }, [ontology, rows, selection]);


    const propertiesPanelRenderer = useCallback(() => {
        return <PropertiesPanel selectedRetentionPolicies={selectedRetentionPolicies} />;
    }, [selectedRetentionPolicies]);


    useToolItem(toolbarContext, {
        path: 'right/end/properties',
        type: 'panel',
        icon: 'icon-info',
    }, {
        panelRender: propertiesPanelRenderer,
        selected: true,
    });

    const expandAll = useCallback(() => {
        const newExpandedItems = [];
        for (const row of rows) {
            if (isEmpty(row.children)) {
                continue;
            }
            newExpandedItems.push(`${row.kind}/${row.type}`);
        }

        setExpandedItems(newExpandedItems);
    }, [rows]);
    const handleSelectAll = useCallback(() => {
        if (selection.length) {
            setSelection([]);

            return;
        }

        const newSelectedRows = [];

        for (const row of rows) {
            if (row.policy) {
                newSelectedRows.push(getSelectionObject(row));
            }
            if (row.children) {
                newSelectedRows.push(...row.children.map(getSelectionObject));
            }
        }

        setSelection(newSelectedRows);
        expandAll();
    }, [expandAll, rows, selection.length]);

    const allItemsCheckboxValue = useMemo(() => {
        if (isEmpty(selection)) {
            return false;
        }

        if (selection.length === rows.length) {
            return true;
        }

        return 'minus';
    }, [rows.length, selection]);

    const doesCheckAllEnabled = useMemo(() => {
        return hasPolicies(rows);
    }, [rows]);

    const isRowSelected = useCallback((row: RetentionPolicyRow) => {
        const idx = selection.findIndex((selectionItem: RetentionPolicyRowSelection) => {
            return getRowId(selectionItem) === getRowId(row);
        });

        return idx > -1;
    }, [selection]);

    const handleSelectionChange = useCallback((row: RetentionPolicyRowSelection, checked: boolean) => {
        setSelection(prev => {
            if (checked) {
                return [...prev, getSelectionObject(row)];
            }

            return prev.filter(prevRow => getRowId(prevRow) !== getRowId(row));
        });
    }, []);

    const getExpandIconName = useCallback((row: RetentionPolicyRow) => {
        const iconName = expandedItems.includes(`${row.kind}/${row.type}`) ? 'icon-cheveron-up' : 'icon-cheveron-down';

        return iconName;
    }, [expandedItems]);

    const handleExpandClick = useCallback((row: RetentionPolicyRow) => {
        setExpandedItems((prev) => {
            const newExpandedItems = addOrRemoveFromList(prev, row);

            return newExpandedItems;
        });
    }, []);

    const ontologyRetentionPolicyColumns = useMemo<Record<string, ArgTable2Column<RetentionPolicyRow> | undefined>>(() => {
        const columns: Record<string, ArgTable2Column<RetentionPolicyRow>> = {
            selection: getSelectionColumn(doesCheckAllEnabled, allItemsCheckboxValue, handleSelectAll, isRowSelected, handleSelectionChange),
            expand: getExpandColumn(getExpandIconName, handleExpandClick),
            objectOrRelationOrPropertyColumn: getObjectOrRelationOrPropertyColumn(
                messages.objectOrRelationOrPropertyColumnTitle,
                isRowSelected,
                handleSelectionChange,
                ontology,
            ),
            retentionPolicyColumn: getRetentionPolicyColumn(messages.retentionPolicyColumnTitle, intl),
            modificationDateColumn: getModificationDateColumn(messages.modificationDateColumn),
            actionsColumn: getActionsColumn(deleteRetentionPolicy, editRetentionPolicy, SUPPORTED_ACTIONS),
        };

        return columns;
    }, [
        allItemsCheckboxValue, deleteRetentionPolicy, doesCheckAllEnabled, editRetentionPolicy, getExpandIconName,
        handleExpandClick, handleSelectAll, handleSelectionChange, intl, isRowSelected, ontology]);

    const [publishRetentionPolicy, publishRetentionPolicyProgressMonitor] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        if (!ontologyId) {
            return;
        }
        try {
            await retentionPolicyConnector.publishRetentionPolicy(ontologyId, progressMonitor);
        } catch (error: any) {
            if (progressMonitor.isCancelled) {
                return;
            }

            if (error.problemDetails?.status === 409) {
                notifications.snackError({ message: messages.publishingRetentionPolicyErrorWhenOntologyIsNotPublished }, error as Error);

                return;
            }

            notifications.snackError({ message: messages.publishingRetentionPolicyError }, error as Error);
        }
    }, [ontologyId]);

    const onAction = useCallback(async (action: string) => {
        switch (action) {
            case ItemActions.Delete: {
                await deleteRetentionPolicies();
            }
                break;

            default:
                break;
        }
    }, [deleteRetentionPolicies]);

    const isPublishButtonDisabled = publishRetentionPolicyProgressMonitor?.isRunning || !retentionPolicy;
    const isLoading = getOntologyProgressMonitor?.isRunning || getRetentionPolicyProgressMonitor?.isRunning;
    const isActionButtonDisabled = selection.length === 0;
    const disableMessage = isActionButtonDisabled ? messages.noItemSelectedTooltipMessage : undefined;

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <div className={classNames('&-ontology-name')}>
                    <h1>{ontology?.name}</h1>
                    <ArgIcon name='icon-dots-horizontal-triple' className={classNames('&-icon')} />
                </div>
                <div className={classNames('&-publish-auto-delete-policy')}>
                    <ArgButton
                        label={messages.publish}
                        onClick={publishRetentionPolicy}
                        loading={publishRetentionPolicyProgressMonitor?.isRunning}
                        disabled={isPublishButtonDisabled}
                    />
                </div>
            </div>
            <ArgToolbarLayout
                className={classNames('&-toolbar')}
                toolbarContext={toolbarContext}
                environmentContext={undefined}
                {...SIZING}
            >
                <div className={classNames('&-body')}>
                    <OverviewSection<RetentionPolicyRow>
                        rowKey={getRowId}
                        onSearchInputChange={(searchValue) => searchValue && expandAll()}
                        hideTitle={true}
                        items={rows}
                        columns={ontologyRetentionPolicyColumns}
                        rowSelected={isRowSelected}
                        loadingData={isLoading}
                        emptyMessage={messages.emptyRetentionPolicy}
                        action={{
                            actionsList: ACTIONS_LIST,
                            onAction: onAction,
                            isDisabled: isActionButtonDisabled,
                            disableMessage,
                        }}
                    />
                </div>
            </ArgToolbarLayout>
        </div>
    );
}
