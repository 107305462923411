import { useMemo } from 'react';

import { useBasicEnvironmentContext } from 'src/framework/environment-contexts/basic-environment-context';
import { WorkflowsEnvironmentContext } from './workflows-environment-context';
import { useWorkflowsStateAccess } from '../../../framework/workflows/caches/use-workflows-state-access';
import { useWorkflowsObjectCache } from '../../../framework/workflows';


export function useWorkflowsEnvironmentContext(): WorkflowsEnvironmentContext {
    const basicEnvironmentContext = useBasicEnvironmentContext();


    const workflowsStateAccess = useWorkflowsStateAccess();
    const workflowsObjectCache = useWorkflowsObjectCache();

    const workflowsEnvironmentContext = useMemo<WorkflowsEnvironmentContext>(() => {
        const result: WorkflowsEnvironmentContext = {
            ...basicEnvironmentContext,
            workflowsStateAccess,
            workflowsObjectCache,
        };

        return result;
    }, [basicEnvironmentContext, workflowsObjectCache, workflowsStateAccess]);

    return workflowsEnvironmentContext;
}
