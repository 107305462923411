import { isString } from 'lodash';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useApplicationBranding } from 'src/components/application/argonos-application-branding';
import { useArgonosModuleBranding } from 'src/components/application/argonos-modules-branding';
import { ArgImage, ArgRenderedText, ArgTooltip2, DEFAULT_TOOLTIP_BUTTON_DELAY, useClassNames } from 'src/components/basic';
import { ArgonosModuleId } from 'src/components/application/modules';

import ChapsIcon from '../../../assets/chapsicon.svg';
import './application-logo.less';

const CLASSNAME = 'common-applications-logo';

interface ApplicationLogoProps {
    currentModuleId: ArgonosModuleId;
    backTooltip?: ArgRenderedText;
    backUrl?: string;
}

export default function ApplicationLogo({
    currentModuleId,
    backTooltip,
    backUrl,
}: ApplicationLogoProps) {
    const classNames = useClassNames(CLASSNAME);
    const [moduleBranding] = useArgonosModuleBranding(currentModuleId);
    const applicationBranding = useApplicationBranding();
    const brandingLogoURL = moduleBranding?.brandingLogoURL || applicationBranding.brandingLogoURL;

    let logo: ReactNode = <Link to={backUrl || '/'} className={classNames('&-container')} replace={true}>
        {
            isString(brandingLogoURL)
                ? <ArgImage className={classNames('&-image')} src={brandingLogoURL} />
                : <img className={classNames('&-image')} src={ChapsIcon} />
        }
    </Link>;

    if (backTooltip) {
        logo = (
            <ArgTooltip2
                placement='bottomLeft'
                mouseEnterDelay={DEFAULT_TOOLTIP_BUTTON_DELAY}
                title={backTooltip}
            >
                {logo}
            </ArgTooltip2>
        );
    }

    return logo;
}
