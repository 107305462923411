import { defineMessages } from 'react-intl';
import { ReactNode } from 'react';
import { get, isString } from 'lodash';

import { ArgFormattedMessage, ClassValue, ThreeDotsLoading, useClassNames } from 'src/components/basic';
import { useWorkflowObjectCacheWorkflow, Workflow, WorkflowId } from '../../../framework/workflows';

import './workflow-property.less';


const CLASSNAME = 'framework-workflows-workflow-property';

const messages = defineMessages({
    error: {
        id: 'framework.workflows.workflow-property.Error',
        defaultMessage: 'Error',
    },
});

interface WorkflowPropertyProps {
    className?: ClassValue;
    workflowId: WorkflowId;
    render: ((workflow: Workflow, search?: string) => ReactNode) | string;
    search?: string;
}

export function WorkflowProperty(props: WorkflowPropertyProps) {
    const {
        className,
        workflowId,
        render,
        search,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const [workflow, error, loading] = useWorkflowObjectCacheWorkflow(workflowId);

    if (error || workflow === null) {
        return (
            <div
                className={classNames('&', className, 'error')}
            >
                <ArgFormattedMessage message={messages.error} />
            </div>
        );
    }

    if (loading || workflow === undefined) {
        return (
            <div
                className={classNames('&', className, 'loading')}
            >
                <ThreeDotsLoading />
            </div>
        );
    }

    const body = isString(render) ? get(workflow, render) : render(workflow, search);

    return (
        <div
            className={classNames('&', className)}
        >
            {body}
        </div>
    );
}
