import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import {
    ArgButton,
    ArgCheckbox,
    ArgIcon,
    ArgInputText,
    ArgModal,
    ArgSliderInput,
    ArgSwitch,
    useArgModalContext,
    useClassNames, useArgNotifications,
} from 'src/components/basic';
import {
    RetentionPolicyActionTargetKind,
    RetentionPolicyLinkKind,
    RetentionPolicyVertexEdge,
} from 'src/settings/universes/retention/types';
import { ConstraintItem, FormatItem, getFormatItem, getInputType, InputType, messages, TypeItem } from '../const';
import { PropertyInputType } from '../property-input-type';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType, OntologyProperty } from '../../../types';
import { getBaseType, getTypeItem } from '../utils';
import { VertexOrEdge } from 'src/exploration/hooks/use-graph-style-customisation';
import { Environment } from 'src/utils/environment';
import { PropertyPreview } from '../property-preview';
import { PropertyTypeAndConstraint } from '../property-type-and-constraints';
import { ArgFormLabel } from '../../../../../../components/basic';
import { AutomaticObjectDeletionModal } from '../../automatic-object-deletion-modal/automatic-object-deletion-modal';
import { AddEditOntology } from '../../../../../models/dtoApi';
import ontologiesConnector from '../../../../../connectors/ontologies-connector';
import { PropertyFormModal } from '../property-form-modal/property-form-modal';
import { UserInputType } from '../../../../../../model/user-metadata';

import './property-modal.less';

export interface EditPropertyModalProps {
    closeModal: () => void;
    propertyList: OntologyProperty[];
    edgeOrVertex: FullOntologyLinkType | FullOntologyObjectType;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    propertyOf: VertexOrEdge;
    property: OntologyProperty;
    retentionSetUp: (retention: RetentionPolicyVertexEdge, type: RetentionPolicyActionTargetKind | 'Property', targetProperty?: string) => string;
    retention: RetentionPolicyVertexEdge;
    setRetention: Dispatch<SetStateAction<RetentionPolicyVertexEdge>>;
    retentionLink: RetentionPolicyLinkKind;
}

const MODAL_DELETION = 'MODAL_DELETION';

export function EditPropertyModal(props: EditPropertyModalProps) {
    const {
        closeModal,
        propertyList,
        edgeOrVertex,
        ontology,
        setOntology,
        propertyOf,
        property,
        retentionSetUp,
        retention,
        setRetention,
        retentionLink,
    } = props;

    const intl = useIntl();
    const classNames = useClassNames('property-modal');

    const modalContext = useArgModalContext();
    const notifications = useArgNotifications();

    const [type, setType] = useState<TypeItem | undefined>(
        getTypeItem(property.type) || undefined,
    );
    const [displayFinalOptionOnly, setDisplayFinalOptionOnly] = useState(
        property.constraint?.hideFullPath || false,
    );

    const [description, setDescription] = useState<string>(
        property.clientMetadata?.[Environment.appId]?.description || '',
    );
    const [format, setFormat] = useState<FormatItem | undefined>(
        getFormatItem(property.constraint?.inputFormat),
    );
    const [constraint, setConstraint] = useState<ConstraintItem>(ConstraintItem.none);
    const [characters, setCharacters] = useState<[number, number]>([10, 40]);
    const [continuousNumber, setContinuousNumber] = useState(property.isContinuous);
    const [isHeadProperty, setIsHeadProperty] = useState(property.isHeadProperty);
    const [inputType, setInputType] = useState<InputType | UserInputType | undefined>(
        property.constraint?.fixedValues ? InputType.singleSelect : InputType.freeInput,
    );
    const [propertyDisplayName, setPropertyDisplayName] = useState<string>(property.displayName);
    const [isTitle, setIsTitle] = useState(property.isTitle);
    const [isRequired, setIsRequired] = useState(property.isMandatory);
    const [isMultivalued, setIsMultivalued] = useState(property.isMultivalued);
    const [loading, setLoading] = useState(false);
    const [optionsList, setOptionsList] = useState(
        property.constraint?.fixedValues?.map(({ value }, id) => {
            return { value, id };
        }) || [
            { value: '', id: 1 },
            { value: '', id: 2 },
        ],
    );
    const [searchWeight, setSearchWeight] = useState<number>(property.weight * 100);

    const _retentionSetUp = useMemo(() => retentionSetUp(retention, 'Property', property.name), [property.name, retention, retentionSetUp]);

    const handleSubmit = async () => {
        const baseType = getBaseType(type);
        if (!baseType) return;

        const properties = edgeOrVertex.properties;
        const newProperties = isTitle
            ? properties.map(property => {
                const ret: OntologyProperty = {
                    ...property,
                    isTitle: false,
                };

                return ret;
            })
            : [...properties];

        const editedProperty: OntologyProperty = {
            name: property.name,
            displayName: propertyDisplayName,
            type: baseType,
            constraint:
                inputType === InputType.singleSelect
                    ? {
                        fixedValues: optionsList.map(({ value }) => ({ value })),
                        hideFullPath: displayFinalOptionOnly,
                    }
                    : undefined,
            isTitle: isTitle,
            isContinuous: continuousNumber,
            isMandatory: isRequired,
            isHeadProperty: isHeadProperty,
            isMultivalued: isMultivalued,
            clientMetadata: {
                ...property.clientMetadata,
                [Environment.appId]: {
                    ...property.clientMetadata?.[Environment.appId],
                    description,
                },
            },
            description: property.description,
            pathDefinition: {
                separator: '/',
            },
            weight: searchWeight / 100,
        };

        const editedIndex = newProperties.findIndex((prop) => prop.name === editedProperty.name);
        if (editedIndex === -1) {
            console.warn(`Cannot edit ${editedProperty.name} because it's not a property of ${edgeOrVertex.name}`);
        }

        newProperties[editedIndex] = editedProperty;

        const editOntologyProps: AddEditOntology = {
            ontologyId: ontology.id,
            name: edgeOrVertex.name,
            newDisplayName: edgeOrVertex.displayName,
            newProperties: [...properties, editedProperty],
        };
        setLoading(true);
        try {
            if (propertyOf === VertexOrEdge.Vertex) {
                await ontologiesConnector.editOntologyObject(editOntologyProps);
            } else {
                await ontologiesConnector.editOntologyEdge(editOntologyProps);
            }
            const newOntology = await ontologiesConnector.getFullOntology(ontology.id);
            setOntology(newOntology);
            setLoading(false);
        } catch (e) {
            notifications.snackError({ message: messages.addPropertyErrorMsg }, e as Error);
            setLoading(false);
        }
    };

    const resetState = () => {
        setPropertyDisplayName(property.displayName);
        setType(getTypeItem(property.type) || undefined);
        setFormat(getFormatItem(property.constraint?.inputFormat));
        setConstraint(ConstraintItem.none);
        setIsHeadProperty(property.isHeadProperty);
        setIsMultivalued(property.isMultivalued);
        setInputType(getInputType(property.constraint?.inputFormat));
        setOptionsList([
            { value: '', id: 1 },
            { value: '', id: 2 },
        ]);
        setDescription(property.clientMetadata?.[Environment.appId]?.description || '');
        setContinuousNumber(property.isContinuous);
        setDisplayFinalOptionOnly(false);
        setSearchWeight(100);
    };

    const handleModalDeletion = useCallback(async () => {
        modalContext.open(MODAL_DELETION,
            <AutomaticObjectDeletionModal
                closeModal={() => modalContext.close(MODAL_DELETION)}
                ontology={ontology}
                edgeOrVertex={edgeOrVertex}
                retentionTarget='Property'
                retentionLink={retentionLink}
                propertyName={propertyDisplayName}
                property={property.name}
                retention={retention}
                onChange={setRetention}
            />,
        );
    }, [edgeOrVertex, modalContext, ontology, property.name, propertyDisplayName, retention, retentionLink, setRetention]);


    const disabled =
        !propertyDisplayName ||
        !type ||
        propertyList.filter((prop) => prop.displayName === propertyDisplayName).length > 0 ||
        loading;

    return (
        <ArgModal
            size='large'
            title={messages.editPropertyTitle}
            visible={true}
            onClose={closeModal}
            className={classNames('&-modal')}
            footer={
                <>
                    <ArgButton
                        className={classNames('arg-modal-footer-btn')}
                        type='secondary'
                        onClick={closeModal}
                        label={messages.cancel}
                    />
                    <ArgButton
                        className={classNames('arg-modal-footer-btn')}
                        type='primary'
                        label={messages.edit}
                        data-testid='edit'
                        disabled={disabled}
                        onClick={() => {
                            handleSubmit();
                            resetState();
                            closeModal();
                        }}
                    />
                </>
            }
        >
            <PropertyFormModal>
                <div className={classNames('&-config')}>

                    <ArgFormLabel
                        className={classNames('&-config-name')}
                        propertyName={messages.fieldName}
                        description={messages.fieldNameSubtitle}
                    >
                        <ArgInputText
                            value={propertyDisplayName}
                            onChange={(input) => setPropertyDisplayName(input ?? '')}
                            autoFocus={true}
                            id='propertyName'
                        />
                    </ArgFormLabel>
                    <ArgFormLabel
                        className={classNames('&-config-state')}
                        propertyName={messages.deletionAutomatic}
                    >
                        <div className={classNames('delete-info')}>
                            <span>{_retentionSetUp}</span>
                            <span onClick={handleModalDeletion} className={classNames('icon')}>
                                <ArgIcon
                                    name='icon-edit-pencil'
                                    size={10}
                                />
                            </span>

                        </div>
                    </ArgFormLabel>
                </div>
                {/* isTitle is only available for vetex properties */}
                {(propertyOf === VertexOrEdge.Vertex) && (
                    <>
                        <ArgCheckbox
                            value={isTitle}
                            onChange={(value) => setIsTitle(value)}
                            size='small'
                            label={messages.isTitle}
                            messageValues={{ fieldName: <strong>{edgeOrVertex.displayName}</strong> }}
                            className={classNames('&-is-title')}
                        />
                        <p className={classNames('&-is-title-description')}>
                            {intl.formatMessage(messages.isTitleDescription)}
                        </p>
                    </>
                )}
                <PropertyTypeAndConstraint
                    type={type}
                    setType={setType}
                    setFormat={setFormat}
                    constraint={constraint}
                    setConstraint={setConstraint}
                    characters={characters}
                    setCharacters={setCharacters}
                    continuousNumber={continuousNumber}
                    setContinuousNumber={setContinuousNumber}
                    inEditModal={true}
                />
                <ArgSwitch
                    checked={isRequired}
                    label={messages.fieldRequired}
                    onChange={(value) => setIsRequired(value)}
                    size='small'
                    className={classNames('&-switch-isRequired')}
                />
                <ArgSwitch
                    value={isMultivalued}
                    onChange={(value) => setIsMultivalued(value)}
                    label={messages.isMultivalue}
                    size='small'
                    className={classNames('&-switch-isMultivalue')}
                />
                <PropertyInputType
                    type={type}
                    inputType={inputType}
                    inputs={[InputType.singleSelect]}
                    setInputType={setInputType}
                    format={format}
                    setFormat={setFormat}
                    optionsList={optionsList}
                    setOptionsList={setOptionsList}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    setDisplayFinalOptionOnly={setDisplayFinalOptionOnly}
                />
                <ArgFormLabel propertyName={messages.fieldSearchWeight}>
                    <ArgSliderInput
                        min={0}
                        max={400}
                        onChange={(value) => setSearchWeight(value)}
                        step={1}
                        value={searchWeight}
                        unitSymbol='%'
                    />
                </ArgFormLabel>
                <ArgFormLabel
                    propertyName={messages.fieldMessage}
                    description={messages.fieldMessageDescription}
                >
                    <ArgInputText
                        value={description}
                        onChange={(input) => setDescription(input ?? '')}
                    />
                </ArgFormLabel>
                <ArgSwitch
                    checked={isHeadProperty}
                    onChange={(value) => {
                        setIsHeadProperty(value);
                    }}
                    label={messages.fieldPriority}
                    className={classNames('&-switch')}
                />
                <PropertyPreview
                    inputType={inputType}
                    propertyName={propertyDisplayName}
                    optionsList={optionsList}
                    displayFinalOptionOnly={displayFinalOptionOnly}
                    type={type}
                />
            </PropertyFormModal>
        </ArgModal>
    );
}
