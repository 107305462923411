import { defineMessages } from 'react-intl';

import { SETTINGS_ROOT_URL } from '../../components/containers/routes-urls';
import { registerToolItem } from 'src/components/basic';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from 'src/settings/envrionment-contexts/context-names';
import { SettingsEnvironmentContext } from 'src/settings/envrionment-contexts/settings-environment-context';
import { SettingsPermissions } from '../permissions/permissions';

export const REFERENCE_TABLES_PATH = `${SETTINGS_ROOT_URL}/reference-tables`;

const FORCE_REFERENCE = localStorage.getItem('FORCE_REFERENTIEL') === 'true';

const messages = defineMessages({
    referenceTablesNavItemLabel: {
        id: 'settings.users.menu.referenceTables',
        defaultMessage: 'References',
    },
});


export function setupReferenceTables() {
    registerToolItem(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path: 'global/reference-tables',
        label: messages.referenceTablesNavItemLabel,
        order: 300,
        icon: 'icon-reference-table',
        visible: (settingsEnvironmentContext: SettingsEnvironmentContext) => {
            const ret = settingsEnvironmentContext.hasAnyPermissions<SettingsPermissions>('admin.reference.access', 'admin.reference.edition', 'admin.reference.export');

            return ret || FORCE_REFERENCE;
        },
        navigateTo: REFERENCE_TABLES_PATH,
    });
}
