import { useCallback } from 'react';
import { FormattedNumber } from 'react-intl';

import { ClassValue } from 'src/components/basic';
import { WorkflowProperty } from './workflow-property';
import { Workflow, WorkflowId } from '../model/workflows';


interface WorkflowInstancesCountProps {
    className?: ClassValue;
    workflowId: WorkflowId;
    search?: string;
}

export function WorkflowInstancesCount(props: WorkflowInstancesCountProps) {
    const {
        className,
        workflowId,
        search,
    } = props;

    const render = useCallback((workflow: Workflow, search?: string) => {
        return <FormattedNumber
            value={workflow.instancesCount}
        />;
    }, []);

    return (
        <WorkflowProperty
            className={className}
            workflowId={workflowId}
            render={render}
            search={search}
        />
    );
}
