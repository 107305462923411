import { RtMessageEventList } from './events';
import { ArgonosModuleId } from '../../components/application/modules';

export enum RtApiSubType {
    Entities = 'Entities',
    Folders = 'Folders',
    Users = 'Users',
    Editors = 'Editors',
    Universes = 'Universes',
    ProgressMonitors = 'ProgressMonitors',
    Workflows = 'Workflows',
}

export interface RtApi {
    argonosModuleId: ArgonosModuleId;

    subType: RtApiSubType | string;

    api: string;

    defaultServiceUrl: string;

    events: Readonly<RtMessageEventList>;
}
