import React, { Ref, useCallback } from 'react';

import { ArgInput, ArgInputCustomComponentProps, ArgInputKeyPressEvent, ArgInputProps } from './arg-input';
import { ArgChangeReason } from '../types';
import { useClassNames } from '../arg-hooks/use-classNames';

import './arg-input-text-area.less';

function stringFormatValue(value: string | null): string {
    if (value === null) {
        return '';
    }

    return value;
}

function stringParseValue(value: string): string | null {
    return value;
}

export interface ArgInputTextAreaProps<U = any>
    extends Omit<ArgInputProps<string, U>, 'formatValue' | 'parseValue'> {
    /**
     * The keyPressRegExp for the input text area component
     */
    keyPressRegExp?: RegExp;
    /**
     * A boolean to explicitly perform change on input event for the input text area component
     */
    performChangeOnInput?: boolean;
    /**
     * A boolean to explicitly spell check for the input text area component
     */
    spellCheck?: boolean;
    /**
     * The number of cols for the input text area component
     */
    cols?: number;
    /**
     * The number of rows for the input text area component
     */
    rows?: number;
    /**
     * A boolean to explicitly render resizable the input text area component
     */
    resizable?: boolean;
}

/**
 * This component is designed to display an input text area field.
 *
 * @example
 * ```
 * <ArgInputTextArea value={value} onChange={handleChangeValue} />
 * ```
 */
export function ArgInputTextArea<U = any>(inputProps: ArgInputTextAreaProps<U>) {
    const {
        keyPressRegExp,
        onInputChange,
        performChangeOnInput,
        onChange,
        onKeyPress,
        initialValue,
        maxLength,
        className,
        spellCheck,
        cols,
        rows,
        resizable,
        ...props
    } = inputProps;

    const classNames = useClassNames('arg-input-textArea');

    let _initialValue = initialValue;

    const useInternalValue = !('value' in inputProps);
    if (useInternalValue) {
        if (initialValue === undefined) {
            _initialValue = '';
        }
    }

    const regexpKeypress = useCallback(
        (event: ArgInputKeyPressEvent): void => {
            if (!keyPressRegExp) {
                return;
            }

            const keyboardEvent = event.keyboardEvent;
            const key = keyboardEvent.key;

            if (
                key.length === 1 &&
                !keyboardEvent.ctrlKey &&
                !keyboardEvent.altKey &&
                !keyboardEvent.metaKey &&
                !keyPressRegExp.test(key)
            ) {
                keyboardEvent.preventDefault();
                keyboardEvent.stopPropagation();

                return;
            }
        },
        [keyPressRegExp],
    );

    const handleInputChange = useCallback((text: string) => {
        onInputChange?.(text);

        if (!performChangeOnInput) {
            return;
        }
        onChange?.(text, 'keypress');
    },
    [performChangeOnInput, onInputChange, onChange],
    );

    const handleChange = useCallback((text: string | null, reason: ArgChangeReason) => {
        if (performChangeOnInput) {
            return;
        }
        onChange?.(text, reason);
    },
    [performChangeOnInput, onChange],
    );

    const handleRenderInput = useCallback((props: ArgInputCustomComponentProps<string>) => {
        const {
            internalInputValue,
            className,
            onBlur,
            onFocus,
            onChange,
            ref,
            id,
            disabled,
            readOnly,
            placeholder,
            autoComplete,
            maxLength,
        } = props;
        const _htmlAutoComplete = (typeof (autoComplete) !== 'string') ? 'off' : autoComplete;

        return <textarea
            id={id}
            className={classNames('&-textArea', className)}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={(event) => onChange(event as any as Event, 'keypress')}
            disabled={disabled}
            readOnly={readOnly}
            ref={ref as Ref<HTMLTextAreaElement>}
            placeholder={placeholder}
            maxLength={maxLength}
            spellCheck={spellCheck}
            cols={cols}
            rows={rows}
            value={internalInputValue || ''}
            autoComplete={_htmlAutoComplete}
        />;
    }, [classNames, spellCheck, cols, rows]);

    const cls = {
        resizable,
    };

    return (
        <ArgInput<string, U>
            formatValue={stringFormatValue}
            parseValue={stringParseValue}
            maxLength={maxLength}
            onKeyPress={onKeyPress || (keyPressRegExp && regexpKeypress) || undefined}
            renderInputComponent={handleRenderInput}
            clearable={false}
            {...props}
            className={classNames('&', className, cls)}
            initialValue={_initialValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
        />
    );
}
