import { defineMessages } from 'react-intl';
import React, { MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import {
    ArgInputSearch,
    ArgOrderedColumn,
    ArgOrderedList,
    ArgToolbar,
    ClassValue,
    createEmptyTargetEnvironmentContext,
    createTargetEnvironmentContextFromItems,
    ProgressMonitor,
    SelectionProvider,
    SubProgressMonitor,
    TargetEnvironmentContext,
    useArgModalContext,
    useClassNames,
    useMemoAsync,
    useSelection,
    useSharedSelectionProvider,
    useTargetToolContext,
    useToolContext,
    useToolItemByMimeType,
} from 'src/components/basic';
import { PageHeader } from '../../common-components/page-header';
import {
    useWorkflowObjectCacheWorkflow,
    useWorkflowObjectCacheWorkflowIds,
    useWorkflowsObjectCache,
    Workflow,
    WORKFLOW_DND_TYPE,
    WorkflowId,
    WorkflowName,
    WorkflowStatus,
} from '../../../framework/workflows';
import { ErrorPane } from '../../../components/common/panes/error-pane';
import { EmptyPane } from '../../../components/common/panes/empty-pane';
import { LoadingPane } from '../../../components/common/panes/loading-pane';
import { WorkflowTargetCell } from '../../../framework/workflows/components/workflow-target-cell';
import { WorkflowDescription } from '../../../framework/workflows/components/workflow-description';
import { WorkflowInstancesCount } from '../../../framework/workflows/components/workflow-instances-count';
import { WorkflowLastUpdated } from '../../../framework/workflows/components/workflow-modifications';
import { WorkflowPublish } from '../../../framework/workflows/components/workflow-publish';
import {
    WORKFLOW_TABLE_ROW_MENU_CONTEXT,
    WORKFLOWS_VIEW_LEFT_CONTEXT,
    WORKFLOWS_VIEW_RIGHT_CONTEXT,
    WorkflowsEnvironmentContext,
} from '../environment-contexts/workflows-environment-context';
import { useWorkflowsEnvironmentContext } from '../environment-contexts/use-workflows-environment-context';
import { useWorkflowsStateAccess } from '../../../framework/workflows/caches/use-workflows-state-access';
import { ArgEllipsisAsyncMenuButton } from '../../../components/basic/arg-tools/arg-ellipsis-async-menu-button';
import { WorkflowState } from '../../../framework/workflows/states/workflow-state';
import { useWorkflowsListState } from '../../../framework/workflows/states/use-worflows-list-state';
import { computeWorkflowURL } from '../utils/compute-urls';

import './workflows-view.less';


const CLASSNAME = 'settings-workflows';

const SELECTION_SOURCE = 'settings-workflows';

const messages = defineMessages({
    pageTitle: {
        id: 'settings.workflows.list.Title',
        defaultMessage: 'Workflows',
    },
    noWorkflows: {
        id: 'settings.workflows.list.NoWorkflows',
        defaultMessage: 'No workflows',
    },
    loadingWorkflows: {
        id: 'settings.workflows.list.LoadingWorkflows',
        defaultMessage: 'Loading workflows <ThreeDotsLoading/>',
    },
    nameColumnTitle: {
        id: 'settings.workflows.list.NameColumn',
        defaultMessage: 'Name',
    },
    descriptionColumnTitle: {
        id: 'settings.workflows.list.DescriptionColumn',
        defaultMessage: 'Description',
    },
    targetColumnTitle: {
        id: 'settings.workflows.list.TargetColumn',
        defaultMessage: 'Target',
    },
    instancesCountColumnTitle: {
        id: 'settings.workflows.list.InstancesCountColumn',
        defaultMessage: 'Instances',
    },
    lastUpdatedColumn: {
        id: 'settings.workflows.list.LastUpdatedColumn',
        defaultMessage: 'Last updated',
    },
    publishColumn: {
        id: 'settings.workflows.list.PublishColumn',
        defaultMessage: 'Publish',
    },
    orderColumnTitle: {
        id: 'settings.workflows.list.OrderColumnTitle',
        defaultMessage: 'Order',
    },
});

const EMPTY_LIST: WorkflowId[] = [];

interface WorkflowsProps {
    className?: ClassValue;
}

export function WorkflowsView(props: WorkflowsProps) {
    const {
        className,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const modalContext = useArgModalContext();

    const workflowsListState = useWorkflowsListState();

    const [workflowIds, errorWorkFlowIds, loadingWorkflowIds] = useWorkflowObjectCacheWorkflowIds();

    const [search, setSearch] = useState<string>('');

    const workflowEnvironmentContext = useWorkflowsEnvironmentContext();
    const rowMenuContext = useTargetToolContext<WorkflowsEnvironmentContext, Workflow>(WORKFLOW_TABLE_ROW_MENU_CONTEXT);
    useToolItemByMimeType(rowMenuContext, WORKFLOW_DND_TYPE);

    const leftToolbarContext = useToolContext<TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>>(WORKFLOWS_VIEW_LEFT_CONTEXT);

    const rightToolbarContext = useToolContext<TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>>(WORKFLOWS_VIEW_RIGHT_CONTEXT);

    const getWorkflowKey = useCallback((item: WorkflowId) => {
        return item;
    }, []);

    const selectionProvider = useSharedSelectionProvider<WorkflowId>(() => {
        return new SelectionProvider<WorkflowId>('settings-workflows', (workflowId: WorkflowId) => {
            return workflowId;
        });
    });

    const workflowsStateAccess = useWorkflowsStateAccess();
    const workflowsCacheObject = useWorkflowsObjectCache();

    const getAsyncWorkflowByWorkflowId = useCallback(async (environmentContext: WorkflowsEnvironmentContext, workflowId: WorkflowId, progressMonitor: ProgressMonitor): Promise<Workflow | undefined> => {
        const result = await workflowsStateAccess.runWorkflow(workflowId, async (workflowState: WorkflowState) => {
            const result = await workflowsCacheObject.getWorkflow(workflowId, workflowState.stateId, progressMonitor);

            return result;
        });

        return result || undefined;
    }, [workflowsCacheObject, workflowsStateAccess]);

    const emptyTargetWorkflowsEnvironmentContext = useMemo<TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>>(() => {
        const result = createEmptyTargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>(workflowEnvironmentContext);

        return result;
    }, [workflowEnvironmentContext]);

    const [targetWorkflowsEnvironmentContext] = useMemoAsync<TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>>(async (progressMonitor: ProgressMonitor) => {
        const itemsKey = selectionProvider.list();
        const items: Record<WorkflowId, Workflow> = {};
        if (!isEmpty(itemsKey)) {
            for (const itemKey of itemsKey) {
                const sub = new SubProgressMonitor(progressMonitor, 1);

                const workflow = await getAsyncWorkflowByWorkflowId(workflowEnvironmentContext, itemKey, sub);
                if (workflow) {
                    items[workflow.id] = workflow;
                }
            }
        }

        const result = createTargetEnvironmentContextFromItems<WorkflowsEnvironmentContext, Workflow>(
            workflowEnvironmentContext,
            itemsKey,
            (workflowId: WorkflowId): Workflow | undefined => {
                const result = items[workflowId];

                return result;
            },
            [WORKFLOW_DND_TYPE],
        );

        return result;
    }, [selectionProvider.stateId, workflowEnvironmentContext, getAsyncWorkflowByWorkflowId]);

    useSelection(selectionProvider);

    const workflowColumns = useMemo<ArgOrderedColumn<WorkflowId>[]>(() => {
        const columns: ArgOrderedColumn<WorkflowId>[] = [];
        columns.push({
            key: 'order',
            title: messages.orderColumnTitle,
            width: '40px',
            render: function Name(_data, workflowId, _idx, search) {
                return _idx;
            },
        }, {
            key: 'name',
            title: messages.nameColumnTitle,
            width: '20%',
            render: function Name(_data, workflowId, _idx, search) {
                return <WorkflowName workflowId={workflowId} search={search || undefined} className='clamp-2' />;
            },
        }, {
            key: 'target',
            title: messages.targetColumnTitle,
            width: '30%',
            render: function Name(_data, workflowId, _idx, search) {
                return <WorkflowTargetCell workflowId={workflowId} search={search || undefined} className='clamp-2' />;
            },
        }, {
            key: 'description',
            title: messages.descriptionColumnTitle,
            width: '30%',
            render: function Name(_data, workflowId, _idx, search) {
                return <WorkflowDescription workflowId={workflowId} search={search || undefined} className='clamp-2' />;
            },
        }, {
            key: 'instancesCount',
            title: messages.instancesCountColumnTitle,
            ellipsis: true,
            width: '40px',
            render: function Name(_data, workflowId, _idx, search) {
                return <WorkflowInstancesCount workflowId={workflowId} search={search || undefined} />;
            },
        }, {
            key: 'lastUpdated',
            title: messages.lastUpdatedColumn,
            ellipsis: true,
            width: '128px',
            render: function Name(_data, workflowId, _idx, search) {
                return <WorkflowLastUpdated workflowId={workflowId} search={search || undefined} />;
            },
        }, {
            key: 'publish',
            title: messages.publishColumn,
            ellipsis: true,
            width: '128px',
            render: function Name(_data, workflowId, _idx, search) {
                return <WorkflowPublish workflowId={workflowId} search={search || undefined} />;
            },
        }, {
            key: 'actions',
            width: '40px',
            render: function Name(_data, workflowId) {
                return (
                    <ArgEllipsisAsyncMenuButton<WorkflowsEnvironmentContext, Workflow>
                            selectedKey={workflowId}
                            toolContext={rowMenuContext}
                            selectedMimeTypes={WORKFLOW_DND_TYPE}
                            getAsyncItemByKey={getAsyncWorkflowByWorkflowId}
                            environmentContext={workflowEnvironmentContext}
                    />
                );
            },
        },
        );

        return columns;
    }, [workflowEnvironmentContext, rowMenuContext, getAsyncWorkflowByWorkflowId]);

    const renderEmptyRows = useCallback(() => {
        if (workflowIds === undefined) {
            return <div className={classNames('&-pane', 'loading')}>
                <LoadingPane
                    message={messages.loadingWorkflows}
                    progressMonitor={loadingWorkflowIds} />
            </div>;
        }

        return <div className={classNames('&-pane', 'empty')}>
            <EmptyPane message={messages.noWorkflows} />
        </div>;
    }, [classNames, loadingWorkflowIds, workflowIds]);

    const handleRowDoubleClick = useCallback((workflowId: WorkflowId, event: MouseEvent) => {
        workflowEnvironmentContext.navigate(computeWorkflowURL(workflowId));
    }, [workflowEnvironmentContext]);

    const handleRenderRow = useCallback((workflowId: WorkflowId, index: number, rowClassName: ClassValue | undefined, cells: ReactNode) => {
        return <WorkflowRow workflowId={workflowId} rowClassName={rowClassName}>
            {cells}
        </WorkflowRow>;
    }, []);

    const moveRows = useCallback(() => {

    }, []);

    let body: ReactNode = null;
    const cls: ClassValue = {};

    if (errorWorkFlowIds) {
        body = <div className={classNames('&-pane', 'error')}>
            <ErrorPane error={errorWorkFlowIds} />
        </div>;
    } else {
        body = <ArgOrderedList<WorkflowId>
            className={classNames('&-list')}
            rows={workflowIds || EMPTY_LIST}
            getRowKey={getWorkflowKey}
            columns={workflowColumns}
            selectionProvider={selectionProvider}
            selectionSource={SELECTION_SOURCE}
            dragType={WORKFLOW_DND_TYPE}
            onMoveItem={moveRows}
            search={search}
            emptyRenderer={renderEmptyRows}
            onRowDoubleClick={handleRowDoubleClick}
            renderRow={handleRenderRow}
        />;
    }

    return (
        <div
            className={classNames('&', className, cls)}
        >
            <PageHeader
                title={messages.pageTitle}
                className={classNames('&-title')}
            />
            <div className={classNames('&-search')}>
                <div className={classNames('&-search-left')}>
                    <ArgInputSearch
                        className={classNames('&-search-input')}
                        value={search}
                        onChange={(value) => {
                            setSearch(value || '');
                        }}
                        disabled={isEmpty(workflowIds)}
                    />

                    <ArgToolbar<TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>>
                        className={classNames('&-search-actions')}
                        toolbarContext={leftToolbarContext}
                        environmentContext={targetWorkflowsEnvironmentContext || emptyTargetWorkflowsEnvironmentContext}
                    />
                </div>
                <div className={classNames('&-search-right')}>
                    <ArgToolbar<TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>>
                        toolbarContext={rightToolbarContext}
                        environmentContext={targetWorkflowsEnvironmentContext || emptyTargetWorkflowsEnvironmentContext}
                    />
                </div>
            </div>
            {body}

        </div>
    );
}

interface WorkflowRowProps {
    workflowId: WorkflowId;
    rowClassName: ClassValue;
    children?: ReactNode;
}


function WorkflowRow(props: WorkflowRowProps) {
    const {
        workflowId,
        rowClassName,
        children,
    } = props;

    const [workflow, error, loading] = useWorkflowObjectCacheWorkflow(workflowId);

    const cls = {
        enabled: workflow?.status === WorkflowStatus.Enabled,
        disabled: workflow?.status === WorkflowStatus.Disabled,
    };

    return <div className={classNames(rowClassName, `${CLASSNAME}-list-row`, cls)}>
        {children}
    </div>;
}
