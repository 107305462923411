import React from 'react';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgIcon } from '../arg-icon/arg-icon';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';
import { ArgRenderedText, ArgMessageValues } from '../types';

import './arg-alert-card.less';

export type ArgAlertType = 'info' | 'success' | 'warning' | 'error'

export interface ArgAlertCardProps {
    /**
     * The classname for the alert card component
     */
    className?: ClassValue;
    /**
     * The type for the alert card component
     */
    type: ArgAlertType;
    /**
     * A title for the alert card component
     */
    title: ArgRenderedText;
    /**
     * A description for the alert card component
     */
    description?: ArgRenderedText;
    /**
     * A props for i18n value
     */
    messageValues?: ArgMessageValues;
}

export const ICONS_NAME: Record<ArgAlertType, string> = {
    info: 'icon-information',
    success: 'icon-checkmark1',
    warning: 'icon-exclamation-point',
    error: 'icon-close',
};

/**
 * This component is designed to display an alert card.
 *
 * @example
 * ```
 * <ArgAlertCard title="My title" type="success" />
 * ```
 */
export function ArgAlertCard(props: ArgAlertCardProps) {
    const {
        type,
        title,
        description,
        messageValues,
        className,
    } = props;

    const classNames = useClassNames('arg-alert-card');

    const iconName = ICONS_NAME[type];

    const cls = {
        info: type === 'info',
        success: type === 'success',
        warning: type === 'warning',
        error: type === 'error',
    };

    return (
        <div className={classNames('&', cls, className)}>
            <ArgIcon name={iconName} className={classNames('&-icon')} data-testid={iconName} />

            <div className={classNames('&-body')}>
                <span className={classNames('&-body-title')}>
                    <ArgMessageRenderer
                        message={title}
                        messageValues={messageValues}
                        className={classNames('&-body-title-content')}
                    />
                </span>

                {description && <span className={classNames('&-body-description')}>
                    <ArgMessageRenderer
                        message={description}
                        messageValues={messageValues}
                    />
                </span>}
            </div>
        </div>
    );
}

