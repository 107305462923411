import { defineMessages } from 'react-intl';

import { ProgressMonitor, registerToolItem } from 'src/components/basic';
import { EditedWorkflow, Workflow, WorkflowConnector } from '../../../framework/workflows';
import {
    WORKFLOWS_VIEW_RIGHT_CONTEXT,
    WorkflowsEnvironmentContext,
} from '../environment-contexts/workflows-environment-context';
import { WorkflowsListState } from '../../../framework/workflows/states/workflows-list-state';
import { computeWorkflowURL } from '../utils/compute-urls';
import { WorkflowCreateUpdateModal } from '../modals/workflow-create-update-modal';


const messages = defineMessages({
    createButton: {
        id: 'settings.workflows.list.CreateWorkflowButton',
        defaultMessage: 'Create',
    },
    createWorkflowError: {
        id: 'settings.workflows.CreateWorkflowError',
        defaultMessage: 'Cannot create workflow.',
    },
});

async function createWorkflowAction(workflowEnvironmentContext: WorkflowsEnvironmentContext) {
    const handleCreateWorkflow = async (value: Partial<Workflow>, initialWorkflow: Workflow | undefined, progressMonitor: ProgressMonitor): Promise<boolean> => {
        try {
            const newWorkflowId = await WorkflowConnector.getInstance().createWorkflow(value as EditedWorkflow, progressMonitor);

            await workflowEnvironmentContext.workflowsStateAccess.runWorkflowsList(async (workflowsListState: WorkflowsListState) => {
                workflowsListState.change('new-workflow'); // RT fallback
            });

            workflowEnvironmentContext.navigate(computeWorkflowURL(newWorkflowId));

            return true;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            workflowEnvironmentContext.notifications.snackError({ message: messages.createWorkflowError }, error as Error);

            return false;
        }
    };

    workflowEnvironmentContext.modalContext.open('workflow-create', <WorkflowCreateUpdateModal
        onClose={() => workflowEnvironmentContext.modalContext.close('workflow-create')}
        onOk={handleCreateWorkflow}
    />);
}

export function registerCreateWorkflowActions() {
    registerToolItem<WorkflowsEnvironmentContext>(WORKFLOWS_VIEW_RIGHT_CONTEXT, {
        path: 'create',
        order: 900,
        type: 'button',
        buttonType: 'primary',
        icon: 'icon-plus',
        label: messages.createButton,
        onClick: async (_, workflowEnvironmentContext: WorkflowsEnvironmentContext) => {
            await createWorkflowAction(workflowEnvironmentContext);
        },
    });
}
