import { useContext, useMemo } from 'react';

import { WorkflowDefinitionVersion, WorkflowId } from '../model/workflows';
import { WorkflowState } from '../states/workflow-state';
import { WorkflowDefinitionState } from '../states/workflow-definition-state';
import {
    computeWorkflowDefinitionStateURL,
    workflowDefinitionStateFactory,
} from '../states/use-worflow-definition-state';
import { computeWorkflowStateURL, workflowStateFactory } from '../states/use-worflow-state';
import { StatesRegistryContext } from '../../../utils/rt-states/states-registry';
import { WorkflowsListState } from '../states/workflows-list-state';
import { computeWorkflowsListStateURL, workflowsListFactory } from '../states/use-worflows-list-state';

export interface WorkflowsStateAccess {
    runWorkflowsList<T>(fct: (state: WorkflowsListState) => Promise<T>): Promise<T>;

    runWorkflow<T>(workflowId: WorkflowId, fct: (state: WorkflowState) => Promise<T>): Promise<T>;

    runWorkflowDefinition<T>(workflowId: WorkflowId, workflowDefinitionVersion: WorkflowDefinitionVersion, fct: (state: WorkflowDefinitionState) => Promise<T>): Promise<T>;
}

export function useWorkflowsStateAccess(): WorkflowsStateAccess {
    const registry = useContext(StatesRegistryContext)!;

    const access = useMemo<WorkflowsStateAccess>(() => {
        const runWorkflows = async <T>(fct: (state: WorkflowsListState) => Promise<T>): Promise<T> => {
            const url = computeWorkflowsListStateURL();
            const [, promise] = registry.get(url, (url) => workflowsListFactory(url));

            const stateRecord = await promise;

            try {
                const retFct = fct(stateRecord.state);

                const ret = await retFct;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };

        const runWorkflow = async <T>(workflowId: WorkflowId, fct: (state: WorkflowState) => Promise<T>): Promise<T> => {
            const url = computeWorkflowStateURL(workflowId);
            const [, promise] = registry.get(url, (url) => workflowStateFactory(url, workflowId));

            const stateRecord = await promise;

            try {
                const retFct = fct(stateRecord.state);

                const ret = await retFct;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };
        const runWorkflowDefinition = async <T>(workflowId: WorkflowId, workflowDefinitionVersion: WorkflowDefinitionVersion, fct: (state: WorkflowDefinitionState) => Promise<T>): Promise<T> => {
            const url = computeWorkflowDefinitionStateURL(workflowId, workflowDefinitionVersion);
            const [, promise] = registry.get(url, (url) => workflowDefinitionStateFactory(url, workflowId, workflowDefinitionVersion));

            const stateRecord = await promise;

            try {
                const retFct = fct(stateRecord.state);

                const ret = await retFct;

                return ret;
            } finally {
                await stateRecord.unregister();
            }
        };


        return {
            runWorkflowsList: runWorkflows,
            runWorkflow,
            runWorkflowDefinition,
        };
    }, [registry]);

    return access;
}
