import { DataType } from '../../../components/common/data-types';
import { TypeItem } from '../../universes/ontology/components/property-and-metaproperty-modals/const';

export const getBaseType = (type: TypeItem | undefined): DataType | undefined => {
    if (type === TypeItem.boolean) {
        return DataType.Boolean;
    }
    if (type === TypeItem.date) {
        return DataType.Date;
    }
    if (type === TypeItem.dateAndTime) {
        return DataType.DateTime;
    }
    if (type === TypeItem.string) {
        return DataType.String;
    }
    if (type === TypeItem.text) {
        return DataType.Text;
    }
    if (type === TypeItem.integer32b) {
        return DataType.Int32;
    }
    if (type === TypeItem.integer64b) {
        return DataType.Int64;
    }
    if (type === TypeItem.float) {
        return DataType.Single;
    }

    return undefined;
};

export const getCustomDataType = (type: TypeItem | undefined): DataType | undefined => {
    if (type === TypeItem.geoCoordinates) {
        return DataType.Coordinates;
    }
    if (type === TypeItem.email) {
        return DataType.Email;
    }
    if (type === TypeItem.url) {
        return DataType.Url;
    }
    if (type === TypeItem.phoneNumber) {
        return DataType.PhoneNumber;
    }
    if (type === TypeItem.address) {
        return DataType.Address;
    }
    if (type === TypeItem.country) {
        return DataType.Country;
    }
    if (type === TypeItem.integer8b) {
        return DataType.Byte;
    }
    if (type === TypeItem.character) {
        return DataType.Char;
    }

    return undefined;
};
