import { defineMessages } from 'react-intl';
import { isEmpty } from 'lodash';

import { Workflow, WORKFLOW_DND_TYPE, WorkflowConnector } from '../../../framework/workflows';
import { ProgressMonitor, registerToolItemForMimeType, TargetEnvironmentContext } from '../../../components/basic';
import { WorkflowsEnvironmentContext } from '../environment-contexts/workflows-environment-context';
import { WorkflowCreateUpdateModal } from '../modals/workflow-create-update-modal';
import { JsonChange } from '../../../utils/connector';
import { WorkflowState } from '../../../framework/workflows/states/workflow-state';


const messages = defineMessages({
    modify: {
        id: 'settings.workflows.actions.Modify',
        defaultMessage: 'Modify',
    },
    updateWorkflowError: {
        id: 'settings.workflows.UpdateWorkflowError',
        defaultMessage: 'Cannot update workflow.',
    },
});

async function modifyWorkflow(workflow: Workflow, workflowEnvironmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>): Promise<void> {
    const handleUpdateWorkflow = async (value: Partial<Workflow>, initialWorkflow: Workflow | undefined, progressMonitor: ProgressMonitor): Promise<boolean> => {
        const changes: JsonChange[] = [];

        if (value.name !== initialWorkflow?.name) {
            changes.push({
                op: 'add',
                path: 'name',
                value: value.name,
            });
        }

        if (value.description !== initialWorkflow?.description) {
            changes.push({
                op: 'add',
                path: 'description',
                value: value.description,
            });
        }

        if (isEmpty(changes)) {
            return true;
        }

        try {
            await WorkflowConnector.getInstance().patchWorkflow(initialWorkflow!.id, changes, progressMonitor);

            await workflowEnvironmentContext.workflowsStateAccess.runWorkflow(initialWorkflow!.id, async (workflowState: WorkflowState) => {
                workflowState.change('updated', changes); // RT fallback
            });

            return true;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            workflowEnvironmentContext.notifications.snackError({ message: messages.updateWorkflowError }, error as Error);

            return false;
        }
    };

    workflowEnvironmentContext.modalContext.open('workflow-update', <WorkflowCreateUpdateModal
        initialWorkflow={workflow}
        onClose={() => workflowEnvironmentContext.modalContext.close('workflow-update')}
        onOk={handleUpdateWorkflow}
    />);
}


export function registerModifyWorkflowActions() {
    registerToolItemForMimeType<WorkflowsEnvironmentContext, Workflow>(WORKFLOW_DND_TYPE, {
        path: 'edit/modify',
        label: messages.modify,
        onClick: async (_, environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>): Promise<void> => {
            const workflow = environmentContext.firstItem();
            if (!workflow) {
                return;
            }
            await modifyWorkflow(workflow, environmentContext);
        },
    });
}
