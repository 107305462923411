import { defineMessages } from 'react-intl';
import React from 'react';

import {
    SETTINGS_REFERENCE_MIME_TYPE,
    SettingsReferenceEnvironmentContext,
} from '../environment-contexts/reference-environment-context';
import { Reference } from '../../models/references';
import { ProgressMonitor, registerToolItemForMimeType } from 'src/components/basic';
import { SettingsPermissions } from '../../permissions/permissions';
import { ConfirmModal } from '../../../components/common/modal2/confirm-modal/confirm-modal';
import ReferencesConnector from '../../connectors/references-connector';

const FORCE_REFERENCE = localStorage.getItem('FORCE_REFERENTIEL') === 'true';

const messages = defineMessages({
    deleteReference: {
        id: 'settings.actions.DeleteReference',
        defaultMessage: 'Delete',
    },
    deleteConfirmTitle: {
        id: 'settings.actions.DeleteConfirmTitle',
        defaultMessage: 'Reference delete',
    },
    deleteConfirmDescription: {
        id: 'settings.actions.DeleteConfirmDescription',
        defaultMessage: 'Are you sure you want to delete the reference "{referenceName}"?',
    },
    deletingReference: {
        id: 'settings.actions.DeletingReference',
        defaultMessage: 'Deleting reference...',
    },
    deleteReferenceError: {
        id: 'settings.actions.DeleteReferenceError',
        defaultMessage: 'Can not delete reference',
    },
});

registerToolItemForMimeType<SettingsReferenceEnvironmentContext, Reference>(SETTINGS_REFERENCE_MIME_TYPE, {
    path: 'delete/delete-reference',
    order: 810,
    type: 'button',
    label: messages.deleteReference,
    visible: (environmentContext) => {
        const result = environmentContext.hasAnyPermissions<SettingsPermissions>('admin.reference.edition');

        return result || FORCE_REFERENCE;
    },

    onClick: async (tool, environmentContext) => {
        const { modalContext, notifications } = environmentContext;
        const firstItem = await environmentContext.firstItem();

        if (!firstItem) {
            return;
        }

        const handleDeleteReference = async (progressMonitor: ProgressMonitor, reference: Reference) => {
            try {
                await ReferencesConnector().deleteReference(reference.id, progressMonitor);
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    throw error;
                }

                notifications.snackError({ message: messages.deleteReferenceError }, error as Error);
            }
        };

        modalContext.open('delete-confirm-modal', (
            <ConfirmModal
                type='delete'
                messageValues={{ referenceName: firstItem.name }}
                title={messages.deleteConfirmTitle}
                alertMessage={messages.deleteConfirmDescription}
                progressMonitorName={messages.deletingReference}
                onClose={() => modalContext.close('delete-confirm-modal')}
                onConfirm={(progressMonitor) => handleDeleteReference(progressMonitor, firstItem)}
            />
        ));
    },
});

