import { DependencyList, useState } from 'react';
import { MessageDescriptor } from 'react-intl';
import { isEqual } from 'lodash';

import { ProgressMonitor } from 'src/components/basic';
import { useEffectAsync } from './use-effect-async';
import { GLOBAL_PM, ProgressMonitorOptions } from '../progress-monitors/progress-monitor';


interface MemoAsyncOptions extends ProgressMonitorOptions {
    useDeepEquals?: boolean;
}

export const DEEP_EQUALS = {
    useDeepEquals: true,
};

export const DEEP_EQUALS_AND_GLOBAL_PM = {
    ...GLOBAL_PM,
    ...DEEP_EQUALS,
};

export function useMemoAsync<T>(
    callback: (progressMonitor: ProgressMonitor, prevValue: T | undefined) => Promise<T | undefined>,
    deps: DependencyList,
    progressMonitorName?: string | MessageDescriptor,
    progressMonitorTaskCount = 1,
    progressMonitorOptions?: MemoAsyncOptions,
    debugName?: string,
): [T | undefined, ProgressMonitor | undefined, Error | undefined] {
    const [value, setValue] = useState<T>();

    const [progressMonitor, error] = useEffectAsync(async (progressMonitor: ProgressMonitor): Promise<void> => {
        const newValue = await callback(progressMonitor, value);

        if (progressMonitorOptions?.useDeepEquals) {
            setValue((prev) => {
                if (isEqual(prev, newValue)) {
                    return prev;
                }

                return newValue;
            });

            return;
        }
        setValue(newValue);
    }, deps, progressMonitorName, progressMonitorTaskCount, progressMonitorOptions, debugName);

    return [value, progressMonitor, error];
}
