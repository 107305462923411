import React, { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import {
    ArgEllipsisMenuButton,
    ArgTable2Column,
    createTargetEnvironmentContextFromItem,
    highlightSplit,
    ProgressMonitor,
    SelectionProvider,
    useArgNotifications,
    useCallbackAsync,
    useClassNames,
    useSelection,
    useSharedSelectionProvider,
    useTargetToolContext,
    useToolItemByMimeType,
} from 'src/components/basic';
import { PageHeader } from 'src/settings/common-components/page-header';
import { OverviewSection } from 'src/components/features/overview/overview-section';
import { dateSorter, stringSorter } from 'src/utils/sorter';
import { DateByUser } from 'src/components/common/date-by-user';
import { Reference } from 'src/settings/models/references';
import { useArgModalManager } from 'src/components/basic/arg-modal/use-arg-modal-manager';
import { ManagerReferenceModal } from '../components/manager-reference-modal';
import { useGetReferences } from 'src/settings/reference/hooks/use-get-references';
import { useHasPermission } from '../../../contexts/user-permission';
import { SettingsPermissions } from '../../permissions/permissions';
import {
    SETTINGS_REFERENCE_MIME_TYPE,
    SETTINGS_REFERENCES_TABLE_ROW_MENU_CONTEXT,
    SettingsReferenceEnvironmentContext,
} from '../environment-contexts/reference-environment-context';
import { useSettingsReferenceEnvironmentContext } from '../hooks/use-reference-environment-context';
import ReferencesConnector from '../../connectors/references-connector';
import '../tool-actions/delete-reference';
import '../tool-actions/edit-reference';
import '../tool-actions/export-reference';

import './references-view.less';

const CLASSNAME = 'settings-references-view';

const SELECTION_SOURCE = 'references-view';
const FORCE_REFERENCE = localStorage.getItem('FORCE_REFERENTIEL') === 'true';

const messages = defineMessages({
    pageTitle: {
        id: 'settings.references.references-view.PageTitle',
        defaultMessage: 'References',
    },
    nameColumnTitle: {
        id: 'settings.references.references-view.NameColumnTitle',
        defaultMessage: 'Name',
    },
    descriptionColumnTitle: {
        id: 'settings.references.references-view.DescriptionColumnTitle',
        defaultMessage: 'Description',
    },
    creationColumnTitle: {
        id: 'settings.references.references-view.CreationColumnTitle',
        defaultMessage: 'Creation',
    },
    lastModificationColumnTitle: {
        id: 'settings.references.references-view.LastModificationColumnTitle',
        defaultMessage: 'Last Modification',
    },

    referenceTableCreateButtonLabel: {
        id: 'settings.references.references-view.ReferenceTableCreateButtonLabel',
        defaultMessage: 'Create',
    },
    noReferenceTablesMessage: {
        id: 'settings.references.references-view.NoReferenceTablesMessage',
        defaultMessage: 'No reference',
    },
    loadingReferenceTablesMessage: {
        id: 'settings.references.references-view.LoadingReferenceTablesMessage',
        defaultMessage: 'Loading references...',
    },
    importReferenceTablesError: {
        id: 'settings.references.references-view.ImportReferenceTablesError',
        defaultMessage: 'Can not import reference',

    },
});

export function ReferencesView() {
    const classNames = useClassNames(CLASSNAME);

    const notifications = useArgNotifications();

    const selectionProvider = useSharedSelectionProvider<Reference>(() => {
        return new SelectionProvider<Reference>(SELECTION_SOURCE, (item: Reference) => {
            if (item.id !== undefined) {
                return item.id;
            }

            throw new Error('Invalid object');
        });
    });

    useSelection(selectionProvider);

    const canEditReferences = useHasPermission<SettingsPermissions>('admin.reference.edition') || FORCE_REFERENCE;
    const canExportReferences = useHasPermission<SettingsPermissions>('admin.reference.export') || FORCE_REFERENCE;

    const environmentContext = useSettingsReferenceEnvironmentContext();
    const rowMenuContext = useTargetToolContext<SettingsReferenceEnvironmentContext, Reference>(SETTINGS_REFERENCES_TABLE_ROW_MENU_CONTEXT);
    useToolItemByMimeType(rowMenuContext, SETTINGS_REFERENCE_MIME_TYPE);

    const getSearchLabel = (extensionColumns: Reference) => {
        const searchLabel = `${extensionColumns.name} ${extensionColumns.description}`;

        return searchLabel;
    };

    const { referenceTables, updateReferenceTables, referenceTablesProgressMonitor } = useGetReferences();

    const columns = useMemo<Record<string, ArgTable2Column<Reference>>>(() => {
        let ret: Record<string, ArgTable2Column<Reference>> = {
            name: {
                key: 'name',
                title: messages.nameColumnTitle,
                dataIndex: 'name',
                sorter: (a: Reference, b: Reference) => (
                    stringSorter<Reference>(a, b, (item) => item.name)
                ),
                ellipsis: true,
                render: function Name(_data, referenceTable, _idx, search) {
                    return <span>{highlightSplit(referenceTable.name, search)}</span>;
                },
            },
            description: {
                key: 'description',
                title: messages.descriptionColumnTitle,
                dataIndex: 'description',
                sorter: (a: Reference, b: Reference) => (
                    stringSorter<Reference>(a, b, (item) => item.description)
                ),
                ellipsis: true,
                render: function Description(_data, referenceTable, _idx, search) {
                    return <span>{highlightSplit(referenceTable.description || '', search)}</span>;
                },
            },
            creation: {
                key: 'creation',
                title: messages.creationColumnTitle,
                dataIndex: 'createdDate',
                sorter: (a: Reference, b: Reference) => (
                    dateSorter<Reference>(a, b, (item) => item.createdDate)
                ),
                render: (lastUpdatedDate, referenceTable) => {
                    if (!lastUpdatedDate) {
                        return undefined;
                    }

                    return <DateByUser date={lastUpdatedDate} user={referenceTable.lastUpdatedBy} />;
                },
            },
            lastModification: {
                key: 'lastUpdatedDate',
                title: messages.lastModificationColumnTitle,
                dataIndex: 'lastUpdatedDate',
                sorter: (a: Reference, b: Reference) => (
                    dateSorter<Reference>(a, b, (item) => item.lastUpdatedDate)
                ),
                render: function LastModification(lastModification, referenceTable) {
                    if (!lastModification) {
                        return undefined;
                    }

                    return <DateByUser date={lastModification} user={referenceTable.lastUpdatedBy} />;
                },
            },
        };

        if (canEditReferences || canExportReferences) {
            const actionsColumns: ArgTable2Column<Reference> = {
                key: 'actions',
                ellipsis: false,
                title: '',
                dataIndex: '',
                width: 40,
                render: (data: any, instance: Reference) => {
                    const rowEnvironmentContext = createTargetEnvironmentContextFromItem<SettingsReferenceEnvironmentContext, Reference>(
                        environmentContext,
                        instance.id,
                        instance,
                        SETTINGS_REFERENCE_MIME_TYPE,
                    );

                    return (
                        <ArgEllipsisMenuButton<SettingsReferenceEnvironmentContext, Reference>
                            selectedItem={instance}
                            selectedKey={instance.id}
                            selectionProvider={selectionProvider}
                            toolContext={rowMenuContext}
                            selectedMimeTypes={SETTINGS_REFERENCE_MIME_TYPE}
                            environmentContext={rowEnvironmentContext}
                        />
                    );
                },
            };

            ret = {
                ...ret,
                actions: actionsColumns,
            };
        }

        return ret;
    }, [canEditReferences, canExportReferences, environmentContext, rowMenuContext, selectionProvider]);

    const createReferenceTableModal = useArgModalManager((close) => <ManagerReferenceModal onClose={close}
                                                                                           onSubmit={() => updateReferenceTables()} />);

    const [loadImportedReference] = useCallbackAsync(async (progressMonitor: ProgressMonitor, file: Blob) => {
        try {
            await ReferencesConnector().importReference(file, progressMonitor);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({
                message: messages.importReferenceTablesError,
            }, error as Error);
        }
    }, [notifications]);

    return (
        <div className={classNames('&')}>
            <PageHeader title={messages.pageTitle} />
            <OverviewSection<Reference>
                rowKey='id'
                hideTitle={true}
                columns={columns}
                searchType='highlight'
                items={referenceTables}
                getItemLabel={getSearchLabel}
                allowCreate={canEditReferences}
                allowImport={canExportReferences}
                onPressImport={(method) => loadImportedReference(method)}
                selectionSource={SELECTION_SOURCE}
                selectionProvider={selectionProvider}
                className={classNames('&-body')}
                onPressCreate={createReferenceTableModal.open}
                emptyMessage={messages.noReferenceTablesMessage}
                loadingMessage={messages.loadingReferenceTablesMessage}
                loadingData={referenceTablesProgressMonitor?.isRunning}
                createButtonLabel={messages.referenceTableCreateButtonLabel}
            />
        </div>
    );
}
