import Debug from 'debug';
import type { HubConnection } from '@microsoft/signalr';

import { Environment } from '../environment';
import type { WebSocketConnectionChannel } from '../../components/ws/websocket-connector';
import { WebSocketConnector } from '../../components/ws/websocket-connector';
import { BasicState } from './basic-state';
import { RtApi } from './rt-api';

const debug = Debug('argonode:utils:states:RtBasicState');

export const DISABLE_RT_TEST = process.env.NODE_ENV === 'test';

export type RtSource = 'local' | 'remote';

export interface RtStateMessage {
    type: string;
    messageContent: any;
}


export abstract class RtBasicState<T extends RtStateMessage = RtStateMessage> extends BasicState {
    #connected = false;
    #temporaryDisconnected?: Error;

    #channel?: WebSocketConnectionChannel<T>;

    constructor(url: string) {
        super(url);
    }

    protected abstract getRtApi(): RtApi;

    protected isRtDisabled(): boolean {
        return false;
    }

    protected get serviceUrl(): string {
        return this.getRtApi().defaultServiceUrl;
    }

    /**
     * For downgraded RT to simulate an event from server
     */
    change(property?: string, value?: any) {
        if (this.#connected && !this.#temporaryDisconnected) {
            return;
        }

        super.change(property, value);
    }

    get channel(): WebSocketConnectionChannel<T> | undefined {
        return this.#channel;
    }

    get connection(): HubConnection | undefined {
        return this.channel?.connection;
    }

    get isConnected(): boolean {
        return this.#connected;
    }

    /**
     * @protected
     */
    eventChange(property: string | undefined, value: any | undefined, source: RtSource) {
        super.change(property, value);
    }

    _handleError = (error: Error) => {
        console.log('Catch connection error', error);
        this.#temporaryDisconnected = error;
    };

    _handleReconnected = () => {
        console.log('Reset connection error');
        this.#temporaryDisconnected = undefined;

        this.eventChange('reconnected', undefined, 'remote');
    };

    _handleMessage = async (type: string, message: T) => {
        //console.log('Get message from', this.url, 'type=', type, 'message=', message);

        if (!this.processMessage) {
            this.eventChange('message', message, 'remote');

            return;
        }

        const result = await this.processMessage.call(this, this.#channel!, type, message);
        if (result) {
            return;
        }

        debug('handleMessage', 'emitEventChange', 'message=', message, 'type=', type, 'url=', this.url);

        this.eventChange('message', message, 'remote');
    };

    async connect(): Promise<void> {
        await super.connect();

        if (Environment.enableRtHubs === false || DISABLE_RT_TEST || this.isRtDisabled()) {
            debug('connect', 'Ignore connection to', this.url);

            return;
        }

        const eventNames = Object.keys(this.getRtApi().events);

        debug('connect', 'Open WebSocketChannel', this.url, 'for events=', eventNames);

        const channel = await WebSocketConnector.getInstance().listenChannel<T>(
            this.getRtApi().api,
            this.serviceUrl,
            eventNames,
            this._prepareMessage,
        );

        this.#channel = channel as WebSocketConnectionChannel<T>;
        debug('connect', 'WebSocketChannel', this.url, 'opened');

        channel.on('OnMessage', this._handleMessage);
        channel.on('OnError', this._handleError);
        channel.on('OnReconnected', this._handleReconnected);

        this.#connected = true;
        this.#temporaryDisconnected = undefined;

        await this._connected(this.#channel);
    }

    async disconnect(): Promise<void> {
        await this._disconnect();

        await super.disconnect();
    }

    private async _disconnect(): Promise<void> {
        if (!this.#connected) {
            return;
        }

        this.#connected = false;
        this.#temporaryDisconnected = undefined;
        const channel = this.#channel;
        if (!channel) {
            return;
        }
        this.#channel = undefined;
        channel.off('OnMessage', this._handleMessage);
        channel.off('OnError', this._handleError);
        channel.off('OnReconnected', this._handleReconnected);

        await this.disconnecting(channel);

        if (this.keepChannelOpened) {
            return;
        }

        await channel.close();
    }

    protected get keepChannelOpened() {
        return false;
    }

    /**
     * @private
     */
    _prepareMessage = (type: string, messageContent: any): T => {
        const ret = this.prepareMessage(type, messageContent);

        return ret;
    };

    prepareMessage(type: string, messageContent: any): T {
        const ret = { type, messageContent };

        return ret as any as T;
    }

    //

    protected processMessage?: (channel: WebSocketConnectionChannel<T>, type: string, messageContent: T) => Promise<boolean | undefined>;

    /**
     * @private
     */
    _connected = async (channel: WebSocketConnectionChannel<T>): Promise<void> => {
        await this.connected(channel);
    };

    async connected(channel: WebSocketConnectionChannel<T>): Promise<void> {

    }

    async disconnecting(channel: WebSocketConnectionChannel<T>): Promise<void> {

    }
}
