const UTF8_FILENAME_REGEX = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
const ASCII_FILENAME_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

// https://stackoverflow.com/a/67994693/13885834
export function getFileNameFromContentDisposition(contentDisposition: string | null): string | undefined {
    if (!contentDisposition) {
        return undefined;
    }

    if (UTF8_FILENAME_REGEX.test(contentDisposition)) {
        const encodedFileName = UTF8_FILENAME_REGEX.exec(contentDisposition);

        if (!encodedFileName?.[1]) {
            return undefined;
        }

        const result = decodeURIComponent(encodedFileName[1]);

        return result;
    }

    const match = ASCII_FILENAME_REGEX.exec(contentDisposition);
    if (!match?.[1]) {
        return undefined;
    }

    const fileName = match[1].replace(/['"]/g, '');

    return fileName;
}
