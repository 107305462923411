import { defineMessages, MessageDescriptor } from 'react-intl';
import React, { useCallback, useMemo } from 'react';
import { uniqBy } from 'lodash';

import {
    ArgMessageRenderer, ArgNodePath, ArgTree, BasicTag,
    ClassValue,
    ProgressMonitor,
    useArgNotifications,
    useClassNames,
    useMemoAsync,
} from 'src/components/basic';
import { WorkflowConnector } from '../../../framework/workflows';
import { LoadingPane } from '../../../components/common/panes/loading-pane';
import { ErrorPane } from '../../../components/common/panes/error-pane';
import { EmptyPane } from '../../../components/common/panes/empty-pane';
import { WorkflowActivity, WorkflowActivityType } from '../../../framework/workflows/model/workflow-activity';

import './workflow-activities-panel.less';

const CLASSNAME = 'settings-workflow-activities-panel';

const messages = defineMessages({
    title: {
        id: 'settings.workflow-activities-panel.Title',
        defaultMessage: 'Activities panel',
    },
    activitiesLibraryFetching: {
        id: 'settings.workflow-activities-panel.ActivitiesLibraryFetching',
        defaultMessage: 'Getting activities library...',
    },
    activitiesLibraryError: {
        id: 'settings.workflow-activities-panel.ActivitiesLibraryError',
        defaultMessage: 'Can not get activities library',
    },
    activitiesEmptyList: {
        id: 'settings.workflow-activities-panel.ActivitiesEmptyList',
        defaultMessage: 'No activities',
    },
    triggerTitle: {
        id: 'settings.workflow-activities-panel.TriggerTitle',
        defaultMessage: 'Triggers <grey>({count})</grey>',
    },
    stateTitle: {
        id: 'settings.workflow-activities-panel.StateTitle',
        defaultMessage: 'States <grey>({count})</grey>',
    },
    actionTitle: {
        id: 'settings.workflow-activities-panel.ActionTitle',
        defaultMessage: 'Actions <grey>({count})</grey>',
    },
});

interface WorkflowActivitiesPanelProps {
    className?: ClassValue;
}

export function WorkflowActivitiesPanel(props: WorkflowActivitiesPanelProps) {
    const { className } = props;

    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();

    const [activitiesLibrary, activitiesLibraryProgressMonitor, activitiesLibraryError] = useMemoAsync(async(progressMonitor: ProgressMonitor) => {
        try {
            const ret = await WorkflowConnector.getInstance().getWorkflowsActivities(progressMonitor);

            return ret;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.activitiesLibraryError }, error as Error);
        }
    }, [], messages.activitiesLibraryFetching);

    const root = useMemo<WorkflowActivity[]>(() => {
        const ret: WorkflowActivity[] = [];

        if (!activitiesLibrary) {
            return ret;
        }

        ret.push(...uniqBy(activitiesLibrary, activity => activity.type));

        return ret;
    }, [activitiesLibrary]);

    const handleGetNodeKey = useCallback((path: ArgNodePath<WorkflowActivity>) => {
        const node = path[path.length - 1];

        if (path.length === 1) {
            return node.type;
        }

        return node.key;
    }, []);

    const handleGetNodeLabel = useCallback((path: ArgNodePath<WorkflowActivity>) => {
        const node = path[path.length - 1];
        const cls = { [node.type]: true };

        if (path.length === 1) {
            let nodeTypeTitle: MessageDescriptor;

            const activities = activitiesLibrary?.filter((a) => a.type === node.type);

            switch (node.type) {
                case WorkflowActivityType.Action:
                    nodeTypeTitle = messages.actionTitle;
                    break;
                case WorkflowActivityType.State:
                    nodeTypeTitle = messages.stateTitle;
                    break;
                case WorkflowActivityType.Trigger:
                default:
                    nodeTypeTitle = messages.triggerTitle;
            }

            return (
                <BasicTag
                    icon='icon-full-circle'
                    label={nodeTypeTitle}
                    messageValues={{ count: activities?.length }}
                    className={classNames('&-tree-activities-type', cls)}
                />
            );
        }

        return (
            <BasicTag
                icon={node.icon}
                label={node.name}
                className={classNames('&-tree-activities-item', cls)}
            />
        );
    }, [activitiesLibrary, classNames]);

    const handleGetNodeChildren = useCallback((path: ArgNodePath<WorkflowActivity>) => {
        const node = path[path.length - 1];

        if (path.length !== 1 || !activitiesLibrary) {
            return null;
        }

        const { type } = node;

        const activites = activitiesLibrary.filter((a) => a.type === type);

        return activites;
    }, [activitiesLibrary]);


    if (activitiesLibraryProgressMonitor?.isRunning) {
        return (
            <div className={classNames('&', 'loading')}>
                <LoadingPane progressMonitor={activitiesLibraryProgressMonitor} />
            </div>
        );
    }

    if (activitiesLibraryError) {
        return (
            <div className={classNames('&', 'error')}>
                <ErrorPane error={activitiesLibraryError} />
            </div>
        );
    }

    if (!activitiesLibrary?.length) {
        return (
            <div className={classNames(className, '&', 'empty')}>
                <EmptyPane
                    size='medium'
                    message={messages.activitiesEmptyList}
                />
            </div>
        );
    }

    return (
        <div className={classNames('&', className)}>
            <ArgMessageRenderer
                size='large'
                message={messages.title}
                className={classNames('&-title')}
            />

            <div className={classNames('&-tree')}>
                <ArgTree<WorkflowActivity>
                    root={root}
                    // openedNodes={openedKeys}
                    // onOpen={handleOnOpenNodes}
                    getNodeKey={handleGetNodeKey}
                    className={classNames('&-tree')}
                    getNodeLabel={handleGetNodeLabel}
                    getNodeChildren={handleGetNodeChildren}
                />
            </div>
        </div>
    );
}
