import { WorkflowTargetType } from '../registry/workflow-target';
import { ArgonosModuleId } from '../../../components/application/modules';

export type WorkflowActivityKey = string;
export type WorkflowActivityApplication = string;

export type WorkflowActivityConfigurationKey = string;
export type WorkflowActivityOutcomeKey = string;
export type WorkflowActivityOutputKey = string;


export interface WorkflowActivityOutput {
    type: 'string' | 'number' | 'boolean';
    optional?: boolean;
    outcomes?: WorkflowActivityOutcomeKey[];
}


export interface WorkflowActivityOutcome {
    label: string;
}

export interface WorkflowActivityConfiguration {
    label: string;

    type: 'string' | 'number' | 'boolean' | 'workflow';

    required?: boolean;

    defaultValue?: any;
}

export enum WorkflowActivityType {
    Trigger = 'trigger',
    State = 'state',
    Action = 'action',
}

export interface WorkflowActivity {
    key: WorkflowActivityKey; // Composite Key
    argonosModuleId: ArgonosModuleId; // Composite Key

    name: string;
    description?: string;
    icon?: string;

    targets: WorkflowTargetType[];
    type: WorkflowActivityType;

    configuration?: Record<WorkflowActivityConfigurationKey, WorkflowActivityConfiguration>;

    outcomes?: Record<WorkflowActivityOutcomeKey, WorkflowActivityOutcome>;

    outputs?: Record<WorkflowActivityOutputKey, WorkflowActivityOutput>;
}
