import { useBasicState } from '../../../utils/rt-states/use-basic-state';
import { WorkflowsListState } from './workflows-list-state';

export function workflowsListFactory(url: string): WorkflowsListState {
    return new WorkflowsListState(url);
}

export function computeWorkflowsListStateURL() {
    return '/workflows/list';
}

export function useWorkflowsListState(): WorkflowsListState {
    const state = useBasicState<WorkflowsListState>(
        (url) => workflowsListFactory(url),
        computeWorkflowsListStateURL(),
    );

    return state!;
}
