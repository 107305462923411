import { Dispatch, SetStateAction } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { ArgIcon, useArgModalContext, useClassNames } from 'src/components/basic';
import { VertexOrEdge } from 'src/exploration/hooks/use-graph-style-customisation';
import { FullOntology, FullOntologyLinkType, FullOntologyObjectType, OntologyProperty } from '../../types';
import { TYPE_ICONS } from '../property-and-metaproperty-modals/const';
import { EditPropertyModal } from '../property-and-metaproperty-modals/property-modals/edit-property-modal';
import {
    DeletePropertyModal,
} from './delete-property-modal';
import {
    RetentionPolicyActionTargetKind,
    RetentionPolicyLinkKind,
    RetentionPolicyVertexEdge,
} from 'src/settings/universes/retention/types';
import { useReorder } from 'src/hooks/use-reorder';

import './property-component.less';

const DELETE_MODAL_NAME = 'confirm-property-delete-modal';
const EDIT_MODAL_NAME = 'edit-property-modal';
const PROPERTY_DND_TYPE = 'property';

const messages = defineMessages({
    mandatoryLabel: {
        id: 'settings.ontology.property-component.MandatoryLabel',
        defaultMessage: '(required)',
    },
});

type PropertyWithId = OntologyProperty & { id: string };

export interface PropertyComponentProps {
    property: PropertyWithId;
    index: number;
    edgeOrVertex: FullOntologyLinkType | FullOntologyObjectType;
    propertyOf: VertexOrEdge;
    ontology: FullOntology;
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    retentionSetUp: (retention: RetentionPolicyVertexEdge, type: RetentionPolicyActionTargetKind | 'Property', targetProperty?: string) => string;
    retention: RetentionPolicyVertexEdge;
    setRetention: Dispatch<SetStateAction<RetentionPolicyVertexEdge>>;
    retentionLink: RetentionPolicyLinkKind;
    reorderProperties: (dragIndex: number, hoverIndex: number) => void;
    updatePropertiesOrder: (dragItem: { id: string | number; index: number }) => Promise<void>;
}

export function PropertyComponent(props: PropertyComponentProps) {
    const {
        property,
        index,
        edgeOrVertex,
        propertyOf,
        ontology,
        setOntology,
        retentionSetUp,
        retention,
        setRetention,
        retentionLink,
        reorderProperties: moveProperty,
        updatePropertiesOrder,
    } = props;
    const classNames = useClassNames('property-component');
    const modalContext = useArgModalContext();

    const { handlerId, isDragging, drag, ref, canDrop } = useReorder(
        PROPERTY_DND_TYPE,
        moveProperty,
        index,
        property,
        updatePropertiesOrder,
    );

    const showDeleteModal = () => {
        modalContext.open(DELETE_MODAL_NAME, (
            <DeletePropertyModal
                closeModal={() => modalContext.close(DELETE_MODAL_NAME)}
                property={property}
                propertyOf={propertyOf}
                edgeOrVertex={edgeOrVertex}
                ontologyId={ontology.id}
                setOntology={setOntology}
            />
        ));
    };

    const showEditModal = () => {
        modalContext.open(EDIT_MODAL_NAME, (
            <EditPropertyModal
                closeModal={() => modalContext.close(EDIT_MODAL_NAME)}
                propertyList={[]}
                edgeOrVertex={edgeOrVertex}
                ontology={ontology}
                setOntology={setOntology}
                propertyOf={propertyOf}
                property={property}
                retentionSetUp={retentionSetUp}
                retention={retention}
                setRetention={setRetention}
                retentionLink={retentionLink}
            />
        ));
    };

    const cls = {
        '&-page-title': true,
        dragging: isDragging,
        candrop: canDrop,
    };

    const typeIcon = TYPE_ICONS[property.type]?.icon;

    return (
        <li
            key={property.name}
            ref={ref}
            data-handler-id={handlerId}
            className={classNames(cls, '&-property-row')}
        >
            <div ref={drag} className='icon-wrapper'>
                <ArgIcon name='icon-6dots' />
            </div>
            <div key={property.displayName} className={classNames('&-list-item')}>
                <div className={classNames('&-list-item-left')}>
                    {typeIcon && (
                        <ArgIcon
                            name={typeIcon}
                            className={classNames('&-property-icon')}
                            size='large'
                        />
                    )}
                    <div>
                        <span className={classNames({ title: property.isTitle })}>{property.displayName}</span>
                        {' '}
                        {property.isMandatory && (
                            <i className='required'>
                                <FormattedMessage {...messages.mandatoryLabel} />
                            </i>
                        )}
                    </div>
                </div>
                <div className={classNames('&-list-item-icon-container')}>
                    <div
                        className={classNames('&-list-item-icon')}
                        onClick={showEditModal}
                    >
                        <ArgIcon name='icon-pencil' size={12} />
                    </div>
                    <div
                        className={classNames('&-list-item-icon')}
                        onClick={showDeleteModal}
                    >
                        <ArgIcon name='icon-trash' size={12} />
                    </div>
                </div>
            </div>
        </li>
    );
}
