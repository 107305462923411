import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ArgIcon, ArgInputSearch, useArgModalContext, useClassNames } from 'src/components/basic';
import { FullOntology, OntologyMetaProperty } from '../../types';
import { TYPE_ICONS } from '../property-and-metaproperty-modals/const';
import { CreateMetapropertyModal } from '../property-and-metaproperty-modals/metaproperty-modals/create-metaproperty-modal';
import { EditMetapropertyModal } from '../property-and-metaproperty-modals/metaproperty-modals/edit-metaproperty-modal';
import { DeleteMetapropertyModal } from './delete-metaproperty-modal';

import './universe-library-panel.less';

const CREATE_MODAL_NAME = 'create-metaproperty-modal';
const EDIT_MODAL_NAME = 'edit-metaproperty-modal';
const DELETE_MODAL_NAME = 'delete-metaproperty-modal';

const messages = defineMessages({
    newMetaproperty: {
        id: 'settings.metaproperties-panel-content.newMetaproperty',
        defaultMessage: 'New Metaproperty',
    },
});

interface MetapropertiesPanelContentProps {
    metaproperties: OntologyMetaProperty[];
    setOntology: Dispatch<SetStateAction<FullOntology | undefined>>;
    ontologyId: string;
}

export function MetapropertiesPanelContent (props: MetapropertiesPanelContentProps) {
    const { metaproperties, setOntology, ontologyId } = props;

    const intl = useIntl();
    const classNames = useClassNames('universe-library-panel');
    const [searchText, setSearchText] = useState<string>();
    const modalContext = useArgModalContext();

    const filteredMetaproperties = useMemo(() => {
        return metaproperties.filter((metaproperty) =>
            metaproperty.displayName.toLowerCase().includes(searchText?.toLowerCase() || ''),
        );
    }, [searchText, metaproperties]);

    const showCreateMetapropertyModal = () => {
        modalContext.open(CREATE_MODAL_NAME, (
            <CreateMetapropertyModal
                closeModal={() => modalContext.close(CREATE_MODAL_NAME)}
                setOntology={setOntology}
                ontologyId={ontologyId}
            />
        ));
    };

    const showEditMetapropertyModal = (metaproperty: OntologyMetaProperty) => {
        modalContext.open(EDIT_MODAL_NAME, (
            <EditMetapropertyModal
                metaproperty={metaproperty}
                closeModal={() => modalContext.close(EDIT_MODAL_NAME)}
                setOntology={setOntology}
                ontologyId={ontologyId}
            />
        ));
    };

    const showDeleteMetapropertyModal = (metaproperty: OntologyMetaProperty) => {
        modalContext.open(DELETE_MODAL_NAME, (
            <DeleteMetapropertyModal
                closeModal={() => modalContext.close(DELETE_MODAL_NAME)}
                metaproperty={metaproperty}
                ontologyId={ontologyId}
                setOntology={setOntology}
            />
        ));
    };

    return (
        <div>
            <div className={classNames('&-list-header')}>
                <ArgInputSearch
                    value={searchText}
                    onInputChange={(text) => setSearchText(text || '')}
                    className={classNames('&-search-bar')}
                />
                <div
                    className={classNames('&-new-metaproperty')}
                    onClick={showCreateMetapropertyModal}
                >
                    <ArgIcon name='icon-add-outline' />
                    <div className={classNames('&-new-metaproperty-text')}>
                        {intl.formatMessage(messages.newMetaproperty)}
                    </div>
                </div>
            </div>
            {filteredMetaproperties.map((metaproperty) => (
                <div key={metaproperty.name} className={classNames('&-list-item')}>
                    <div className={classNames('&-list-item-left')}>
                        {metaproperty.type && (
                            <ArgIcon
                                name={TYPE_ICONS[metaproperty.type].icon}
                                className={classNames('&-property-icon')}
                                size='large'
                            />
                        )}
                        <div>{metaproperty.displayName}</div>
                    </div>
                    <div className={classNames('&-list-item-icon-container')}>
                        <div
                            className={classNames('&-list-item-icon')}
                            onClick={() => showEditMetapropertyModal(metaproperty)}
                        >
                            <ArgIcon name='icon-pencil' size={12} />
                        </div>
                        <div
                            className={classNames('&-list-item-icon')}
                            onClick={() => showDeleteMetapropertyModal(metaproperty)}
                        >
                            <ArgIcon name='icon-trash' size={12} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
