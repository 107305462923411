import * as uuid from 'uuid';

import { ProgressMonitor } from 'src/components/basic';
import { mapReference, RawReference } from 'src/settings/connectors/references-mapper';
import { BaseReference, Reference, ReferenceId } from 'src/settings/models/references';
import { getSettingsApi } from 'src/utils/connectors/api-url';
import { BaseConnector } from 'src/utils/connectors/base-connector';

export type CreateReferenceDTO = Omit<Reference, 'id' | 'createdDate' | 'createdBy' | 'lastUpdatedDate' | 'lastUpdatedBy'>;

class ReferencesConnector extends BaseConnector {
    private static instance: ReferencesConnector;
    private mockData: Map<string, RawReference> = new Map();

    private constructor(settingsKey: string, apiUrl: string | undefined) {
        super(settingsKey, apiUrl);
        // Initialize with some mock data
        this.mockData.set('pays', {
            id: 'ext01',
            name: 'Pays',
            description: 'Liste de pays',
            items: [
                {
                    id: uuid.v4(),
                    key: 'FR',
                    value: 'France',
                },
                {
                    id: uuid.v4(),
                    key: 'UK',
                    value: 'United Kingdom',
                },
            ],
            createdDate: '2024-06-27T13:15:18.3650770Z',
            createdBy: {
                id: 'a13253dd-6660-45c7-b76f-c7afee81ba22',
                displayName: 'Gobinet Nathan',
            },
            lastUpdatedDate: '2024-07-03T06:55:30.3330350Z',
            lastUpdatedBy: {
                id: '00000000-0000-0000-0000-000000000000',
                displayName: 'Zakaria',
            },
        });

        // Add Extension 02 (without items)
        this.mockData.set('ville-france', {
            id: 'ext02',
            name: 'Villes',
            description: 'Liste de ville de la France',
            createdDate: '2024-07-01T09:30:00.0000000Z',
            createdBy: {
                id: 'b24364ee-7777-48d8-c87g-d8bfee92cb33',
                displayName: 'John Doe',
            },
            lastUpdatedDate: '2024-07-05T14:45:20.1110000Z',
            lastUpdatedBy: {
                id: 'b24364ee-7777-48d8-c87g-d8bfee92cb33',
                displayName: 'John Doe',
            },
        });
    }

    static getInstance(): ReferencesConnector {
        if (!ReferencesConnector.instance) {
            ReferencesConnector.instance = new ReferencesConnector('settings.references', getSettingsApi());
        }

        return ReferencesConnector.instance;
    }


    private delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async createReference(reference: CreateReferenceDTO, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Reference> {
        try {
            progressMonitor.beginTask('Creating reference table', 100);
            await this.delay(1000); // Simulate network delay
            if (this.mockData.has(reference.name)) {
                throw new Error('Reference table already exists');
            }
            const now = new Date().toISOString();
            const newRawTable: RawReference = {
                id: `ext${this.mockData.size + 1}`,
                ...reference,
                createdDate: now,
                createdBy: { id: 'server-user-id', displayName: 'Server User' },
                lastUpdatedDate: now,
                lastUpdatedBy: { id: 'server-user-id', displayName: 'Server User' },
            };
            this.mockData.set(reference.name, newRawTable);
            progressMonitor.worked(100);

            return mapReference(newRawTable);
        } catch (error) {
            console.error('Error creating reference table:', error);
            throw error;
        } finally {
            progressMonitor.done();
        }
    }

    async getReferences(progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Reference[]> {
        try {
            progressMonitor.beginTask('Fetching reference tables', 100);
            await this.delay(600); // Simulate network delay
            const tables: BaseReference[] = Array.from(this.mockData.values()).map(mapReference);
            progressMonitor.worked(100);

            return tables;
        } catch (error) {
            console.error('Error fetching reference tables:', error);
            throw error;
        } finally {
            progressMonitor.done();
        }
    }

    async getReference(id: ReferenceId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Reference> {
        try {
            progressMonitor.beginTask(`Fetching reference table: ${id}`, 100);
            await this.delay(500); // Simulate network delay
            const rawTable = Array.from(this.mockData.values()).find(table => table.id === id);
            if (!rawTable) {
                throw new Error(`Reference table with id '${id}' not found`);
            }
            progressMonitor.worked(100);
            const mappedTable = mapReference(rawTable);
            if (!mappedTable.items) {
                throw new Error(`Reference table with id '${id}' does not have items`);
            }

            return mappedTable as Reference;
        } catch (error) {
            console.error(`Error fetching reference table ${id}:`, error);
            throw error;
        } finally {
            progressMonitor.done();
        }
    }

    async updateReference(id: ReferenceId, reference: Partial<CreateReferenceDTO>, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<Reference> {
        try {
            progressMonitor.beginTask(`Updating reference table: ${id}`, 100);
            await this.delay(800); // Simulate network delay
            const existingRawTable = Array.from(this.mockData.values()).find(table => table.id === id);
            if (!existingRawTable) {
                throw new Error(`Reference table with id '${id}' not found`);
            }
            const updatedRawTable: RawReference = {
                ...existingRawTable,
                ...reference,
                lastUpdatedDate: new Date().toISOString(),
                lastUpdatedBy: { id: 'server-user-id', displayName: 'Server User' },
            };
            this.mockData.set(updatedRawTable.name, updatedRawTable);
            progressMonitor.worked(100);
            const mappedTable = mapReference(updatedRawTable);
            if (!mappedTable.items) {
                throw new Error(`Updated reference table with id '${id}' does not have items`);
            }

            return mappedTable as Reference;
        } catch (error) {
            console.error('Error updating reference table:', error);
            throw error;
        } finally {
            progressMonitor.done();
        }
    }

    async deleteReference(id: ReferenceId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        try {
            progressMonitor.beginTask(`Deleting reference table: ${id}`, 100);
            await this.delay(700); // Simulate network delay
            const tableToDelete = Array.from(this.mockData.entries()).find(([_, table]) => table.id === id);
            if (!tableToDelete) {
                throw new Error(`Reference table with id '${id}' not found`);
            }
            this.mockData.delete(tableToDelete[0]);
            progressMonitor.worked(100);
        } catch (error) {
            console.error('Error deleting reference table:', error);
            throw error;
        } finally {
            progressMonitor.done();
        }
    }

    async importReference(blob: Blob, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const url = '/references/import';

        await this.request(
            url,
            {
                headers: {
                    'Content-Type': blob.type,
                },
                body: blob,
                method: 'POST',
                verifyJSONResponse: false,
            },
            progressMonitor,
        );
    }

    async exportReference(reference: BaseReference, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const url = '/references/export';

        await this.downloadRequest(`${reference.name}.zip`, url, undefined, progressMonitor);
    }
}


export default ReferencesConnector.getInstance;
