import Debug from 'debug';
import { omit } from 'lodash';

import { BaseConnector } from '../../../utils/connectors/base-connector';
import { getWorkflowBaseApi } from '../../../utils/connectors/api-url';
import { ProgressMonitor } from '../../../components/basic';
import {
    EditedWorkflow,
    Workflow,
    WorkflowDefinition,
    WorkflowDefinitionVersion,
    WorkflowId,
} from '../model/workflows';
import { mapWorkflow, mapWorkflowActivity, mapWorkflowDefinition } from './mappers';
import { getFileNameFromContentDisposition } from '../../../exploration/utils/connector/header-parser';
import { createPatchRequest, ETaggedObject, isNotChangedEtagError, JsonChange } from '../../../utils/connector';
import { WorkflowActivity } from '../model/workflow-activity';
import { workflowActivitiesMock } from './mock-workflow-activities';


const MOCK_ACTIVITIES = true;

const debug = Debug('frameworks:workflows:WorkflowConnector');

export interface WorkflowExportResponse {
    fileName: string | undefined;
    blob: Blob;
}


export class WorkflowConnector extends BaseConnector {
    private static instance: WorkflowConnector;

    static getInstance(): WorkflowConnector {
        if (!WorkflowConnector.instance) {
            WorkflowConnector.instance = new WorkflowConnector('workflow', getWorkflowBaseApi());
        }

        return WorkflowConnector.instance;
    }

    async listWorkflows(
        workflowIds?: WorkflowId[],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Workflow[]> {
        const url = '/workflows';

        const raw = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        let ret: Workflow[] = raw.workflows.map(mapWorkflow);

        if (workflowIds) {
            ret = ret.filter((workflow) => {
                if (workflowIds.includes(workflow.id)) {
                    return true;
                }

                return false;
            });
        }

        return ret;
    }

    async listWorkflowIds(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<WorkflowId[]> {
        const url = '/workflows';

        const raw = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret: WorkflowId[] = raw.workflows.map((workflow: any) => workflow.id);

        return ret;
    }

    async getWorkflow(
        workflowId: WorkflowId,
        previousWorkflow?: Workflow,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<Workflow> {
        const url = `/workflows/${encodeURIComponent(workflowId)}`;

        let raw;
        try {
            raw = await this.request(url, {
                verifyJSONResponse: true,
                etag: (previousWorkflow as ETaggedObject)?.etag,
            }, progressMonitor);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            if (isNotChangedEtagError(error)) {
                return previousWorkflow!;
            }
            throw error;
        }

        const result = mapWorkflow(raw);

        debug('getWorkflow', 'workflow=', result);

        return result;
    }

    async deleteWorkflow(
        workflowId: WorkflowId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/workflows/${encodeURIComponent(workflowId)}`;

        await this.request(url, {
            method: 'DELETE',
        }, progressMonitor);
    }


    async createWorkflow(
        editedWorkflow: EditedWorkflow,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<WorkflowId> {
        const url = '/workflows';

        const json = omit(editedWorkflow, 'targetType') as any;
        json.status = 'Disabled';
        json.triggerLock = {
            key: editedWorkflow.targetType,
            order: 0,
        };

        console.log('Post', 'editedWorkflow=', editedWorkflow, 'json=', json);

        const result = await this.request(url, {
            method: 'POST',
            json,
        }, progressMonitor);

        const { id }: { id: WorkflowId } = result;

        return id;
    }

    async createWorkflowDefinition(
        workflowId: WorkflowId,
        workflowDefinition: WorkflowDefinition,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<WorkflowDefinitionVersion> {
        const url = `/workflows/${encodeURIComponent(workflowId)}`;

        const result = await this.request(url, {
            method: 'POST',
            json: workflowDefinition,
        }, progressMonitor);

        const { version }: { version: WorkflowDefinitionVersion } = result;

        return version;
    }

    async publishWorkflowDefinition(
        workflowId: WorkflowId,
        workflowDefinitionVersion: WorkflowDefinitionVersion,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/workflows/${encodeURIComponent(workflowId)}/workflow-definition/${encodeURIComponent(workflowDefinitionVersion)}/publish`;

        await this.request(url, {
            method: 'POST',
        }, progressMonitor);
    }


    async getWorkflowDefinition(
        workflowId: WorkflowId,
        workflowDefinitionVersion: WorkflowDefinitionVersion,
        previousWorkflowDefinition?: WorkflowDefinition,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<WorkflowDefinition> {
        const url = `/workflows/${encodeURIComponent(workflowId)}/workflow-definition/${encodeURIComponent(workflowDefinitionVersion)}`;

        let raw;
        try {
            raw = await this.request(url, {
                verifyJSONResponse: true,
                etag: (previousWorkflowDefinition as ETaggedObject)?.etag,
            }, progressMonitor);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }
            if (isNotChangedEtagError(error)) {
                return previousWorkflowDefinition!;
            }
            throw error;
        }

        const result = mapWorkflowDefinition(raw);

        debug('getWorkflowDefinition', 'workflow=', result);

        return result;
    }

    async exportWorkflowDefinition(
        workflowId: WorkflowId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<WorkflowExportResponse> {
        const url = `/workflows/${encodeURIComponent(workflowId)}/export`;

        const response = await this.request(url, {
            method: 'GET',
            forceResponse: true,
        }, progressMonitor);

        const blob = await response.blob();
        const contentDisposition = response.headers.get('content-disposition');
        const fileName = getFileNameFromContentDisposition(contentDisposition);

        const workflowExportResponse = { fileName, blob };

        return workflowExportResponse;
    }

    async getWorkflowsActivities(
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<WorkflowActivity[]> {
        const url = '/workflows-activities';

        if (MOCK_ACTIVITIES) {
            return workflowActivitiesMock;
        }

        const response = await this.request(url, {
            method: 'GET',
        }, progressMonitor);

        const result = response.activites.map(mapWorkflowActivity) as WorkflowActivity[];

        return result;
    }


    async patchWorkflow(workflowId: WorkflowId, changes: JsonChange[], progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        if (!changes.length) {
            return;
        }

        const url = `/workflows/${encodeURIComponent(workflowId)}`;

        const options = createPatchRequest('Change name and/or description', ...changes);

        await this.request(url, options, progressMonitor);
    }
}
