import { defineMessages } from 'react-intl';

import {
    SETTINGS_REFERENCE_MIME_TYPE,
    SettingsReferenceEnvironmentContext,
} from '../environment-contexts/reference-environment-context';
import { Reference } from '../../models/references';
import { ProgressMonitor, registerToolItemForMimeType } from 'src/components/basic';
import { SettingsPermissions } from '../../permissions/permissions';
import ReferencesConnector from '../../connectors/references-connector';

const FORCE_REFERENCE = localStorage.getItem('FORCE_REFERENTIEL') === 'true';

const messages = defineMessages({
    exportReference: {
        id: 'settings.actions.ExportReference',
        defaultMessage: 'Export',
    },
    exportingReference: {
        id: 'settings.actions.ExportingReference',
        defaultMessage: 'Exporting reference...',
    },
    exportReferenceError: {
        id: 'settings.actions.ExportReferenceError',
        defaultMessage: 'Can not delete reference',
    },
});

registerToolItemForMimeType<SettingsReferenceEnvironmentContext, Reference>(SETTINGS_REFERENCE_MIME_TYPE, {
    path: 'actions/export-reference',
    order: 510,
    type: 'button',
    label: messages.exportReference,
    visible: (environmentContext) => {
        const result = environmentContext.hasAnyPermissions<SettingsPermissions>('admin.reference.export');

        return result || FORCE_REFERENCE;
    },

    onClick: async (tool, environmentContext) => {
        const firstItem = await environmentContext.firstItem();

        if (!firstItem) {
            return;
        }

        const progressMonitor = new ProgressMonitor(messages.exportingReference, 1);

        try {
            await ReferencesConnector().exportReference(firstItem, progressMonitor);
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            environmentContext.notifications.snackError({ message: messages.exportReferenceError }, error as Error);
        }
    },
});

