import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';

import {
    ArgButton,
    ArgSize,
    ArgToolMenu,
    ClassValue,
    OffsetValue,
    SelectionProvider,
    ToolContext,
    useClassNames,
    useToolNodes,
} from 'src/components/basic/index';
import { TargetEnvironmentContext } from './target-tool-context';
import { useCreateTargetEnvironmentContext } from './use-create-target-environment-context';

import './arg-ellipsis-menu-button.less';


const CLASSNAME = 'arg-ellipsis-menu-button';

const POPOVER_OFFSET: OffsetValue = { mainAxis: 4, crossAxis: 2 };


interface ArgEllipsisMenuButtonProps<T, K> {
    className?: ClassValue;

    toolContext: ToolContext<TargetEnvironmentContext<T, K>>;
    environmentContext: T;

    selectionProvider?: SelectionProvider<K>;
    getItemByKey?: (environmentContext: T, key: string) => (K | undefined);

    selectedKey?: string;
    selectedItem?: K;
    selectedMimeTypes?: string | string[];

    buttonSize?: ArgSize;
}

export function ArgEllipsisMenuButton<T = undefined, K = any>(props: ArgEllipsisMenuButtonProps<T, K>) {
    const {
        buttonSize,
        className,
        toolContext,
        environmentContext,
        selectionProvider,
        selectedKey,
        selectedItem,
        getItemByKey,
        selectedMimeTypes,
    } = props;

    const [visible, setVisible] = useState<boolean>(false);


    const classNames = useClassNames(CLASSNAME);

    const unmountedRef = useRef<boolean>(false);
    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    // We clone the ToolContext because the button is used in every row and it is not possible to do that
    // (share a toolcontext and using  useToolNodes hook)
    const targetToolContext = useMemo<ToolContext<TargetEnvironmentContext<T, K>>>(() => {
        const result = new ToolContext<TargetEnvironmentContext<T, K>>(toolContext);

        return result;
    }, [toolContext]);

    const targetEnvironmentContext = useCreateTargetEnvironmentContext<T, K>(
        environmentContext,
        {
            selectionProvider,
            getItemByKey,
            selectedKey,
            selectedItem,
        },
        selectedMimeTypes,
    );

    const handleCloseMenu = useCallback(() => {
        if (unmountedRef.current) {
            return;
        }

        setVisible(false);
    }, []);

    const [treeNodes] = useToolNodes(targetToolContext, targetEnvironmentContext);

    const actionsMenu = useCallback(() => {
        return <ArgToolMenu<TargetEnvironmentContext<T, K>>
            menuContext={targetToolContext}
            onCloseMenu={handleCloseMenu}
            environmentContext={targetEnvironmentContext}
        />;
    }, [handleCloseMenu, targetEnvironmentContext, targetToolContext]);

    if (isEmpty(treeNodes)) {
        return null;
    }

    return (
        <ArgButton
            size={buttonSize}
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            className={classNames('&', className)}
            popoverClassName={classNames('&-popover')}
            popoverOffset={POPOVER_OFFSET}
        />
    );
}
