import { defineMessages } from 'react-intl';

import { SETTINGS_ROOT_URL } from '../../components/containers/routes-urls';
import { registerToolItem } from '../../components/basic';
import { SETTINGS_LEFT_MENU_CONTEXT_NAME } from '../envrionment-contexts/context-names';
import { SettingsEnvironmentContext } from '../envrionment-contexts/settings-environment-context';
import { registerWorkflowTarget } from '../../framework/workflows/registry/workflows-registry';
import { ArgonosModuleId } from '../../components/application/modules';
import { setupWorkflowActions } from './actions';

export const WORKFLOWS_PATH = `${SETTINGS_ROOT_URL}/workflows`;
export const WORKFLOW_ROUTE = `${WORKFLOWS_PATH}/:workflowId`;

const messages = defineMessages({
    workflowsNavItemLabel: {
        id: 'settings.users.menu.WorkflowsView',
        defaultMessage: 'Workflows',
    },
    ontology: {
        id: 'settings.workflows.types.Ontology',
        defaultMessage: 'Ontology',
    },
});


export function setupWorkflows() {
    registerToolItem(SETTINGS_LEFT_MENU_CONTEXT_NAME, {
        path: 'global/workflows',
        label: messages.workflowsNavItemLabel,
        icon: 'icon-progress-check',
        order: 600,
        visible: (settingsEnvironmentContext: SettingsEnvironmentContext) => {
            return true;
        },
        navigateTo: WORKFLOWS_PATH,
    });

    setupWorkflowActions();
}

export function setupWorkflowTypes(argonosModuleId: ArgonosModuleId) {
    registerWorkflowTarget({
        targetType: 'Ontology',
        label: messages.ontology,
        argonosModuleId: argonosModuleId,
    });
}
