import {
    UniverseEdgeTypeName,
    UniverseId,
    UniversePropertyName,
    UniversePropertyTypes,
    UniverseVertexTypeName,
} from './universe';
import { ExplorationId, ExplorationOrigin } from './exploration';
import { CaseId } from '../../model/folder-case-piece';
import { BriefId } from './brief';
import { CasePieceId } from 'src/model/basic-case-piece';
import { Filter, FilterOperation } from './filter';
import { DashboardId } from '../features/dashboards/dashboards';
import { DataViewMode } from './view-mode';
import { ChartId } from '../features/charts/charts';
import { DataSourceId } from '../features/datasources/datasources';
import { SearchMode } from '../features/searches/types';
import { VertexId } from './vertex';

export const FILES_DND_TYPE = 'Files';

export const SUPPORTED_IMAGES_TYPE = ['image/png', 'image/jpeg', 'image/gif', 'image/jpg', 'image/webp'];


// Exploration
export const EXPLORATION_DND_TYPE = 'application/arg-exploration';

export interface ExplorationDndDataTransfer {
    explorationId: ExplorationId;
    explorationName?: string;
    universeId?: UniverseId;
    caseId: CaseId;
    explorationOrigin?: ExplorationOrigin;
    dataViewMode?: DataViewMode;
}

// Search
export const SEARCH_DND_TYPE = 'application/arg-search';

export interface SearchDndDataTransfer {
    caseId: CaseId;
    explorationId: ExplorationId;
    explorationName?: string;
    universeId?: UniverseId;
    searchMode: SearchMode;
}

// Vertices specific
// Needs to work with EXPLORATION_VIEW_DND_TYPE
export const VERTICES_DND_TYPE = 'application/arg-exploration-vertices';

export interface VerticesDndDataTransfer {
    filter: Filter | FilterOperation;
    universeId: UniverseId;
    fromExplorationId?: ExplorationId;
    fromCaseId?: CaseId;
    count?: number;
}

// Brief
export const BRIEF_VIEW_DND_TYPE = 'application/arg-brief';

export interface BriefViewDndDataTransfer {
    caseId: CaseId;
    briefId: BriefId;
    briefName?: string; // Used only if only one brief is passed
    universeId?: UniverseId;
}

export const RESOURCE_DND_TYPE = 'application/arg-resource';

export interface ResourceDndDataTransfer {
    resourceId: CasePieceId;
    resourceName?: string;
    caseId: CaseId;

    contentType?: string;
    contentLength?: number;

    // For some resource
    explorationId?: ExplorationId;
    universeId?: UniverseId;
    vertexId?: VertexId;

    // For screenshot
    screenshotWidth?: number;
    screenshotHeight?: number;
    explorationLayout?: Record<string, any>;

    isScreenshot?: boolean;
}

// Dashboard
export const DASHBOARD_DND_TYPE = 'application/arg-dashboard';

export interface DashboardDndDataTransfer {
    caseId: CaseId;
    dashboardId: DashboardId;
    dashboardName?: string;
}


// Dashboard
export const DATASOURCE_DND_TYPE = 'application/arg-datasource';

export interface DataSourceDndDataTransfer {
    dataSourceId: DataSourceId;
    dataSourceName?: string;
}

// Charts
export const CHART_DND_TYPE = 'application/arg-chart';

export interface ChartDndDataTransfer {
    caseId: CaseId;
    chartId: ChartId;

    chartName?: string;
    viewMode?: DataViewMode;
}

export const VERTEX_TYPE_DND_TYPE = 'application/arg-universe-vertex';

export interface VertexTypeDndDataTransfer {
    universeId: UniverseId;
    vertexType: UniverseVertexTypeName;
    displayName: string;
    traversalObjectIndex?: number;
}

export const EDGE_TYPE_DND_TYPE = 'application/arg-universe-edge';

export interface EdgeTypeDndDataTransfer {
    universeId: UniverseId;
    edgeType: UniverseEdgeTypeName;
    displayName: string;
    traversalObjectIndex?: number;
}

export const UNIVERSE_PROPERTY_TYPE_DND_TYPE = 'application/arg-universe-property';

export interface UniversePropertyTypeDndDataTransfer {
    universeId: UniverseId;
    objectType: 'vertex' | 'edge';
    objectName: UniverseEdgeTypeName | UniverseVertexTypeName;
    traversalObjectIndex?: number;
    propertyName: UniversePropertyName;
    displayName: string;
}

export const DATASOURCE_PARAMETER_DND_TYPE = 'application/arg-universe-projection';

export const getParameterDndType = (baseType: string) => `${DATASOURCE_PARAMETER_DND_TYPE}/${baseType.toLowerCase()}`;

export const DATASOURCE_PARAMETER_DND_INT_TYPE = getParameterDndType(UniversePropertyTypes.Int32);
export const DATASOURCE_PARAMETER_DND_STRING_TYPE = getParameterDndType(UniversePropertyTypes.String);
export const DATASOURCE_PARAMETER_DND_DATE_TYPE = getParameterDndType(UniversePropertyTypes.Date);
