import { getDataPreparationApi } from '../../utils/connectors/api-url';
import { RtApi, RtApiSubType } from '../../utils/rt-states/rt-api';
import { PREPARATION_ALL_ENTITY_EVENTS, PREPARATION_EVENTS } from './events';
import { FOLDERS_EVENTS } from '../../utils/rt-states/folders/events';
import { USER_ALL_EVENTS } from '../../utils/rt-states/events';

let preparationEntitiesRtApi: RtApi | undefined = undefined;

export function getDataPreparationEntitiesRtApi(): RtApi {
    if (preparationEntitiesRtApi) {
        return preparationEntitiesRtApi;
    }

    const api = getDataPreparationApi();
    if (!api) {
        throw new Error('DataPreparationApi is not defined');
    }

    preparationEntitiesRtApi = {
        argonosModuleId: 'chapsVision.DataPreparation',
        subType: RtApiSubType.Entities,
        api,
        defaultServiceUrl: '/casePieces',
        events: PREPARATION_ALL_ENTITY_EVENTS,
    };

    return preparationEntitiesRtApi;
}

let preparationFoldersRtApi: RtApi | undefined = undefined;

export function getDataPreparationFoldersRtApi(): RtApi {
    if (preparationFoldersRtApi) {
        return preparationFoldersRtApi;
    }

    const api = getDataPreparationApi();
    if (!api) {
        throw new Error('DataPreparationApi is not defined');
    }

    preparationFoldersRtApi = {
        argonosModuleId: 'chapsVision.DataPreparation',
        subType: RtApiSubType.Folders,
        api,
        defaultServiceUrl: '/cases',
        events: FOLDERS_EVENTS,
    };

    return preparationFoldersRtApi;
}

let preparationUsersRtApi: RtApi | undefined = undefined;

export function getDataPreparationUsersRtApi(): RtApi {
    if (preparationUsersRtApi) {
        return preparationUsersRtApi;
    }

    const api = getDataPreparationApi();
    if (!api) {
        throw new Error('DataPreparationApi is not defined');
    }

    preparationUsersRtApi = {
        argonosModuleId: 'chapsVision.DataPreparation',
        subType: RtApiSubType.Users,
        api,
        defaultServiceUrl: '/users',
        events: USER_ALL_EVENTS,
    };

    return preparationUsersRtApi;
}

let preparationRtApi: RtApi | undefined = undefined;

export function getDataPreparationRtApi(): RtApi {
    if (preparationRtApi) {
        return preparationRtApi;
    }

    const api = getDataPreparationApi();
    if (!api) {
        throw new Error('DataPreparationApi is not defined');
    }

    preparationRtApi = {
        argonosModuleId: 'chapsVision.DataPreparation',
        subType: 'preparation',
        api,
        defaultServiceUrl: '/preparation',
        events: PREPARATION_EVENTS,
    };

    return preparationRtApi;
}
