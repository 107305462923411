import Debug from 'debug';

import { RtBasicState, RtStateMessage } from '../../../utils/rt-states/rt-basic-state';
import { RtApi } from '../../../utils/rt-states/rt-api';
import { WatchCommands } from '../../../utils/rt-states/folders/folder-basic-state';
import { WebSocketChannel } from '../../../components/ws/websocket-connector';
import { WorkflowId } from '../model/workflows';
import { WORKFLOW_EVENTS } from './events';
import { getWorkflowsRtApi } from './rt-apis';

const debug = Debug('argonode:framework:workflows:WorkflowState');

export type WorkflowStateMessage = RtStateMessage;

const WATCH_COMMANDS: WatchCommands = {
    watch: 'Watch',
    unwatch: 'Unwatch',
    linkName: 'watch',
};

export class WorkflowState extends RtBasicState<WorkflowStateMessage> {
    #workflowId: WorkflowId;
    #deleted = false;
    #acceptedEvents = WORKFLOW_EVENTS;

    constructor(url: string, workflowId: WorkflowId) {
        super(url);
        this.#workflowId = workflowId;
    }

    protected getRtApi(): RtApi {
        return getWorkflowsRtApi();
    }

    protected get keepChannelOpened() {
        return true;
    }

    get deleted(): boolean {
        return this.#deleted;
    }

    protected isRtDisabled(): boolean {
        return true;
    }


    protected get watchKey(): string {
        return this.#workflowId;
    }

    protected get watchCommands(): WatchCommands {
        return WATCH_COMMANDS;
    }

    protected processMessage = async (channel: WebSocketChannel<WorkflowStateMessage>, type: string, message: WorkflowStateMessage): Promise<boolean | undefined> => {
        const { workflowId } = message.messageContent;

        if (workflowId !== this.#workflowId) {
            // Ignore message
            return true;
        }

        if (!this.#acceptedEvents[message.type]) {
            // Ignore message
            return true;
        }

        if (message.type === 'WorkflowDeleted') {
            this.#deleted = true;
            debug('processMessage', 'workflow DELETED url=', this.url);
        }

        const ret = this.processWorkflowMessage(message);

        return ret;
    };

    protected processWorkflowMessage(message: WorkflowStateMessage): boolean {
        return false;
    }
}
