import React from 'react';

import { ClassValue, useClassNames } from 'src/components/basic';

import WorkflowEmpty from './workflow-empty.svg';
import './workflow-flowchart.less';

const CLASSNAME = 'settings-workflow-flowchart';

interface WorkflowFlowchartPanelProps {
    className?: ClassValue;
}

export function WorkflowFlowchart(props: WorkflowFlowchartPanelProps) {
    const { className } = props;

    const classNames = useClassNames(CLASSNAME);


    return (
        <div className={classNames('&', className)}>
            <img src={WorkflowEmpty} className={classNames('&-empty')} />
        </div>
    );
}
