import { defineMessages, MessageDescriptor } from 'react-intl';

export const dataTypesMessages = defineMessages({
    typeInteger32b: {
        id: 'common.data-types.integer32b',
        defaultMessage: 'Integer 32b',
    },
    typeInteger64b: {
        id: 'common.data-types.integer64b',
        defaultMessage: 'Integer 64b',
    },
    typeBoolean: {
        id: 'common.data-types.boolean',
        defaultMessage: 'Boolean',
    },
    typeCharacter: {
        id: 'common.data-types.character',
        defaultMessage: 'Character',
    },
    typeString: {
        id: 'common.data-types.string',
        defaultMessage: 'String',
    },
    typeText: {
        id: 'common.data-types.text',
        defaultMessage: 'Text',
    },
    typeDate: {
        id: 'common.data-types.date',
        defaultMessage: 'Date',
    },
    typeDateAndTime: {
        id: 'common.data-types.date-and-time',
        defaultMessage: 'Date and time',
    },
    typeGeoCoordinates: {
        id: 'common.data-types.geo-coordinates',
        defaultMessage: 'Geographic coordinates',
    },
    typeInteger8b: {
        id: 'common.data-types.integer8b',
        defaultMessage: 'Integer 8b',
    },
    typeFloat: {
        id: 'common.data-types.float',
        defaultMessage: 'Float',
    },
    typeEmail: {
        id: 'common.data-types.email',
        defaultMessage: 'Email address',
    },
    typeUrl: {
        id: 'common.data-types.url',
        defaultMessage: 'Url',
    },
    typePhoneNumber: {
        id: 'common.data-types.phone-number',
        defaultMessage: 'Phone number',
    },
    typeAddress: {
        id: 'common.data-types.address',
        defaultMessage: 'Address',
    },
    typeCountry: {
        id: 'common.data-types.country',
        defaultMessage: 'Country',
    },
    typeNumber: {
        id: 'common.data-types.number',
        defaultMessage: 'Number',
    },
    typeGuid: {
        id: 'common.data-types.Guid',
        defaultMessage: 'Guid',
    },
    typeInteger16b: {
        id: 'common.data-types.integer16b',
        defaultMessage: 'Integer 16b',
    },
    typeDouble: {
        id: 'common.data-types.Double',
        defaultMessage: 'Double',
    },
    typeUnknown: {
        id: 'common.data-types.Unknown',
        defaultMessage: 'Unknown type',
    },
});


export enum DataType {
    String = 'String',
    Char = 'Char',
    Boolean = 'Boolean',
    Byte = 'Byte',
    Int16 = 'Int16',
    Int32 = 'Int32',
    Int64 = 'Int64',
    Single = 'Single',
    Double = 'Double',
    Date = 'Date',
    Coordinates = 'Coordinates',
    Guid = 'Guid',
    Text = 'Text',
    DateTime = 'DateTime',
    Email = 'Email',
    Url = 'Url',
    PhoneNumber = 'PhoneNumber',
    Address = 'Address',
    Action = 'Action',
    Resources = 'Resources',
    Image = 'Image',
    Country = 'Country',
    Object = 'Object',
    AnnotationArea = 'AnnotationArea',
}

export function getDataTypesIconClass(dataType: DataType) {
    switch (dataType) {
        case DataType.Boolean:
            return 'icon-boolean';

        case DataType.Date:
            return 'icon-calendar';

        case DataType.DateTime:
            return 'icon-calendar-clock';

        case DataType.String:
        case DataType.Text:
        case DataType.Char:
        case DataType.Guid:
            return 'icon-text';


        case DataType.Coordinates:
            return 'icon-globe';

        case DataType.Byte:
        case DataType.Int16:
        case DataType.Int32:
        case DataType.Int64:
        case DataType.Double:
            return 'icon-numeric';

        case DataType.Single:
            return 'icon-decimal';

        case DataType.Email:
            return 'icon-envelop';

        case DataType.Url:
            return 'icon-sphere';

        case DataType.PhoneNumber:
            return 'icon-mobile';

        case DataType.Address:
            return 'icon-location1';

        case DataType.Country:
            return 'icon-flag';

        default:
            return undefined;
    }
}

export function getDataTypesMessageDescriptor(dataType: DataType): MessageDescriptor {
    switch (dataType) {
        case DataType.Boolean:
            return dataTypesMessages.typeBoolean;

        case DataType.Date:
            return dataTypesMessages.typeDate;

        case DataType.DateTime:
            return dataTypesMessages.typeDateAndTime;

        case DataType.String:
            return dataTypesMessages.typeString;

        case DataType.Text:
        case DataType.Guid:
            return dataTypesMessages.typeText;

        case DataType.Char:
            return dataTypesMessages.typeCharacter;

        case DataType.Coordinates:
            return dataTypesMessages.typeGeoCoordinates;

        case DataType.Byte:
        case DataType.Int16:
        case DataType.Int32:
        case DataType.Int64:
        case DataType.Double:
            return dataTypesMessages.typeNumber;

        case DataType.Single:
            return dataTypesMessages.typeFloat;

        case DataType.Email:
            return dataTypesMessages.typeEmail;

        case DataType.Url:
            return dataTypesMessages.typeUrl;

        case DataType.PhoneNumber:
            return dataTypesMessages.typePhoneNumber;

        case DataType.Address:
            return dataTypesMessages.typeAddress;

        case DataType.Country:
            return dataTypesMessages.typeCountry;

        default:
            return dataTypesMessages.typeUnknown;
    }
}
