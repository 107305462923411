import React from 'react';

import { ArgInputNumber, ArgInputNumberProps } from './arg-input-number';


export type ArgInputIntegerProps = Omit<ArgInputNumberProps, 'formatValue' | 'parseValue' | 'onKeyPress' | 'type'>;

/**
 * This component is designed to display an input integer field.
 *
 * @example
 * ```
 * <ArgInputInteger value={value} onChange={handleChangeValue} />
 * ```
 */
export function ArgInputInteger(props: ArgInputIntegerProps) {
    return <ArgInputNumber numberType='integer' {...props} />;
}
