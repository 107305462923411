import React, { useCallback, useContext } from 'react';
import { defineMessages } from 'react-intl';

import { getDataTestIdFromProps } from '../utils';
import { ArgUploaderContext } from './arg-uploader-context';
import { useClassNames } from '../arg-hooks/use-classNames';
import { ArgButton, ArgButtonProps } from '../arg-button/arg-button';
import { UploadMethod, UploadRequestInformation } from './arg-uploader-context-provider';

//import './arg-uploader-button.less';

const messages = defineMessages({
    browse: {
        id: 'basic.arg-uploader-button.Browse',
        defaultMessage: 'Browse',
    },
});

const DEFAULT_MULTIPLE = false;

export interface ArgUploaderButtonProps<T> extends Omit<ArgButtonProps, 'onClick'> {
    /**
     * The upload informations (series of key/value)
     */
    information?: T;

    /**
     * The method to call when the user select files to upload
     */
    method?: UploadMethod;

    /**
     * Allow multiple files upload
     */
    multiple?: boolean;

    /**
     * List of accepted files mimes
     */
    acceptedFiles?: string;

    /**
     * Hide label or not
     */
    hideLabel?: boolean;

    /**
     * Callback to call whenever the selected files changed
     */
    onChange?: (files: File[]) => void;
}

/**
 * This component is designed to display an uploader button.
 *
 * @example
 * ```
 * <ArgUploaderButton
 *     label='Upload'
 * />
 * ```
 */
export function ArgUploaderButton<T extends UploadRequestInformation>(props: ArgUploaderButtonProps<T>) {
    const {
        className,
        onChange,
        method,
        label = messages.browse,
        hideLabel,
        information,
        multiple = DEFAULT_MULTIPLE,
        acceptedFiles,
        ...restProps
    } = props;

    const uploader = useContext(ArgUploaderContext);


    const dataTestId = getDataTestIdFromProps(props);

    const classNames = useClassNames('arg-uploader-button');

    const handleUpload = useCallback((event: Event) => {
        const files = (event.target as HTMLInputElement).files;
        if (!files?.length) {
            return;
        }

        if (onChange) {
            onChange(Array.from(files));

            return;
        }

        if (method) {
            // Send the files to the queue of the uploader
            uploader.add({
                files: [...files],
                information,
                method,
            });

            return;
        }

        throw new Error('Invalid props');
    }, [onChange, uploader, information, method]);

    // Handle the click on the hidden input file from the button
    const handleClick = useCallback(() => {
        const inputElement: HTMLInputElement = document.createElement('input');

        inputElement.type = 'file';
        inputElement.multiple = !!multiple;
        inputElement.onchange = handleUpload;

        if (acceptedFiles) {
            inputElement.accept = acceptedFiles;
        }

        inputElement.click();

        inputElement.remove();
    }, [multiple, handleUpload, acceptedFiles]);

    const cls = {};

    return (
        <ArgButton
            {...restProps}
            label={hideLabel ? undefined : label}
            onClick={handleClick}
            data-testid={dataTestId}
            className={classNames('&', cls, className)} />
    );
}
