import Debug from 'debug';

import { RtBasicState, RtStateMessage } from '../../../utils/rt-states/rt-basic-state';
import { RtApi } from '../../../utils/rt-states/rt-api';
import { WatchCommands } from '../../../utils/rt-states/folders/folder-basic-state';
import { WebSocketChannel } from '../../../components/ws/websocket-connector';
import { WorkflowDefinitionVersion, WorkflowId } from '../model/workflows';
import { getWorkflowsRtApi } from './rt-apis';
import { WORKFLOW_DEFINITION_EVENTS } from './events';

const debug = Debug('argonode:framework:workflows:WorkflowDefinitionState');

export type WorkflowDefinitionStateMessage = RtStateMessage;

const WATCH_COMMANDS: WatchCommands = {
    watch: 'Watch',
    unwatch: 'Unwatch',
    linkName: 'watch',
};

export class WorkflowDefinitionState extends RtBasicState<WorkflowDefinitionStateMessage> {
    #workflowId: WorkflowId;
    #deleted = false;
    #acceptedEvents = WORKFLOW_DEFINITION_EVENTS;
    #workflowVersion: WorkflowDefinitionVersion;

    constructor(url: string, workflowId: WorkflowId, version: WorkflowDefinitionVersion) {
        super(url);
        this.#workflowId = workflowId;
        this.#workflowVersion = version;
    }

    protected getRtApi(): RtApi {
        return getWorkflowsRtApi();
    }

    protected get keepChannelOpened() {
        return true;
    }

    get deleted(): boolean {
        return this.#deleted;
    }

    protected isRtDisabled(): boolean {
        return true;
    }


    protected get watchKey(): string {
        return this.#workflowId;
    }

    protected get watchCommands(): WatchCommands {
        return WATCH_COMMANDS;
    }

    protected processMessage = async (channel: WebSocketChannel<WorkflowDefinitionStateMessage>, type: string, message: WorkflowDefinitionStateMessage): Promise<boolean | undefined> => {
        const { workflowId, workflowVersion } = message.messageContent;

        if (workflowId !== this.#workflowId || workflowVersion !== this.#workflowVersion) {
            // Ignore message
            return true;
        }

        if (!this.#acceptedEvents[message.type]) {
            // Ignore message
            return true;
        }

        if (message.type === 'WorkflowDefinitionDeleted') {
            this.#deleted = true;
            debug('processMessage', 'workflow DELETED url=', this.url);
        }

        const ret = this.processWorkflowDefinitionMessage(message);

        return ret;
    };

    protected processWorkflowDefinitionMessage(message: WorkflowDefinitionStateMessage): boolean {
        return false;
    }
}
