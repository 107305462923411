import { WorkflowTarget } from '../registry/workflow-target';
import { ArgonosModulesRegistry } from '../../../components/application/argonos-modules-registry';
import { ClassValue, renderText, useClassNames } from '../../../components/basic';

import './workflow-target-type.less';

const CLASSNAME = 'framework-workflows-workflow-target-type';

interface WorkflowTargetTypeProps {
    className?: ClassValue;
    workflowTarget: WorkflowTarget;
    showApplication?: boolean;
}

export function WorkflowTargetType(props: WorkflowTargetTypeProps) {
    const {
        className,
        workflowTarget,
        showApplication = true,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const argonosModule = ArgonosModulesRegistry.getInstance().getById(workflowTarget.argonosModuleId);

    return <div className={classNames('&', className)}>
        <div className={classNames('&-label')}>
            {renderText(workflowTarget.label)}
        </div>
        {showApplication && argonosModule?.name && <div className={classNames('&-application')}>
            {' - '}
            {renderText(argonosModule?.name)}
        </div>}
    </div>;
}
