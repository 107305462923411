import { defineMessages } from 'react-intl';

import { Workflow, WORKFLOW_DND_TYPE } from '../../../framework/workflows';
import { registerToolItemForMimeType, TargetEnvironmentContext } from '../../../components/basic';
import { WorkflowsEnvironmentContext } from '../environment-contexts/workflows-environment-context';
import { computeWorkflowURL } from '../utils/compute-urls';


const messages = defineMessages({
    modify: {
        id: 'settings.workflows.actions.Open',
        defaultMessage: 'Open',
    },
});

async function openWorkflow(workflow: Workflow, environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>): Promise<void> {
    environmentContext.navigate(computeWorkflowURL(workflow.id));
}


export function registerOpenWorkflowActions() {
    registerToolItemForMimeType<WorkflowsEnvironmentContext, Workflow>(WORKFLOW_DND_TYPE, {
        path: 'open/open',
        label: messages.modify,
        onClick: async (_, environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>): Promise<void> => {
            const workflow = environmentContext.firstItem();
            if (!workflow) {
                return;
            }
            await openWorkflow(workflow, environmentContext);
        },
    });
}
