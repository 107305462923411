import React, { useCallback, useState } from 'react';

import {
    ArgGetItemDescription,
    ArgGetItemKey,
    ArgGetItemLabel,
    computeItemDescription,
    computeItemKey,
    computeItemLabel,
} from '../utils';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { ArgMessageRenderer } from '../arg-message-renderer/arg-message-renderer';
import { ArgIconRadio } from '../arg-radio/arg-icon-radio';
import { ArgSize } from '../types';

import './arg-choice-list.less';

export interface ArgChoiceListProps<T> {
    /**
     * An HTML class for the container's choice list component
     */
    className?: ClassValue;
    /**
     * An HTML class for the item
     */
    itemClassName?: ClassValue;
    /**
     * A class representing the current selected value
     */
    value?: T;
    /**
     * An array representing the current visible choice list
     */
    items: T[];
    /**
     * A callback when selection is changing
     */
    onChange: (item: T) => void;
    /**
     * The item key selected
     */
    getItemKey?: ArgGetItemKey<T>;
    /**
     * The item label selected
     */
    getItemLabel?: ArgGetItemLabel<T>;
    /**
     * The item description selected
     */
    getItemDescription?: ArgGetItemDescription<T>;
    /**
     * A boolean to render border for the container's choice list component
     */
    bordered?: boolean;
    /**
     * The size for the container's choice list component
     */
    size?: ArgSize;
    /**
     * A boolean to explicitly disable the container's choice list component
     */
    disabled?: boolean;
}

/**
 * This component is designed to display a choice list.
 *
 */
export function ArgChoiceList<T>(props: ArgChoiceListProps<T>) {
    const {
        itemClassName,
        className,
        value: externalValue,
        items,
        getItemKey, // NO undefined has a sense  // = 'key',
        getItemLabel = 'label',
        getItemDescription = 'description',
        onChange,
        bordered = true,
        size = 'small',
        disabled,
    } = props;

    const useInternalValue = !('value' in props);

    const [internalValue, setInternalValue] = useState<T>();

    const value = useInternalValue ? internalValue : externalValue;

    const classNames = useClassNames('arg-choice-list');

    const handleItemClick = useCallback((item: T) => {
        setInternalValue(item);
        onChange(item);
    }, [onChange]);

    const cls = {
        bordered,
        disabled,
    };

    const selectedItemKey = value && computeItemKey(value, getItemKey);

    return (
        <div className={classNames('&', className, cls)}>
            {items.map((item, index) => {
                const itemKey = computeItemKey(item, getItemKey, `index#${index}`);
                const itemLabel = computeItemLabel(item, getItemLabel);
                const itemDescription = computeItemDescription(item, getItemDescription);

                const isSelected = (itemKey === selectedItemKey);

                const itemCls = {
                    selected: isSelected,
                    bordered,
                    'with-description': itemDescription !== undefined,
                };

                return (
                    <button
                        key={itemKey}
                        className={classNames('&-item', itemCls, cls, itemClassName)}
                        onClick={() => handleItemClick(item)}
                        type='button'
                    >
                        <div className={classNames('&-item-left')}>
                            <div className={classNames('&-item-left-icon')}>
                                <ArgIconRadio
                                    className={classNames('&-item-left-icon-radio')}
                                    size={size}
                                    state={isSelected}
                                />
                            </div>
                        </div>
                        <div className={classNames('&-item-right')}>
                            <div className={classNames('&-item-right-title', { 'has-description': !!itemDescription })}>
                                <ArgMessageRenderer message={itemLabel} />
                            </div>
                            {itemDescription && (
                                <div className={classNames('&-item-right-description')}>
                                    <ArgMessageRenderer message={itemDescription} />
                                </div>
                            )}
                        </div>
                    </button>
                );
            })}
        </div>
    );
}
