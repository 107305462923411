import React, { ReactNode } from 'react';

import { ArgCheckbox, ArgCheckboxProps } from '../arg-checkbox/arg-checkbox';
import { useClassNames } from '../arg-hooks/use-classNames';
import { DEFAULT_SIZE } from '../defaults';
import { getDataTestIdFromProps } from '../utils';
import { ArgIconRadio } from './arg-icon-radio';

//import './arg-radio.less';

interface ArgRadioProps extends Omit<ArgCheckboxProps<boolean>, 'states' | 'icon'> {
    /**
     * The icon for the checkbox component (unused)
     */
    icon?: ReactNode; // Unused
}

/**
 * This component is designed to display a radio button.
 *
 * @example
 * ```
 *   <ArgRadio
 *   label="ArgRadio label"
 *   tooltip="ArgRadio tooltip"
 *   />
 * ```
 */
export function ArgRadio(props: ArgRadioProps) {
    const {
        size = DEFAULT_SIZE,
        className,
        id,
        label,
        messageValues,
        initialValue,
        value,
        children,
        buttonRef,
        disabled,
        onChange,
    } = props;

    const classNames = useClassNames('arg-radio');
    const dataTestId = getDataTestIdFromProps(props);

    return (
        <ArgCheckbox
            size={size}
            className={classNames('&', className)}
            id={id}
            label={label}
            messageValues={messageValues}
            initialValue={initialValue}
            value={value}
            // eslint-disable-next-line react/no-children-prop
            children={children}
            buttonRef={buttonRef}
            disabled={disabled}
            onChange={onChange}
            icon={<ArgIconRadio size={size} state={value} className={classNames('&-icon')} />}
            data-testid={dataTestId}
        />
    );
}
