import { WorkflowActivity, WorkflowActivityType } from '../model/workflow-activity';


export const workflowActivitiesMock: WorkflowActivity[] = [{
    key: 'create-ontology',
    argonosModuleId: 'chapsVision.Exploration',
    name: 'Create ontology',
    icon: 'icon-create',
    targets: ['chapsVision.Exploration:Ontology'],
    type: WorkflowActivityType.Trigger,

    outcomes: {
        ok: {
            label: 'Démarrer',
        },
    },
}, {
    key: 'waiting-response',
    argonosModuleId: 'chapsVision.Settings',
    name: 'En attente d\'approbation',
    icon: 'icon-valid',
    targets: ['chapsVision.Exploration:Ontology'],
    type: WorkflowActivityType.State,

    outcomes: {
        ok: {
            label: 'Validé',
        },
        ko: {
            label: 'Non validé',
        },
    },
    outputs: {
        valid: {
            type: 'boolean',
            outcomes: ['ok', 'ko'],
        },
    },
}, {
    key: 'send-notification',
    argonosModuleId: 'chapsVision.Settings',
    name: 'Envoyer d\'une notification',
    icon: 'icon-notification',
    targets: ['chapsVision.Exploration:Ontology'],
    type: WorkflowActivityType.Action,

    configuration: {
        message: {
            label: 'Validé',
            type: 'string',
            defaultValue: 'Groupe créé',
        },
    },
    outcomes: {
        sent: {
            label: 'Envoyé',
        },
    },
}, {
    key: 'start',
    argonosModuleId: 'chapsVision.Settings',
    name: 'Démarrer un autre workflow',
    icon: 'icon-start',
    targets: ['chapsVision.Exploration:Ontology'],
    type: WorkflowActivityType.Action,

    configuration: {
        workflowId: {
            label: 'Workflow',
            type: 'workflow',
            required: true,
        },
    },
},
];
