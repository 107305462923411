import Debug from 'debug';

import { RtBasicState, RtStateMessage } from '../../../utils/rt-states/rt-basic-state';
import { RtApi } from '../../../utils/rt-states/rt-api';
import { WatchCommands } from '../../../utils/rt-states/folders/folder-basic-state';
import { WebSocketChannel } from '../../../components/ws/websocket-connector';
import { getWorkflowsRtApi } from './rt-apis';
import { WORKFLOWS_EVENTS } from './events';

const debug = Debug('argonode:framework:workflows:WorkflowsState');

export type WorkflowsStateMessage = RtStateMessage;

const WATCH_COMMANDS: WatchCommands = {
    watch: 'Watch',
    unwatch: 'Unwatch',
    linkName: 'watch',
};

export class WorkflowsListState extends RtBasicState<WorkflowsStateMessage> {
    #deleted = false;
    #acceptedEvents = WORKFLOWS_EVENTS;

    constructor(url: string) {
        super(url);
    }

    protected getRtApi(): RtApi {
        return getWorkflowsRtApi();
    }

    protected get keepChannelOpened() {
        return true;
    }

    get deleted(): boolean {
        return this.#deleted;
    }

    protected isRtDisabled(): boolean {
        return true;
    }

    protected get watchCommands(): WatchCommands {
        return WATCH_COMMANDS;
    }

    protected processMessage = async (channel: WebSocketChannel<WorkflowsStateMessage>, type: string, message: WorkflowsStateMessage): Promise<boolean | undefined> => {
        if (!this.#acceptedEvents[message.type]) {
            // Ignore message
            return true;
        }

        const ret = this.processWorkflowsMessage(message);

        return ret;
    };

    protected processWorkflowsMessage(message: WorkflowsStateMessage): boolean {
        return false;
    }
}
