import { defineMessages, useIntl } from 'react-intl';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import {
    ArgCombo,
    ArgFormLabel,
    ArgInputCustomComponentProps,
    ArgInputText,
    ArgInputTextArea,
    ArgModal,
    ArgRenderedText,
    ClassValue,
    ProgressMonitor,
    useCallbackAsync,
    useClassNames,
} from 'src/components/basic';
import { Workflow } from '../../../framework/workflows';
import { getWorkflowTargetByType, listWorkflowTargets } from '../../../framework/workflows/registry/workflows-registry';
import { WorkflowTarget } from '../../../framework/workflows/registry/workflow-target';
import { WorkflowTargetType } from '../../../framework/workflows/components/workflow-target-type';

import './workflow-create-update-modal.less';

const CLASSNAME = 'workflow-create-modal';

const messages = defineMessages({
    createTitle: {
        id: 'settings.workflows.create-modal.CreateTitle',
        defaultMessage: 'Create workflow',
    },
    updateTitle: {
        id: 'settings.workflows.create-modal.UpdateTitle',
        defaultMessage: 'Update workflow',
    },
    createMainButton: {
        id: 'settings.workflows.create-modal.mainButton.create.Label',
        defaultMessage: 'Create',
    },
    updateMainButton: {
        id: 'settings.workflows.create-modal.mainButton.update.Label',
        defaultMessage: 'Update',
    },
    nameTitle: {
        id: 'settings.workflows.create-modal.name.Title',
        defaultMessage: 'Name',
    },
    namePlaceholder: {
        id: 'settings.workflows.create-modal.name.Placeholder',
        defaultMessage: 'Enter the name',
    },
    nameRequired: {
        id: 'settings.workflows.create-modal.name.Required',
        defaultMessage: 'Name is required',
    },
    targetTitle: {
        id: 'settings.workflows.create-modal.target.Title',
        defaultMessage: 'Target',
    },
    targetPlaceholder: {
        id: 'settings.workflows.create-modal.target.Placeholder',
        defaultMessage: 'Select a target',
    },
    targetRequired: {
        id: 'settings.workflows.create-modal.target.Required',
        defaultMessage: 'Target is required',
    },
    descriptionTitle: {
        id: 'settings.workflows.create-modal.description.Title',
        defaultMessage: 'Description',
    },
});

interface WorkflowCreateUpdateModalProps {
    className?: ClassValue;

    initialWorkflow?: Workflow;

    onOk: (value: Partial<Workflow>, initialWorkflow: Workflow | undefined, progressMonitor: ProgressMonitor) => Promise<boolean>;
    onClose: () => void;
}

export function WorkflowCreateUpdateModal(props: WorkflowCreateUpdateModalProps) {
    const {
        className,
        onClose,
        onOk,
        initialWorkflow,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const intl = useIntl();

    const [name, setName] = useState<string>(() => {
        return initialWorkflow?.name || '';
    });
    const [target, setTarget] = useState<WorkflowTarget | undefined>(() => {
        if (!initialWorkflow?.targetType) {
            return undefined;
        }

        const workflowTarget = getWorkflowTargetByType(initialWorkflow.targetType);

        return workflowTarget;
    });
    const [description, setDescription] = useState<string>(() => {
        return initialWorkflow?.description || '';
    });

    const [errors, setErrors] = useState<Record<string, ArgRenderedText>>({});

    const targets = useMemo<WorkflowTarget[]>(() => {
        const list = [...listWorkflowTargets()];

        const result = list.sort((w1: WorkflowTarget, w2: WorkflowTarget) => {
            const s1 = intl.formatMessage(w1.label);
            const s2 = intl.formatMessage(w2.label);

            return s1.localeCompare(s2);
        });

        return result;
    }, [intl]);

    const computeErrors = useCallback(() => {
        const errors: Record<string, ArgRenderedText> = {};

        if (!name) {
            errors.name = messages.nameRequired;
        }
        if (!target && !initialWorkflow) {
            errors.target = messages.targetRequired;
        }

        return errors;
    }, [name, target]);

    const [handleOk] = useCallbackAsync(async (progressMonitor: ProgressMonitor) => {
        const errors = computeErrors();
        setErrors(errors);
        if (!isEmpty(errors)) {
            return;
        }

        const workflow: Partial<Workflow> = {
            name,
            description,
            targetType: target?.targetType,
        };

        const result = await onOk(workflow, initialWorkflow, progressMonitor);
        if (!result) {
            return;
        }

        onClose();
    }, [computeErrors, description, name, onClose, onOk]);

    const handleItemKey = useCallback((workflowTarget: WorkflowTarget) => {
        return workflowTarget.targetType;
    }, []);

    const handleRenderItem = useCallback((workflowTarget: WorkflowTarget) => {
        return <WorkflowTargetType
            key={workflowTarget.targetType}
            workflowTarget={workflowTarget}
            showApplication={true}
        />;
    }, []);

    const handleRenderComboInput = useCallback((props: ArgInputCustomComponentProps<WorkflowTarget>): ReactNode => {
        if (!props.value) {
            return null;
        }

        return <WorkflowTargetType
            workflowTarget={props.value}
            showApplication={true}
            className={classNames('&-body-field-target-type')}
        />;
    }, [classNames]);

    return (
        <ArgModal
            className={classNames('&', className)}
            onClose={onClose}
            title={(initialWorkflow) ? messages.updateTitle : messages.createTitle}
            okText={(initialWorkflow) ? messages.updateMainButton : messages.createMainButton}
            onOk={handleOk}
        >
            <div className={classNames('&-body')}>
                <ArgFormLabel
                    required={true}
                    className={classNames('&-body-field')}
                    propertyName={messages.nameTitle}
                    errorMessage={errors.name}
                >
                    <ArgInputText
                        className={classNames('&-body-field-name')}
                        value={name}
                        onChange={(value) => setName(value || '')}
                        placeholder={messages.namePlaceholder}
                    />
                </ArgFormLabel>

                {!initialWorkflow && <ArgFormLabel
                    required={true}
                    className={classNames('&-body-field')}
                    propertyName={messages.targetTitle}
                    errorMessage={errors.target}
                >
                    <ArgCombo<WorkflowTarget>
                        className={classNames('&-body-field-target')}
                        placeholder={messages.targetPlaceholder}
                        value={target}
                        items={targets}
                        onChange={(value) => setTarget(value)}
                        renderInput={handleRenderComboInput}
                        getItemKey={handleItemKey}
                        renderItem={handleRenderItem}
                        readOnly={!!initialWorkflow}
                    />
                </ArgFormLabel>}

                <ArgFormLabel
                    className={classNames('&-body-field')}
                    propertyName={messages.descriptionTitle}
                >
                    <ArgInputTextArea
                        className={classNames('&-body-field-description')}
                        value={description}
                        onChange={(description) => setDescription(description || '')}
                    />
                </ArgFormLabel>
            </div>
        </ArgModal>
    );
}
