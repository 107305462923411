import { ReactNode } from 'react';
import { keyBy } from 'lodash';

import {
    FormButton,
    FormComposite,
    FormDocument,
    FormIFrame,
    FormImage,
    FormLabel,
    FormProperty,
    FormSection,
    FormTabs,
    FormUUID,
} from '../../../components/common/forms/model';
import { messages } from '../../../components/common/forms/messages';
import { FormTabsComponent } from '../../../components/common/forms/components/form-tabs-component';
import { FormRenderContext, FormRenderFactory } from '../../../components/common/forms/render-factory';
import { ArgMessageRenderer } from '../../../components/basic';
import { FormElementWithLabel } from 'src/components/common/forms/components/form-element-with-label';
import { OntologyFormProperty } from './components/ontology-form-property';
import { FormSectionCollapse } from 'src/components/common/forms/components/form-section-collapse';
import { FormCompositeComponent } from 'src/components/common/forms/components/form-composite-component';
import { FormFloatingComponent } from 'src/components/common/forms/components/form-floating-component';
import { FormImageComponent } from 'src/components/common/forms/components/form-image-component';
import { FormIframeComponent } from 'src/components/common/forms/components/form-iframe-component';
import { FormButtonComponent } from 'src/components/common/forms/components/form-button-component';
import { OntologyFormContext } from './types';
import { FormInteractionWrapper } from './components/form-interaction-wrapper';
import { FormDroppableContainer } from './components/form-droppable-container';

export function createRenderFactory(ontologyFormContext: OntologyFormContext): FormRenderFactory {
    const vertexPropertiesByName = keyBy(ontologyFormContext.vertexProperties, (property) => property.name);

    const renderFactory: FormRenderFactory = {
        button(formButton: FormButton, formContext: FormRenderContext): ReactNode {
            return (
                <FormInteractionWrapper key={formButton.id} formElement={formButton} formContext={formContext} ontologyContext={ontologyFormContext}>
                    <FormFloatingComponent element={formButton}>
                        <FormButtonComponent formButton={formButton} />
                    </FormFloatingComponent>
                </FormInteractionWrapper>
            );
        },
        iframe(formIFrame: FormIFrame, formContext: FormRenderContext): ReactNode {
            return (
                <FormInteractionWrapper key={formIFrame.id} formElement={formIFrame} formContext={formContext} ontologyContext={ontologyFormContext}>
                    <FormFloatingComponent element={formIFrame}>
                        <FormIframeComponent formIFrame={formIFrame} border={true} />
                    </FormFloatingComponent>
                </FormInteractionWrapper>
            );
        },
        image(formImage: FormImage, formContext: FormRenderContext): ReactNode {
            return (
                <FormInteractionWrapper key={formImage.id} formElement={formImage} formContext={formContext} ontologyContext={ontologyFormContext}>
                    <FormFloatingComponent element={formImage}>
                        <FormImageComponent formImage={formImage} />
                    </FormFloatingComponent>
                </FormInteractionWrapper>
            );
        },
        label(formLabel: FormLabel, formContext: FormRenderContext): ReactNode {
            const _label = formLabel.name || messages.labelName;

            return (
                <FormInteractionWrapper key={formLabel.id} formElement={formLabel} formContext={formContext} ontologyContext={ontologyFormContext}>
                    <FormFloatingComponent element={formLabel}>
                        <label>
                            <ArgMessageRenderer message={_label} />
                        </label>
                    </FormFloatingComponent>
                </FormInteractionWrapper>
            );
        },
        uuid(formUUID: FormUUID, formContext: FormRenderContext): ReactNode {
            return (
                <FormInteractionWrapper key={formUUID.id} formElement={formUUID} formContext={formContext} ontologyContext={ontologyFormContext}>
                    <FormElementWithLabel name={formUUID.name || messages.uuid}>
                        aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee
                    </FormElementWithLabel>
                </FormInteractionWrapper>
            );
        },
        property(formProperty: FormProperty, formContext: FormRenderContext): ReactNode {
            const propertyType = vertexPropertiesByName[formProperty.propertyName];

            if (!propertyType) {
                console.error('Ignore unknown ontology property', formProperty.propertyName);

                return null;
            }

            return (
                <FormInteractionWrapper key={formProperty.id} formElement={formProperty} formContext={formContext} ontologyContext={ontologyFormContext}>
                    <FormElementWithLabel
                        name={formProperty.name || propertyType.displayName}
                        disableHover={true}
                    >
                        <OntologyFormProperty
                            propertyType={propertyType.type}
                            isMandatory={propertyType.isMandatory}
                        />
                    </FormElementWithLabel>
                </FormInteractionWrapper>
            );
        },
        section(formSection: FormSection, formContext: FormRenderContext, children: ReactNode[]): ReactNode {
            return (
                <FormInteractionWrapper key={formSection.id} formElement={formSection} formContext={formContext} ontologyContext={ontologyFormContext}>
                    <FormSectionCollapse formSection={formSection}>
                        <FormDroppableContainer formContainer={formSection} formContext={formContext} ontologyContext={ontologyFormContext}>
                            {children}
                        </FormDroppableContainer>
                    </FormSectionCollapse>
                </FormInteractionWrapper>
            );
        },
        document(formDocument: FormDocument, context: FormRenderContext, children: ReactNode[]): ReactNode {
            return (
                <FormDroppableContainer
                    key={formDocument.id}
                    formContext={context}
                    ontologyContext={ontologyFormContext}
                    formContainer={formDocument}
                >
                    {children}
                </FormDroppableContainer>
            );
        },
        tabs(formTabs: FormTabs, context: FormRenderContext, childrenByTab: Record<string, ReactNode[]>): ReactNode {
            return (
                <FormTabsComponent
                    key={formTabs.id}
                    formTabs={formTabs}
                    childrenByTab={childrenByTab}
                    formRenderContext={context}
                    wrapChildren={(children, formTab) => (
                        <FormDroppableContainer formContainer={formTab} formContext={context} ontologyContext={ontologyFormContext}>
                            {children}
                        </FormDroppableContainer>
                    )}
                />
            );
        },
        composite(formComposite: FormComposite, formContext: FormRenderContext): ReactNode {
            return (
                <FormInteractionWrapper key={formComposite.id} formElement={formComposite} formContext={formContext} ontologyContext={ontologyFormContext}>
                    <FormCompositeComponent
                        formComposite={formComposite}
                        vertexPropertiesByName={vertexPropertiesByName}
                        renderProperty={(item, type) => (
                            <OntologyFormProperty
                                key={`composite-${item.id}`}
                                propertyType={type.type}
                                isMandatory={type.isMandatory}
                            />
                        )}
                    />
                </FormInteractionWrapper>
            );
        },
    };

    return renderFactory;
}
