import { WorkflowTarget, WorkflowTargetType } from './workflow-target';

const workflowTargets: Record<WorkflowTargetType, WorkflowTarget> = {};

export function registerWorkflowTarget(workflowTarget: WorkflowTarget) {
    if (workflowTargets[workflowTarget.targetType]) {
        throw new Error(`Workflow target with type ${workflowTarget.targetType} already exists`);
    }

    workflowTargets[workflowTarget.targetType] = workflowTarget;
}

export function listWorkflowTargets(): WorkflowTarget[] {
    return Object.values(workflowTargets);
}

export function getWorkflowTargetByType(workflowTargetType: WorkflowTargetType): WorkflowTarget | undefined {
    const result = workflowTargets[workflowTargetType];

    return result;
}

