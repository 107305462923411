import React, { useCallback, useMemo, useState } from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import * as uuid from 'uuid';

import {
    ARG_BYPASS_DND_DISABLER_CLASSNAME,
    ArgButton,
    ArgFormLabel,
    ArgInputSearch,
    ArgInputText,
    ArgInputTextArea,
    ArgMenu,
    ArgMenuItem,
    ArgMessageRenderer,
    ArgModal,
    ArgOrderedColumn,
    ArgOrderedList,
    ArgTag,
    ArgUploaderButton,
    SelectionProvider,
    useArgModalContext,
    useArgNotifications,
    useClassNames,
    useSelection,
    useSharedSelectionProvider,
} from 'src/components/basic';
import { ReferenceItem } from 'src/settings/models/references';
import { useCreateReference } from 'src/settings/reference/hooks/use-create-reference';
import { ConfirmModal } from '../../../components/common/modal2/confirm-modal/confirm-modal';
import { stringSorter } from '../../../utils/sorter';

import './manager-reference-modal.less';

const CLASSNAME = 'settings-manager-reference-modal';

const REFERENCE_ITEM_DND_TYPE = 'application/arg-reference-item';
const SELECTION_SOURCE = 'manager-reference-modal-source';

const messages = defineMessages({
    referenceTableCreationModalTitle: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalTitle',
        defaultMessage: 'Create a reference',
    },
    referenceTableCreationModalEditTitle: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalEditTitle',
        defaultMessage: 'Edit a reference',
    },
    referenceTableCreationModalOkText: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalOkText',
        defaultMessage: 'Create',
    },
    referenceTableCreationModalEditOkText: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalEditOkText',
        defaultMessage: 'Edit',
    },
    referenceTableCreationModalNameFieldLabel: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalNameFieldLabel',
        defaultMessage: 'Reference name',
    },
    referenceTableCreationModalNameFieldDescription: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalNameFieldDescription',
        defaultMessage: 'Reference name must be unique',
    },
    referenceTableCreationModalDescriptionLabel: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalDescriptionLabel',
        defaultMessage: 'Reference description',
    },
    referenceTableCreationModalValuesListLabel: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalValuesListLabel',
        defaultMessage: 'Values list',
    },
    referenceTableCreationModalFileInitLabel: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalFileInitLabel',
        defaultMessage: 'Initialize with a CSV file',
    },
    referenceTableCreationModalFileConfirmTitle: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalFileConfirmTitle',
        defaultMessage: 'Import values list from CSV file',
    },
    referenceTableCreationModalFileConfirmDescription: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalFileColumnsDescription',
        defaultMessage: 'The imported list will reset the current list',
    },
    referenceTableCreationModalFileColumnsError: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalFileColumnsError',
        defaultMessage: 'The import CSV must have at least one column for the key and one for the value',
    },
    referenceTableCreationModalFileEmptyError: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalFileEmptyError',
        defaultMessage: 'The CSV does not contain any data',
    },
    referenceTableCreationModalSelectAll: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalSelectAll',
        defaultMessage: 'Select all',
    },
    referenceTableCreationModalUnselectAll: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalUnselectAll',
        defaultMessage: 'Unselect all',
    },
    referenceTableEmptyRowsMessage: {
        id: 'settings.references.manager-reference-modal.ReferenceTableEmptyRowsMessage',
        defaultMessage: 'Click the "<icon>icon-add-outline</icon> Row" button below to insert a new row',
    },
    referenceTableErrorsTitle: {
        id: 'settings.references.manager-reference-modal.ReferenceTableErrorsTitle',
        defaultMessage: 'Please correct the following errors:',
    },
    referenceTableCreationModalAddRowButtonLabel: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalAddRowButtonLabel',
        defaultMessage: 'Row',
    },
    referenceTableCreationModalKeyColumnLabel: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalKeyColumnLabel',
        defaultMessage: 'Key',
    },
    referenceTableCreationModalValueColumnLabel: {
        id: 'settings.references.manager-reference-modal.ReferenceTableCreationModalValueColumnLabel',
        defaultMessage: 'Value',
    },
    delete: {
        id: 'settings.references.manager-reference-modal.Delete',
        defaultMessage: 'Delete',
    },
    actions: {
        id: 'settings.references.manager-reference-modal.Actions',
        defaultMessage: 'Actions',
    },
});


const INITIAL_ROWS: ReferenceItem[] = [];

interface ManagerReferenceModalProps {
    onClose: () => void;
    onSubmit?: () => void;
    initialReferenceName?: string;
    initialReferenceItems?: ReferenceItem[];
}

export function ManagerReferenceModal(props: ManagerReferenceModalProps) {
    const {
        onClose,
        onSubmit,
        initialReferenceName,
        initialReferenceItems = INITIAL_ROWS,
    } = props;

    const classNames = useClassNames(CLASSNAME);
    const modalContext = useArgModalContext();
    const notifications = useArgNotifications();

    const [searchTerm, setSearchTerm] = useState('');
    const [importedCsvFile, setImportedCsvFile] = useState<File>();

    const isEditingMode = !!initialReferenceName;

    const selectionProvider = useSharedSelectionProvider<ReferenceItem>(() => {
        return new SelectionProvider<ReferenceItem>('manager-reference-modal', (item: ReferenceItem) => {
            if (item.id !== undefined) {
                return item.id;
            }

            throw new Error('Invalid object');
        });
    });

    useSelection(selectionProvider);

    const onSubmitReferenceTable = useCallback(() => {
        onSubmit?.();
        onClose?.();
    }, [onClose, onSubmit]);

    const {
        tableName,
        setTableName,
        tableDescription,
        setTableDescription,
        rows,
        addRow,
        initRows,
        updateRow,
        moveRow,
        deleteRow,
        deleteRows,
        createReferenceTable,
        createReferenceTableProgressMonitor,
        isValid,
        errors,
    } = useCreateReference({
        selectionProvider,
        onCreate: onSubmitReferenceTable,
        initialRows: initialReferenceItems,
        initialName: initialReferenceName,
    });

    const columns = useMemo<ArgOrderedColumn<ReferenceItem>[]>(() => {
        const ret: ArgOrderedColumn<ReferenceItem>[] = [
            {
                key: 'key',
                title: messages.referenceTableCreationModalKeyColumnLabel,
                titleTooltip: true,
                width: '35%',
                getCellStringValue: (item) => item.key,
                sorter: (a, b) => stringSorter<ReferenceItem>(a, b, item => item.key),
                render: (key, referenceItem, index) => {
                    const hasDuplicate = rows.find((row, rowIndex) => {
                        return row.key === referenceItem.key && rowIndex !== index;
                    });

                    return (
                        <ArgInputText
                            state={hasDuplicate ? 'invalid' : undefined}
                            value={referenceItem.key}
                            className={classNames('&-key-field')}
                            onChange={value => {
                                updateRow(index!, 'key', value || '');
                            }}
                        />
                    );
                },
            },
            {
                key: 'value',
                title: messages.referenceTableCreationModalValueColumnLabel,
                titleTooltip: true,
                width: '65%',
                getCellStringValue: (item) => item.value,
                sorter: (a, b) => stringSorter<ReferenceItem>(a, b, item => item.value),
                render: (_, referenceItem, index) => {
                    return (
                        <div className={classNames('&-value')}>
                            <ArgInputText
                                value={referenceItem.value}
                                className={classNames('&-value-field')}
                                onChange={value => {
                                    updateRow(index!, 'value', value || '');
                                }}
                            />
                        </div>
                    );
                },
            },
            {
                key: 'actions',
                minWidth: 36,
                width: 36,
                render: (_, referenceItem, index) => {
                    return (
                        <ArgButton
                            icon='icon-trash'
                            type='ghost'
                            onClick={() => deleteRow(index!)}
                        />
                    );
                },
            },
        ];

        return ret;
    }, [classNames, deleteRow, updateRow, rows]);

    const okButtonTooltip = useMemo(() => (errors.length > 0 ? <ErrorTooltip errors={errors} /> : undefined), [errors]);

    const handleSelectAll = useCallback(() => {
        selectionProvider.set(rows, SELECTION_SOURCE);
    }, [rows, selectionProvider]);

    const handleUnSelectAll = useCallback(() => {
        selectionProvider.clear(SELECTION_SOURCE);
    }, [selectionProvider]);

    const checkImportedCsvFile = useCallback(async (files: File[]) => {
        if (files.length !== 1) {
            return;
        }

        const csvFile = files[0];

        const fileData = await csvFile.text();

        if (!fileData.length) {
            notifications.snackError({ message: messages.referenceTableCreationModalFileEmptyError });

            return;
        }

        const fileRows = fileData.split('\n');

        if (fileRows.length === 1) {
            notifications.snackError({ message: messages.referenceTableCreationModalFileEmptyError });

            return;
        }

        const headers = fileRows[0];
        const headersCells = headers.split(',');

        if (headersCells.length === 1) {
            notifications.snackError({ message: messages.referenceTableCreationModalFileColumnsError });

            return;
        }

        const rowsData = fileRows.slice(1);

        const referenceItems: ReferenceItem[] = rowsData.reduce<ReferenceItem[]>((acc, row) => {
            const id = uuid.v4();

            const [key, value] = row.split(',');

            acc.push({ id, key, value });

            return acc;
        }, []);

        if (rows.length > 0) {
            modalContext.open('confirm-init-rows', (
                <ConfirmModal
                    type='confirm'
                    title={messages.referenceTableCreationModalFileConfirmTitle}
                    alertMessage={messages.referenceTableCreationModalFileConfirmDescription}
                    onClose={() => modalContext.close('confirm-init-rows')}
                    onConfirm={async () => {
                        setImportedCsvFile(csvFile);
                        initRows(referenceItems);
                    }}
                />
            ));

            return;
        }

        setImportedCsvFile(csvFile);
        initRows(referenceItems);
    }, [initRows, modalContext, notifications, rows]);

    const handleDeleteMultipleValues = useCallback(() => {
        const deleteValuesIndexes = selectionProvider.list().reduce<number[]>((acc, valueId: string) => {
            const index = rows.findIndex((row) => row.id === valueId);

            if (index === undefined) {
                return acc;
            }

            acc.push(index);

            return acc;
        }, []);

        deleteRows(deleteValuesIndexes);
    }, [deleteRows, rows, selectionProvider]);

    const handleResetRows = useCallback(() => {
        const rowIndexes = rows.map((_, rowIndex) => rowIndex);

        deleteRows(rowIndexes);
        setImportedCsvFile(undefined);
    }, [deleteRows, rows]);

    const renderActionMenu = useCallback(() => {
        return (
            <ArgMenu>
                <ArgMenuItem label={messages.delete} onClick={handleDeleteMultipleValues} />
            </ArgMenu>
        );
    }, [handleDeleteMultipleValues]);

    return (
        <ArgModal
            size='large'
            progressMonitor={createReferenceTableProgressMonitor}
            title={isEditingMode ? messages.referenceTableCreationModalEditTitle : messages.referenceTableCreationModalTitle}
            okText={isEditingMode ? messages.referenceTableCreationModalEditOkText : messages.referenceTableCreationModalOkText}
            okDisabled={!isValid}
            okButtonTooltip={okButtonTooltip}
            onCancel={onClose}
            onClose={onClose}
            onOk={createReferenceTable}
            className={classNames('&')}
        >
            <div className={classNames('&-content', ARG_BYPASS_DND_DISABLER_CLASSNAME)}>
                <div className={classNames('&-content-header')}>
                    <ArgFormLabel
                        className={classNames('&-name-field')}
                        required={true}
                        propertyName={messages.referenceTableCreationModalNameFieldLabel}
                        size='medium'
                    >
                        <ArgInputText
                            autoFocus={true}
                            value={tableName}
                            onChange={value => setTableName(value || '')}
                            placeholder={messages.referenceTableCreationModalNameFieldLabel}
                        />
                        <ArgMessageRenderer
                            size='small'
                            className={classNames('&-name-field-description')}
                            message={messages.referenceTableCreationModalNameFieldDescription}
                        />
                    </ArgFormLabel>
                    <ArgFormLabel
                        size='medium'
                        className={classNames('&-description')}
                        propertyName={messages.referenceTableCreationModalDescriptionLabel}
                    >
                        <ArgInputTextArea
                            rows={4}
                            size='small'
                            value={tableDescription}
                            onChange={value => setTableDescription(value || '')}
                            placeholder={messages.referenceTableCreationModalDescriptionLabel}
                        />
                    </ArgFormLabel>
                </div>
                <div className={classNames('&-list-container')}>
                    <ArgMessageRenderer
                        size='medium'
                        className={classNames('&-list-label')}
                        message={messages.referenceTableCreationModalValuesListLabel}
                    />
                    <div className={classNames('&-list-controls')}>
                        <ArgInputSearch
                            value={searchTerm}
                            className={classNames('&-list-controls-search')}
                            onChange={value => setSearchTerm(value || '')}
                        />

                        <ArgButton
                            type='secondary'
                            right='dropdown'
                            label={messages.actions}
                            disabled={!selectionProvider.count}
                            popover={renderActionMenu}
                            popoverPlacement='bottomLeft'
                        />

                        {importedCsvFile ? (
                            <ArgTag
                                size='medium'
                                closable={true}
                                iconColor='primary'
                                icon='icon-csv-export'
                                backgroundColor='primary'
                                label={importedCsvFile.name}
                                onClose={handleResetRows}
                            />
                        ) : (
                            <ArgUploaderButton
                                type='ghost'
                                size='large'
                                acceptedFiles='.csv'
                                onChange={checkImportedCsvFile}
                                icon='icon-csv-export'
                                label={messages.referenceTableCreationModalFileInitLabel}
                                className={classNames('&-list-controls-init')}
                            />
                        )}
                    </div>

                    <div className={classNames('&-add-row-button')}>
                        <ArgButton
                            type='link'
                            size='medium'
                            icon='icon-plus'
                            label={messages.referenceTableCreationModalAddRowButtonLabel}
                            onClick={addRow}
                        />
                    </div>

                    <div className={classNames('&-list-controls-selection-tools')}>
                        <ArgButton
                            type='ghost'
                            size='small'
                            disabled={rows.length == 0 || selectionProvider.count === rows.length}
                            label={messages.referenceTableCreationModalSelectAll}
                            className={classNames('&-list-controls-selection-tools-item')}
                            onClick={handleSelectAll}
                        />
                        |
                        <ArgButton
                            type='ghost'
                            size='small'
                            disabled={!rows.length || !selectionProvider.count}
                            label={messages.referenceTableCreationModalUnselectAll}
                            className={classNames('&-list-controls-selection-tools-item')}
                            onClick={handleUnSelectAll}
                        />
                    </div>

                    <ArgOrderedList<ReferenceItem>
                        rows={rows}
                        getRowKey={(item) => item.id}
                        columns={columns}
                        selectionProvider={selectionProvider}
                        selectionSource={SELECTION_SOURCE}
                        dragType={REFERENCE_ITEM_DND_TYPE}
                        className={classNames('&-list-items')}
                        onMoveItem={moveRow}
                        emptyRenderer={() => (
                            <ArgMessageRenderer
                                className={classNames('&-add-row-message')}
                                message={messages.referenceTableEmptyRowsMessage}
                            />
                        )}
                    />
                </div>
            </div>
        </ArgModal>
    );
}

const ERROR_TOOLTIP_CLASSNAME = `${CLASSNAME}-error-tooltip`;

function ErrorTooltip({ errors }: { errors: MessageDescriptor[] }) {
    const classNames = useClassNames(ERROR_TOOLTIP_CLASSNAME);

    return <div className={classNames('&')}>
        <h4 className={classNames('&-header')}>
            <ArgMessageRenderer message={messages.referenceTableErrorsTitle} />
        </h4>
        <ul className={classNames('&-content')}>
            {errors.map(error => (
                <li className={classNames('&-item')} key={error.id}>
                    <ArgMessageRenderer message={error} />
                </li>
            ))}
        </ul>
    </div>;
}
