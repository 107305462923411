import { WorkflowId } from '../../../framework/workflows';
import { SETTINGS_ROUTE_PATH } from '../../settings-router';


export function computeWorkflowsURL(): string {
    return `${SETTINGS_ROUTE_PATH}/workflows`;
}

export function computeWorkflowURL(workflowId: WorkflowId): string {
    return `${SETTINGS_ROUTE_PATH}/workflows/${encodeURIComponent(workflowId)}`;
}
