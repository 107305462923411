import { useContext } from 'react';
import Debug from 'debug';

import { ArgPanelsConfiguration, ArgToolbarLayoutProps, useToolbarPanelsConfiguration } from '../../basic';
import { DEFAULT_PANELS_CONFIGURATION } from './default-panels-configuration';
import { UserConfigurationsContext } from '../../../contexts/user-configurations';

const debug = Debug('common:use-toolbar-panels-configuration-for-user');

export function useToolbarPanelsConfigurationForUser(
    panelsConfiguration: ArgPanelsConfiguration = DEFAULT_PANELS_CONFIGURATION,
): Partial<ArgToolbarLayoutProps> {
    const configurations = useContext(UserConfigurationsContext);

    if (!configurations) {
        throw new Error('No user configuration context');
    }

    const ret = useToolbarPanelsConfiguration(configurations, panelsConfiguration);

    return ret;
}
