import { RtMessageEventList } from '../../../utils/rt-states/events';

export const WORKFLOW_EVENTS: RtMessageEventList = {
    'WorkflowUpdated': true,
};

export const WORKFLOW_DEFINITION_EVENTS: RtMessageEventList = {
    'WorkflowDefinitionUpdated': true,
};


export const WORKFLOWS_EVENTS: RtMessageEventList = {
    'WorkflowsCreated': true,
    'WorkflowsUpdated': true,
    'WorkflowsDeleted': true,
};


export const WORKFLOWS_ALL_EVENTS = {
    ...WORKFLOW_EVENTS,
    ...WORKFLOWS_EVENTS,
};

