export enum CasePieceType {
    Exploration = 0,
    Brief = 1,
    //Screenshot = 2,
    Form = 3,
    FlaggedObject = 4,
    Resource = 5,
    Dashboard = 6,
    Chart = 7,
    DataSource = 8,
    Collection = 9,
    Search = 10,
    FastSearch = 30,
    AdvancedSearch = 31,
    MassiveSearch = 32,

    Folder = 100,

    Processus = 200,
    Component = 201,
    Secret = 202,

    Act = 300,
    Person = 301,
    Offence = 302,
    Service = 303,

    Workflow = 400,

    Unknown = 999,
}
