import { find, isArray, isNil, keyBy, mapValues, template } from 'lodash';
import { CSSProperties, ReactNode } from 'react';
import { utc } from 'dayjs';
import { IntlShape } from 'react-intl';

import { ArgButton, ProgressMonitor } from 'src/components/basic';
import { highlightSplit } from 'src/components/basic/utils';
import { getCountryByCodeOrName } from 'src/utils/countries/countries-utils';
import { Environment } from 'src/utils/environment';
import {
    PropertyValueInfos,
    SystemPropertyTypeNames,
    UniverseId,
    UniversePropertyName,
    UniversePropertyType,
    UniversePropertyTypes,
    UniverseVertexTypeName,
    VertexOrEdgeProperties,
} from '../model/universe';
import { CountryComponent } from '../../components/common/countries/country';
import { ExplorationId } from '../model/exploration';
import { FacetsRequest } from './connector/types';
import { UniverseConnector } from './connector/universe-connector';
import { PropertyBounds } from '../../components/common/graph/customisation/use-get-property-bounds';
import { getVertexControlType } from './vertex-utils';
import { AnnotationDescription } from '../features/tag/annotation-description';

import './property-utils.less';


const USE_COUNTRY_COMPONENT = true;

const URL_TEMPLATE = {
    interpolate: /{([\s\S]+?)}/g,
};

export class PropertyUtils {
    static generatePropertyValue(
        propertyName: UniverseVertexTypeName,
        propertyValueIndex: number,
        schemaProperties: UniversePropertyType[],
        intl: IntlShape,
        vertexOrEdgeProperty: PropertyValueInfos[] | VertexOrEdgeProperties,
        customProtocolSupport?: boolean,
        searchedToken?: string,
    ): ReactNode {
        const property = find(schemaProperties, { name: propertyName });
        if (!property) {
            return;
        }

        const rawPropertyValues: PropertyValueInfos[] = isArray(vertexOrEdgeProperty) ? vertexOrEdgeProperty : vertexOrEdgeProperty[propertyName];
        if (!rawPropertyValues?.[propertyValueIndex]) {
            return;
        }

        const propertyValue = rawPropertyValues[propertyValueIndex].value;
        if (propertyValue === null || propertyValue === undefined) {
            return;
        }

        function decorateText(text: string) {
            if (!text || !searchedToken) {
                return text;
            }

            const highlighted = highlightSplit(text, searchedToken);

            return highlighted;
        }

        if (customProtocolSupport) {
            const linkValues = PropertyUtils.computeLinkPropertiesValues(propertyName, schemaProperties, rawPropertyValues);

            if (property.type === UniversePropertyTypes.Address) {
                const coordinates = linkValues.Coordinates;
                if (coordinates?.value) {
                    const ret = PropertyUtils.renderCustomProtocol(
                        coordinates.type,
                        coordinates.value,
                        intl,
                        property,
                        propertyValue,
                        decorateText,
                    );
                    if (ret !== undefined) {
                        return ret;
                    }
                }
            }

            const ret = PropertyUtils.renderCustomProtocol(
                property,
                propertyValue,
                intl,
                undefined,
                undefined,
                decorateText,
            );
            if (ret !== undefined) {
                return ret;
            }
        }

        let text = getVertexControlType(property)?.toText(propertyValue, intl);

        if (!text) {
            return;
        }

        switch (property.type) {
            case UniversePropertyTypes.Text:
            case UniversePropertyTypes.String:
            case UniversePropertyTypes.Address:
                text = text.replace(/([,.;])/g, (x: string) => `${x}\u200B`);
                break;
        }

        const ret = decorateText(text);

        return ret;
    }

    static getPropertiesByName = (properties?: UniversePropertyType[]) => {
        return keyBy(properties, property => property.name);
    };

    private static computeLinkPropertiesValues(
        propertyName: string,
        schemaProperties: UniversePropertyType[],
        vertexOrEdgeProperty?: PropertyValueInfos[],
    ): Record<string, { type: UniversePropertyType; value: any }> {
        const linkedProperties = schemaProperties.find((p) => p.name === propertyName)?.linkedProperties;
        if (!linkedProperties) {
            return {};
        }

        const linkedValues = mapValues(linkedProperties, (propertyName) => {
            return {
                type: schemaProperties.find((p) => p.name === propertyName)!,
                value: vertexOrEdgeProperty,
            };
        });

        return linkedValues;
    }

    private static renderCustomProtocol(
        property: UniversePropertyType,
        propertyValue: any,
        intl: IntlShape,
        originalProperty?: UniversePropertyType,
        originalValue?: any,
        decorateText?: (text: string) => ReactNode,
    ): ReactNode | undefined {
        if (!decorateText) {
            decorateText = (text: string) => text;
        }

        const buttonStyle: CSSProperties = {
            fontSize: 'inherit',
            fontWeight: 'inherit',
        };

        switch (property.type) {
            case UniversePropertyTypes.Email:
                return <ArgButton
                    type='link'
                    onClick={() => window.location.href = `mailto:${propertyValue}`}
                    label={decorateText(originalValue || propertyValue)}
                    style={buttonStyle}
                    className='underline'
                />;

            case UniversePropertyTypes.Url:
                return <ArgButton
                    type='link'
                    onClick={() => window.open(propertyValue)}
                    label={decorateText(originalValue || propertyValue)}
                    style={buttonStyle}
                    className='underline'
                />;

            case UniversePropertyTypes.PhoneNumber:
                return <ArgButton
                    type='link'
                    onClick={() => window.location.href = `tel:${propertyValue}`}
                    label={decorateText(originalValue || propertyValue)}
                    style={buttonStyle}
                    className='underline'
                />;

            case UniversePropertyTypes.Address: {
                const mapURL = Environment.addressMapExternalURL;
                if (!mapURL || mapURL === 'none') {
                    return decorateText(originalValue || propertyValue);
                }

                // Replace ${address} token in the template
                const url = template(mapURL, URL_TEMPLATE)({ address: propertyValue });

                return <ArgButton
                    type='link'
                    onClick={() => window.open(url)}
                    label={decorateText(originalValue || propertyValue)}
                    style={buttonStyle}
                    className='underline'
                />;
            }

            case UniversePropertyTypes.Country: {
                const country = getCountryByCodeOrName(propertyValue);
                if (!country) {
                    return decorateText(originalValue || propertyValue);
                }

                if (USE_COUNTRY_COMPONENT) {
                    return <>
                        <CountryComponent
                            country={country}
                            flag={true}
                            className='exploration-property-utils-country'
                        />
                        {decorateText(originalValue || propertyValue)}
                    </>;
                }

                return <>
                    <span className={`fi fi-${country.code.toLowerCase()}`}
                          style={{ marginRight: 8 }}>

                    </span>
                    {decorateText(originalValue || propertyValue)}
                </>;
            }

            case UniversePropertyTypes.Coordinates: {
                const coords = getVertexControlType(originalProperty || property)?.toText(
                    originalValue || propertyValue,
                    intl,
                );

                if (!coords) {
                    return undefined;
                }

                const mapURL = Environment.latlongMapExternalURL;
                if (!mapURL || mapURL === 'none') {
                    return coords;
                }

                // Replace tokens with the lodash template (${latitude} and ${longitude})
                const href = template(mapURL, URL_TEMPLATE)(propertyValue);

                return (
                    <a href={href} target='_blank' rel='noopenner noreferrer'>
                        {coords}
                    </a>
                );
            }

            case UniversePropertyTypes.AnnotationArea: {
                const val = JSON.parse(propertyValue);
                const clientDataTag = JSON.parse(val.clientData);

                if (!clientDataTag) {
                    return null;
                }

                return (
                    <div className='exploration-property-utils-annotation'>
                        <AnnotationDescription
                            tagProperties={clientDataTag.tagProperties}
                            objectProperty={clientDataTag.vertexProperty}
                        />
                    </div>
                );
            }
        }
    }

    public static async getGradientBounds(
        property: string,
        progressMonitor: ProgressMonitor,
        type: 'date' | 'number',
        label: string,
        facetType: 'Vertices' | 'Edges',
        explorationId: ExplorationId,
        universeId: UniverseId,
    ): Promise<PropertyBounds<number | Date> | undefined> {
        try {
            const requestBody: FacetsRequest = {
                facetType: facetType,
                properties: [property],
                filter: {
                    filterGroups: [{
                        type: {
                            included: [label],
                        },
                        exploration: explorationId ? [{ included: [explorationId] }] : undefined,
                    }],
                },
                options: {
                    includeValueSpreads: true,
                    groupDatesByDay: false,
                    numberOfValueSpreadRanges: 1,
                },
            };
            const result = await UniverseConnector.getInstance().getVisualizationFacets(
                universeId,
                requestBody,
                progressMonitor,
            );

            const resultBounds = result?.facets?.[0];

            if (type === 'date') {
                const leftBound = !isNil(resultBounds?.minValue) ? utc(resultBounds?.minValue) : undefined;
                const rightBound = !isNil(resultBounds?.minValue) ? utc(resultBounds?.maxValue) : undefined;

                if (leftBound === undefined || rightBound === undefined || leftBound === rightBound) {
                    return undefined;
                }

                return {
                    leftBound: !isNil(resultBounds?.minValue) ? utc(resultBounds?.minValue) : undefined,
                    rightBound: !isNil(resultBounds?.minValue) ? utc(resultBounds?.maxValue) : undefined,
                } as PropertyBounds<Date>;
            }

            const leftBound = !isNil(resultBounds?.minValue) ? resultBounds?.minValue : undefined;
            const rightBound = !isNil(resultBounds?.minValue) ? resultBounds?.maxValue : undefined;

            if (leftBound === undefined || rightBound === undefined || leftBound === rightBound) {
                return undefined;
            }

            return {
                leftBound: !isNil(resultBounds?.minValue) ? resultBounds?.minValue : undefined,
                rightBound: !isNil(resultBounds?.maxValue) ? resultBounds?.maxValue : undefined,
            } as PropertyBounds<number>;
        } catch (error) {
            if (progressMonitor?.isCancelled) {
                return;
            }
            console.error(error);
        } finally {
            progressMonitor?.done();
        }
    }

    // Returns true if the property type matches one of the flagged system property name. If iterating over all of one vertex properties, consider using VertexUtils.getProperties.
    static isSystemTypedPropertyType(propertyType: UniversePropertyName): boolean {
        return SystemPropertyTypeNames[propertyType];
    }
}
