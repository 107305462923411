import { useState } from 'react';
import { defineMessages } from 'react-intl';

import { useArgNotifications, useEffectAsync, useCallbackAsync, ProgressMonitor, ArgRenderedText } from 'src/components/basic';
import ReferencesConnector from 'src/settings/connectors/references-connector';
import { Reference } from 'src/settings/models/references';

const messages = defineMessages({
    referenceTableFetchError: {
        id: 'settings.references.use-get-references.ReferenceTableFetchError',
        defaultMessage: 'Failed to load references!',
    },
    referenceTableUpdateError: {
        id: 'settings.references.use-get-references.ReferenceTableUpdateError',
        defaultMessage: 'Failed to update references!',
    },
});

/**
 * Fetches reference tables from the server.
 *
 * @param progressMonitor - Monitor to track the progress of the fetch operation
 * @param notifications - Notifications service to display errors
 * @param errorMessage - Error message to display if the fetch fails
 * @returns Promise resolving to an array of Reference
 * @throws Will throw an error if the fetch fails and the operation wasn't cancelled
 */
const fetchReferenceTables = async (
    progressMonitor: ProgressMonitor,
    notifications: ReturnType<typeof useArgNotifications>,
    errorMessage: ArgRenderedText,
): Promise<Reference[]> => {
    try {
        const referenceTables = await ReferencesConnector().getReferences(progressMonitor);

        return referenceTables;
    } catch (error) {
        if (progressMonitor.isCancelled) {
            throw error;
        }
        notifications.snackError(
            { message: errorMessage },
            error as Error,
        );
        throw error;
    }
};

/**
 * Custom hook for managing reference tables.
 *
 * @returns An object containing:
 *   - referenceTables: An array of Reference
 *   - updateReferenceTables: A function to manually trigger an update of the reference tables
 *   - referenceTablesProgressMonitor: A ProgressMonitor for tracking the fetch operation
 */
export const useGetReferences = () => {
    const [referenceTables, setReferenceTables] = useState<Reference[]>(() => []);
    const notifications = useArgNotifications();

    const [referenceTablesProgressMonitor] = useEffectAsync(async (progressMonitor) => {
        const tables = await fetchReferenceTables(progressMonitor, notifications, messages.referenceTableFetchError);
        setReferenceTables(tables);
    }, []);

    const [updateReferenceTables] = useCallbackAsync(async (progressMonitor) => {
        const tables = await fetchReferenceTables(progressMonitor, notifications, messages.referenceTableUpdateError);
        setReferenceTables(tables);
    }, [notifications]);

    return {
        referenceTables,
        updateReferenceTables,
        referenceTablesProgressMonitor,
    };
};
