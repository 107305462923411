import { cloneElement, createContext, ReactElement, ReactNode, useContext, useMemo, useState } from 'react';

import { $yield } from '../utils/yield';

export interface ArgDrawerContainerProps {
    /**
     * The children component for the drawer container.
     */
    children: ReactNode;
}

export interface ArgDrawerContext {
    open: (drawer: ReactElement) => void;
    close: () => void;

    hasDrawerOpened: boolean;
}

interface MyDrawer {
    element: ReactElement;
    resolve?: (returnValue?: any) => void;
}

export const ArgDrawerContainerContext = createContext<ArgDrawerContext>({
    open: (namedrawer: ReactElement): void => {
    },
    close: (returnValue?: any): void => {
    },
    hasDrawerOpened: false,
});

/**
 * This component is designed to display an drawer container.
 *
 * @example
 * ```
 * <ArgDrawerContainer>
 *     <HomePage
 *         key='homepage'
 *         className={classNames(className)}
 *     />
 * </ArgDrawerContainer>
 * ```
 */
export function ArgDrawerContainer(props: ArgDrawerContainerProps) {
    const { children } = props;

    const [drawer, setDrawer] = useState<MyDrawer>();

    const addDrawer = useMemo<ArgDrawerContext>(() => {
        const open = (drawer: ReactElement) => {
            const newDrawer = cloneElement(drawer);

            setDrawer((prev) => {
                if (prev) {
                    throw new Error('Drawer is already opened');
                }

                return { element: newDrawer };
            });
        };
        const close = (returnValue?: any) => {
            setDrawer((prev) => {
                if (!prev) {
                    return prev;
                }

                const resolve = prev?.resolve;
                if (resolve) {
                    $yield(() => {
                        resolve(returnValue);
                    });
                }

                return undefined;
            });
        };

        const hasDrawerOpened = !!drawer;

        return { open, close, hasDrawerOpened };
    }, [drawer]);

    return (
        <ArgDrawerContainerContext.Provider value={addDrawer}>
            {children}
            {drawer && drawer.element}
        </ArgDrawerContainerContext.Provider>
    );
}

export function useArgDrawerContext(): ArgDrawerContext {
    const drawerContext = useContext(ArgDrawerContainerContext);

    return drawerContext;
}
