import { defineMessages } from 'react-intl';

import {
    ArgMessageRenderer,
    ArgRenderedText,
    ClassValue,
    renderText,
    useArgNotifications,
    useClassNames,
    useMemoAsync,
} from 'src/components/basic';
import { Environment } from '../../../utils/environment';
import { useArgonosModuleBranding } from 'src/components/application/argonos-modules-branding';
import { useCurrentArgonosModule } from 'src/components/application/argonos-current-module';
import { SettingsConnector } from '../../../settings/connectors/settings-connector';

import './application-title.less';

const messages = defineMessages({
    versionError: {
        id: 'common.top-bar.versionError',
        defaultMessage: 'Can not get application version',
    },
    versionPreview: {
        id: 'common.top-bar.versionPreview',
        defaultMessage: 'Preview {version}',
    },
});

interface ApplicationTitleProps {
    currentModuleId: string;
    className?: ClassValue;
    title?: ArgRenderedText | false;
}

export function ApplicationTitle(props: ApplicationTitleProps) {
    const { className, title } = props;

    const classNames = useClassNames('common-applications');
    const module = useCurrentArgonosModule();
    const [moduleBranding] = useArgonosModuleBranding(module.id);
    const notifications = useArgNotifications();

    const [version] = useMemoAsync(async(progressMonitor) => {
        try {
            const ret = SettingsConnector.getInstance().applicationVersion(module.apiURL, progressMonitor);

            return ret;
        } catch (error) {
            if (progressMonitor.isCancelled) {
                throw error;
            }

            notifications.snackError({ message: messages.versionError }, error as Error);
        }
    }, [notifications, module.apiURL]);

    const _title = (title !== false)
        ? renderText(title
            || moduleBranding?.brandingName
            || module.name
            || Environment.appName,
        )
        : undefined;

    return (
        <div className={classNames('&', className)}>
            {_title && (
                <div className={classNames('&-branding')}>
                    {renderText(_title)}
                </div>
            )}
            {version && version.version?.includes('preview') && (
                <div className={classNames('&-preview')}>
                    <ArgMessageRenderer
                        message={messages.versionPreview}
                        messageValues={{ version: version.version }}
                    />
                </div>
            )}
        </div>
    );
}
