import { defineMessages } from 'react-intl';

import { Workflow, WORKFLOW_DND_TYPE, WorkflowConnector } from '../../../framework/workflows';
import { downloadBlob } from '../../../utils/file';
import { WorkflowsEnvironmentContext } from '../environment-contexts/workflows-environment-context';
import { registerToolItemForMimeType, TargetEnvironmentContext } from '../../../components/basic';

const messages = defineMessages({
    exportSucceed: {
        id: 'settings.workflows.ExportSucceed',
        defaultMessage: 'Export succeed',
    },
    export: {
        id: 'settings.workflows.actions.Export',
        defaultMessage: 'Export',
    },
});


async function exportWorkflows(workflows: Workflow[], environmentContext: WorkflowsEnvironmentContext): Promise<void> {
    const ps = workflows.map(async (workflow: Workflow) => {
        const response = await WorkflowConnector.getInstance().exportWorkflowDefinition(workflow.id);

        downloadBlob(response.fileName || `${workflow.name}.json`, response.blob);

        environmentContext.notifications.snackInfo({ message: messages.exportSucceed });
    });

    await Promise.all(ps);
}

export function registerExportWorkflowsActions() {
    registerToolItemForMimeType<WorkflowsEnvironmentContext, Workflow>(WORKFLOW_DND_TYPE, {
        path: 'actions/export',
        label: messages.export,
        onClick: async (_, environmentContext: TargetEnvironmentContext<WorkflowsEnvironmentContext, Workflow>): Promise<void> => {
            const workflow = environmentContext.firstItem();
            if (!workflow) {
                return;
            }
            await exportWorkflows([workflow], environmentContext);
        },
    });
}
