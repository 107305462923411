import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import React, { CSSProperties, MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';
import { find, isFunction, isNil } from 'lodash';

import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { SelectionProvider } from '../arg-providers/selection-provider';
import { ARG_BYPASS_DND_DISABLER_CLASSNAME } from '../arg-dnd/disable-dnd-container';
import { ArgOrderedItem } from './arg-ordered-item';
import { DraggableItem } from 'src/hooks/use-reorder';
import { ArgCheckboxMinus } from '../arg-checkbox/arg-checkbox';
import { ArgTable2Column, ColumnOrder, getNewColumnOrder } from '../arg-table/arg-table2';
import { renderText } from '../utils/message-descriptor-formatters';
import { ArgTooltip2 } from '../arg-tooltip/arg-tooltip2';
import { ArgRenderFunction } from '../types';
import { ArgButton } from '../arg-button/arg-button';
import { ArgIcon } from '../arg-icon/arg-icon';
import { getSizePercent, getSizePixel } from './utils';

import './arg-ordered-list.less';

const CLASSNAME = 'arg-ordered-list';

const messages = defineMessages({});

export type ArgOrderedColumn<T> = Pick<
    ArgTable2Column<T>,
    'key' | 'title' | 'titleTooltip' | 'headerClassName' | 'render' | 'minWidth' | 'width' | 'maxWidth' | 'className' | 'getCellStringValue' | 'ellipsis' | 'defaultSortOrder' | 'sorter'
>

export interface ArgOrderedListProps<T> {
    rows: T[];
    getRowKey: (row: T) => string;
    columns: ArgOrderedColumn<T>[];
    selectionProvider: SelectionProvider<T>;
    selectionSource: string;
    dragType: string;
    onMoveItem: (dragIndex: number, hoverIndex: number) => void;
    className?: ClassValue;
    emptyRenderer?: ArgRenderFunction;
    search?: string;
    onRowClick?: (row: T, event: MouseEvent) => void;
    onRowDoubleClick?: (row: T, event: MouseEvent) => void;
    rowClassName?: (row: T, index: number) => ClassValue;
    renderRow?: (row: T, index: number, rowClassName: ClassValue | undefined, cells: ReactNode) => ReactNode;
}

export function ArgOrderedList<T>(props: ArgOrderedListProps<T>) {
    const {
        className,
        dragType,
        rows,
        getRowKey,
        columns,
        selectionSource,
        selectionProvider,
        onMoveItem,
        emptyRenderer,
        search,
        onRowClick,
        onRowDoubleClick,
        rowClassName,
        renderRow,
    } = props;

    const classNames = useClassNames(CLASSNAME);

    const sortClassName = classNames('&-header-cell-button-sort');

    const defaultColumnOrder: ColumnOrder | undefined = useMemo(() => {
        const column = find(columns, (column) => !isNil(column.defaultSortOrder) && !isNil(column.key));

        if (!column || !column.key) {
            return undefined;
        }

        const ret: ColumnOrder = {
            columnKey: column.key,
            columnDirection: column.defaultSortOrder === 'ascend' ? 'ascending' : 'descending',

        };

        return ret;
    }, [columns]);

    const [columnsOrder, setColumnsOrder] = useState<ColumnOrder | undefined>(defaultColumnOrder);

    const rowsData = useMemo<T[]>(() => {
        if (!columnsOrder) {
            return rows;
        }

        const column = columns.find((c) => c.key === columnsOrder.columnKey);

        if (!column) {
            return rows;
        }

        const { sorter } = column;

        if (!sorter) {
            return rows;
        }

        if (columnsOrder.columnDirection === 'descending') {
            rows.sort((t1, t2) => (-sorter(t1, t2)));
        } else {
            rows.sort(sorter);
        }

        return rows;
    }, [columns, columnsOrder, rows]);

    const selectionState = useMemo(() => {
        if (selectionProvider.count == rows.length) {
            return true;
        }

        if (selectionProvider.count > 0) {
            return 'minus';
        }

        return false;
    }, [rows.length, selectionProvider.count]);


    const handleColumnSortClick = useCallback((column: ArgTable2Column<T>) => {
        const { newColumnOrder } = getNewColumnOrder(column, columnsOrder);

        setColumnsOrder(newColumnOrder);
    }, [columnsOrder]);

    const handleOrderedItemsSelection = useCallback((selection: boolean | 'minus') => {
        if (selection === true) {
            selectionProvider.set(rows, selectionSource);

            return;
        }

        selectionProvider.clear(selectionSource);
    }, [rows, selectionProvider, selectionSource]);

    if (!rows.length && emptyRenderer) {
        return <div className={classNames('&', 'empty')}>
            {emptyRenderer()}
        </div>;
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className={classNames('&', ARG_BYPASS_DND_DISABLER_CLASSNAME, className)}>
                <div className={classNames('&-headers')}>
                    <div className={classNames('&-order')} />

                    <div className={classNames('&-checkbox')}>
                        <ArgCheckboxMinus
                            size='node'
                            value={selectionState}
                            onChange={handleOrderedItemsSelection}
                        />
                    </div>

                    <div className={classNames('&-columns')}>

                        {columns.map((column) => {
                            let title: ReactNode = null;
                            let titleLabel: ReactNode = null;

                            const columnTitle = column.title;

                            if (isFunction(columnTitle)) {
                                titleLabel = columnTitle(column as ArgTable2Column<T>);
                            } else {
                                titleLabel = renderText(columnTitle);
                            }

                            title = (
                                <div className={classNames('&-columns-cell-title', 'clamp-2')}>
                                    {titleLabel}
                                </div>
                            );

                            const titleTooltip = ((column.titleTooltip) === true) ? titleLabel : column.titleTooltip;

                            if (column.sorter) {
                                let sortIcon = 'icon-updown';

                                if (columnsOrder?.columnKey === column.key) {
                                    switch (columnsOrder?.columnDirection) {
                                        case 'ascending':
                                            sortIcon = 'icon-arrow-up';
                                            break;
                                        case 'descending':
                                            sortIcon = 'icon-arrow-down';
                                            break;
                                    }
                                }

                                title = <ArgButton
                                    type='ghost'
                                    size='small'
                                    right={<ArgIcon name={sortIcon} className={sortClassName} />}
                                    label={titleLabel}
                                    onClick={() => handleColumnSortClick(column as ArgTable2Column<T>)}
                                    className={classNames('&-columns-cell-button')}
                                    tooltip={titleTooltip}
                                    tooltipPlacement='bottomLeft'
                                />;
                            }

                            if (column.titleTooltip) {
                                title = (
                                    <ArgTooltip2
                                        title={titleTooltip}
                                        placement='bottomLeft'
                                    >
                                        {title}
                                    </ArgTooltip2>
                                );
                            }

                            const { width, minWidth, maxWidth } = column;

                            const titleStyle: CSSProperties = {};

                            if (getSizePixel(width) !== undefined) {
                                titleStyle.width = `${getSizePixel(width)}px`;
                            } else if (getSizePercent(width) !== undefined) {
                                titleStyle.flex = `${getSizePercent(width)} ${getSizePercent(width)}`;
                            }

                            return (
                                <div
                                    key={column.key}
                                    style={titleStyle}
                                    className={classNames('&-columns-cell', column.headerClassName)}
                                >
                                    {title}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={classNames('&-body')}>
                    {rowsData.map((row, index) => {
                        const itemKey: string = getRowKey(row);
                        const draggableItem: DraggableItem = { id: itemKey };

                        return (
                            <ArgOrderedItem<T>
                                key={itemKey}
                                orderedItem={row}
                                columns={columns}
                                orderedItemIndex={index}
                                dragType={dragType}
                                draggableItem={draggableItem}
                                onMoveItem={onMoveItem}
                                selectionProvider={selectionProvider}
                                selectionSource={selectionSource}
                                search={search}
                                onRowClick={onRowClick}
                                onRowDoubleClick={onRowDoubleClick}
                                rowClassName={rowClassName}
                                renderRow={renderRow}
                            />
                        );
                    })}
                </div>
            </div>
        </DndProvider>
    );
}
