import React, { ReactNode } from 'react';

import { ClassValue, useClassNames } from 'src/components/basic';

import './arg-divider.less';

const CLASSNAME = 'arg-divider';

export type DividerType = 'horizontal' | 'vertical';

export interface ArgDividerProps {
    /**
     * An HTML class for the container
     */
    className?: ClassValue;

    /**
     * The Content to be rendered inside the divider
     */
    children?: ReactNode;

    /**
     * The type of the divider.
     */
    type?: DividerType;
}

/**
 * This component is designed to create dividers or separators in a layout. It supports both horizontal and vertical orientations.
 *
 * @example
 * ```
 * <ArgDivider />
 * ```
 */
export function ArgDivider(props: ArgDividerProps) {
    const {
        className,
        children,
        type = 'horizontal',
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const cls = {
        '&-vertical': type === 'vertical',
        '&-horizontal': type === 'horizontal',
    };

    return (
        <div
            className={classNames('&', cls, className)}
            data-testid='arg-divider'
            role='separator'
        >
            {children}
        </div>
    );
}
