import { useCallback } from 'react';

import { ClassValue } from 'src/components/basic';
import { WorkflowProperty } from './workflow-property';
import { Workflow, WorkflowId } from '../model/workflows';
import { DateByUser } from '../../../components/common/date-by-user';


interface WorkflowPublishProps {
    className?: ClassValue;
    workflowId: WorkflowId;
    search?: string;
}

export function WorkflowPublish(props: WorkflowPublishProps) {
    const {
        className,
        workflowId,
        search,
    } = props;


    const render = useCallback((workflow: Workflow, search?: string) => {
        if (!workflow.lastUpdatedBy) {
            return null;
        }

        return <DateByUser
            date={workflow.lastPublishedDate}
            search={search || undefined}
            relative={true}
            user={workflow.lastPublishedBy}
        />;
    }, []);

    return (
        <WorkflowProperty
            className={className}
            workflowId={workflowId}
            render={render}
            search={search}
        />
    );
}
