import { Fragment, ReactNode, useCallback, useState } from 'react';
import { isEmpty, isFunction, map, omit } from 'lodash';

import { useToolNodes } from '../arg-tools/use-tool-nodes';
import { ArgToolbarItem } from './arg-toolbar-item';
import { ArgToolbarCombo } from './arg-toolbar-combo';
import { ArgToolbarDivider } from './arg-toolbar-divider';
import { ArgMenu } from '../arg-menu/arg-menu';
import { ArgRenderedText } from '../types';
import { ToolContext, ToolTreeContext, ToolTreeNode } from '../arg-tools/tool-context';
import { ClassValue, useClassNames } from '../arg-hooks/use-classNames';
import { countVisibleChildren } from '../arg-tools/utils';
import { ButtonClickEvent } from '../arg-button/arg-button';
import {
    getToolBadges,
    getToolIcon,
    getToolLabel,
    getToolNavigateTo,
    getToolTooltip,
    isToolDisabled,
    Tool,
} from '../arg-tools/tool';
import { useRenderToolMenuFromNodes } from '../arg-tools/arg-tool-menu';
import { preventContextMenu } from '../../../utils/prevent-context-menu';
import { ArgToolbarActionDropdown } from './arg-toolbar-action-dropdown';
import { ArgToolBarLabel, ArgToolBarLabelRenderContext } from './arg-toolbar-label';
import { ArgPopoverRenderer } from '../arg-popover/types';
import { ArgBadge } from '../arg-badge/arg-badge';

import './arg-toolbar.less';

export interface ArgToolbarProps<T> {
    /**
     * The prefix for the toolbar's item filter.
     */
    prefix?: string;
    /**
     * An HTML class for the container
     */
    className?: ClassValue;
    /**
     * The context for the toolbar.
     *
     * Permits to define a list of items to display.
     */
    toolbarContext: ToolContext<T>;
    /**
     * A boolean to explicitly render the toolbar disabled
     */
    disabled?: boolean;
    /**
     * The selected panel
     */
    selectedPanel?: string;
    /**
     * A callback when selecting a panel
     */
    onPanelSelection?: (path: string) => void;
    /**
     * The selected editor
     */
    selectedEditor?: string;
    /**
     * A callback when selecting an editor
     */
    onEditorSelection?: (path: string) => void;
    /**
     * A boolean to explicitly hide an editor
     */
    hideEditor?: boolean;
    /**
     * The environment context for the toolbar
     */
    environmentContext: T;
}

/**
 * This component is designed to display a toolbar.
 *
 * @example
 * ```
 * <ArgToolbar<LogsHomePageEnvironmentContext>
 *     toolbarContext={buttonsContext}
 *     environmentContext={logsHomePageEnvironmentContext}
 * />
 * ```
 */
export function ArgToolbar<T = undefined>(props: ArgToolbarProps<T>) {
    const {
        prefix,
        className,
        toolbarContext,
        disabled,
        selectedPanel,
        onPanelSelection,
        selectedEditor,
        onEditorSelection,
        hideEditor,
        environmentContext,
    } = props;

    const [toolbarNodes, toolTreeContext] = useToolNodes(toolbarContext, environmentContext, prefix, hideEditor);
    const classNames = useClassNames('arg-toolbar');

    let shouldRenderDivider = false;
    const renderNodes = (nodes: ToolTreeNode<T>[]): ReactNode => {
        return map(nodes, (node: ToolTreeNode<T>) => {
            let popover: ArgRenderedText;
            if (node.type === 'group') {
                const visibleChildren = countVisibleChildren(node, environmentContext);
                if (!visibleChildren?.length) {
                    return;
                }

                const addDivider = visibleChildren?.length > 0 && shouldRenderDivider;
                shouldRenderDivider = true;

                return (
                    <Fragment key={node.path}>
                        {addDivider && (
                            <ArgToolbarDivider
                                key='divider'
                                className={classNames('&-divider')}
                                data-tool-path={node.path}
                                data-tool-order={node.order}
                            />
                        )}

                        {renderNodes(visibleChildren)}
                    </Fragment>
                );
            } else if (node.type === 'panel') {
                const oldOnClick = node.onClick;
                node = {
                    ...node,
                    onClick(tool: Tool<T>, environmentContext: T, event?: ButtonClickEvent) {
                        onPanelSelection?.(node.path);

                        oldOnClick?.(tool, environmentContext, event);
                    },
                    selected: (node.path === selectedPanel),
                };
            } else if (node.type === 'editor') {
                const oldOnClick = node.onClick;
                node = {
                    ...node,
                    onClick(tool: Tool<T>, environmentContext: T, event?: ButtonClickEvent) {
                        onEditorSelection?.(node.path);

                        oldOnClick?.(tool, environmentContext, event);
                    },

                    selected: (node.path === selectedEditor),
                };
            } else if (node.type === 'combo') {
                const tooltip = getToolTooltip(node, environmentContext);
                const toolDisabled = isToolDisabled(node, environmentContext);

                return <ArgToolbarCombo<T>
                    {...node}
                    disabled={disabled || toolDisabled}
                    tooltip={tooltip}
                    key={node.path}
                    className={classNames('&-item', node.className)}
                    environmentContext={environmentContext}
                    data-tool-path={node.path}
                    data-tool-order={node.order}
                />;
            } else if (node.type === 'actionDropdown') {
                const tooltip = getToolTooltip(node, environmentContext);
                const toolDisabled = isToolDisabled(node, environmentContext);

                return <ArgToolbarActionDropdown<T>
                    {...node}
                    disabled={disabled || toolDisabled}
                    tooltip={tooltip}
                    key={node.path}
                    className={classNames('&-item', node.className)}
                    environmentContext={environmentContext}
                    data-tool-path={node.path}
                    data-tool-order={node.order}
                />;
            } else if (node.type === 'label') {
                const tooltip = getToolTooltip(node, environmentContext);
                const label = getToolLabel(node, environmentContext);
                const visible = isFunction(node.visible) ? node.visible(environmentContext) : node.visible;
                const customRender = node.customRender
                    ? (props: Tool<T>, context: ArgToolBarLabelRenderContext, popoverRender?: ArgPopoverRenderer) => {
                        return node.customRender?.(props, environmentContext, context, popoverRender);
                    }
                    : undefined;

                return <ArgToolBarLabel<T>
                    key={node.path}
                    label={label}
                    tooltip={tooltip}
                    testid={node.testid}
                    customRender={customRender}
                    visible={visible}
                    environmentContext={environmentContext}
                    className={classNames(node.className)}
                />;
            } else if (node.type === 'separator') {
                shouldRenderDivider = false;

                return <ArgToolbarDivider
                    className={classNames('&-divider')}
                    key={`divider-${node.path}`}
                    data-tool-path={node.path}
                    data-tool-order={node.order}
                />;
            }

            if (node.type === 'button' || node.type === 'editor' || node.type === 'custom') {
                if (node.children) {
                    popover = <ArgToolbarItemMenu<T>
                        node={node}
                        toolTreeContext={toolTreeContext}
                        environmentContext={environmentContext}
                        data-tool-path={node.path}
                        data-tool-order={node.order}
                    />;
                }
            }

            shouldRenderDivider = true;
            const _restProps = omit(node, 'children');
            const tooltip = getToolTooltip(node, environmentContext);
            const navigateTo = getToolNavigateTo(node, environmentContext);
            const toolDisabled = isToolDisabled(node, environmentContext);
            const badges = getToolBadges(node, environmentContext);

            const triggerPopover = popover ? 'contextMenu' : undefined;

            const testid = node.testid && !isEmpty(node.testid) ? `arg-toolbar-item ${node.testid}` : 'arg-toolbar-item';

            return (
                <ArgToolbarItem<T>
                    {..._restProps}
                    testid={testid}
                    popover={popover}
                    popoverPlacement='bottomLeft'
                    popoverTrigger={triggerPopover}
                    tooltip={tooltip}
                    navigateTo={navigateTo}
                    disabled={disabled || toolDisabled}
                    key={node.path}
                    icon={getToolIcon(node, environmentContext)}
                    iconBadges={badges ? <ArgBadge {...badges} /> : undefined}
                    label={getToolLabel(node, environmentContext)}
                    className={classNames('&-item', node.className)}
                    data-tool-path={node.path}
                    data-tool-order={node.order}
                    environmentContext={environmentContext}
                />
            );
        });
    };

    const renderedNodes = renderNodes(toolbarNodes);

    const cls = {
        disabled,
    };

    return (
        <div
            className={classNames('&', (prefix) ? `&-${prefix}` : undefined, className, cls)}
            data-tool-context={toolbarContext.id}
        >
            {renderedNodes}
        </div>
    );
}

interface ArgToolbarItemMenuProps<T> {
    className?: ClassValue;
    node: ToolTreeNode<T>;
    toolTreeContext: ToolTreeContext<T>;
    environmentContext: T;
    getPopupContainer?: (node: HTMLElement) => HTMLElement;
}

export function ArgToolbarItemMenu<T>(props: ArgToolbarItemMenuProps<T>) {
    const {
        className,
        node,
        toolTreeContext,
        getPopupContainer,
        environmentContext,
    } = props;

    const [activeKey, setActiveKey] = useState<string>();

    const onCloseMenu = useCallback(() => {

    }, []);

    const renderedNodes = useRenderToolMenuFromNodes<T>(
        node.children!,
        toolTreeContext,
        environmentContext,
        onCloseMenu,
        setActiveKey,
    );

    return (
        <ArgMenu
            className={className}
            openKeys={activeKey ? [activeKey] : undefined}
            onContextMenu={preventContextMenu}
            getPopupContainer={getPopupContainer}
            data-tool-path={node.path}
            data-tool-order={node.order}
        >
            {renderedNodes}
        </ArgMenu>
    );
}
