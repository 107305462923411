import { defineMessages } from 'react-intl';

import {
    SETTINGS_REFERENCE_MIME_TYPE,
    SettingsReferenceEnvironmentContext,
} from '../environment-contexts/reference-environment-context';
import { Reference } from '../../models/references';
import { registerToolItemForMimeType } from 'src/components/basic';
import { SettingsPermissions } from '../../permissions/permissions';
import { ManagerReferenceModal } from '../components/manager-reference-modal';

const FORCE_REFERENCE = localStorage.getItem('FORCE_REFERENTIEL') === 'true';

const messages = defineMessages({
    editReference: {
        id: 'settings.actions.edit-reference',
        defaultMessage: 'Edit',
    },
});

registerToolItemForMimeType<SettingsReferenceEnvironmentContext, Reference>(SETTINGS_REFERENCE_MIME_TYPE, {
    path: 'actions/edit-reference',
    order: 310,
    type: 'button',
    label: messages.editReference,
    visible: (environmentContext) => {
        const result = environmentContext.hasAnyPermissions<SettingsPermissions>('admin.reference.edition');

        return result || FORCE_REFERENCE;
    },

    onClick: async (tool, environmentContext) => {
        const firstItem = await environmentContext.firstItem();

        if (!firstItem) {
            return;
        }

        const { modalContext } = environmentContext;

        const updateReference = () => {

        };

        modalContext.open('update-reference', (
            <ManagerReferenceModal
                initialReferenceItems={firstItem.items}
                initialReferenceName={firstItem.name}
                onSubmit={() => updateReference()}
                onClose={() => modalContext.close('update-reference')}

            />
        ));
    },
});

