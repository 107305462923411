import { defineMessages } from 'react-intl';

import {
    WORKFLOWS_VIEW_RIGHT_CONTEXT,
    WorkflowsEnvironmentContext,
} from '../environment-contexts/workflows-environment-context';
import { registerToolItem } from '../../../components/basic';


const messages = defineMessages({
    importButton: {
        id: 'settings.workflows.list.ImportButton',
        defaultMessage: 'Import',
    },
});

export function registerImportWorkflowsAction() {
    registerToolItem<WorkflowsEnvironmentContext>(WORKFLOWS_VIEW_RIGHT_CONTEXT, {
        path: 'import',
        order: 200,
        type: 'button',
        buttonType: 'secondary',
        icon: 'icon-import',
        label: messages.importButton,
        onClick: async (_, workflowEnvironmentContext: WorkflowsEnvironmentContext) => {
            //await createWorkflowAction(workflowEnvironmentContext);
        },
    });
}
