import { ResourceId } from '../../../model/resource';
import { Point } from '../../common/previews/tags/canvas-utils';
import { EdgeId } from '../../model/edge';
import { UniversePropertyName, UniversePropertyType, UniverseType } from '../../model/universe';
import { Vertex, VertexId } from '../../model/vertex';

export const TAG_DND_TYPE = 'application/arg-tag';

export type TagId = string;
export type TagObjectId = string;

export type TagObjectIds = {
    linkId: EdgeId;
    id: TagObjectId;
};

export enum TagType {
    Text,
    Image,
    Video,
    Audio,
}

export const PdfTagTypes = [
    TagType.Text,
    TagType.Image,
];

export interface TagProperties {
    type: TagType;
    text?: string;
    image?: string;
}

export interface TagObject extends TagObjectIds {
    object: Vertex;
    property?: UniversePropertyType;
    // Keep reference to parent tag to be able to access properties
    parentTag: Tag;
}

export interface Tag<SpecificTagProperties extends TagProperties | undefined = TagProperties> {
    id?: TagId;
    associatedObjects: TagObject[];
    properties?: SpecificTagProperties;
    universe?: UniverseType;
}

export interface TagLink {
    tagId: TagId;
    linkId: EdgeId;
    vertexId: VertexId;
    properties: TagProperties;
    vertexProperty: UniversePropertyType;
    imageResourceId?: ResourceId;
    identifiedPropertyName?: UniversePropertyName;
}

export interface Rectangle {
    origin: Point;
    destination: Point;
}

export interface PositionTagProperties extends TagProperties {
    position: Rectangle;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface PdfTagProperties<T = {}> extends PositionTagProperties {
    position: Rectangle & { page: number } & T;
}

export type ImageTagProperties = PositionTagProperties;

export interface VideoTagProperties extends PositionTagProperties {
    position: Rectangle & { time: number };
}

export interface StartEndPosition {
    start: number;
    end: number;
}
export interface AudioTagProperties extends TagProperties {
    position: StartEndPosition;
}

export interface PdfTagTextPosition {
    startOffset: number;
    endOffset: number;
}

export enum SortingType {
    Tag = 'Tag',
}
