import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';

import {
    ArgButton,
    ArgSize,
    ArgToolMenu,
    ClassValue,
    OffsetValue,
    ProgressMonitor,
    SelectionProvider,
    SubProgressMonitor,
    TargetEnvironmentContext,
    ThreeDotsLoading,
    ToolContext,
    useClassNames,
    useCreateTargetEnvironmentContext,
    useMemoAsync,
    useSharedSelectionProvider,
    useToolNodes,
} from 'src/components/basic/index';

import './arg-ellipsis-menu-button.less';


const CLASSNAME = 'arg-ellipsis-menu-button';

const POPOVER_OFFSET: OffsetValue = { mainAxis: 4, crossAxis: 2 };

const EMPTY_ITEMS = {};

interface ArgEllipsisAsyncMenuButtonProps<T, K> {
    className?: ClassValue;

    toolContext: ToolContext<TargetEnvironmentContext<T, K>>;
    environmentContext: T;

    selectionProvider?: SelectionProvider<string>;
    getAsyncItemByKey: (environmentContext: T, key: string, progressMonitor: ProgressMonitor) => Promise<K | undefined>;

    selectedKey?: string;
    selectedMimeTypes?: string | string[];

    buttonSize?: ArgSize;
}

export function ArgEllipsisAsyncMenuButton<T = undefined, K = any>(props: ArgEllipsisAsyncMenuButtonProps<T, K>) {
    const {
        buttonSize,
        className,
        toolContext,
        environmentContext,
        selectionProvider,
        selectedKey,
        getAsyncItemByKey,
        selectedMimeTypes,
    } = props;

    const [visible, setVisible] = useState<boolean>(false);


    const classNames = useClassNames(CLASSNAME);

    const unmountedRef = useRef<boolean>(false);
    useEffect(() => {
        return () => {
            unmountedRef.current = true;
        };
    }, []);

    // We clone the ToolContext because the button is used in every row and it is not possible to do that
    // (share a toolcontext and using  useToolNodes hook)
    const targetToolContext = useMemo<ToolContext<TargetEnvironmentContext<T, K>>>(() => {
        const result = new ToolContext<TargetEnvironmentContext<T, K>>(toolContext);

        return result;
    }, [toolContext]);


    const [items, loadingItems, errorItems] = useMemoAsync(async (progressMonitor: ProgressMonitor): Promise<Record<string, K>> => {
        if (!visible) {
            return EMPTY_ITEMS;
        }

        let keys: string[];

        if (selectionProvider) {
            keys = selectionProvider.list();
        } else if (selectedKey) {
            keys = [selectedKey];
        } else {
            keys = [];
        }

        if (isEmpty(keys)) {
            return EMPTY_ITEMS;
        }

        const ps = keys.map(async (key): Promise<K | undefined> => {
            const sub = new SubProgressMonitor(progressMonitor, 1);

            const result = await getAsyncItemByKey(environmentContext, key, sub);

            return result;
        });

        const items = await Promise.all(ps);

        const result = items.reduce((acc: Record<string, K>, item: K | undefined, index) => {
            if (!item) {
                return acc;
            }
            const key = keys[index];
            acc[key] = item;

            return acc;
        }, {} as Record<string, K>);

        return result;
    }, [visible, selectionProvider?.stateId, environmentContext, getAsyncItemByKey, selectedKey, selectionProvider]);

    const getItemByKey = useCallback((environmentContext: T, key: string): K | undefined => {
        if (!items) {
            return undefined;
        }

        const result = items[key];

        return result;
    }, [items]);

    const asyncSelectionProvider = useSharedSelectionProvider<K>(() => {
        const result = new SelectionProvider<K>(`async-selection-provider:${selectionProvider ? selectionProvider.id : '--none--'}`, (item: K): string => {
            throw new Error('Not supported !');
        });

        if (selectionProvider) {
            result.add(selectionProvider.list(), 'init');
        }

        return result;
    });

    const asyncTargetEnvironmentContext = useCreateTargetEnvironmentContext<T, K>(
        environmentContext,
        {
            selectionProvider: (selectionProvider && items !== undefined) ? asyncSelectionProvider : undefined,
            getItemByKey,
            selectedKey,
            selectedItem: (selectedKey !== undefined) ? getItemByKey(environmentContext, selectedKey) : undefined,
        },
        selectedMimeTypes,
    );

    const handleCloseMenu = useCallback(() => {
        if (unmountedRef.current) {
            return;
        }

        setVisible(false);
    }, []);

    const [treeNodes] = useToolNodes(targetToolContext, asyncTargetEnvironmentContext);

    const actionsMenu = useCallback(() => {
        if (items === undefined || loadingItems?.isRunning) {
            return <div>
                <ThreeDotsLoading />
            </div>;
        }

        return <ArgToolMenu<TargetEnvironmentContext<T, K>>
            menuContext={targetToolContext}
            onCloseMenu={handleCloseMenu}
            environmentContext={asyncTargetEnvironmentContext}
        />;
    }, [handleCloseMenu, asyncTargetEnvironmentContext, targetToolContext, items, loadingItems]);

    if (isEmpty(treeNodes)) {
        return null;
    }

    return (
        <ArgButton
            size={buttonSize}
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={visible}
            popoverPlacement='bottomLeft'
            onPopoverVisibleChange={setVisible}
            className={classNames('&', className)}
            popoverClassName={classNames('&-popover')}
            popoverOffset={POPOVER_OFFSET}
        />
    );
}
