import { useCallback } from 'react';

import { ClassValue } from 'src/components/basic';
import { WorkflowProperty } from './workflow-property';
import { Workflow, WorkflowId } from '../model/workflows';
import { getWorkflowTargetByType } from '../registry/workflows-registry';
import { WorkflowTargetType } from './workflow-target-type';


interface WorkflowTargetCellProps {
    className?: ClassValue;
    workflowId: WorkflowId;
    search?: string;
}

export function WorkflowTargetCell(props: WorkflowTargetCellProps) {
    const {
        className,
        workflowId,
        search,
    } = props;

    const render = useCallback((workflow: Workflow) => {
        const type = workflow.targetType;
        if (!type) {
            return '? type';
        }

        const workflowTarget = getWorkflowTargetByType(type);
        if (!workflowTarget) {
            return '?';
        }

        return <WorkflowTargetType
            workflowTarget={workflowTarget}
            showApplication={false}
        />;
    }, []);

    return (
        <WorkflowProperty
            className={className}
            workflowId={workflowId}
            render={render}
            search={search}
        />
    );
}
